import React, { useState } from "react";
import Button from "../../../components/Button";
import axios from "axios";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal2 from "../../../components/modal/Modal2";
import Select from "../../../components/Select";
import { APP, config, notify } from "../../../utils/constant";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";

const EditLocation = ({
  open,
  handleUpdateLocation,
  locationToEdit,
  setLocationToEdit,
  getSingleQr,
  getQrCodes,
  id,
}) => {
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [provinces, setProvinces] = useState([]);
  const [provinceId, setProvinceId] = useState();
  const [districts, setDistricts] = useState([]);
  const [districtId, setDistrictId] = useState();
  const [sectors, setSectors] = useState([]);
  const [sectorId, setSectorId] = useState();
  const [cells, setCells] = useState([]);
  const [cellId, setCellId] = useState();
  const [villages, setVillages] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    axios
      .put(
        APP.API_MAIN_URL +
          `/admin/qr-codes/${id}/locations/${locationToEdit.id}`,
        locationToEdit,
        config(token)
      )
      .then((res) => {
        setLoading(false);
        getSingleQr();
        console.log(res?.data);
        handleUpdateLocation(!open);
        getQrCodes();
        getSingleQr();
        reset();
        notify("success", res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        notify("error", "Failed to updated!");
      });
  };

  const getProvinces = () => {
    try {
      axios
        .get(
          "https://api.account.konvey.rw/api/utils/location/provinces",
          config
        )
        .then((res) => {
          console.log(res?.data?.data, "provinces");
          setProvinces(res?.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // api to fetch districts

  const getDistricts = () => {
    try {
      axios
        .get(
          `https://api.account.konvey.rw/api/utils/location/province/${provinceId}/districts`,
          config
        )
        .then((res) => {
          setDistricts(res?.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // api to fetch sectors

  const getSectors = () => {
    try {
      axios
        .get(
          `https://api.account.konvey.rw/api/utils/location/district/${districtId}/sectors`,
          config
        )
        .then((res) => {
          setSectors(res?.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // api to fetch cells

  const getCells = () => {
    try {
      axios
        .get(
          `https://api.account.konvey.rw/api/utils/location/sector/${sectorId}/cells`,
          config
        )
        .then((res) => {
          setCells(res?.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // api to fetch villages

  const getVillages = () => {
    try {
      axios
        .get(
          `https://api.account.konvey.rw/api/utils/location/cell/${cellId}/villages`,
          config
        )
        .then((res) => {
          setVillages(res?.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProvinces();
    getDistricts();
    getSectors();
    getCells();
    getVillages();
  }, [provinceId, districtId, sectorId, cellId]);

  // useForm methods
  const {
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
    control,
  } = useForm();
  console.log("What's the sure", locationToEdit);
  return (
    <div>
      <Modal2
        isOpen={open}
        onClose={() => {
          handleUpdateLocation(!open);
          reset();
        }}
        Style={"w-[40%] m-4 flex m-auto"}
      >
        <Modal2.Header>
          <div className="font-bold">Edit location</div>
        </Modal2.Header>
        <Modal2.Body>
          <form action="">
            <div className="flex flex-col px-2">
              <Select
                label="Province"
                customClass="w-full mb-2"
                isRequired={true}
                placeholder={"province"}
                options={provinces.map((province) => {
                  return { value: province.id, label: province.name };
                })}
                register={{
                  control,
                  name: `province_id`,
                }}
                handleChange={(e) => {
                  clearErrors("province_id");
                  setProvinceId(e.value);
                  setLocationToEdit((prevData) => ({
                    ...prevData,
                    province_id: e.value,
                    province: { id: e.value, name: e.label },
                  }));
                }}
                value={{
                  value: locationToEdit?.province?.id,
                  label: locationToEdit?.province?.name,
                }}
                error={errors[`province_id`]?.message}
              />
              <Select
                label="District"
                customClass="w-full"
                isLoading={false}
                placeholder={"district"}
                options={districts.map((district) => {
                  return { value: district.id, label: district.name };
                })}
                register={{
                  control,
                  name: `district_id`,
                }}
                handleChange={(e) => {
                  clearErrors("district_id");
                  setDistrictId(e.value);
                  setLocationToEdit((prevData) => ({
                    ...prevData,
                    district_id: e.value,
                    district: {
                      id: e.value,
                      province_id: provinceId,
                      name: e.label,
                    },
                  }));
                }}
                value={{
                  value: locationToEdit?.district?.id,
                  label: locationToEdit?.district?.name,
                }}
              />
              <Select
                label="Sector"
                customClass="w-full mb-2"
                isLoading={false}
                placeholder={"sector"}
                options={sectors.map((sector) => {
                  return { value: sector.id, label: sector.name };
                })}
                register={{
                  control,
                  name: `sector_id`,
                }}
                defaultValue={""}
                handleChange={(e) => {
                  clearErrors("sector");
                  setSectorId(e.value);
                  setLocationToEdit((prevData) => ({
                    ...prevData,
                    sector_id: e.value,
                    sector: {
                      id: e.value,
                      province_id: provinceId,
                      district_id: districtId,
                      name: e.label,
                    },
                  }));
                }}
                value={{
                  value: locationToEdit?.sector?.id,
                  label: locationToEdit?.sector?.name,
                }}
              />
              <Select
                label="Cell"
                customClass="w-full mb-2"
                isLoading={false}
                placeholder={"cell"}
                options={cells.map((cell) => {
                  return { value: cell.id, label: cell.name };
                })}
                register={{
                  control,
                  name: `cell_id`,
                }}
                defaultValue={""}
                handleChange={(e) => {
                  clearErrors("cell");
                  setCellId(e.value);
                  setLocationToEdit((prevData) => ({
                    ...prevData,
                    cell_id: e.value,
                    cell: {
                      id: e.value,
                      province_id: provinceId,
                      district_id: districtId,
                      sector_id: sectorId,
                      name: e.label,
                    },
                  }));
                }}
                value={{
                  value: locationToEdit?.cell?.id,
                  label: locationToEdit?.cell?.name,
                }}
              />
              <Select
                label="Village"
                customClass="w-full mb-2 "
                isLoading={false}
                placeholder={"Village"}
                options={villages.map((village) => {
                  return { value: village.id, label: village.name };
                })}
                register={{
                  control,
                  name: `village_id`,
                }}
                defaultValue={""}
                handleChange={(e) => {
                  clearErrors("village");
                  setLocationToEdit((prevData) => ({
                    ...prevData,
                    village_id: e.value,
                    village: {
                      id: e.value,
                      province_id: provinceId,
                      district_id: districtId,
                      cell_id: cellId,
                      name: e.label,
                    },
                  }));
                }}
                value={{
                  value: locationToEdit?.village?.id,
                  label: locationToEdit?.village?.name,
                }}
              />
            </div>
          </form>
        </Modal2.Body>
        <div className="flex justify-end items-center space-x-6 mx-5 mb-5 ">
          <Button
            type="submit"
            content={"Cancel"}
            loading={false}
            // btnColor="secondary"
            onClick={() => {
              handleUpdateLocation(!open);
            }}
            Style={"text-primary bg-white border h-[40px] mt-2 ml-0"}
          />

          <Button
            type="submit"
            content="Update"
            loading={loading}
            onClick={handleSubmit(onSubmit)}
            btnColor="primary"
            Style={"text-white h-[40px] mt-2 ml-0 border"}
          />
        </div>
      </Modal2>
    </div>
  );
};

export default EditLocation;
