import React, { useEffect } from "react";
import Input from "../../../../../components/Input";
import Select from "../../../../../components/Select";
import CircularLoader from "../../../../../components/CircularLoader";
import { RiErrorWarningLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../../../../redux/actions/articles";
import MultiSelect from "../../../../../components/MultiSelect";

function AddEnglishCategory({
  clearErrors,
  register,
  errors,
  control,
  name,
  setNames,
  translatedEn,
  lastMod,
  setLastMod,
  translationLoading,
  isTranslated,
  typeOptions,
}) {
  const { articleCategories } = useSelector((state) => state.articleCategory);
  const { plans, categories } = useSelector((state) => state.subscription);
  const dispatch = useDispatch();
  const handleAddName = (e) => {
    const newName = e.target.value;
    setNames({ ...name, name: newName });
    setLastMod((prev) => ({
      ...prev,
      name: { ...prev.name, en: newName },
    }));
  };
  const handleParent = (e) => {
    setLastMod((prev) => ({
      ...prev,
      parent: e,
    }));
  };
  const handleCategory = (e) => {
    setLastMod((prev) => ({ ...prev, category: e }));
  };
  const handlePlan = (array) => {
    setLastMod((prev) => ({ ...prev, subscription_plans: array }));
  };
  const handleAddDesc = (e) => {
    const newDesc = e.target.value;
    setNames({ ...name, description: newDesc });

    setLastMod((prev) => ({
      ...prev,
      description: { ...prev.description, en: newDesc },
    }));
  };
  useEffect(() => {
    dispatch(getCategory());
  }, []);

  return (
    <div>
      {translationLoading ? (
        <div className="flex items-center w-full mt-4">
          <div className="flex gap-2 items-center m-auto">
            <CircularLoader />
            <p>Translating...</p>
          </div>
        </div>
      ) : null}
      {isTranslated ? (
        <div className="flex gap-2 mt-6 mb-2">
          <RiErrorWarningLine size={30} color="blue" />
          <h1>Check the translated texts carefully</h1>
        </div>
      ) : (
        ""
      )}
      <Input
        labelText={"Name"}
        labelFor={"name"}
        name={`name`}
        type={"text"}
        placeholder={"Enter name of category"}
        isRequired={true}
        customClass="w-full mb-2 w-[75%] ml-1"
        onChange={(e) => {
          clearErrors("name");
          handleAddName(e);
        }}
        value={lastMod?.name["en"]}
        register={register}
        errors={errors}
      />
      <Select
        label={"Parent category"}
        labelFor={"parent"}
        className="w-full py-2 first-letter"
        isLoading={false}
        placeholder={"Select parent"}
        onChange={(e) => {
          handleParent(e);
        }}
        value={{
          label: lastMod?.parent ? lastMod?.parent?.label : "1",
          value: lastMod.parent ? lastMod?.parent?.value : 1,
        }}
        defaultValue={" "}
        options={articleCategories.map((cat) => {
          return { value: cat?.id, label: cat?.name["en"] };
        })}
      />
      <Select
        label="Content type"
        customClass="w-full mb-2 text-[18px]"
        isRequired={false}
        placeholder={"Content type"}
        options={typeOptions}
        register={
          {
            control,
            name: `contentType`,
            rules: { required: "contentType is required" },
          }
          // register
        }
        defaultValue={lastMod?.content_type}
        handleChange={(e) => {
          setLastMod((prev) => ({
            ...prev,
            content_type: e,
          }));
        }}
        error={errors[`contentType`]?.message}
      />
      {/* <Select
        label={"Subscription category"}
        labelFor={"category"}
        className="w-full py-2 first-letter"
        isLoading={false}
        placeholder={"Select category"}
        onChange={(e) => {
          handleCategory(e);
          console.log(e);
        }}
        options={categories.map((cat) => {
          return {
            value: cat.id,
            label:
              cat.name[
                cat.languages.find((lan) => lan?.is_default === 1)?.language
                  ?.code
                  ? cat.languages.find((lan) => lan?.is_default === 1)?.language
                      ?.code
                  : "rw"
              ],
          };
        })}
        defaultValue={lastMod.category}
      /> */}
      {/* <MultiSelect
        label="Choose plan"
        customClass={"w-full mb-2 "}
        className="w-full mb-2"
        isLoading={false}
        placeholder={"Select plan"}
        handleChange={(e) => {
          handlePlan(e.map((e) => e));
        }}
        options={plans.map((plan) => {
          return {
            value: plan.id,
            label: plan.name.en,
          };
        })}
        defaultValue={lastMod.subscription_plans}
        loading={false}
        register={{
          control,
          name: "plan",
          rules: { required: "plan are required" },
        }}
        error={errors["languages"]?.message}
      /> */}
    </div>
  );
}

export default AddEnglishCategory;
