import {
  SET_CATEGORY,
  SET_PLAN,
  SET_BENEFIT,
  RESET_SUBSCRIPTIONS,
  SET_IS_LOADING_CATEGORY,
  SET_IS_LOADING_PLAN,
  SET_IS_LOADING_BENEFIT,
  SET_SELECTED_LANGUAGE,
  SET_SELECTED_CATEGORY_LANGUAGE,
} from "../actions/subscription";

const initialState = {
  categories: [],
  plans: [],
  benefits: [],
  isCategoryLoading: false,
  isPlanLoading: false,
  isBenefitLoading: false,
  selectedLangauge: [],
  selectedCatLanguage: [],
};

const subscription = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORY:
      return { ...state, categories: action.payload };
    case SET_PLAN:
      return { ...state, plans: action.payload };
    case SET_BENEFIT:
      return { ...state, benefits: action.payload };
    case SET_SELECTED_LANGUAGE:
      return { ...state, selectedLangauge: action.payload };
    case SET_SELECTED_CATEGORY_LANGUAGE:
      return { ...state, selectedCatLanguage: action.payload };
    case SET_IS_LOADING_CATEGORY:
      return { ...state, isCategoryLoading: action.payload };
    case SET_IS_LOADING_PLAN:
      return { ...state, isPlanLoading: action.payload };
    case SET_IS_LOADING_BENEFIT:
      return { ...state, isBenefitLoading: action.payload };

    case RESET_SUBSCRIPTIONS:
      return initialState;

    default:
      return state;
  }
};

export default subscription;
