import React, { useState, Fragment } from 'react';
import Table from '../../../../components/tableComponent/Table';
import { skeletonData, skeletonColumns } from '../../../../seeds/Data';
import SkeletonTable from '../../../../components/SkeletonTable';
import { FiMoreVertical } from 'react-icons/fi';
import { Popover, Transition } from '@headlessui/react';
import PaymentWalletDeposit from './PaymentWalletDeposit';
import PaymentWalletTransaction from './PaymentWalletTransaction';
import PaymentWalletTransfer from './PaymentWalletTransfer';
import { useDispatch, useSelector } from 'react-redux';
import PaymentWalletDetails from './PaymentWalletDetails';
import {
  fetchBankWithdraws,
  fetchMobileWithdraws,
  fetchPaymentWalletDeposits,
  fetchPaymentWalletTransactions,
  fetchPaymentWalletTransfers,
} from '../../../../redux/actions/wallet';
import { TbSubtask } from 'react-icons/tb';
import DeactivatePaymentWallet from './DeactivatePaymentWallet';

const PaymentWallet = () => {
  const dispatch = useDispatch();
  const { is_fetching_payment_wallets, payment_wallets } = useSelector(
    (state) => state.wallet
  );

  const columns = [
    {
      Header: 'Reference',
      accessor: 'reference',
    },
    {
      Header: 'Balance',
      accessor: 'total_balance',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Names',
      accessor: '',
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.profile?.first_name +
              ' ' +
              row.original.profile?.last_name}
          </div>
        );
      },
    },
    {
      Header: 'Created at',
      accessor: 'description',
      Cell: ({ row }) => {
        return <div>{row.original.created_at.split('T')[0]}</div>;
      },
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  w-max font-bold gap-4">
            <Popover className="relative">
              <Popover.Button>
                <FiMoreVertical size={30} className="cursor-pointer" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 right-0">
                  <div
                    className="flex flex-col bg-white shadow-md border border-black/10  rounded-sm text-xs "
                    onClick={() => {}}
                  >
                    <h1
                      className="px-4 py-2 hover:bg-[#D9D9D9] text-center cursor-pointer"
                      onClick={() => {
                        setPaymentWalletId(row.original.id);
                        dispatch(
                          fetchPaymentWalletTransactions(row.original.id)
                        );
                        dispatch(fetchPaymentWalletDeposits(row.original.id));
                        dispatch(fetchPaymentWalletTransfers(row.original.id));

                        dispatch(fetchMobileWithdraws(row.original.id));
                        dispatch(fetchBankWithdraws(row.original.id));

                        handleOpenPaymentWalletDetails();
                      }}
                    >
                      Details
                    </h1>

                    <h1
                      className={`px-4 py-2 hover:bg-[#D9D9D9] text-center cursor-pointer ${
                        row.original.status === 'active'
                          ? 'text-error'
                          : 'text-success'
                      }`}
                      onClick={() => {
                        setActivationStatus(row.original.status);
                        setPaymentWalletId(row.original.id);
                        handleDeactivateModal();
                      }}
                    >
                      {row.original.status === 'active'
                        ? 'Deactivate'
                        : 'Activate'}
                    </h1>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
        );
      },
    },
  ];

  const [openPaymentDepositModal, setOpenPaymentDepositModal] = useState(false);
  const [openPaymentTransactionModal, setOpenPaymentTransactionModal] =
    useState(false);
  const [openPaymentTransferModal, setOpenPaymentTransferModal] =
    useState(false);
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [openPaymentWalletDetailsModal, setOpenPaymentWalletDetailsModal] =
    useState(false);
  const [paymentWalletId, setPaymentWalletId] = useState('');
  const [activationStatus, setActivationStatus] = useState('');

  const handlePaymentDepositModal = () => {
    setOpenPaymentDepositModal(!openPaymentDepositModal);
  };

  const handlePaymentTransactionModal = () => {
    setOpenPaymentTransactionModal(!openPaymentTransactionModal);
  };

  const handlePaymentTransferModal = () => {
    setOpenPaymentTransferModal(!openPaymentTransferModal);
  };

  const handleDeactivateModal = () => {
    setOpenDeactivateModal(!openDeactivateModal);
  };

  const handleOpenPaymentWalletDetails = () => {
    setOpenPaymentWalletDetailsModal(!openPaymentWalletDetailsModal);
  };

  return (
    <>
      <DeactivatePaymentWallet
        openModal={openDeactivateModal}
        handleOpenModal={handleDeactivateModal}
        paymentWalletId={paymentWalletId}
        status={activationStatus}
      />

      <PaymentWalletDetails
        openModal={openPaymentWalletDetailsModal}
        paymentWalletId={paymentWalletId}
        handleOpenModal={handleOpenPaymentWalletDetails}
      />

      <div className=" ">
        {!is_fetching_payment_wallets ? (
          payment_wallets?.length == 0 ? (
            <p className="text-[20px] text-[#808080] font-bold flex justify-center items-center mt-6">
              There are no Payment Wallets into the System, Payment Wallets will
              appear here.
            </p>
          ) : (
            <Table
              columns={columns}
              initialSize={10}
              data={payment_wallets?.length > 0 ? payment_wallets : []}
              className="mx-0"
            />
          )
        ) : (
          <SkeletonTable columns={skeletonColumns} data={skeletonData} />
        )}
      </div>
    </>
  );
};

export default PaymentWallet;
