// by David
import React, { useState, useEffect, useRef } from 'react';
import {
  useGlobalFilter,
  usePagination,
  useTable,
  useSortBy,
} from 'react-table';
import { BiSearch } from 'react-icons/bi';
import { useReactToPrint } from 'react-to-print';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import TableFilter from 'react-table-filter';
import 'react-table-filter/lib/styles.css';

export default function Table({
  name,
  columns,
  data,
  setAdInfo,
  setBillInfo,
  rowSelected,
  setRowSelected,
  setToggleRowClick,
  title,
  hideSearch = false,
}) {
  
  const [filteredData, setFilteredData] = useState([]);

  const sortedColumns = React.useMemo(() => [...columns], [columns]);

  const TableInstance = useTable(
    {
      data: filteredData,
      columns: sortedColumns,
      initialState: { pageSize: 3 },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,

    setGlobalFilter,
    getTableBodyProps,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    setPageSize,
    pageOptions,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = TableInstance;

  const { globalFilter, pageIndex, pageSize } = state;
  const [show, setShow] = useState(false);

  const updateFilterHandler = (newData) => {
    setFilteredData(newData);
  };

  useEffect(() => {
    filterRef.current.reset(data, true);
    setFilteredData(data);
  }, [data]);

  // limit the number of rows per page

  // const contentFx = (id, info) => {
  //   setRowSelected(id);
  //   setToggleRowClick();
  //   if (name === "Campaigns") {
  //     return setAdInfo(info);
  //   } else if (name === "Billing") {
  //     return setBillInfo(info);
  //   } else if (name === "") {
  //   }
  // };

  const filterRef = useRef(null);

  // export to pdf

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: title,
    pageStyle: 'print',
  });

  return (
    <div className="w-full flex flex-col rounded  p-4 bg-white font-nunito">
      

      <table
        {...getTableProps()}
        className="w-full  border-b border-spacing-2 "
        ref={componentRef}
      >
        <thead className="bg-[#F8F9FA]">
          {headerGroups.map((headerGroup) => (
            <TableFilter
              {...headerGroup.getHeaderGroupProps()}
              style={{ color: 'black' }}
              rows={data}
              onFilterUpdate={updateFilterHandler}
              ref={filterRef}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column)}
                  className="p-[15px] text-start"
                  filterkey={column.Header}
                  showsearch={'true'}
                >
                  <div className="flex justify-start items-center gap-2">
                    {column.render('Header')}
                  </div>
                </th>
              ))}
            </TableFilter>
          ))}
        </thead>
        {data.length > 0 && (
          <tbody {...getTableBodyProps()} className="">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`border border-separate border-spacing-2 border-black/30  cursor-pointer ${
                    rowSelected === row.id ? ' bg-black' : ''
                  }`}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>
                        <div className="flex  p-[15px]  font-semibold font-nunito">
                          {cell.render('Cell')}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
     
    </div>
  );
}
