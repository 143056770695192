import {
  SET_ARTICLE_CATEGORY,
  SET_ARTICLES,
  RESET_ARTICLES,
  SET_IS_LOADING_ARTICLES,
  SET_IS_LOADING_CATEGORY,
  SET_SELECTED_CATEGORY_LANGUAGE,
  SET_SELECTED_LANGUAGE,
} from "../actions/articles";

const initialState = {
  articleCategories: [],
  articles: [],
  isCategoryLoading: false,
  isArticleLoading: false,
  selectedLangauge: [],
  selectedCatLanguage: [],
};

const articleCategory = (state = initialState, action) => {
  switch (action.type) {
    case SET_ARTICLE_CATEGORY:
      return { ...state, articleCategories: action.payload };
    case SET_ARTICLES:
      return { ...state, articles: action.payload };
    case SET_SELECTED_LANGUAGE:
      return { ...state, selectedLangauge: action.payload };
    case SET_SELECTED_CATEGORY_LANGUAGE:
      return { ...state, selectedCatLanguage: action.payload };
    case SET_IS_LOADING_CATEGORY:
      return { ...state, isCategoryLoading: action.payload };
    case SET_IS_LOADING_ARTICLES:
      return { ...state, isArticleLoading: action.payload };

    case RESET_ARTICLES:
      return initialState;

    default:
      return state;
  }
};

export default articleCategory;
