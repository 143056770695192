import React, { useState } from 'react';
import Axios from '../../components/Axios';
import Modal from '../../components/modal/Modal';
import Button from '../../components/Button';
import { ToastComponent, ToasterComponent } from '../../components/Toast';
import { RiErrorWarningFill } from 'react-icons/ri';
import { APP } from '../../utils/constant';
import { useSelector } from 'react-redux';
// import { token } from '../../Routes/AdvertiserRoutes';
const DeleteBankAccount = ({
  openModal1,
  handleDeleteBankAccountModal,
  bankId,
  bankAccountId,
  getAccounts,
}) => {
  console.log(bankAccountId, 'bankAccountId');
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const handleDeleteBankAccount = () => {
    setLoading(true);
    Axios.delete(
      APP.API_MAIN_URL + `/admin/banks/${bankId}/accounts/${bankAccountId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => {
        console.log(res);
        setLoading(false);
        handleDeleteBankAccountModal();
        getAccounts();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        notify('error', error.response.data.message);
      });
  };

  return (
    <Modal
      isOpen={openModal1}
      onClose={handleDeleteBankAccountModal}
      Style={'w-max h-max m-auto flex mx-auto items-center justify-center'}
    >
      <Modal.Body>
        <ToasterComponent />
        <div className="flex flex-col space-y-4 font-bold">
          <div className="w-full flex items-center justify-center">
            <RiErrorWarningFill size={40} color="#FF3333" />
          </div>
          <div>
            <h2>Confirm if you want to delete this Account</h2>
          </div>
          <div className="flex flex-row w-full justify-between">
            <Button
              type="button"
              content="Cancel"
              loading={false}
              btnColor="primary"
              Style={
                'text-black w-max h-[40px] mt-2 ml-0 bg-white border rounded-sm cursor-pointer'
              }
              onClick={() => {
                handleDeleteBankAccountModal();
              }}
            />
            <Button
              type="button"
              content="Delete"
              loading={loading}
              btnColor="primary"
              onClick={() => {
                handleDeleteBankAccount();
              }}
              Style={'text-white w-max h-[40px] mt-2 ml-0 cursor-pointer'}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteBankAccount;
