import React from 'react';
import Modal from '../../components/modal/Modal';
import Input from '../../components/Input';
import TextArea from '../../components/TextArea';
import { useForm } from 'react-hook-form';
import Button from '../../components/Button';

const Engage = ({ open, setOpen }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const onSubmit = () => {
    console.log('onSubmit');
  };
  return (
    <Modal
      isOpen={open}
      onClose={() => setOpen(!open)}
      Style={'w-3/4 lg:w-1/2 m-auto h-fit m-4 flex z-[10]'}
    >
      <Modal.Header>
        <div className="font-bold">Engage Feedback</div>
      </Modal.Header>
      <Modal.Body>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
          <TextArea
            labelText={'Reply'}
            labelFor={'Reply'}
            name={'Reply'}
            type={'text'}
            isRequired={true}
            placeholder={'Reply'}
            customClass="w-full mb-2"
            inputStyle={'h-[80px]'}
            // value={campaign.description}
            onChange={(e) => {
              // setCampaign((prevData) => {
              //   return {
              //     ...prevData,
              //     description: e.target.value,
              //   };
              // });
            }}
            register={register}
            errors={errors}
          />

          <Button
            content="Reply"
            type="submit"
            btnColor="black"
            onClick={() => setOpen(!open)}
            Style="text-white w-full  mr-4 ml-0"
          />
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default Engage;
