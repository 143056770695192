import React, { Fragment, useState, useEffect } from "react";
import Table from "../components/tableComponent/Table";
import { Popover, Transition } from "@headlessui/react";
import { FiMoreVertical, FiDownload } from "react-icons/fi";
import CampaignDetails from "./resourcesItems/CampaignDetails";
import { skeletonData, skeletonColumns } from "../seeds/Data";
import SkeletonTable from "../components/SkeletonTable";
import Axios from "../components/Axios";
import { ToastComponent, ToasterComponent } from "../components/Toast";
import { APP } from "../utils/constant";

const Campaigns = ({
  campaigns,
  toggleCampaigns,
  setToggleCampaigns,
  profile,
  campaignFetching,
  config,
  role,
}) => {
  const [show, setShow] = useState(false);
  const [campaignId, setCampaignId] = useState();
  const [campaignObject, setCampaignObject] = useState("");
  const [campaignLoading, setCampaignLoading] = useState(false);

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };
  const columns = [
    {
      Header: "Client",
      accessor: "user",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row flex-wrap">
            {row?.original?.profile?.first_name +
              " " +
              row?.original?.profile?.last_name}
          </div>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => {
        console.log("hello", row);
        return (
          <div className="flex flex-row flex-wrap">
            {row?.original?.profile?.email}
          </div>
        );
      },
    },
    {
      Header: "Name",
      accessor: "title",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  w-max">
            <h1 className="font-bold">{row.original.title}</h1>
          </div>
        );
      },
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  w-max">
            <h1 className="font-bold">{row.original.campaign_type.name}</h1>
          </div>
        );
      },
    },
    {
      Header: "Channel",
      accessor: "",
      Cell: ({ row }) => {
        const end = row.original.selected_channels.length - 1;
        return (
          <div className="flex flex-row flex-wrap">
            {row.original.selected_channels.map((channel, index) => {
              return (
                <h1 className="ml-[2px]">
                  {" "}
                  {channel} {index === end ? "" : ","}
                </h1>
              );
            })}
          </div>
        );
      },
      // Cell: ({ row }) => {
      //   const end = row.original.selected_channels.length - 1;
      //   const end2 = row.original.selected_channels[end].length - 1;
      //   return (
      //     <div className="flex flex-row flex-wrap">
      //       {/* {JSON.parse(row.original.selected_channels).map((publisher, i) => {
      //         return (
      //           <h1 className="ml-[2px]">
      //             {publisher?.label}
      //             {i === end ? "." : ","}
      //           </h1>
      //         );
      //       })} */}
      //     </div>
      //   );
      // },
    },
    {
      Header: "Status",
      accessor: "",
      Cell: ({ row }) => {
        if (row?.original?.status === "active") {
          return (
            <div className="bg-[#00BC5C] px-4 py-2 capitalize rounded-sm text-sm w-[110px] flex items-center justify-center">
              {row?.original?.status}
            </div>
          );
        } else if (row?.original?.status === "in_progress") {
          return (
            <div className="bg-[#ECD23A] bg-opacity-90 px-4 py-2 capitalize rounded-sm text-sm w-[110px] flex items-center justify-center">
              {row?.original?.status}
            </div>
          );
        } else {
          return (
            <div className="bg-error bg-opacity-50 capitalize px-2 py-2 rounded-sm text-sm w-[110px] flex items-center justify-center">
              {row?.original?.status}
            </div>
          );
        }
      },
    },
    {
      Header: "Amount (Rwf)",
      accessor: "",
      Cell: ({ row }) => {
        let total = 0;
        const end = row?.original?.selected_channels?.length - 1;
        return row?.original?.selected_channels?.map((channel, index) => {
          if (channel === "sms") {
            total += parseInt(row?.original?.campaign_sms[0].total_price);
          } else if (channel === "email") {
            total += parseInt(row?.original?.campaign_email[0].total_price);
          }
          return <h1 className="ml-[2px]"> {total} Rwf</h1>;
        });
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  font-bold">
            <Popover className="relative">
              <Popover.Button>
                <FiMoreVertical size={30} className="cursor-pointer" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10">
                  <div className="flex flex-col bg-white  shadow-md border border-black/10  rounded-sm text-xs w-max">
                    <div>
                      <div
                        className="px-4 py-2 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                        onClick={() => {
                          setShow(true);
                          setCampaignId(row?.original.id);
                          console.log(row?.original.id, "row?.original.id");
                          getCampaign(row?.original.id);
                        }}
                      >
                        Details
                      </div>
                    </div>
                    {/* {JSON.parse(row?.original?.selected_channels).map(
                      (element) => {
                        if (element?.details?.files?.length > 0) {
                          return (
                            <div
                              className={`flex flex-row py-2 px-4 w-full hover:bg-[#D9D9D9] text-center cursor-pointer`}
                            >
                              <button
                                className="flex"
                                onClick={() => {
                                  element?.details?.files?.map(
                                    (file, index) => {
                                      const link = document.createElement("a");
                                      link.href = file;
                                      link.download = "Campaign file";
                                      document.body.appendChild(link);
                                      link.click();
                                      document.body.removeChild(link);
                                    }
                                  );
                                }}
                              >
                                <FiDownload />
                                <p>Download</p>
                              </button>
                            </div>
                          );
                        }
                      }
                    )} */}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
        );
      },
    },
  ];

  const getCampaign = (id) => {
    setCampaignLoading(true);
    Axios.get(APP.API_MAIN_URL + `/admin/campaigns/${id}`, config)
      .then((res) => {
        setCampaignLoading(false);
        setCampaignObject(res.data.data);
        console.log("compaign", res.data);
      })
      .catch((err) => {
        console.log(err);
        setCampaignLoading(false);
        notify("error", err.response.data.message);
      });
  };

  console.log(campaignObject, "campaignObject campaignObject");
  return (
    <div className="content p-4 over h-full overflow-y-scroll">
      <CampaignDetails
        show={show}
        setShow={setShow}
        data={campaigns}
        campaignId={campaignId}
        toggleCampaigns={toggleCampaigns}
        setToggleCampaigns={setToggleCampaigns}
        campaignObject={campaignObject}
        profile={profile}
        config={config}
        role={role}
      />
      {campaignFetching ? (
        <SkeletonTable columns={skeletonColumns} data={skeletonData} />
      ) : campaigns?.length > 0 ? (
        <Table columns={columns} data={campaigns} />
      ) : (
        <p>No campaigns recently added</p>
      )}
      <ToasterComponent />
    </div>
  );
};

export default Campaigns;
