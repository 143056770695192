import React, { useState, useEffect } from "react";
import Modal from "../../components/modal/Modal";
import Axios from "../../components/Axios";
import Loader from "../../components/Loader";
const SenderNameDetails = ({ show, setShow, senderNameId }) => {
  console.log(senderNameId, "senderNameId");
  const [senderNameDetails, setSenderNameDetails] = useState({});

  const token = localStorage.getItem("userToken");
  const [loading, setLoading] = useState(false);

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    setLoading(true);
    Axios.get(
      `https://api.account.konvey.rw/api/admin/sender-names/${senderNameId}`,
      config
    )
      .then((res) => {
        setLoading(false);
        setSenderNameDetails(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [senderNameId]);

  return (
    <Modal
      isOpen={show}
      onClose={() => {
        setShow(!show);
      }}
      Style={"w-1/2 m-auto h-max p-8 flex z-[10]"}
    >
      <Modal.Header>
        <h1 className="font-bold">Sender Name Details</h1>{" "}
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col mt-2 justify-center min-h-[180px]">
          {loading ? (
            <Loader loader={true} />
          ) : (
            <div className="p-4 flex flex-row w-full">
              <div className="w-4/5">
                <div className="border border-black/50 rounded-md p-2 m-2">
                  <div className="flex flex-row gap-2">
                    <h1 className="font-bold">Name:</h1>
                    <h1 className="font-bold">{senderNameDetails.name}</h1>
                  </div>
                  <div className="flex flex-row gap-2">
                    <h1 className="font-bold"> Action :</h1>
                    <h1 className="font-bold">{senderNameDetails.status}</h1>
                  </div>

                  {/* <div>
                <p>{senderNameDetails.reason}</p>
              </div>

              <div className="flex flex-row gap-2">
                <h1 className="font-bold"> Processed by :</h1>
                <h1 className="font-bold">
                  {senderNameDetails.processed_by.first_name +
                    ' ' +
                    senderNameDetails.processed_by.last_name}
                </h1>
              </div> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SenderNameDetails;
