import React, { useState } from "react";
import { RiseLoader } from "react-spinners";

const Loader = (loader, setLoader) => {
  return (
    <div className="loader-container flex item-center justify-center w-full my-auto">
      <RiseLoader color="#000000" loading={loader} size={20} />
    </div>
  );
};

export default Loader;
