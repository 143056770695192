import React, { useState } from "react";
import Modal2 from "../../../components/modal/Modal2";
import Button from "../../../components/Button";
import { useForm } from "react-hook-form";
import axios from "axios";
import { APP, config, notify } from "../../../utils/constant";

import Input from "../../../components/Input";
import TextArea from "../../../components/TextArea";
import { HiMinus, HiPlus } from "react-icons/hi";
import { useSelector } from "react-redux";

const AddEnvironment = ({
  handleAddEnvironment,
  openAddEnvironment,
  locationId,
  qrCodeId,
  getQrCodes,
  getSingleQr,
}) => {
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [environments, setEnvironments] = useState([
    {
      name: "",
      details: "",
      qr_code_location_id: locationId,
    },
  ]);

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const onSubmit = () => {
    setLoading(true);
    console.log("ENVIRONMENT DATA", environments);
    axios
      .post(
        APP.API_MAIN_URL + `/admin/qr-codes/${qrCodeId}/environments`,
        { environments },
        config(token)
      )
      .then((res) => {
        setLoading(false);
        handleAddEnvironment(!openAddEnvironment);
        getSingleQr();
        getQrCodes();
        reset();
        notify("success", "Environment Added successfully!");
      })
      .catch((err) => {
        setLoading(false);
        notify("error", "Fail to Add Environment");
        console.log(err);
      });
  };
  const handleRemoveEnvironments = (index) => {
    setEnvironments((prevData) => {
      const newData = prevData.filter((_, i) => i !== index);

      return newData;
    });
  };

  const handleAddEnvironments = (index) => {
    setEnvironments((prevData) => {
      // Check if the current object is not empty
      const isCurrentObjectNotEmpty = prevData.some(
        (environment) => environment.name || environment.details
      );

      // If the current object is not empty, add a new object
      if (isCurrentObjectNotEmpty) {
        return [
          ...prevData,
          {
            name: "",
            details: "",
            qr_code_location_id: locationId,
          },
        ];
      } else {
        return prevData;
      }
    });
  };
  console.log("Environments", environments);
  console.log("second env testing", locationId);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal2
        isOpen={openAddEnvironment}
        onClose={() => {
          reset();
          handleAddEnvironment(!openAddEnvironment);
        }}
        Style={"w-fit m-4 flex h-max m-auto lg:w-[40%] "}
      >
        <Modal2.Header className={"sticky top-0 "}>
          <div className="font-bold ">Add Environment</div>
        </Modal2.Header>
        <Modal2.Body>
          {environments.map((place, index) => (
            <div
              className="p-3 border border-light rounded-md mb-5"
              key={index}
            >
              <Input
                labelText={"Name"}
                name={`name ${index}`} // Ensure unique name attributes
                type={"text"}
                placeholder={"Add name of environment"}
                value={environments[index]?.name}
                isRequired={true}
                customClass="w-full text-sm mx-auto"
                register={register}
                errors={errors}
                onChange={(e) => {
                  setEnvironments((prevEnvironments) => {
                    const updatedEnvironments = [...prevEnvironments];
                    updatedEnvironments[index] = {
                      ...updatedEnvironments[index],
                      name: e.target.value,
                    };
                    return updatedEnvironments;
                  });
                  clearErrors("name");
                }}
              />
              <TextArea
                labelText={"Details"}
                labelFor={"details"}
                name={`details ${index}`}
                isRequired={true}
                value={environments[index]?.details}
                placeholder={"Enter details"}
                customClass="w-full mt-2"
                register={register}
                errors={errors}
                onChange={(e) => {
                  setEnvironments((prevEnvironments) => {
                    const updatedEnvironments = [...prevEnvironments];
                    updatedEnvironments[index] = {
                      ...updatedEnvironments[index],
                      details: e.target.value,
                      qr_code_location_id: locationId,
                    };
                    return updatedEnvironments;
                  });
                  clearErrors("details");
                }}
              />
              <div className="flex items-center justify-end w-[full] space-x-2 mt-4">
                <div
                  onClick={() => handleRemoveEnvironments(index)}
                  className={`flex items-center p-1 justify-center cursor-pointer bg-red-500 rounded-full ${
                    index === 0 ? "hidden" : "visible"
                  }`}
                >
                  <HiMinus
                    color="white"
                    size={18}
                    className={`cursor-pointer `}
                  />
                </div>
                <div
                  className={`flex items-center p-1 justify-center cursor-pointer bg-primary rounded-full  ${
                    index === environments.length - 1 &&
                    environments[environments.length - 1].name !== "" &&
                    environments[environments.length - 1].details !== ""
                      ? "visible"
                      : "hidden"
                  }`}
                  onClick={() => handleAddEnvironments(index)}
                >
                  <HiPlus color="white" size={18} />
                </div>
              </div>
            </div>
          ))}
        </Modal2.Body>
        <Modal2.Footer className={"h-14 px-0"}>
          <Button
            type="submit"
            content="Save"
            loading={loading}
            Style={"text-white bg-primary h-[40px] w-full  "}
          />
        </Modal2.Footer>
      </Modal2>
    </form>
  );
};

export default AddEnvironment;
