import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { setUserDetails, setUserToken } from "../../redux/actions/user";
import { APP } from "../../utils/constant";
import axios from "axios";
import Loader from "../Loader";
import { FadeLoader } from "react-spinners";

function Auth() {
  const dispatch = useDispatch();
  const { token } = useParams();

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  let [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  useEffect(() => {
    if (token?.trim().length > 10) {
      dispatch(setUserToken(token));
    }
  }, []);

  const getLoggedInUser = async () => {
    await axios
      .get(APP.API_MAIN_URL + "/auth/me", config)
      .then((res) => {
        dispatch(setUserDetails(res.data.user));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const redirect = async () => {
    if (!token) {
      return;
    }
    if (token?.trim().length > 10) {
      await getLoggedInUser();
      if (page !== undefined && page !== null) {
        window.location.replace(window.location.origin + "/" + page);
      } else {
        window.location.replace(window.location.origin);
      }
    } else {
      window.location.replace(APP.ACCOUNT_URL);
    }
  };

  useEffect(() => {
    redirect();
  }, []);

  return (
    <div className="flex items-center justify-center font-bold text-[26px] text-[#6f6e6e] h-screen">
      <FadeLoader color="#000000" size={50} />
    </div>
  );
}

export default Auth;
