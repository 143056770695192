import React, { useEffect, Fragment, useState } from "react";
import Table from "../../components/tableComponent/Table";
import { Popover, Transition } from "@headlessui/react";
import { FiMoreVertical } from "react-icons/fi";
import Modal from "../../components/modal/Modal";
import { skeletonData, skeletonColumns } from "../../seeds/Data";
import SkeletonTable from "../../components/SkeletonTable";
import UpdateQrCode from "./UpdateQrCode";
import Create_Qr_code from "../modals/Create_Qr_code";
import Axios from "../../components/Axios";
import { ToastComponent, ToasterComponent } from "../../components/Toast";
import Button from "../../components/Button";
import Qrcode_Details from "../modals/Qrcode_Details";
import { APP } from "../../utils/constant";

const QrCode = ({ config }) => {
  const [show, setShow] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [id, setId] = useState();
  const [deleteId, setDeleteId] = useState();
  const [fetchData, setFetchData] = useState([]);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [deleteCode, setDeleteCode] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState({
    qr_code_name: "",
  });
  const [open, setOpen] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [open_details_modal, setOpen_details_modal] = useState(false);
  const [QRcode_data, setQRcode_data] = useState([]);
  console.log(QRcode_data, "typeof");
  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  // function to sort data to get newly created data
  const getLatest = (a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA; // Sort in descending order of creation date
  };

  const openModal = () => {
    setShow(!show);
  };

  const getQrCodes = () => {
    //setToggelFetch(true);
    Axios.get("https://api.account.konvey.rw/api/admin/qr-codes", config)
      .then((res) => {
        setFetchData(res?.data?.data?.sort(getLatest));
        setToggleFetch(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getQrCodes();
  }, [toggle]);

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Location",
      accessor: (row) => {
        return (
          <div className="flex flex-row w-1/3 items-center justify-center">
            <h1>{row?.locations_count}</h1>
          </div>
        );
      },
    },
    {
      Header: "Environments",
      accessor: (row) => {
        return (
          <div className="flex flex-row w-1/3 items-center justify-center">
            <h1> {row?.environments_count}</h1>
          </div>
        );
      },
    },
    {
      Header: "Placement",
      accessor: (row) => {
        return (
          <div className="flex flex-row w-1/3 items-center justify-center">
            <h1>{row?.placements_count}</h1>
          </div>
        );
      },
    },

    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  font-bold">
            <Popover className="relative">
              <Popover.Button>
                <FiMoreVertical size={30} className="cursor-pointer" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10">
                  <div className="flex flex-col bg-white  shadow-md border border-black/10  rounded-sm text-xs w-max">
                    <div
                      className="px-4 py-2 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                      onClick={() => {
                        setQRcode_data(row?.original);
                        setOpen_details_modal(!open_details_modal);
                      }}
                    >
                      View details
                    </div>
                    <div
                      className="px-4 py-2 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                      onClick={() => {
                        setUpdateData(row.original);
                        setShow(true);
                      }}
                    >
                      Update
                    </div>
                    <div
                      className="px-4 py-2 w-full hover:bg-[#D9D9D9] text-center cursor-pointer text-error"
                      onClick={() => {
                        setDeleteId(row.original.id);
                        setDeleteCode(true);
                      }}
                    >
                      Delete
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
        );
      },
    },
  ];

  const handleDelete = () => {
    setDelLoading(true);
    Axios.delete(
      `https://api.account.konvey.rw/api/admin/qr-codes/${deleteId}`,
      config
    )
      .then((res) => {
        notify("success", "Qr code deleted successfully");
        setDelLoading(false);
        setDeleteCode(false);
        setDelLoading(false);
        setToggle(!toggle);
      })
      .catch((err) => {
        notify("error", "Failed to delete qr code");
        setDelLoading(false);
      });
  };

  return (
    <div className="bg-secondary border border-white shadow-lg w-full h-max">
      <div className="flex flex-col space-y-12 lg:w-full w-[95%] mx-auto py-4">
        {/*modal to delete*/}
        <Modal
          isOpen={deleteCode}
          onClose={() => setDeleteCode(!deleteCode)}
          Style={"lg:w-1/4 w-3/4 m-auto h-max flex z-[10]"}
        >
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <div>Confirm delete action</div>
            <div className="flex flex-row justify-end">
              <Button
                content="Confirm"
                type="button"
                btnColor="black"
                Style="text-white w-max ml-0"
                loading={delLoading}
                onClick={() => {
                  handleDelete();
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/*end of modal*/}
        {/* <UpdateQrCode
          open={open}
          setOpen={setOpen}
          config={config}
          data={updateData}
          toggle={toggle}
          setToggle={setToggle}
        /> */}
        <Create_Qr_code
          open={show}
          setOpen={setShow}
          config={config}
          toggle={toggle}
          setToggle={setToggle}
        />
        <Qrcode_Details
          data={QRcode_data}
          show={open_details_modal}
          setShow={setOpen_details_modal}
          getQrCodes={getQrCodes}
        />

        <div className="mx-auto w-[90%] flex items-end justify-end">
          <Button
            content="New Qr Code"
            type="button"
            btnColor="black"
            Style="text-white w-max ml-0"
            onClick={openModal}
          />
        </div>
        <div className="overflow-auto w-full md:w-[90%] mx-auto">
          {toggleFetch ? (
            <SkeletonTable columns={skeletonColumns} data={skeletonData} />
          ) : (
            <Table columns={columns} data={fetchData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default QrCode;
