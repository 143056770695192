import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from '../../../components/modal/Modal';
import Button from '../../../components/Button';
import { ToastComponent, ToasterComponent } from '../../../components/Toast';
import { APP } from '../../../utils/constant';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBankAccountPayment } from '../../../redux/actions/bank';
import RejectReason from './RejectReason';

const PaymentDetails = ({
  openModal,
  handleOpenModal,
  paymentDetailsData,
  bankId,
  bankAccountId,
}) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [rejectReasonModal, setRejectReasonModal] = useState(false);
  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const handleRejectReasonModal = () => {
    setRejectReasonModal(!rejectReasonModal);
  };

  const handleApprovePayment = () => {
    setApproveLoading(true);
    axios
      .put(
        APP.API_MAIN_URL +
          `/admin/banks/${bankId}/accounts/${bankAccountId}/payments/${paymentDetailsData.id}/approve`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(fetchBankAccountPayment(bankId, bankAccountId));
        setApproveLoading(false);
      })
      .catch((error) => {
        setApproveLoading(false);
        notify('error', error.response.data.message);
        console.log(error);
      });
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        onClose={() => {
          handleOpenModal();
        }}
        Style={'w-[90%] lg:w-5/6 h-[90%] flex m-auto py-4'}
      >
        <Modal.Header>
          <h1 className="font-bold">Payment Details</h1>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col lg:flex-row gap-2">
              <p className="font-bold">Ref:</p>
              <p className="">{paymentDetailsData.reference}</p>
            </div>
            <div className="flex flex-col lg:flex-row gap-2">
              <p className="font-bold">Date:</p>
              <p>{paymentDetailsData.created_at.split('T')[0]}</p>
            </div>
            <div className="flex flex-col lg:flex-row gap-2">
              <p className="font-bold">Total Amount:</p>
              <p>{paymentDetailsData.total_amount}</p>
            </div>

            <div className="flex flex-col lg:flex-row gap-2">
              <p className="font-bold">Supporting Doc:</p>
              <p>{}</p>
            </div>

            <div className="flex flex-col lg:flex-row gap-2">
              <p className="font-bold">Status:</p>
              <p>{paymentDetailsData.status}</p>
            </div>

            <div className="flex flex-col lg:flex-row gap-2">
              <p className="font-bold">Processed By:</p>
              <p>
                {paymentDetailsData.processed_by
                  ? paymentDetailsData.processed_by
                  : 'Not Yet'}
              </p>
            </div>

            {paymentDetailsData.status === 'pending' ? (
              <div className="flex flex-col border border-error rounded-lg p-2">
                <p className="font-bold text-light">Attention : ⚠️</p>

                <div className={`flex flex-row gap-4 `}>
                  <Button
                    type="button"
                    content="Reject"
                    loading={rejectLoading}
                    onClick={() => {
                      console.log('clicked');
                      handleRejectReasonModal();
                    }}
                    btnColor="error"
                    Style={`text-white w-fit h-[40px] mt-8 ml-0 font-bold`}
                  />

                  <Button
                    type="button"
                    content="Confirm"
                    loading={approveLoading}
                    onClick={() => {
                      handleApprovePayment();
                    }}
                    btnColor="success/60"
                    Style={`text-white w-fit h-[40px] mt-8 ml-0 font-bold`}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>
      <RejectReason
        openModal={rejectReasonModal}
        handleOpenModal={handleRejectReasonModal}
        bankId={bankId}
        bankAccountId={bankAccountId}
        id={paymentDetailsData.id}
      />
    </>
  );
};

export default PaymentDetails;
