import React, { useState } from 'react';
import Modal from '../../components/modal/Modal';
import Input from '../../components/Input';
import { useForm } from 'react-hook-form';
import Button from '../../components/Button';
import MultiSelect from '../../components/MultiSelect';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { HiPlus } from 'react-icons/hi';
import { AiFillMinusCircle } from 'react-icons/ai';

const Escalate = ({ open, setOpen }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const [option, setOption] = useState('department');
  const [selectedDepartment, setSelectedDepartment] = useState({
    department: '',
  });
  const [emails, setEmails] = useState([{ email: '' }]);

  const addEmails = () => {
    setEmails((prevData) => {
      return [...prevData, { email: '' }];
    });
  };

  const removeEmails = (index) => {
    setEmails((prevData) => {
      const emailsArray = [...prevData];
      emailsArray.splice(index, 1);
      return emailsArray;
    });
  };
  const handleEmail = (e, index) => {
    const { value } = e.target;
    setEmails((prevData) => {
      const newEmail = [...prevData];
      newEmail[index] = { email: value };
      return newEmail;
    });
  };

  const departmentOptions = [
    {
      value: 'marketing@gmail.com',
      label: 'Marketing Team',
    },
    {
      value: 'it@gmail.com',
      label: 'IT Team',
    },
    {
      value: 'finance@gmail.com',
      label: 'Finance Team',
    },
    {
      value: 'support@gmail.com',
      label: 'Support Team',
    },
    {
      value: 'research@gmail.com',
      label: 'Research Team',
    },
  ];

  const handleRadioChange = (e) => {
    const { value } = e.target;
    setOption(value);
  };

  const onSubmit = () => {
    console.log('onSubmit');
  };
  console.log(emails, 'emails');
  return (
    <Modal
      isOpen={open}
      onClose={() => setOpen(!open)}
      Style={'w-3/4 lg:w-1/3 m-auto h-fit m-4 flex z-[10]'}
    >
      <Modal.Header>
        <div className="font-bold">Escalate Feedback</div>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <div className="flex flex-row gap-4 mt-4">
            <div className="flex items-center gap-1">
              <input
                type="radio"
                id="escalateChoice1"
                name="escalateChoice1"
                value="department"
                checked={option === 'department'}
                onChange={handleRadioChange}
              />
              <label for="scheduleChoice1">Department</label>
            </div>
            <div className="flex items-center gap-1">
              <input
                type="radio"
                id="escalateChoice2"
                name="escalateChoice2"
                value="custom"
                checked={option === 'custom'}
                onChange={handleRadioChange}
              />
              <label for="scheduleChoice2">Custom</label>
            </div>
          </div>
          {option === 'custom' ? (
            <div className="flex flex-col relative ">
              {emails.map((email, index) => {
                return (
                  <div key={index} className={`flex w-full`}>
                    <Input
                      labelText={`Email ${index + 1}`}
                      labelFor={`email ${index + 1}`}
                      name={`email ${index + 1}`}
                      type={'text'}
                      isRequired={true}
                      placeholder={'enter email'}
                      customClass="w-full"
                      register={register}
                      errors={errors}
                      value={email.email}
                      onChange={(e) => {
                        handleEmail(e, index);
                      }}
                    />
                    <div
                      className="h-fit w-fit"
                      data-tooltip-id="remove_email"
                      data-tooltip-content="remove email"
                    >
                      <AiFillMinusCircle
                        color="#BDBDBD"
                        size={25}
                        className={`cursor-pointer ml-2 mt-6 ${
                          index === 0 ? 'hidden' : 'visible'
                        }`}
                        onClick={() => removeEmails(index)}
                      />
                      <Tooltip id="remove_email" />
                    </div>
                  </div>
                );
              })}

              <div
                className="h-fit w-fit absolute right-[0px] bottom-[-40px]"
                data-tooltip-id="add_email"
                data-tooltip-content="add email"
              >
                <Button
                  type="button"
                  content={<HiPlus color="#FBFBFB" size={20} />}
                  loading={false}
                  onClick={addEmails}
                  btnColor="primary"
                  Style={'text-white w-fit mr-2'}
                  padding="p-1"
                />
                <Tooltip id="add_email" />
              </div>
            </div>
          ) : (
            <div>
              <MultiSelect
                label="Department"
                className="w-full mb-2"
                isLoading={false}
                placeholder={'Select department'}
                defaultValue={selectedDepartment.department}
                handleChange={(e) => {
                  setSelectedDepartment((prevData) => {
                    return {
                      department: e.map((e) => e.label),
                    };
                  });
                  // setCampaign((prevData) => {
                  //   return {
                  //     ...prevData,
                  //     publisher_type: e.map((e) => e.label),
                  //   };
                  // });
                }}
                options={departmentOptions}
                register={{
                  control,
                  name: 'department',
                  rules: { required: 'Department is required' },
                }}
                error={errors['department']?.message}
              />
            </div>
          )}

          <Button
            content="Send"
            type="submit"
            btnColor="black"
            onClick={() => setOpen(!open)}
            Style="text-white w-full mt-8 mr-4 ml-0"
          />
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default Escalate;
