import React, { Fragment, useEffect, useState } from "react";
import Table from "../components/tableComponent/Table";
import { Popover, Transition } from "@headlessui/react";
import { FiMoreVertical } from "react-icons/fi";
import CampaignDetails from "./resourcesItems/CampaignDetails";
import { skeletonData, skeletonColumns } from "../seeds/Data";
import SkeletonTable from "../components/SkeletonTable";
import Axios from "../components/Axios";

import { APP, notify } from "../utils/constant";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import BlastDetails from "../modals/blastModals/BlastDetails";
import { setAllBlasts } from "../redux/actions/blast";

const Blast = ({ profile, config, role }) => {
  const { blasts } = useSelector((state) => state.blast);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [blastId, setBlastId] = useState();
  const [blastObject, setBlastObject] = useState("");
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [rowSelected, setRowSelected] = useState(null);

  const [loading, setLoading] = useState(false);

  const getAllBlasts = async () => {
    setLoading(true);
    try {
      const res = await axios.get(APP.API_MAIN_URL + `/admin/blasts`, config);
      dispatch(setAllBlasts(res.data.data));
      setLoading(false);
      console.log("all blasts=>", res.data.data);
    } catch (error) {
      setLoading(false);
      notify("error", "Error in retrieving blasts");
      console.log("Error in retriving Blasts");
    }
  };

  useEffect(() => {
    getAllBlasts();
  }, [token]);

  const columns = [
    {
      Header: "Title",
      accessor: "title",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row flex-wrap capitalize">
            {row?.original?.title}
          </div>
        );
      },
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row flex-wrap">
            {row?.original?.description}
          </div>
        );
      },
    },
    {
      Header: "Type",
      accessor: "blast_type",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  w-max capitalize">
            <h1 className="font-bold">{row?.original?.blast_type}</h1>
          </div>
        );
      },
    },

    {
      Header: "Status",
      accessor: "",
      Cell: ({ row }) => {
        if (row?.original?.status === "active") {
          return (
            <div className="bg-[#00BC5C] px-4 py-2 capitalize rounded-sm text-sm w-[110px] flex items-center justify-center">
              {row?.original?.status}
            </div>
          );
        } else if (row?.original?.status === "in_progress") {
          return (
            <div className="bg-[#ECD23A] bg-opacity-90 px-4 py-2 capitalize rounded-sm text-sm w-[110px] flex items-center justify-center">
              {row?.original?.status}
            </div>
          );
        } else {
          return (
            <div className="bg-error bg-opacity-50 capitalize px-2 py-2 rounded-sm text-sm w-[110px] flex items-center justify-center">
              {row?.original?.status}
            </div>
          );
        }
      },
    },
    {
      Header: "Create At",
      accessor: "",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row flex-wrap">
            {row?.original?.created_at.split("T")[0] +
              " " +
              row?.original?.created_at.split("T")[1].split(".")[0]}
          </div>
        );
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  font-bold">
            <Popover className="relative">
              <Popover.Button>
                <FiMoreVertical size={30} className="cursor-pointer" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10">
                  <div className="flex flex-col bg-white  shadow-md border border-black/10  rounded-sm text-xs w-max">
                    <div>
                      {/* <div
                        className="px-4 py-2 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                        onClick={() => {
                          setShow(true);
                          setBlastId(row?.original.id);
                          console.log(row?.original.id, "row?.original.id");
                          getBlast(row?.original.id);
                        }}
                      >
                        Approve
                      </div> */}
                      <div
                        className="px-4 py-2 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                        onClick={() => {
                          setShow(true);
                          setBlastId(row?.original.id);
                          console.log(row?.original.id, "row?.original.id");
                          getBlast(row?.original.id);
                        }}
                      >
                        Details
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
        );
      },
    },
  ];

  const getBlast = (id) => {
    setLoading(true);
    Axios.get(APP.API_MAIN_URL + `/admin/blasts/${id}`, config)
      .then((res) => {
        setLoading(false);
        setBlastObject(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        notify("error", err.response.data.message);
      });
  };

  const handleRowClick = (rowIndex) => {
    setRowSelected(rowIndex);
  };
  console.log("first", blastObject);
  return (
    <div className="content p-4 over h-full overflow-y-scroll">
      <BlastDetails
        show={show}
        setShow={setShow}
        data={blasts}
        blastId={blastId}
        blastObject={blastObject}
        profile={profile}
        config={config}
        role={role}
        getAllBlasts={getAllBlasts}
      />
      {loading ? (
        <SkeletonTable columns={skeletonColumns} data={skeletonData} />
      ) : blasts?.length > 0 ? (
        <Table
          columns={columns}
          data={blasts.map((blast, index) => ({
            ...blast,
            selected: index === rowSelected,
          }))}
          onRowClick={handleRowClick}
        />
      ) : (
        <p className="text-3xl">No Blast recently added</p>
      )}
    </div>
  );
};

export default Blast;
