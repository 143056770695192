// by David
import React from 'react'

export const Card = ({children, Style}) => {
  return (
<div className={`${Style}`}>
  <div className={`shadow-lg rounded flex flex-col justify-between leading-normal`}>
    {children}
  </div>
</div>
  )
}