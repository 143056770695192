import React, { useState, useEffect } from "react";
import Modal from "../../components/modal/Modal";
import Loader from "../../components/Loader";
import SmsDetails from "../../modals/campainModals/modals/SmsDetails";
import EmailDetails from "../../modals/campainModals/modals/EmailDetails";
import Qr_code_details from "../../modals/campainModals/modals/Qr_code_details";
import { ToastComponent, ToasterComponent } from "../../components/Toast";
import Axios from "../../components/Axios";
import Button from "../../components/Button";
import TextArea from "../../components/TextArea";
import { useForm } from "react-hook-form";
import { APP } from "../../utils/constant";
import { useSelector } from "react-redux";

const fileDisplay = (files) => {
  if (files.length > 0) {
    return files.map((file, index) => {
      if (file.includes("application/pdf")) {
        return (
          <iframe src={`${file}`} frameborder="0" width="70%" height="500" />
        );
      } else if (file.includes("video")) {
        return (
          <video width="300" height="500" src={file} controls type="video/mp4">
            video file not supported
          </video>
        );
      } else {
        return (
          <div className="flex flex-row">
            <img src={file} height={250} width={250} alt="campaign file" />
          </div>
        );
      }
    });
  }
};

const CampaignDetails = ({
  show,
  setShow,
  data,
  campaignId,
  toggleCampaigns,
  setToggleCampaigns,
  profile,
  role,
  config,
}) => {
  const { token } = useSelector((state) => state.user);
  const [campaignObject, setCampaignObject] = useState("");
  const [fetchLoading, setFetchLoading] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [open, setOpen] = useState(false);
  const [openApproval, setOpenApproval] = useState(false);
  const [sendRequest, setSendRequest] = React.useState(false);
  const [reject, setReject] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [isCampaignRequestClicked, setIsCampaignRequestClicked] =
    useState(false);
  const [campaignRequestData, setCampaignRequestData] = useState("");
  const [clickedIndex, setClickedIndex] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  let permission = "APPROVER";
  console.log(campaignObject, "campaignObject");
  const sms = campaignObject?.campaign_sms?.length > 0 ? true : false;
  const email = campaignObject?.campaign_email?.length > 0 ? true : false;
  const qrCode =
    campaignObject?.campaign_custom_qr_codes?.length > 0 ||
    campaignObject?.campaign_standard_qr_code?.length > 0
      ? true
      : false;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const formattedDate = dateObj.toLocaleDateString("en", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
    return formattedDate;
  };

  const formatTime = (timeString) => {
    const timeObj = new Date(timeString);
    const formattedTime = timeObj.toLocaleTimeString("en", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  };

  // delete campaign
  const approveCampaign = () => {
    setApproveLoading(true);
    Axios.put(
      APP.API_MAIN_URL + `/admin/campaign-requests/${requestId}/approve`,
      {},

      config
    )
      .then((res) => {
        setToggleCampaigns(!toggleCampaigns);
        console.log(res);
        notify("success", "Campaign approved successfully");
        setApproveLoading(false);
        setOpenApproval(!openApproval);
        setRequestId("");
      })
      .catch((err) => {
        console.log(err);
        setApproveLoading(false);
        notify("error", "Error approving campaign");
      });
  };

  const handleReason = (e) => {
    const { value } = e.target;
    setRejectReason(value);
  };

  const rejectCampaign = () => {
    Axios.put(
      APP.API_MAIN_URL +
        `/admin/campaign-requests/${campaignObject.id}/approve`,
      {},
      config
    )
      .then((res) => {
        setToggleCampaigns(!toggleCampaigns);
        console.log(res);
        notify("success", "Campaign approved successfully");

        setOpenApproval(!openApproval);
      })
      .catch((err) => {
        console.log(err);
        setRejectLoading(false);
        notify("error", "Error approving campaign");
      });
  };

  const onSubmit = () => {
    setRejectLoading(true);
    Axios.put(
      APP.API_MAIN_URL + `/admin/campaign-requests/${requestId}/reject`,
      {
        reason: rejectReason,
      },
      config
    )
      .then((res) => {
        setRejectLoading(false);
        setRejectReason("");
        console.log(res);
        setToggleCampaigns(!toggleCampaigns);
        setOpen(!open);
      })
      .catch((err) => {
        setRejectLoading(false);
        console.log(err);
        notify("error", err.response.data.message);
      });
  };
  console.log("compaign", campaignObject);
  useEffect(() => {
    setFetchLoading(true);
    Axios.get(APP.API_MAIN_URL + `/admin/campaigns/${campaignId}`, config)
      .then((res) => {
        setFetchLoading(false);
        setCampaignObject(res.data.data);
      })
      .catch((err) => {
        setFetchLoading(false);
        console.log(err);
      });
  }, [campaignId]);

  console.log(
    campaignRequestData?.status !== "approved",
    role,
    "campaign console"
  );

  return (
    <div className="font-nunito">
      {/* approve campaign modal */}

      {/* <Modal
        isOpen={openApproval}
        onClose={() => setOpenApproval(!openApproval)}
        Style={"w-fit m-4 flex m-auto z-[10]"}
      >
        <Modal.Body>
          <div className="flex flex-col ">
            <h1 className="font-nunito text-2xl text-start text-black font-bold mb-4 mr-8">
              Comfirm approval of campaign ?
            </h1>
            <div className="flex justify-between">
              <Button
                type="submit"
                content="Cancel"
                loading={false}
                btnColor="primary"
                onClick={() => {
                  setOpenApproval(!openApproval);
                  setShow(!show);
                }}
                Style={"text-white h-[40px] mt-2 ml-0"}
              />

              <Button
                type="submit"
                content="Confirm"
                loading={loading}
                btnColor="primary"
                onClick={() => {
                  approveCampaign();
                }}
                Style={"text-white h-[40px] mt-2 ml-0 border"}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      {/*end of modal*/}
      <Modal
        isOpen={show}
        onClose={() => setShow()}
        Style={
          "h-[95%] lg:h-[90%] w-[90%] lg:w-3/4 w-full  mt-8  mx-auto pb-0 font-nunito text-sm"
        }
      >
        {/* cancel campaign modal */}

        <Modal
          isOpen={open}
          onClose={() => setOpen(!open)}
          Style={"w-1/2 m-4 flex m-auto"}
        >
          <Modal.Header>
            <h1 className="font-bold">Campaign Rejection</h1>
          </Modal.Header>
          <Modal.Body>
            <div className="flex flex-col ">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="text-primary">
                  <TextArea
                    labelText={"Reason for rejection"}
                    labelFor={`Rejection reason`}
                    name={`Rejection reason`}
                    placeholder={"Reason........"}
                    customClass="w-full mb-2"
                    inputStyle="h-[90px]"
                    isRequired={true}
                    value={rejectReason}
                    onChange={(e) => {
                      handleReason(e);
                    }}
                    register={register}
                    errors={errors}
                  />{" "}
                </div>
                <div className="flex justify-end">
                  <Button
                    type="submit"
                    content="Confirm"
                    loading={rejectLoading}
                    btnColor="black"
                    Style={"text-white h-[40px] mt-2 ml-0 border rounded-lg"}
                  />
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>

        {/*end of modal*/}

        <Modal.Body>
          <div className="p-4 flex flex-row w-full">
            {fetchLoading ? (
              <div className="h-[150px] w-full flex ">
                <Loader loader={true} />
              </div>
            ) : (
              <div className="flex flex-row w-full">
                <div className="w-4/5">
                  <div
                    className={` ${
                      isCampaignRequestClicked ? "hidden" : "visible"
                    } flex flex-col `}
                  >
                    <div className="w-full flex flex-col space-y-4">
                      <div className="flex lg:flex-row flex-col space-y-2 justify-between w-full lg:items-center">
                        <div className="flex flex-row gap-2 items-center">
                          <div className="max-w-[80px] w-[50px] h-[50px] border-[0px] rounded-full border-white">
                            <img
                              src={""}
                              className="w-[50px] h-[50px] border-[0px] rounded-full border-white"
                              alt="logo"
                            />
                          </div>
                          <h2 className=" font-bold text-xl">
                            {campaignObject?.profile?.first_name +
                              " " +
                              campaignObject?.profile?.last_name}
                          </h2>
                        </div>
                        <div>
                          <p className="flex items-center py-2 text-sm font-bold text-light">
                            {formatTime(campaignObject?.created_at)}
                            <span className="h-[5px] w-[5px] bg-light rounded-full mx-2"></span>
                            {formatDate(campaignObject?.created_at)}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <h1 className=" font-bold text-2xl">
                        {campaignObject?.title}
                      </h1>
                      <h2 className="font-bold  text-lg">
                        {campaignObject?.description}
                      </h2>
                    </div>

                    <div className="flex flex-col space-y-2 mt-2  w-full lg:w-3/4">
                      <div className="flex lg:flex-row flex-col  lg:gap-1 lg:items-center mt-1">
                        <h1 className="text-light  font-bold w-fit text-xl ">
                          Campaign type:
                        </h1>
                        <h2 className="text-black text-lg">
                          {campaignObject?.campaign_type?.name}
                        </h2>
                      </div>
                      <div className="flex lg:flex-row  lg:gap-1 lg:items-center mt-1 flex-col">
                        <h1 className="text-light  font-bold w-fit text-sm">
                          Selected channels:
                        </h1>
                        <div>
                          <h2 className="text-black text-sm">
                            {sms
                              ? "SMS"
                              : email
                              ? "Email"
                              : qrCode
                              ? "QR Code"
                              : sms && email
                              ? "SMS, Email."
                              : null}
                          </h2>
                        </div>
                      </div>
                      {sms ? (
                        <SmsDetails data={campaignObject.campaign_sms} />
                      ) : null}

                      {email ? (
                        <EmailDetails data={campaignObject.campaign_email} />
                      ) : null}

                      {/* {data[index]?.selected_channels &&
                JSON.parse(data[index]?.selected_channels)?.map((element) => {
                  const channelName = element.label;
                  let channelValue = element.value;
                  if (channelValue === "sms") {
                    return (
                      <SmsDetails data={[element]} fileDisplay={fileDisplay} />
                    );
                  } else if (channelValue === "email") {
                    return (
                      <EmailDetails
                        data={[element]}
                        channelValue={channelValue}
                      />
                    );
                  } else if (channelValue === "qr_code") {
                    return (
                      <Qr_code_details
                        data={[element]}
                        channelValue={channelValue}
                      />
                    );
                  }
                })} */}

                      <div className="flex flex-row  gap-1 items-center mt-4 text-xl">
                        <h1 className="text-light  font-bold w-fit text-sm">
                          {" "}
                          Status:
                        </h1>
                        <h2 className="text-black font-bold text-sm">
                          {campaignObject?.status}
                        </h2>
                      </div>
                      {/* {data[index]?.status === 'PENDING' } */}
                    </div>
                  </div>
                  <div
                    className={
                      isCampaignRequestClicked
                        ? "visible w-full self-center m-auto"
                        : "hidden"
                    }
                  >
                    <div className="flex flex-row items-center gap-2">
                      <h1 className="text-lg text-light font-bold">Status :</h1>
                      <h1 className="text-lg">{campaignRequestData?.status}</h1>
                    </div>
                    <div
                      className={
                        campaignRequestData?.status === "rejected"
                          ? "visible"
                          : "hidden"
                      }
                    >
                      <div className="flex flex-row items-center gap-2">
                        <h1 className="text-lg text-light font-bold">
                          Reason :
                        </h1>
                        <h1 className="text-lg">
                          {campaignRequestData?.reason}
                        </h1>
                      </div>
                    </div>
                    <div
                      className={`${
                        campaignRequestData?.status !== "pending"
                          ? "visible"
                          : "visible"
                      } flex flex-col border border-error w-full p-4 rounded-lg `}
                    >
                      <h1 className="text-light  font-bold w-fit">
                        Attention : ⚠️
                      </h1>
                      <p>This action can not be reversed, be aware !</p>
                      <div className="flex flex-row space-x-4 w-full">
                        <button
                          className={`rounded-lg text-white bg-error p-2 font-bold w-fit mt-4 ${
                            permission === "APPROVER" ? "hidden" : "flex"
                          }`}
                          onClick={() => {
                            setShow(!show);
                            setOpen(!open);
                          }}
                        >
                          Cancel
                        </button>

                        <button
                          className={`rounded-lg text-white ${
                            sendRequest
                              ? "bg-success "
                              : "bg-success bg-opacity-60"
                          } ${
                            permission === "APPROVER" ? "hidden" : "flex"
                          } p-2 font-bold w-fit mt-4`}
                          onClick={() => {
                            setSendRequest(!sendRequest);
                          }}
                        >
                          {sendRequest
                            ? "🗣️ Request Approval "
                            : "ℹ️ Request has been sent"}
                        </button>

                        <Button
                          type="submit"
                          content={reject ? "🗣️ Rejected " : "Reject Approval"}
                          loading={false}
                          btnColor="error"
                          onClick={() => {
                            setReject(!reject);
                            setOpen(!open);
                          }}
                          Style={`text-white h-[40px] mt-2 rounded-lg text-white ${
                            reject ? "bg-error " : "bg-error"
                          } ${
                            permission === "APPROVER" ? "flex" : "hidden"
                          } p-2 font-bold w-fit mt-4 `}
                        />

                        {/* <button
                          className={`rounded-lg text-white ${
                            reject ? 'bg-error ' : 'bg-error bg-opacity-60'
                          } ${
                            permission === 'APPROVER' ? 'flex' : 'hidden'
                          } p-2 font-bold w-fit mt-4`}
                          onClick={() => {
                            setReject(!reject);
                          }}
                        >
                          {reject ? '🗣️ Rejected ' : 'Reject Approval'}
                        </button> */}

                        <Button
                          type="submit"
                          content={"☑️ Approve"}
                          loading={approveLoading}
                          btnColor="success/60"
                          onClick={() => {
                            approveCampaign();
                          }}
                          Style={`text-white h-[40px] mt-2 rounded-lg text-white  ${
                            campaignRequestData?.status !== "approved" &&
                            role == 3
                              ? "flex"
                              : "hidden"
                          } p-2 font-bold w-fit mt-4 `}
                        />

                        <button
                          className={`rounded-lg text-white bg-success bg-opacity-60 p-2 font-bold w-fit mt-4  ${
                            permission === "APPROVER" ? "flex" : "hidden"
                          } ${reject ? "flex" : "hidden"}`}
                        >
                          undo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col border-l-2 border-black/20 ml-4 pl-2 w-1/5 ">
                  {campaignObject?.campaign_requests?.map((request, index) => {
                    return (
                      <div
                        className={`border border-black/20 p-2 flex flex-col rounded-lg cursor-pointer ${
                          clickedIndex === index && isCampaignRequestClicked
                            ? "bg-gray/20"
                            : "bg-white"
                        }`}
                        onClick={() => {
                          setIsCampaignRequestClicked(
                            !isCampaignRequestClicked
                          );
                          setCampaignRequestData(request);
                          setClickedIndex(index);
                          setRequestId(request.id);
                        }}
                      >
                        <div>
                          {" "}
                          <h1 className="font-bold">
                            Campaign request {index + 1}
                          </h1>
                          <div className="flex flex-row items-center gap-2">
                            <h1 className="">On</h1>
                            <p className="flex items-center py-2 text-sm font-bold text-light">
                              {formatDate(request.created_at)}
                              <span className="h-[5px] w-[5px] bg-light rounded-full mx-2"></span>
                              {formatTime(request.created_at)}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.DismissButton>
            <p className="text-xl">Close</p>
          </Modal.DismissButton>
        </Modal.Footer>
      </Modal>

      <ToasterComponent />
    </div>
  );
};

export default CampaignDetails;
