import React, { useState, useEffect } from 'react';
import Axios from '../../../components/Axios';
import { useForm } from 'react-hook-form';
import Modal from '../../../components/modal/Modal';
import Button from '../../../components/Button';
import { ToastComponent, ToasterComponent } from '../../../components/Toast';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Drag from '../../../components/Drag';
import { APP } from '../../../utils/constant';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBanks } from '../../../redux/actions/bank';

//import { token } from '../../../Routes/AdvertiserRoutes';

const AddBank = ({
  openModal,
  handleOpenModal,
  data,
  setData,
  files,
  setFiles,
  isError,
  setIsError,
  edit,
  config,
  setEdit,
  setToggleData,
  toggleData,
  handleOpenAccountModal,
  handleConfirmAddAccountModal,
}) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setError,
  } = useForm();

  const [showButtons, setShowButtons] = useState(true);
  const [countries, setCountries] = useState([]);

  const handleShowButtons = () => {
    setShowButtons(!showButtons);
  };

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];

  const onSubmit = () => {
    try {
      if (files?.length === 0) {
        setIsError(true);
        setTimeout(() => {
          setIsError(false);
        }, 5000);
        console.log(isError, 'isError');
      } else {
        edit
          ? Axios.put(
              APP.ADVERTISER_API_URL + `/account/bank-details`,
              { ...data, logo: files[0] },
              config
            )
              .then((res) => {
                notify('success', 'updated successfully!');
                console.log({ ...data, logo: files[0] }, '....data');
                reset();
                setEdit(false);
                setToggleData(!toggleData);
                setFiles([]);
              })
              .catch((err) => {
                notify('error', 'request failed, please try again');
                console.log(err);
              })
          : Axios.post(
              APP.ADVERTISER_API_URL + '/admin/banks',
              { ...data, logo: files[0] },
              config
            )
              .then((res) => {
                reset();
                dispatch(fetchBanks());
                setToggleData(!toggleData);
                handleOpenModal();
                handleConfirmAddAccountModal();
              })
              .catch((err) => {
                notify('error', 'request failed, please try again');
                console.log('error in saving bank', err);
              });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="overflow-y-auto">
      <ToasterComponent />
      <form
        className="flex-col text-sm mx-auto w-3/4 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Modal
          isOpen={openModal}
          onClose={() => {
            reset();
            handleOpenModal();
          }}
          Style={'w-[90%] lg:w-1/3  flex m-auto py-4'}
        >
          <Modal.Header> Add bank</Modal.Header>
          <Modal.Body>
            <div className="flex flex-col px-4 ">
              <Input
                labelText={'Bank name'}
                labelFor={'Bank name'}
                name={'bankname'}
                type={'text'}
                placeholder={'enter bank name'}
                isRequired={true}
                customClass="w-full my-1"
                onChange={(e) => {
                  e.target.value.length > 0
                    ? errors.bankname && delete errors.bankname
                    : (errors.bankname = { message: 'Name is required' });
                  setData((prevData) => {
                    return { ...prevData, name: e.target.value };
                  });
                }}
                register={register}
                errors={errors}
              />

              <Input
                labelText={'Swift code'}
                labelFor={'Swift code'}
                name={`swift_code`}
                type={'text'}
                placeholder={'enter swift code'}
                isRequired={false}
                customClass="w-full my-1"
                onChange={(e) => {
                  setData((prevData) => {
                    return { ...prevData, swift_code: e.target.value };
                  });
                }}
                register={register}
                errors={errors}
              />
              <Select
                label={'Status'}
                labelFor={'status'}
                className="w-full"
                isLoading={false}
                placeholder={'Select status'}
                onChange={(e) => {
                  setData((prevData) => {
                    return { ...prevData, status: e.value };
                  });
                }}
                options={statusOptions}
              />

              <div className="flex flex-col mt-4">
                <h2>Upload logo</h2>
                <Drag
                  style="w-full "
                  flexStyle="flex flex-col space-y-8"
                  uploadLimit={0}
                  files={files}
                  setFiles={setFiles}
                />
                <h2 className="text-error text-lg">
                  {isError ? 'Logo is missing' : ''}
                </h2>
              </div>
              <Button
                type="submit"
                content={'Submit'}
                loading={false}
                btnColor="primary"
                Style={'bottom-[0px] text-white p-8'}
              />
            </div>
          </Modal.Body>
        </Modal>
      </form>
    </div>
  );
};

export default AddBank;
