import React, { useState, useEffect } from 'react';
import Modal from '../../../../components/modal/Modal';
import PaymentWalletDeposit from './PaymentWalletDeposit';
import PaymentWalletTransaction from './PaymentWalletTransaction';
import PaymentWalletTransfer from './PaymentWalletTransfer';
import MobileWithdraw from './MobileWithdraw';
import BankWithdraw from './BankWithdraw';
import { Tab } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import {
  fetchCreditWallets,
  fetchPaymentWallets,
} from '../../../../redux/actions/wallet';

const PaymentWalletDetails = ({
  openModal,
  handleOpenModal,
  paymentWalletId,
}) => {
  const dispatch = useDispatch();
  const [selectedContent, setSelectedContent] = useState('Deposits');

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <Modal
      isOpen={openModal}
      onClose={handleOpenModal}
      Style={'w-[90%] lg:w-5/6 h-[95%] flex flex-col m-auto py-4'}
    >
      <Modal.Header>
        <h1 className="font-bold text-lg">PAYMENT WALLET DETAILS</h1>
      </Modal.Header>
      <Modal.Body>
        <Tab.Group>
          <Tab.List className="w-full overflow-x-auto mb-4 flex flex-row gap-2">
            <Tab
              className={({ selected }) =>
                classNames(
                  'flex w-fit items-center justify-between rounded-lg p-2 text-[16px] text-primary px-4',
                  'ring-white ring-opacity-60 outline-none',
                  selected ? 'bg-zinc-200 text-primary' : 'hover:bg-zinc-200'
                )
              }
              onClick={() => {
                setSelectedContent('Transactions');
              }}
            >
              <div className="flex gap-4 cursor-pointer items-center">
                {/* <MdProductionQuantityLimits size={20} className="" /> */}
                <h1 className="font-bold">Transactions</h1>
              </div>
            </Tab>

            <Tab
              className={({ selected }) =>
                classNames(
                  'flex w-fit items-center justify-between rounded-lg p-2 text-[16px] text-primary px-4',
                  'ring-white ring-opacity-60 outline-none',
                  selected ? 'bg-zinc-200 text-primary' : 'hover:bg-zinc-200'
                )
              }
              onClick={() => {
                setSelectedContent('Deposits');
              }}
            >
              <div className="flex gap-4 cursor-pointer items-center">
                {/* <MdProductionQuantityLimits size={20} className="" /> */}
                <h1 className="font-bold">Deposits</h1>
              </div>
            </Tab>

            <Tab
              className={({ selected }) =>
                classNames(
                  'flex w-fit items-center justify-between rounded-lg p-2 text-[16px] text-primary px-4',
                  'ring-white ring-opacity-60 outline-none',
                  selected ? 'bg-zinc-200 text-primary' : 'hover:bg-zinc-200'
                )
              }
              onClick={() => {
                setSelectedContent('Transfers');
              }}
            >
              <div className="flex gap-4 cursor-pointer items-center">
                {/* <MdProductionQuantityLimits size={20} className="" /> */}
                <h1 className="font-bold">Transfers</h1>
              </div>
            </Tab>

            <Tab
              className={({ selected }) =>
                classNames(
                  'flex w-fit items-center justify-between rounded-lg p-2 text-[16px] text-primary px-4',
                  'ring-white ring-opacity-60 outline-none',
                  selected ? 'bg-zinc-200 text-primary' : 'hover:bg-zinc-200'
                )
              }
              onClick={() => {
                setSelectedContent('mobileWithdraw');
              }}
            >
              <div className="flex gap-4 cursor-pointer items-center">
                {/* <MdProductionQuantityLimits size={20} className="" /> */}
                <h1 className="font-bold">Mobile Withdraws</h1>
              </div>
            </Tab>

            <Tab
              className={({ selected }) =>
                classNames(
                  'flex w-fit items-center justify-between rounded-lg p-2 text-[16px] text-primary px-4',
                  'ring-white ring-opacity-60 outline-none',
                  selected ? 'bg-zinc-200 text-primary' : 'hover:bg-zinc-200'
                )
              }
              onClick={() => {
                setSelectedContent('bankWithdraw');
              }}
            >
              <div className="flex gap-4 cursor-pointer items-center">
                {/* <MdProductionQuantityLimits size={20} className="" /> */}
                <h1 className="font-bold">Bank Withdraws</h1>
              </div>
            </Tab>
          </Tab.List>

          <div className="w-full px-4">
            <Tab.Panels>
              <Tab.Panel
                className={selectedContent === 'Transactions' ? '' : 'hidden'}
              >
                <PaymentWalletTransaction paymentWalletId={paymentWalletId} />
              </Tab.Panel>

              <Tab.Panel
                className={selectedContent === 'Deposits' ? '' : 'hidden'}
              >
                <PaymentWalletDeposit />
              </Tab.Panel>

              <Tab.Panel
                className={selectedContent === 'Transfers' ? '' : 'hidden'}
              >
                <PaymentWalletTransfer />
              </Tab.Panel>

              <Tab.Panel
                className={selectedContent === 'mobileWithdraw' ? '' : 'hidden'}
              >
                <MobileWithdraw />
              </Tab.Panel>

              <Tab.Panel
                className={selectedContent === 'bankWithdraw' ? '' : 'hidden'}
              >
                <BankWithdraw />
              </Tab.Panel>
            </Tab.Panels>
          </div>
        </Tab.Group>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentWalletDetails;
