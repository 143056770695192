import React from "react";
import TextArea from "../../../../../components/TextArea";
import Select from "../../../../../components/Select";
import Input from "../../../../../components/Input";
import { RiErrorWarningLine } from "react-icons/ri";
import CircularLoader from "../../../../../components/CircularLoader";

function AddKinyarwandaCategory({
  clearErrors,
  register,
  control,
  errors,
  name,
  setNames,
  translatedRw,
  setTranslatedRw,
  translationLoading,
  isTranslated,
  lastMod,
  setLastMod,
  typeOptions,
}) {
  const handleAddName = (e) => {
    const newName = e.target.value;
    setTranslatedRw({ ...translatedRw, name: newName });

    setLastMod((prev) => ({
      ...prev,
      name: { ...prev.name, rw: newName },
    }));
  };

  const handleAddDesc = (e) => {
    const newDesc = e.target.value;
    setNames({ ...translatedRw, description: newDesc });
    setLastMod((prev) => ({
      ...prev,
      description: { ...prev.description, rw: newDesc },
    }));
  };

  return (
    <div>
      {translationLoading ? (
        <div className="flex items-center w-full mt-4">
          <div className="flex gap-2 items-center m-auto">
            <CircularLoader />
            <p>Translating...</p>
          </div>
        </div>
      ) : null}
      {isTranslated ? (
        <div className="flex gap-2 mt-6 mb-2">
          <RiErrorWarningLine size={30} color="blue" />
          <h1>Suzuma neza ibyahinduwe</h1>
        </div>
      ) : (
        ""
      )}
      <Input
        labelText={"Izina"}
        labelFor={"name"}
        name={`name`}
        type={"text"}
        value={lastMod?.name["rw"]}
        placeholder={"Andika izina ry'icyiciro"}
        isRequired={true}
        customClass="w-full mb-2 w-[75%] ml-1"
        onChange={(e) => {
          clearErrors("izina");
          handleAddName(e);
        }}
        register={register}
        errors={errors}
      />
      <TextArea
        labelFor={"description"}
        labelText="Ibisobanuro"
        name={`ubusobanuro`}
        errors={errors}
        placeholder={`Andika ibisobanuro by'icyikiro`}
        customClass={"w-full mb-2 text-[18px]"}
        isRequired={true}
        register={register}
        value={lastMod?.description["rw"]}
        onChange={(e) => {
          clearErrors("ubusobanuro");
          handleAddDesc(e);
        }}
      />
      <Select
        label="Content type"
        customClass=" w-full mb-2 text-[18px]"
        isRequired={false}
        placeholder={"Content type"}
        options={typeOptions}
        register={{
          control,
          name: `contentType`,
          rules: { required: "contentType is required" },
        }}
        defaultValue={
          lastMod?.content_type ? lastMod?.content_type : lastMod.content_type
        }
        handleChange={(e) => {
          setLastMod((prev) => ({
            ...prev,
            content_type: e,
          }));
        }}
        error={errors[`contentType`]?.message}
      />
    </div>
  );
}

export default AddKinyarwandaCategory;
