import React from "react";
import Input from "../../../../../components/Input";
import TextArea from "../../../../../components/TextArea";
import Select from "../../../../../components/Select";
import { HiMinus, HiPlus } from "react-icons/hi";
import { Tooltip } from "react-tooltip";
import { RiErrorWarningLine } from "react-icons/ri";
import CircularLoader from "../../../../../components/CircularLoader";
import FileUpload from "../../../../../components/MultipleFileUpload";

function AddNewArticleEn({
  clearErrors,
  register,
  errors,
  planData,
  setPlanData,
  translationLoading,
  isTranslated,
  files64,
  setFiles64,
}) {
  const handleTitle = (e) => {
    const { value } = e.target;

    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      title: { ...prevPlanData.title, en: value },
    }));
  };
  const handleDescription = (e) => {
    const { value } = e.target;
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      body: { ...prevPlanData.body, en: value },
    }));
  };

  return (
    <div>
      {translationLoading ? (
        <div className="flex items-center w-full mt-4">
          <div className="flex gap-2 items-center m-auto">
            <CircularLoader />
            <p>Translating...</p>
          </div>
        </div>
      ) : null}
      {isTranslated ? (
        <div className="flex gap-2 mt-6 mb-2">
          <RiErrorWarningLine size={30} color="blue" />
          <h1>Check the translated texts carefully</h1>
        </div>
      ) : (
        ""
      )}
      <Input
        labelText={"Title"}
        labelFor={"title"}
        name={`title `}
        type={"text"}
        isRequired={true}
        placeholder={"Enter title"}
        onChange={(e) => {
          handleTitle(e);
        }}
        value={planData.title["en"]}
        customClass="w-full mb-2 mt-1"
        register={register}
        errors={errors}
      />
      <TextArea
        labelText={"Compose content"}
        labelFor={"content"}
        name={`content`}
        type={"text"}
        isRequired={true}
        placeholder={"Compose content"}
        value={planData.body["en"]}
        onChange={(e) => {
          handleDescription(e);
        }}
        customClass="w-full mb-4"
        register={register}
        errors={errors}
      />

      <div className="flex flex-col mt-4">
        <h2 className="text-bold text-[18px]">Upload file</h2>
        <FileUpload files64={files64} setFiles64={setFiles64} />
      </div>
    </div>
  );
}

export default AddNewArticleEn;
