import React, { Fragment, useEffect } from "react";
import Table from "../components/tableComponent/Table";
import { Popover, Transition } from "@headlessui/react";
import { FiMoreVertical } from "react-icons/fi";
import AudienceModal from "./modals/AudienceModal";
import SkeletonTable from "../components/SkeletonTable";
import { skeletonData, skeletonColumns } from "../seeds/Data";
import Axios from "../components/Axios";
import { ToastComponent, ToasterComponent } from "../components/Toast";
import { APP } from "../utils/constant";

const Advertisers = ({ userFetching, config }) => {
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [userId, setUserId] = React.useState();
  const [filteredAudience, setFilteredAudience] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [sysUser, setSysUser] = React.useState([]);
  const [activate, setActivate] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const getSystemUsers = () => {
    Axios.get(APP.API_MAIN_URL + `/admin/users/standard`, config)
      .then((res) => {
        console.log(res?.data?.data, "system users");
        setSysUser(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteSystemUser = (id) => {
    Axios.delete(APP.API_MAIN_URL + `/admin/users/standard/${id}`, config)
      .then((res) => {
        console.log(res, "delete");
        setToggle(!toggle);
        notify("success", "User deleted successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const activateUser = (id) => {
    setActivate(true);
    Axios.put(
      APP.API_MAIN_URL + `/admin/users/standard/${id}/activate`,
      {},
      config
    )
      .then((res) => {
        setActivate(false);
        setToggle(!toggle);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const disactivateUser = (id) => {
    setActivate(true);
    Axios.put(
      APP.API_MAIN_URL + `/admin/users/standard/${id}}/deactivate`,
      {},
      config
    )
      .then((res) => {
        setActivate(false);
        console.log(res.data);
        setToggle(!toggle);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSystemUsers();
  }, [toggle]);

  const columns = [
    {
      Header: "First name",
      accessor: "first_name",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  w-max">
            <h1 className="font-bold">{row.original.first_name}</h1>
          </div>
        );
      },
    },
    {
      Header: "Last name",
      accessor: "last_name",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  w-max">
            <h1 className="font-bold">{row.original.last_name}</h1>
          </div>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  w-max">
            <h1 className="font-bold">{row.original.email}</h1>
          </div>
        );
      },
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  w-max">
            <h1 className="font-bold">{row.original.phone}</h1>
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        return (
          <div
            className={`flex flex-row  w-full p-2 items-center justify-center bg-opacity-20 ${
              row?.original?.status === "active" ? "bg-success" : "bg-error"
            }`}
          >
            <h1 className="font-bold">{row.original.status}</h1>
          </div>
        );
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  font-bold">
            <Popover className="relative">
              <Popover.Button>
                <div className="flex">
                  <FiMoreVertical size={30} className="cursor-pointer" />
                </div>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 font-bold">
                  <div className="flex flex-col bg-white  shadow-md border border-black/10  rounded-sm text-xs w-max">
                    {row?.original?.status === "active" ? (
                      <div
                        className="px-4 py-1 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                        onClick={() => {
                          disactivateUser(row?.original?.id);
                        }}
                      >
                        dis-activate
                      </div>
                    ) : (
                      <div
                        className="px-4 py-1 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                        onClick={() => {
                          activateUser(row?.original?.id);
                        }}
                      >
                        activate
                      </div>
                    )}
                    <div
                      className="px-4 py-1 w-full text-error hover:bg-[#D9D9D9] text-center cursor-pointer"
                      onClick={() => {
                        deleteSystemUser(row?.original?.id);
                      }}
                    >
                      delete
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
        );
      },
    },
  ];
  console.log(filteredAudience, "...........i878");
  return (
    <div className="content p-4 over h-full overflow-y-scroll">
      <AudienceModal
        open={open}
        setOpen={setOpen}
        audience={filteredAudience}
      />
      {userFetching ? (
        <SkeletonTable columns={skeletonColumns} data={skeletonData} />
      ) : (
        <Table columns={columns} data={sysUser} />
      )}
    </div>
  );
};

export default Advertisers;
