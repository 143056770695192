import React, { useState, Fragment } from 'react';
import Modal from '../../../../components/modal/Modal';
import Table from '../../../../components/tableComponent/Table';
import { skeletonColumns, skeletonData } from '../../../../seeds/Data';
import SkeletonTable from '../../../../components/SkeletonTable';
import { useSelector } from 'react-redux';
import { FiMoreVertical } from 'react-icons/fi';
import { Popover, Transition } from '@headlessui/react';

const PaymentWalletDeposit = ({ openModal, handleOpenModal }) => {
  const { is_fetching_payment_wallet_deposits, payment_wallet_deposits } =
    useSelector((state) => state.wallet);

  const columns = [
    {
      Header: 'Date',
      accessor: '',
      Cell: ({ row }) => {
        return <div>{row.original.created_at.split('T')[0]}</div>;
      },
    },
    {
      Header: 'Reference',
      accessor: 'reference',
    },
    {
      Header: 'Amount',
      accessor: 'total_amount',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Names',
      accessor: '',
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.wallet?.profile?.first_name +
              ' ' +
              row.original.wallet?.profile?.last_name}
          </div>
        );
      },
    },
  ];

  return (
    <div className="overflow-x-auto my-auto">
      {!is_fetching_payment_wallet_deposits ? (
        payment_wallet_deposits?.length == 0 ? (
          <p className="text-[20px] text-[#808080] font-bold flex justify-center items-center mt-6">
            There are no Payment Wallet Deposits yet for this Wallet, Deposits
            will appear here.
          </p>
        ) : (
          <Table
            columns={columns}
            initialSize={10}
            data={
              payment_wallet_deposits?.length > 0 ? payment_wallet_deposits : []
            }
            className="mx-0"
          />
        )
      ) : (
        <SkeletonTable columns={skeletonColumns} data={skeletonData} />
      )}
    </div>
  );
};

export default PaymentWalletDeposit;
