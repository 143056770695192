import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from '../../../../components/modal/Modal';
import Button from '../../../../components/Button';
import { ToastComponent } from '../../../../components/Toast';
import { APP } from '../../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBankWithdraws } from '../../../../redux/actions/wallet';
import PaymentProof from './PaymentProof';

const WithdrawDetails = ({
  openModal,
  handleOpenModal,
  withdrawId,
  withdrawDetailsData,
  isFetchingData,
}) => {
  const dispatch = useDispatch();
  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const { token } = useSelector((state) => state.user);

  const [initiateLoading, setInitiateLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [paymentWalletId, setPaymentWalletId] = useState('');
  const [openPaymentProof, setOpenPaymentProof] = useState(false);
  const [paymentProof, setPaymentProof] = useState({});
  const [isFetchingPaymentProof, setIsFetchingPaymentProof] = useState(false);
  const handleOpenPaymentProof = () => {
    setOpenPaymentProof(!openPaymentProof);
  };

  const handleInitiateWithdraw = () => {
    setInitiateLoading(true);

    axios
      .put(
        APP.API_MAIN_URL +
          `/admin/wallets/payout/${withdrawDetailsData.wallet_id}/withdraws/bank-withdraws/${withdrawDetailsData.id}/initiate`,
        {
          status: 'approved',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        setInitiateLoading(false);
        dispatch(fetchBankWithdraws(withdrawDetailsData.wallet_id));
        // setFetchedCreditWallets(res.data.data);
      })
      .catch((error) => {
        setInitiateLoading(false);
        console.log(error);
      });
  };

  const handleApproveWithdraw = () => {
    setApproveLoading(true);

    axios
      .put(
        APP.API_MAIN_URL +
          `/admin/wallets/payout/${withdrawDetailsData.wallet_id}/withdraws/bank-withdraws/${withdrawDetailsData.id}/approve`,
        {
          status: 'approved',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        setApproveLoading(false);
        dispatch(fetchBankWithdraws(withdrawDetailsData.wallet_id));
        // setFetchedCreditWallets(res.data.data);
      })
      .catch((error) => {
        setApproveLoading(false);
        console.log(error);
      });
  };

  const fetchPaymentProof = (walletId, withdrawId) => {
    setIsFetchingPaymentProof(true);
    axios
      .get(
        APP.API_MAIN_URL +
          `/admin/wallets/payout/${walletId}/withdraws/bank-withdraws/${withdrawId}/payments`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        setIsFetchingPaymentProof(false);
        setPaymentProof(res.data.data);
      })
      .catch((error) => {
        setIsFetchingPaymentProof(false);
        console.log(error);
      });
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        onClose={() => {
          handleOpenModal();
        }}
        Style={'w-[90%] lg:w-5/6 h-[90%] flex m-auto py-4'}
      >
        <Modal.Header>
          <h1 className="font-bold">Withdraw Details</h1>
        </Modal.Header>
        <Modal.Body>
          {isFetchingData ? (
            'Loading data...'
          ) : (
            <div className="flex flex-col gap-2">
              <div className="flex flex-col lg:flex-row gap-2">
                <p className="font-bold">Ref:</p>
                <p className="">{withdrawDetailsData?.reference}</p>
              </div>
              <div className="flex flex-col lg:flex-row gap-2">
                <p className="font-bold">Date:</p>
                <p className="">
                  {withdrawDetailsData?.created_at?.split('T')[0]}
                </p>
              </div>

              <div className="flex flex-col lg:flex-row gap-2">
                <p className="font-bold">Total Amount:</p>
                <p>{withdrawDetailsData?.total_amount}</p>
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex flex-col lg:flex-row gap-2">
                  <p className="font-bold">Bank Name:</p>
                  <p>{}</p>
                </div>

                <div className="flex flex-col lg:flex-row gap-2">
                  <p className="font-bold">Account Number:</p>
                  <p>
                    {withdrawDetailsData?.bank_account_detail?.account_number}
                  </p>
                </div>

                <div className="flex flex-col lg:flex-row gap-2">
                  <p className="font-bold">Account Name:</p>
                  <p>
                    {withdrawDetailsData?.bank_account_detail?.account_name}
                  </p>
                </div>
              </div>

              <div className="flex flex-col lg:flex-row gap-2">
                <p className="font-bold">Verification status:</p>
                <p>{withdrawDetailsData?.verification_status}</p>
              </div>

              <div className="flex flex-col lg:flex-row gap-2">
                <p className="font-bold">Initialization status:</p>
                <p>{withdrawDetailsData?.initialization_status}</p>
              </div>

              {withdrawDetailsData?.initialization_status === 'approved' ? (
                <div className="flex flex-col lg:flex-row gap-2">
                  <p className="font-bold"> Initialized By:</p>
                  <p>
                    {withdrawDetailsData?.initialized_by?.first_name +
                      ' ' +
                      withdrawDetailsData?.initialized_by?.last_name}
                  </p>
                </div>
              ) : null}

              <div className="flex flex-col lg:flex-row gap-2">
                <p className="font-bold">Approval status:</p>
                <p>{withdrawDetailsData?.approval_status}</p>
              </div>

              {withdrawDetailsData?.approval_status === 'approved' ? (
                <div className="flex flex-col lg:flex-row gap-2">
                  <p className="font-bold"> Approved By:</p>
                  <p>
                    {withdrawDetailsData?.approved_by?.first_name +
                      ' ' +
                      withdrawDetailsData?.approved_by?.last_name}
                  </p>
                </div>
              ) : null}

              <div className="flex flex-col lg:flex-row gap-2">
                <p className="font-bold">Status:</p>
                <p>{withdrawDetailsData?.status}</p>
              </div>

              <Button
                type="button"
                content="Payment Proof"
                loading={false}
                onClick={() => {
                  fetchPaymentProof(
                    withdrawDetailsData.wallet_id,
                    withdrawDetailsData.id
                  );
                  handleOpenPaymentProof();
                }}
                btnColor="primary"
                Style={`text-white w-fit h-[40px] mt-8 ml-0 font-bold`}
              />

              {withdrawDetailsData?.status === 'completed' ? null : (
                <div className="flex flex-col border border-error rounded-lg p-2">
                  <p className="font-bold text-light">Attention : ⚠️</p>

                  <div className={`flex flex-row gap-4 `}>
                    <Button
                      type="button"
                      content="Initiate"
                      loading={initiateLoading}
                      onClick={() => {
                        handleInitiateWithdraw();
                      }}
                      btnColor="primary"
                      Style={`text-white w-fit h-[40px] mt-8 ml-0 font-bold ${
                        withdrawDetailsData?.initialization_status ===
                        'approved'
                          ? 'hidden'
                          : ''
                      }`}
                    />

                    <Button
                      type="button"
                      content="Approve"
                      loading={approveLoading}
                      onClick={() => {
                        handleApproveWithdraw();
                      }}
                      btnColor="primary"
                      Style={`text-white w-fit h-[40px] mt-8 ml-0 font-bold ${
                        withdrawDetailsData?.approval_status === 'approved'
                          ? 'hidden'
                          : ''
                      }`}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>

      <PaymentProof
        openModal={openPaymentProof}
        handleOpenModal={handleOpenPaymentProof}
        proofDetails={paymentProof}
        isFetchingPaymentProof={isFetchingPaymentProof}
      />
    </>
  );
};

export default WithdrawDetails;
