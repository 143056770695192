import React, { useEffect } from 'react';
import BankInformation from './resourcesItems/BankInformation';
import QrCode from './resourcesItems/QrCode';
import SenderName from './resourcesItems/SenderName';
import CompanyInfo from './resourcesItems/CompanyInfo';
import Feedback from './resourcesItems/Feedback';
import Wallet from './resourcesItems/Wallets/Wallet';
import MobileMoney from './resourcesItems/mobileMoney/MobileMoney';

const Resources = ({
  toggleItem,
  config,
  SkeletonTable,
  skeletonData,
  skeletonColumns,
  feedbacks,
}) => {
  const params = new URLSearchParams(window.location.search);
  const item = params.get('item');
  return (
    <div className="content flex lg:flex-row flex-col space-y-8 font-nunito  bg-secondary h-full overflow-y-scroll">
      <div className="flex p-2 w-full h-max">
        <div className="bg-secondary border border-white shadow-lg w-full h-max">
          {item === 'banks' ? (
            <BankInformation
              config={config}
              SkeletonTable={SkeletonTable}
              skeletonData={skeletonData}
              skeletonColumns={skeletonColumns}
            />
          ) : item === 'qrCodes' ? (
            <QrCode config={config} />
          ) : item === 'sendername' ? (
            <SenderName
              config={config}
              SkeletonTable={SkeletonTable}
              skeletonData={skeletonData}
              skeletonColumns={skeletonColumns}
            />
          ) : item === 'info' ? (
            <CompanyInfo />
          ) : item === 'feedback' ? (
            <Feedback feedbacks={feedbacks} />
          ) : item === 'wallets' ? (
            <Wallet />
          ) : item === 'mobileMoney' ? (
            <MobileMoney />
          ) : null}
        </div>
      </div>
      {/* end of right side form */}
    </div>
  );
};

export default Resources;
