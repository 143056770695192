import React, { useState } from "react";
import axios from "axios";
import Modal2 from "../../../../components/modal/Modal2";
import { useForm } from "react-hook-form";
import Button from "../../../../components/Button";

import "react-tooltip/dist/react-tooltip.css";

import { ToastComponent } from "../../../../components/Toast";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { IoArrowBackOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { APP, config } from "../../../../utils/constant";

import AddNewArticleEn from "./languages/AddNewArticleEn";

import AddNewArticleRw from "./languages/AddNewArticleRw";
import AddNewArticleFr from "./languages/AddNewArticleFr";
import { getPlans } from "../../../../redux/actions/subscription";

const AddNewArticle = ({
  openModal,
  handleModal,
  selectedCategory,
  selectedLangue,
}) => {
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [files64, setFiles64] = useState([]);
  const [planData, setPlanData] = useState({
    title: {},
    body: {},
  });
  const [finalData, setFinalData] = useState({
    title: {},
    body: {},
    files: [
      {
        tag: "",
        file: "",
      },
    ],
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    setError,
    control,
  } = useForm();
  const [steps, setSteps] = useState([0]);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const [translationLoading, setTranslationLoading] = useState(false);
  const [isTranslatedToRw, setIsTranslatedToRw] = useState(false);
  const [isTranslatedToEn, setIsTranslatedToEn] = useState(false);
  const [isTranslatedToFr, setIsTranslatedToFr] = useState(false);

  const handleNext = () => {
    let newStep = steps;
    if (activeStep === selectedLangue.length - 1) {
      setLoading(true);

      axios
        .post(
          APP.API_MAIN_URL +
            `/admin/vas/categories/${selectedCategory.id}/articles`,
          { body: planData.body, title: planData.title, files: files64 },
          config(token)
        )
        .then((res) => {
          setLoading(false);
          notify("success", "Plan successfully saved!");
          dispatch(getPlans(selectedCategory.id));
          setPlanData({
            title: {},
            body: {},
          });
          setFiles64([]);
          handleModal();
        })
        .catch((error) => {
          notify("error", error.response.data.message);
          setLoading(false);
        });
    }
    if (
      newStep.length === activeStep + 1 &&
      steps.length < selectedLangue.length &&
      activeStep <= selectedLangue.length
    ) {
      newStep.push(activeStep + 1);
      setSteps(newStep);
    }
    if (activeStep === 0) {
      const planObj = {
        title:
          planData.title[
            selectedLangue[0].language_id === 1
              ? "rw"
              : selectedLangue[0].language_id === 2
              ? "en"
              : "fr"
          ],
        body: planData.body[
          [
            selectedLangue[0].language_id === 1
              ? "rw"
              : selectedLangue[0].language_id === 2
              ? "en"
              : "fr",
          ]
        ],
      };

      const dataObj = {};

      dataObj.title = planObj.title;
      dataObj.body = planObj.body;

      const benObj = {
        contents: planObj,
        current_language:
          selectedLangue[0].language_id === 1
            ? "rw"
            : selectedLangue[0].language_id === 2
            ? "en"
            : "fr",
        target_language:
          selectedLangue[1].language_id === 1
            ? "rw"
            : selectedLangue[1].language_id === 2
            ? "en"
            : "fr",
      };

      setTranslationLoading(true);
      selectedLangue[1].language_id === "1"
        ? setIsTranslatedToRw(true)
        : selectedLangue[1].language_id === "3"
        ? setIsTranslatedToFr(true)
        : setIsTranslatedToEn(true);
      axios
        .post(
          APP.API_MAIN_URL + "/utils/translations/translate",
          benObj,
          config(token)
        )
        .then((res) => {
          setTranslationLoading(false);
          console.log("RESPONSE FOR TRANSLATION", res.data);
          const inputObject = res.data.translated_contents;
          const cod =
            selectedLangue[1].language_id === 1
              ? "rw"
              : selectedLangue[1].language_id === 2
              ? "en"
              : "fr";

          setPlanData((prevPlanData) => ({
            ...prevPlanData,
            body: { ...prevPlanData.body, [cod]: inputObject.body },
            title: { ...prevPlanData.title, [cod]: inputObject.title },
          }));
        })
        .catch((error) => {
          setTranslationLoading(false);
          console.log("Error in translating", error);
        });

      if (activeStep < steps.length && activeStep < selectedLangue.length - 1) {
        let newSkipped = skipped;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    }

    if (activeStep === 1) {
      const planObj = {
        title:
          planData.title[
            selectedLangue[0].language_id === 1
              ? "rw"
              : selectedLangue[0].language_id === 2
              ? "en"
              : "fr"
          ],
        body: planData.body[
          selectedLangue[0].language_id === 1
            ? "rw"
            : selectedLangue[0].language_id === 2
            ? "en"
            : "fr"
        ],
      };

      const benObj = {
        contents: planObj,
        current_language:
          selectedLangue[0].language_id === 1
            ? "rw"
            : selectedLangue[0].language_id === 2
            ? "en"
            : "fr",
        target_language:
          selectedLangue[2].language_id === 1
            ? "rw"
            : selectedLangue[2].language_id === 2
            ? "en"
            : "fr",
      };

      setTranslationLoading(true);
      selectedLangue[2].language_id === "1"
        ? setIsTranslatedToRw(true)
        : selectedLangue[2].language_id === "3"
        ? setIsTranslatedToFr(true)
        : setIsTranslatedToEn(true);
      axios
        .post(
          APP.API_MAIN_URL + "/utils/translations/translate",
          benObj,
          config(token)
        )
        .then((res) => {
          setTranslationLoading(false);
          console.log("RESPONSE FOR TRANSLATION", res.data);
          const inputObject = res.data.translated_contents;
          const cod =
            selectedLangue[2].language_id === 1
              ? "rw"
              : selectedLangue[2].language_id === 2
              ? "en"
              : "fr";
          setPlanData((prevPlanData) => ({
            ...prevPlanData,
            body: { ...prevPlanData.body, [cod]: inputObject.body },
            title: { ...prevPlanData.title, [cod]: inputObject.title },
          }));
        })
        .catch((error) => {
          setTranslationLoading(false);
          console.log("Error in translating", error);
        });
      if (
        activeStep < steps.length &&
        activeStep !== selectedLangue.length - 1
      ) {
        let newSkipped = skipped;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const [loading, setLoading] = useState(false);

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const onSubmit = () => {
    // function transformData(inputData) {
    //   const transformedData = {
    //     name: {
    //       en: "",
    //       fr: "",
    //       rw: "",
    //     },
    //     description: {
    //       en: "",
    //       fr: "",
    //       rw: "",
    //     },
    //     currency_id: 0,
    //     subscription_type: "",
    //     amount: 0,
    //     max_daily_articles: 0,
    //     benefits: [],
    //   };
    //   inputData.forEach((plan) => {
    //     transformedData.name[plan.lan] = plan.name;
    //     transformedData.description[plan.lan] = plan.description;
    //     transformedData.currency_id = plan.currency_id;
    //     transformedData.subscription_type = plan.duration_type;
    //     transformedData.amount = parseFloat(plan.price) || 0;
    //     transformedData.max_daily_articles =
    //       parseInt(plan.max_daily_articles) || 0;
    //     transformedData.benefits.push({
    //       name: {
    //         en: plan.benefits[0].name,
    //         fr: plan.benefits[0].name,
    //         rw: plan.benefits[0].name,
    //       },
    //       description: {
    //         en: plan.benefits[0].description,
    //         fr: plan.benefits[0].description,
    //         rw: plan.benefits[0].description,
    //       },
    //     });
    //   });
    //   return transformedData;
    // }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal2
          isOpen={openModal}
          onClose={() => {
            handleModal();
            reset();
          }}
          Style={"w-[90%] lg:w-1/2  flex m-auto py-4"}
        >
          <Modal2.Header>
            <h1 className="font-bold">Create Content</h1>
          </Modal2.Header>
          <Modal2.Body>
            <div className="flex flex-col px-4">
              <Box sx={{ width: "100%" }}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    return (
                      <Step
                        key={label}
                        {...stepProps}
                        sx={{
                          "& .MuiStepLabel-root .Mui-completed": {
                            color: "black", // circle color (COMPLETED)
                          },
                          "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                            {
                              color: "grey.500", // Just text label (COMPLETED)
                            },

                          "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active":
                            {
                              color: "black",
                              border: "1px solid gray",
                              borderRadius: "1000px",
                            },
                          "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                            {
                              color: "black", // Just text label (ACTIVE)
                            },
                          "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text":
                            {
                              fill: "white", // circle's number (ACTIVE)
                            },
                        }}
                      >
                        <StepLabel {...labelProps} sx={{ color: "black" }}>
                          <h1 className="text-lg font-bold">
                            {label === 0 && selectedLangue.length !== 0
                              ? selectedLangue[index]?.language_id === 1
                                ? "Kinyarwanda"
                                : selectedLangue[index]?.language_id === 2
                                ? "English"
                                : "French"
                              : label === 1
                              ? selectedLangue[index]?.language_id === 1
                                ? "Kinyarwanda"
                                : selectedLangue[index]?.language_id === 2
                                ? "English"
                                : "French"
                              : selectedLangue[index]?.language_id === 1
                              ? "Kinyarwanda"
                              : selectedLangue[index]?.language_id === 2
                              ? "English"
                              : "French"}
                          </h1>
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>

                {/* Render step content */}
                <div className="mt-3">
                  {activeStep === 0 && selectedLangue[0]?.language_id === 1 ? (
                    <AddNewArticleRw
                      register={register}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                      control={control}
                      planData={planData}
                      setPlanData={setPlanData}
                      translationLoading={translationLoading}
                      isTranslated={isTranslatedToRw}
                      files64={files64}
                      setFiles64={setFiles64}
                    />
                  ) : activeStep === 0 &&
                    selectedLangue[0]?.language_id === 2 ? (
                    <AddNewArticleEn
                      register={register}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                      control={control}
                      planData={planData}
                      setPlanData={setPlanData}
                      translationLoading={translationLoading}
                      isTranslated={isTranslatedToEn}
                      files64={files64}
                      setFiles64={setFiles64}
                    />
                  ) : activeStep === 0 &&
                    selectedLangue[0]?.language_id === 3 ? (
                    <AddNewArticleFr
                      register={register}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                      control={control}
                      planData={planData}
                      setPlanData={setPlanData}
                      translationLoading={translationLoading}
                      isTranslated={isTranslatedToFr}
                      files64={files64}
                      setFiles64={setFiles64}
                    />
                  ) : null}

                  {activeStep === 1 && selectedLangue[1]?.language_id === 1 ? (
                    //  create new plan
                    <AddNewArticleRw
                      register={register}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                      control={control}
                      planData={planData}
                      setPlanData={setPlanData}
                      translationLoading={translationLoading}
                      isTranslated={isTranslatedToRw}
                      files64={files64}
                      setFiles64={setFiles64}
                    />
                  ) : activeStep === 1 &&
                    selectedLangue[1]?.language_id === 2 ? (
                    <AddNewArticleEn
                      register={register}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                      control={control}
                      planData={planData}
                      setPlanData={setPlanData}
                      translationLoading={translationLoading}
                      isTranslated={isTranslatedToEn}
                      files64={files64}
                      setFiles64={setFiles64}
                    />
                  ) : activeStep === 1 &&
                    selectedLangue[1]?.language_id === 3 ? (
                    <AddNewArticleFr
                      register={register}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                      control={control}
                      planData={planData}
                      setPlanData={setPlanData}
                      translationLoading={translationLoading}
                      isTranslated={isTranslatedToFr}
                      files64={files64}
                      setFiles64={setFiles64}
                    />
                  ) : null}

                  {activeStep === 2 && selectedLangue[2]?.language_id === 1 ? (
                    //  create new plan
                    <AddNewArticleRw
                      register={register}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                      control={control}
                      planData={planData}
                      setPlanData={setPlanData}
                      translationLoading={translationLoading}
                      isTranslated={isTranslatedToRw}
                      files64={files64}
                      setFiles64={setFiles64}
                    />
                  ) : activeStep === 2 &&
                    selectedLangue[2]?.language_id === 2 ? (
                    <AddNewArticleEn
                      register={register}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                      control={control}
                      planData={planData}
                      setPlanData={setPlanData}
                      translationLoading={translationLoading}
                      isTranslated={isTranslatedToEn}
                      files64={files64}
                      setFiles64={setFiles64}
                    />
                  ) : activeStep === 2 &&
                    selectedLangue[2]?.language_id === 3 ? (
                    <AddNewArticleFr
                      register={register}
                      errors={errors}
                      clearErrors={clearErrors}
                      setError={setError}
                      control={control}
                      planData={planData}
                      setPlanData={setPlanData}
                      translationLoading={translationLoading}
                      isTranslated={isTranslatedToFr}
                      files64={files64}
                      setFiles64={setFiles64}
                    />
                  ) : null}
                </div>

                <React.Fragment>
                  <div className="flex flex-row justify-between mt-8">
                    <Button
                      type="button"
                      content={
                        <div className="flex gap-1 items-center justify-center">
                          <IoArrowBackOutline color="white" />
                          <h1 className="text-white font-nunito text-base">
                            Back
                          </h1>
                        </div>
                      }
                      Style={"h-[40px] mt-2 ml-0"}
                      loading={false}
                      btnColor="gray"
                      disabled={activeStep === 0}
                      onClick={() => {
                        handleBack();
                      }}
                    />
                    <div className="flex">
                      &nbsp;&nbsp;
                      <Button
                        type="submit"
                        content={
                          <div className="flex items-center justify-center">
                            <h1 className="text-white font-nunito text-base ">
                              {activeStep === selectedLangue.length - 1
                                ? "Submit"
                                : "Next"}
                            </h1>
                          </div>
                        }
                        loading={loading}
                        onClick={handleNext}
                        btnColor="primary"
                        Style={"text-white w-max h-[40px] mt-2 ml-0"}
                      />
                    </div>
                  </div>
                </React.Fragment>
              </Box>
            </div>
          </Modal2.Body>
        </Modal2>
      </form>
    </>
  );
};

export default AddNewArticle;
