import React, { useState, Fragment } from 'react';
import Table from '../../../../components/tableComponent/Table';
import { skeletonData, skeletonColumns } from '../../../../seeds/Data';
import SkeletonTable from '../../../../components/SkeletonTable';
import { FiMoreVertical } from 'react-icons/fi';
import { Popover, Transition } from '@headlessui/react';
import CreditWalletDeposit from './CreditWalletDeposit';
import CreditWalletTransaction from './CreditWalletTransaction';
import CreditWalletTransfer from './CreditWalletTransfer';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCreditWalletDeposits,
  fetchCreditWalletTransactions,
  fetchCreditWalletTransfers,
} from '../../../../redux/actions/wallet';
import DeactivateCreditWallet from './DeactivateCreditWallet';

const CreditWallet = () => {
  const dispatch = useDispatch();
  const { is_fetching_credit_wallets, credit_wallets } = useSelector(
    (state) => state.wallet
  );

  const columns = [
    {
      Header: 'Reference',
      accessor: 'reference',
    },
    {
      Header: 'Balance',
      accessor: 'total_balance',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Names',
      accessor: '',
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.profile?.first_name +
              ' ' +
              row.original.profile?.last_name}
          </div>
        );
      },
    },
    {
      Header: 'Created at',
      accessor: 'description',
      Cell: ({ row }) => {
        return <div>{row.original.created_at.split('T')[0]}</div>;
      },
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  w-max font-bold">
            <Popover className="relative">
              <Popover.Button>
                <FiMoreVertical size={30} className="cursor-pointer" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 right-0">
                  <div
                    className="flex flex-col bg-white shadow-md border border-black/10  rounded-sm text-xs "
                    onClick={() => {}}
                  >
                    <h1
                      className="px-4 py-2 hover:bg-[#D9D9D9] text-center cursor-pointer"
                      onClick={() => {
                        dispatch(
                          fetchCreditWalletTransactions(row.original.id)
                        );
                        handleCreditTransactionModal();
                      }}
                    >
                      Transactions
                    </h1>

                    <h1
                      className="px-4 py-2 hover:bg-[#D9D9D9] text-center cursor-pointer"
                      onClick={() => {
                        dispatch(fetchCreditWalletTransfers(row.original.id));

                        handleCreditTransferModal();
                      }}
                    >
                      Transfers
                    </h1>

                    <h1
                      className="px-4 py-2 hover:bg-[#D9D9D9] text-center cursor-pointer"
                      onClick={() => {
                        dispatch(fetchCreditWalletDeposits(row.original.id));
                        handleCreditDepositModal();
                      }}
                    >
                      Deposits
                    </h1>

                    <h1
                      className={`px-4 py-2 hover:bg-[#D9D9D9] text-center cursor-pointer ${
                        row.original.status === 'active'
                          ? 'text-error'
                          : 'text-success'
                      }`}
                      onClick={() => {
                        setActivationStatus(row.original.status);
                        setCreditWalletId(row.original.id);
                        handleDeactivateModal();
                      }}
                    >
                      {row.original.status === 'active'
                        ? 'Deactivate'
                        : 'Activate'}
                    </h1>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
        );
      },
    },
  ];

  const [openCreditDepositModal, setOpenCreditDepositModal] = useState(false);
  const [openCreditTransactionModal, setOpenCreditTransactionModal] =
    useState(false);
  const [openCreditTransferModal, setOpenCreditTransferModal] = useState(false);
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [creditWalletId, setCreditWalletId] = useState('');
  const [activationStatus, setActivationStatus] = useState('');

  const handleCreditDepositModal = () => {
    setOpenCreditDepositModal(!openCreditDepositModal);
  };

  const handleCreditTransactionModal = () => {
    setOpenCreditTransactionModal(!openCreditTransactionModal);
  };

  const handleCreditTransferModal = () => {
    setOpenCreditTransferModal(!openCreditTransferModal);
  };

  const handleDeactivateModal = () => {
    setOpenDeactivateModal(!openDeactivateModal);
  };

  return (
    <>
      <CreditWalletDeposit
        openModal={openCreditDepositModal}
        handleOpenModal={handleCreditDepositModal}
      />

      <CreditWalletTransaction
        openModal={openCreditTransactionModal}
        handleOpenModal={handleCreditTransactionModal}
      />

      <CreditWalletTransfer
        openModal={openCreditTransferModal}
        handleOpenModal={handleCreditTransferModal}
      />

      <DeactivateCreditWallet
        openModal={openDeactivateModal}
        handleOpenModal={handleDeactivateModal}
        creditWalletId={creditWalletId}
        status={activationStatus}
      />

      <div className=" ">
        {!is_fetching_credit_wallets ? (
          credit_wallets?.length == 0 ? (
            <p className="text-[20px] text-[#808080] font-bold flex justify-center items-center mt-6">
              There are no Credit Wallets into the System, Credit Wallets will
              appear here.
            </p>
          ) : (
            <Table
              columns={columns}
              initialSize={10}
              data={credit_wallets?.length > 0 ? credit_wallets : []}
              className="mx-0"
            />
          )
        ) : (
          <SkeletonTable columns={skeletonColumns} data={skeletonData} />
        )}
      </div>
    </>
  );
};

export default CreditWallet;
