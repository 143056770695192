import React, { useState } from "react";
import Modal from "../../components/modal/Modal";
import Axios from "../../components/Axios";
import TextArea from "../../components/TextArea";
import { useForm } from "react-hook-form";
import Button from "../../components/Button";
import { APP } from "../../utils/constant";

const RejectSenderName = ({ show, setShow, senderNameId }) => {
  const [rejectReason, setRejectReason] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const rejectSenderName = () => {
    setButtonLoading(true);
    Axios.put(
      APP.API_MAIN_URL + `/admin/sender-name-requests/${senderNameId}/reject`,
      {
        reason: rejectReason,
      }
    )
      .then((res) => {
        setButtonLoading(false);
        setShow(!show);
        console.log(res.data);
      })
      .catch((error) => {
        setButtonLoading(false);
        console.log(error);
      });
  };

  const onSubmit = (data) => {
    rejectSenderName();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <div>
      <Modal
        isOpen={show}
        onClose={() => setShow(!show)}
        Style={"w-1/3 m-auto h-max  p-8 flex z-[10]"}
      >
        <Modal.Header>
          <h1 className="font-bold">Reject Reason</h1>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col mt-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="text-primary font-bold">
                <TextArea
                  labelText={"Reason for rejection"}
                  labelFor={`reject Reason`}
                  name={`reject Reason`}
                  isRequired={true}
                  value={rejectReason}
                  placeholder={"Reason........"}
                  customClass="w-full mb-2"
                  inputStyle="h-[90px]"
                  onChange={(e) => {
                    setRejectReason(e.target.value);
                  }}
                  register={register}
                  errors={errors}
                />{" "}
              </div>

              <div className="flex justify-end items-end space-x-4 mt-100">
                <Button
                  type="submit"
                  content="Reject"
                  loading={buttonLoading}
                  btnColor="primary"
                  Style={`text-white w-fit h-[40px] mt-8 ml-0 border border-primary`}
                />
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RejectSenderName;
