import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { RxCross2, RxDashboard } from "react-icons/rx";
import Avatar from "react-avatar";
import Logout from "../modals/Logout";
import {
  RiAdvertisementLine,
  RiAdvertisementFill,
  RiBankFill,
} from "react-icons/ri";

import { FaCoins } from "react-icons/fa";

import {
  MdNotificationsNone,
  MdOutlineAccessTime,
  MdOutlineArticle,
} from "react-icons/md";
import { GiBlast, GiEngagementRing } from "react-icons/gi";
import { VscSymbolProperty, VscFeedback } from "react-icons/vsc";
import { FaUsers } from "react-icons/fa";
import { MdOutlineSportsHandball } from "react-icons/md";
import { AiFillInfoCircle, AiOutlineTablet } from "react-icons/ai";
import { IoWalletSharp } from "react-icons/io5";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { BsPeople, BsCreditCard } from "react-icons/bs";
import { SiNamecheap } from "react-icons/si";
import { IoQrCodeSharp } from "react-icons/io5";
import KONVEYWhite from "../Assets/KONVEYWhite.png";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTab } from "../redux/actions/user";
import { GrPlan } from "react-icons/gr";

const SideBar = ({
  logoutModal,
  setLogoutModal,
  responsive,
  responsiveSideBar,
  showSidebar,
  setShowSidebar,
  toggleItem,
  setToggleItem,
  loggedInUser,
}) => {
  const { token, selectedTab } = useSelector((state) => state.user);

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState("Konvey");
  const [engagementShow, setEngagementShow] = useState(false);
  const [advertiser, setAdvertiser] = useState([
    { icon: RxDashboard, title: "Dashboard", to: `/` },
    {
      icon: GiEngagementRing,
      title: "Engagement",
      to: `/engagement`,
    },
    {
      icon: MdNotificationsNone,
      title: "Notifications",
      to: `/notifications`,
    },
    {
      icon: VscSymbolProperty,
      title: "Resources",
      to: `/resources`,
    },
    { icon: FaUsers, title: "Users", to: `/users` },
  ]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavLinkClick = (e) => {
    if (window.innerWidth < 976) {
      setShowSidebar(false);
      setTimeout(() => {
        responsiveSideBar();
        setShowSidebar(true);
      }, 500);
    }
  };

  useEffect(() => {
    handleSelectChange(selectedTab);
  }, [selectedTab]);

  const handleLogout = () => {
    setLogoutModal();
  };

  const userMenu = [
    {
      icon: BsPeople,
      title: "System users",
      to: `/sysusers`,
    },
    {
      icon: RiAdvertisementFill,
      title: "Advertisers",
      to: `/advertisers`,
    },
  ];
  const engagementMenu = [
    { icons: GiBlast, title: "Blast", to: "/engagement/blast" },
    { icons: GiEngagementRing, title: "Campaign", to: "/engagement/campaigns" },
  ];
  const resourceMenu = [
    {
      icons: RiBankFill,
      title: "Banks",
      to: `/resources/?item=banks`,
    },
    {
      icons: IoWalletSharp,
      title: "Wallets",
      to: `/resources/?item=wallets`,
    },
    {
      icons: FaCoins,
      title: "Mobile Payments",
      to: `/resources/?item=mobileMoney`,
    },
    {
      icons: IoQrCodeSharp,
      title: "QR codes",
      to: `/resources/?item=qrCodes`,
    },

    {
      icons: SiNamecheap,
      title: "Sender name",
      to: `/resources/?item=sendername`,
    },
    {
      icons: AiFillInfoCircle,
      title: "Company info",
      to: `/resources/?item=info`,
    },
    {
      icons: VscFeedback,
      title: "Feedback",
      to: `/resources/?item=feedback`,
    },
  ];

  const handleSelectChange = (selectedValue) => {
    if (selectedValue === "konvey") {
      setAdvertiser([
        { icon: RxDashboard, title: "Dashboard", to: `/` },
        {
          icon: MdNotificationsNone,
          title: "Engagement",
          to: `/engagement`,
        },
        {
          icon: MdNotificationsNone,
          title: "Notifications",
          to: `/notifications`,
        },
        {
          icon: VscSymbolProperty,
          title: "Resources",
          to: `/resources`,
        },
        { icon: RiAdvertisementLine, title: "Users", to: `/advertisers` },
      ]);
    } else if (selectedValue === "flex") {
      setAdvertiser([
        { icon: RxDashboard, title: "Dashboard", to: `/` },
        { icon: RiAdvertisementLine, title: "Advertisers", to: `/advertisers` },
        {
          icon: MdNotificationsNone,
          title: "Campaigns",
          to: `/campaigns`,
        },
      ]);
    } else if (selectedValue === "vas") {
      setAdvertiser([
        { icon: RxDashboard, title: "Dashboard", to: `/` },
        {
          icon: MdOutlineArticle,
          title: "Contents",
          to: `/vas/content`,
        },
        {
          icon: MdOutlineSportsHandball,
          title: "Subscriptions",
          to: `/vas/plan`,
        },
        {
          icon: MdOutlineAccessTime,
          title: "Remember",
          to: `/vas/remember`,
        },
        {
          icon: MdNotificationsNone,
          title: "Campaigns",
          to: `/campaigns`,
        },
      ]);
    }
    navigate("/");
  };

  return (
    <>
      <Logout logoutModal={logoutModal} setLogoutModal={setLogoutModal} />

      <div
        className={`bg-primary w-[220px] fixed  left-[-220px] lg:left-0 top-0 z-10 h-full mb-4 transition-transform duration-50 ${
          responsive ? "sidebar" : ""
        } ${showSidebar ? "" : "hideSideBar"} lg:flex flex-col `}
      >
        <div className="flex bg-[#454545] w-full h-[64px] text-2xl">
          <div className="flex flex-row items-center gap-8  mx-auto">
            <img
              src={KONVEYWhite}
              alt=""
              width={100}
              height={50}
              className="self-center"
            />
            <RxCross2
              color="white"
              className="mt-[2px] lg:hidden cursor-pointer"
              onClick={() => {
                responsiveSideBar();
              }}
            />
          </div>
        </div>
        <div className="flex h-max  w-full border-b-[1px] p-2">
          <select
            id="select"
            value={selectedTab}
            className="w-full p-2 rounded-sm border border-white"
            onChange={(e) => {
              dispatch(setSelectedTab(e.target.value));
            }}
          >
            <option value="konvey">Konvey</option>
            <option value="flex">Flex platforms</option>
            <option value="vas">Flex VAS</option>
            <option value="add">dashboad</option>
            <option value="add">Addition</option>
          </select>
        </div>

        <div className="flex flex-col w-full items-start self-center mx-auto overflow-y-auto itemsHeight px-4 py-2">
          {advertiser.map((element, i) => {
            if (element.title === "Users") {
              return (
                <div
                  key={i}
                  className="text-white w-full flex rounded-sm px-8 py-2 flex-col gap-2 cursor-pointer justify-start items-start mt-2"
                >
                  <button
                    onClick={() => setOpen(!open)}
                    className="relative flex flex-col w-full justify-around"
                  >
                    <h1 className="font-nunito font-bold text-white">
                      Users
                      {open ? (
                        <IoMdArrowDropup
                          className="absolute top-0 right-0"
                          size={20}
                          color="white"
                        />
                      ) : (
                        <IoMdArrowDropdown
                          className="absolute top-0 right-0"
                          size={20}
                          color="white"
                        />
                      )}
                    </h1>
                  </button>

                  <div className="bg-primary text-white justify-start items-start pl-2 flex w-full  flex-col space-y-2">
                    {open &&
                      userMenu.map((element, i) => {
                        return (
                          <div key={i}>
                            <NavLink
                              key={element.title}
                              to={element.to}
                              className={({ isActive }) => {
                                return isActive ? "activeLink" : "hoverLink";
                              }}
                            >
                              <div
                                className="flex w-full  rounded-sm flex-row gap-2 cursor-pointer  mt-2"
                                key={element.title}
                                onClick={() => {
                                  handleNavLinkClick();
                                }}
                              >
                                <element.icon
                                  className="icon"
                                  size={15}
                                  color="white"
                                />
                                <h1 className="font-nunito font-bold text-white opacity-75 text-sm">
                                  {element.title}
                                </h1>
                              </div>
                            </NavLink>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            } else if (element.title === "Engagement") {
              return (
                <div className="text-white w-full flex rounded-sm px-8 py-2 flex-col gap-2 cursor-pointer justify-start items-start mt-2">
                  <button
                    onClick={() => setEngagementShow(!engagementShow)}
                    className="relative flex flex-col w-full justify-around"
                  >
                    <div className=" flex items-center font-nunito font-bold text-white">
                      Engagement
                      {engagementShow ? (
                        <IoMdArrowDropup className="" size={20} color="white" />
                      ) : (
                        <IoMdArrowDropdown
                          className=""
                          size={20}
                          color="white"
                        />
                      )}
                    </div>
                  </button>

                  <div className="bg-primary text-white justify-start items-start pl-2 flex w-full  flex-col space-y-2">
                    {engagementShow &&
                      engagementMenu.map((element, index) => {
                        return (
                          <NavLink
                            className={({ isActive }) => {
                              return isActive ? "activeLink" : "hoverLink";
                            }}
                            key={element.title}
                            to={element.to}
                          >
                            <div
                              className="flex w-full  rounded-sm flex-row gap-2 cursor-pointer  mt-2"
                              key={element.title}
                              onClick={() => {
                                handleNavLinkClick();
                                setToggleItem(!toggleItem);
                              }}
                            >
                              <element.icons
                                className="icon"
                                size={15}
                                color="white"
                              />
                              <h1 className="font-bold text-white opacity-75 text-sm">
                                {element.title}
                              </h1>
                            </div>
                          </NavLink>
                        );
                      })}
                  </div>
                </div>
              );
            }
            // if (element.title === "Resources") {
            //   return (
            //     <div className="text-white w-full flex rounded-sm px-8 py-2 flex-col gap-2 cursor-pointer justify-start items-start mt-2">
            //       <button
            //         onClick={() => setShow(!show)}
            //         className="relative flex flex-col w-full justify-around"
            //       >
            //         <h1 className="font-nunito font-bold text-white">
            //           Resources
            //           {show ? (
            //             <IoMdArrowDropup
            //               className="absolute top-0 right-0"
            //               size={20}
            //               color="white"
            //             />
            //           ) : (
            //             <IoMdArrowDropdown
            //               className="absolute top-0 right-0"
            //               size={20}
            //               color="white"
            //             />
            //           )}
            //         </h1>
            //       </button>

            //       <div className="bg-primary text-white justify-start items-start pl-2 flex w-full  flex-col space-y-2">
            //         {show &&
            //           resourceMenu.map((element, inex) => {
            //             return (
            //               <NavLink
            //                 className={({ isActive }) => {
            //                   return isActive ? "activeLink" : "hoverLink";
            //                 }}
            //                 key={inex}
            //                 to={element.to}
            //               >
            //                 <div
            //                   className="flex w-full  rounded-sm flex-row gap-2 cursor-pointer  mt-2"
            //                   onClick={() => {
            //                     handleNavLinkClick();
            //                     setToggleItem(!toggleItem);
            //                   }}
            //                 >
            //                   <element.icon
            //                     className="icon"
            //                     size={15}
            //                     color="white"
            //                   />
            //                   <h1 className="font-bold text-white opacity-75 text-sm">
            //                     {element.title}
            //                   </h1>
            //                 </div>
            //               </NavLink>
            //             );
            //           })}
            //       </div>
            //     </div>
            //   );
            // }
            else if (element.title === "Resources") {
              return (
                <div className="text-white w-full flex rounded-sm px-8 py-2 flex-col gap-2 cursor-pointer justify-start items-start mt-2">
                  <button
                    onClick={() => setShow(!show)}
                    className="relative flex flex-col w-full justify-around"
                  >
                    <div className=" flex items-center font-nunito font-bold text-white">
                      Resources
                      {show ? (
                        <IoMdArrowDropup className="" size={20} color="white" />
                      ) : (
                        <IoMdArrowDropdown
                          className=""
                          size={20}
                          color="white"
                        />
                      )}
                    </div>
                  </button>

                  <div className="bg-primary text-white justify-start items-start pl-2 flex w-full  flex-col space-y-2">
                    {show &&
                      resourceMenu.map((element, index) => {
                        return (
                          <NavLink
                            className={({ isActive }) => {
                              return isActive ? "activeLink" : "hoverLink";
                            }}
                            key={element.title}
                            to={element.to}
                          >
                            <div
                              className="flex w-full  rounded-sm flex-row gap-2 cursor-pointer  mt-2"
                              key={element.title}
                              onClick={() => {
                                handleNavLinkClick();
                                setToggleItem(!toggleItem);
                              }}
                            >
                              <element.icons
                                className="icon"
                                size={15}
                                color="white"
                              />
                              <h1 className="font-bold text-white opacity-75 text-sm">
                                {element.title}
                              </h1>
                            </div>
                          </NavLink>
                        );
                      })}
                  </div>
                </div>
              );
            } else {
              return (
                <NavLink
                  key={element.title}
                  to={element.to}
                  className={({ isActive }) => {
                    return isActive ? "activeLink" : "hoverLink";
                  }}
                >
                  <div
                    className="flex w-full rounded-sm px-8 py-2 flex-row gap-2 cursor-pointer justify-start items-start mt-2"
                    key={element.title}
                    onClick={() => {
                      handleNavLinkClick();
                    }}
                  >
                    <element.icon className="icon" size={20} color="white" />
                    <h1 className="font-nunito font-bold text-white">
                      {element.title}
                    </h1>
                  </div>
                </NavLink>
              );
            }
          })}
        </div>

        <div className="flex fixed border-white border-y-[1px] justify-center items-center w-[220px] p-4 h-[80px] bottom-[40px] bg-black">
          <div className="flex gap-2 w-fit cursor-pointer justify-center items-center">
            <Avatar
              name={loggedInUser?.first_name + " " + loggedInUser?.last_name}
              round={true}
              size="50px"
              className=" font-nunito font-bold text-1xl self-center border "
              color="black"
            />
            <div className="flex flex-col ">
              <h1 className="font-nunito font-bold text-white text-base">
                {loggedInUser?.first_name + " " + loggedInUser?.last_name}
              </h1>
              <h2 className="font-nunito text-white text-xs opacity-70 cursor-pointer">
                View Profile
              </h2>
            </div>
          </div>
        </div>
        <div className="flex fixed w-[220px] h-[40px] justify-center items-center bottom-0 bg-black">
          <div
            className="flex w-fit mx-auto justify-center items-center cursor-pointer gap-2 "
            onClick={handleLogout}
          >
            <FiLogOut size={15} color="white" />
            <div className="">
              <h1 className="font-nunito font-bold text-base text-white">
                Logout
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
