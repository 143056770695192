import React, { useState } from "react";
import Button from "../../../components/Button";
import Modal2 from "../../../components/modal/Modal2";

import { useSelector } from "react-redux";

function ViewQrContent({ open, handleViewQrContent, content }) {
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  return (
    <Modal2
      isOpen={open}
      onClose={handleViewQrContent}
      Style={
        "w-[100%] h-[70vh] md:w-[60%] m-auto flex mx-auto items-center justify-center"
      }
    >
      <Modal2.Body className={" h-full"}>
        <div className="flex flex-col space-y-4 h-full">
          <div className="w-full flex items-center justify-center font-bold">
            <h2 className="capitalize">{content?.title}</h2>
          </div>
          <iframe
            src={content?.url}
            title={content?.title}
            className="w-full h-full border-none overflow-hidden "
          ></iframe>
          <div className="flex items-center">
            <div className="border-r pr-32 border-[#eef7fd] ">
              <Button
                type="button"
                content="Close"
                loading={false}
                btnColor="primary"
                Style={
                  "text-black w-max h-[40px] mt-2 ml-0 bg-white border rounded-3xl cursor-pointer"
                }
                onClick={() => {
                  handleViewQrContent();
                }}
              />
            </div>
            <div className="h-16 overflow-auto">
              <p className="pl-5 py-3">{content?.details}</p>
            </div>
          </div>
        </div>
      </Modal2.Body>
    </Modal2>
  );
}

export default ViewQrContent;
