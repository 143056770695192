import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import CampaignData from "../../modals/campainModals/CampaignData";
import SenderName from "./modals/SenderName";
import Loader from "../../components/Loader";
import Axios from "../../components/Axios";
import { APP } from "../../utils/constant";

const Approvals = ({ notificationLoading, approvalNotifications }) => {
  // console.log(approvalNotifications, 'approval notifications');
  const [openCampaignDetails, setOpenCampaignDetails] = useState(false);
  const [openSenderNameDetails, setOpenSenderNameDetails] = useState(false);
  const [senderNameDetails, setSenderNameDetails] = useState(false);
  const [campaignId, setCampaignId] = useState("");
  const [senderNameId, setSenderNameId] = useState("");
  const [clickedTodayIndex, setClickedTodayIndex] = useState("");

  const handleCampaignDetailsModal = () => {
    setOpenCampaignDetails(!openCampaignDetails);
  };
  const handleSenderNameDetailsModal = () => {
    setOpenSenderNameDetails(!openSenderNameDetails);
  };

  const currentDate = new Date();
  const yesterdayDate = new Date(currentDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  const groupedNotifications = {};
  approvalNotifications.forEach((notification) => {
    const created_at = new Date(notification.created_at);
    const dateKey = created_at.toDateString(); // Use the date as the key for grouping

    if (!groupedNotifications[dateKey]) {
      groupedNotifications[dateKey] = [];
    }

    groupedNotifications[dateKey].push(notification);
  });

  const today = new Date().toDateString();
  const yesterday = new Date(
    new Date().getTime() - 24 * 60 * 60 * 1000
  ).toDateString();

  const previousDaysNotifications = Object.keys(groupedNotifications).reduce(
    (accumulator, dateKey) => {
      if (dateKey !== today && dateKey !== yesterday) {
        accumulator.push(...groupedNotifications[dateKey]);
      }
      return accumulator;
    },
    []
  );

  const handleClickNotification = (notification) => {
    markAsRead(notification.id);
    if (notification.notification_type.name === "campaigns") {
      setCampaignId(notification.data.id);
      handleCampaignDetailsModal();
    } else if (notification.notification_type.name === "sender_names") {
      setSenderNameId(notification.id);
      setSenderNameDetails({
        name: JSON.parse(notification.details).sender_name,
        id: JSON.parse(notification.details).sender_name_id,
      });
      handleSenderNameDetailsModal();
    }
  };

  const notificationBody = (firstName, lastName, title, details) => {
    const Details = JSON.parse(details);
    if (title === "sender_names") {
      return (
        <div className="flex flex-row">
          <p>
            <span className="font-bold pr-2">{firstName + " " + lastName}</span>
            has created a sender name
            <span className="font-bold pl-2">{Details.sender_name}</span>{" "}
            {Details.sender_name_request_status === "pending"
              ? "and needs your approval"
              : null}
            {Details.sender_name_request_status === "rejected"
              ? ", please refer to your sendername for more details."
              : "."}
          </p>
        </div>
      );
    }
  };

  const markAsRead = (notificationId) => {
    Axios.put(
      APP.API_MAIN_URL + `/utils/admin/notifications/read/${notificationId}`
    )
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <CampaignData
        show={openCampaignDetails}
        setShow={handleCampaignDetailsModal}
        campaignId={campaignId}
      />
      <SenderName
        senderNameDetails={senderNameDetails}
        openModal={openSenderNameDetails}
        handleOpenModal={() => setOpenSenderNameDetails(!openSenderNameDetails)}
      />

      <div
        className={`flex flex-col overflow-y-auto h-full ${
          notificationLoading ? "items-center justify-center" : ""
        } `}
      >
        {notificationLoading ? (
          <div className="h-[150px] w-full flex ">
            <Loader loader={true} />
          </div>
        ) : null}
        {today in groupedNotifications && (
          <React.Fragment key={today}>
            <h2 className="font-bold p-4 border border-light/30 text-light">
              Today
            </h2>
            {groupedNotifications[today].map((notification, index) => {
              const Details = JSON.parse(notification.details);
              const dateObj = new Date(notification.created_at);
              const formattedDate = dateObj.toLocaleDateString("en", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              });

              const formattedTime = dateObj.toLocaleTimeString("en", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              });
              return (
                <div
                  className="border border-light/30 rounded pl-4 pr-2  py-4 cursor-pointer"
                  onClick={() => {
                    handleClickNotification(notification);
                    setClickedTodayIndex(index);
                  }}
                  key={notification.id}
                >
                  <div className="flex flex-row items-start gap-2 w-full ">
                    <img
                      src={notification?.user?.user_profile.profile_image}
                      alt=""
                      className="w-[60px] h-[60px] rounded-full object-cover"
                    />
                    <div className="flex justify-between items-center w-full">
                      <div className="flex flex-col">
                        <p className="text-sm flex flex-row ">
                          {notificationBody(
                            notification.user.first_name,
                            notification.user.last_name,
                            notification.notification_type.name,
                            notification.details
                          )}
                        </p>
                        <p className="flex items-center py-2 text-sm font-bold text-light">
                          {formattedTime}
                          <span className="h-[5px] w-[5px] bg-light rounded-full mx-2"></span>
                          {formattedDate}
                        </p>
                      </div>
                      <div className="flex flex-col items-center justify-center gap-1">
                        <div
                          className={`h-[9px] w-[9px] rounded-full ${
                            (notification.status ||
                              clickedTodayIndex === index) === "read"
                              ? "bg-white"
                              : "bg-black"
                          }`}
                        ></div>
                        <RxCross2 className="cursor-pointer mt-2" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </React.Fragment>
        )}

        {yesterday in groupedNotifications && (
          <React.Fragment key={yesterday}>
            <h2 className="font-semibold  p-4 border border-light/30 text-light">
              Yesterday
            </h2>
            {groupedNotifications[yesterday].map((notification) => {
              const dateObj = new Date(notification.created_at);
              const formattedDate = dateObj.toLocaleDateString("en", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              });

              const formattedTime = dateObj.toLocaleTimeString("en", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              });
              return (
                <div
                  className="border-b border-light/30 rounded p-4 w-full cursor-pointer"
                  onClick={() => {
                    handleClickNotification(notification);
                  }}
                  key={notification.id}
                >
                  <div className="flex flex-row items-start gap-2 w-full">
                    <img
                      src={notification?.user?.user_profile.profile_image}
                      alt=""
                      className="w-[60px] h-[60px] rounded-full object-cover"
                    />
                    <div className="flex items-center justify-between w-full">
                      <div className="flex flex-col">
                        <p className="text-sm">
                          {notificationBody(
                            notification.user.first_name,
                            notification.user.last_name,
                            notification.notification_type.name,
                            notification.details
                          )}
                        </p>

                        <p className="flex items-center py-2 text-sm font-bold text-light">
                          {formattedTime}
                          <span className="h-[5px] w-[5px] bg-light rounded-full mx-2"></span>
                          {formattedDate}
                        </p>
                      </div>
                      <div className="flex flex-col items-center justify-center gap-1">
                        <div className="h-[7px] w-[7px] rounded-full bg-gray"></div>
                        <RxCross2 className="cursor-pointer" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </React.Fragment>
        )}

        {previousDaysNotifications.length > 0 && (
          <React.Fragment key="previousDays">
            <h2 className="font-semibold p-4 border border-light/30 text-light">
              Previous Days
            </h2>
            {previousDaysNotifications.map((notification) => {
              const dateObj = new Date(notification.created_at);
              const formattedDate = dateObj.toLocaleDateString("en", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              });

              const formattedTime = dateObj.toLocaleTimeString("en", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              });

              return (
                <div
                  className="border-b border-light/30 rounded p-4 cursor-pointer"
                  key={notification.id}
                  onClick={() => {
                    handleClickNotification(notification);
                  }}
                >
                  <div className="flex flex-row items-start gap-2">
                    <img
                      src={notification?.user?.user_profile.profile_image}
                      alt=""
                      className="w-[60px] h-[60px] rounded-full object-cover"
                    />
                    <div className="flex items-center justify-between w-full">
                      <div className="flex flex-col">
                        <p className="text-sm">
                          {/* <b className="px-1">{notification.sender}</b>
                          {notification.body}
                          <b className="pl-1">{notification.subject}</b> */}
                          {notificationBody(
                            notification.user.first_name,
                            notification.user.last_name,
                            notification.notification_type.name,
                            notification.details
                          )}
                        </p>

                        <p className="flex items-center py-2 text-sm font-bold text-light">
                          {formattedTime}
                          <span className="h-[5px] w-[5px] bg-light rounded-full mx-2"></span>
                          {formattedDate}
                        </p>
                      </div>
                      <div className="flex flex-col items-center justify-center gap-1">
                        <div className="h-[7px] w-[7px] rounded-full bg-gray"></div>
                        <RxCross2 className="cursor-pointer" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </React.Fragment>
        )}
      </div>
    </>
  );
};

export default Approvals;
