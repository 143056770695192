import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from './components/Select';
import Input from './components/Input';
import Button from './components/Button';

export const Test = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    control,
  } = useForm();

  const [campaign, setCampaign] = useState();
  const campaignTypeOptions = [
    { value: 1, label: 'App Installation' },
    { value: 2, label: 'Awareness' },
    { value: 3, label: 'Conversion' },
    { value: 4, label: 'SMS Broadcast' },
    { value: 5, label: 'Other' },
  ];
  const handleCampaignType = (campaignType) => {
    setCampaign(campaignType);
  };
  const onSubmit = (data) => {
    console.log(data);
  };
  console.log(campaign, 'campaign type here');
  // console.log(control, 'control');
  // console.log(errors['campaignType'], "errors['publisherType']");
  return (
    <div>
      <form
        action="#none"
        
        onSubmit={handleSubmit(onSubmit)}
        className=""
      >
        <Input
          labelText={'Campaign Title'}
          labelFor={'campaign Title'}
          name={'campaign Title'}
          type={'text'}
          isRequired={true}
          placeholder={'Campaign Title'}
          customClass="w-1/2 mb-2"
          register={register}
          errors={errors}
        />
        <Select
          className="w-1/2 mb-2"
          label="Campaign type"
          isLoading={false}
          placeholder={'Select campaign type'}
          defaultValue={'select'}
          options={campaignTypeOptions}
          isRequired={true}
          register={{
            control,
            name: 'campaignType',
            rules: { required: 'Campaign type is required' },
          }}
          handleChange={(e) => {
            handleCampaignType(e);
          }}
          error={errors['campaignType']?.message}
        />

        <Button
          type="submit"
          content={
            <div className="flex items-center justify-center">
              <h1 className="text-white font-nunito text-base">Submit</h1>
            </div>
          }
          // loading={loading}
          btnColor="primary"
          Style={'text-white w-[100px] h-[40px] mt-2 ml-0'}
        />
      </form>
    </div>
  );
};
