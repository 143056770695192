import React, { useEffect, useState, Fragment } from 'react';
import Table from '../../../components/tableComponent/Table';
import { skeletonData, skeletonColumns } from '../../../seeds/Data';
import SkeletonTable from '../../../components/SkeletonTable';
import { FiMoreVertical } from 'react-icons/fi';
import { Popover, Transition } from '@headlessui/react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAirtelMoneyPayment } from '../../../redux/actions/mobileMoney';

const AirtelMoney = () => {
  const dispatch = useDispatch();
  const { is_fetching_airtel_money_payment, airtel_money_payment } =
    useSelector((state) => state.mobileMoney);

  const columns = [
    {
      Header: 'Created At',
      accessor: 'reference',
      Cell: ({ row }) => {
        return <div>{row.original.created_at.split('T')[0]}</div>;
      },
    },
    {
      Header: 'Phone',
      accessor: 'phone',
    },
    {
      Header: 'Total Amount',
      accessor: 'total_amount',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
  ];
  useEffect(() => {
    dispatch(fetchAirtelMoneyPayment());
  }, []);

  return (
    <>
      <div className="">
        {!is_fetching_airtel_money_payment ? (
          airtel_money_payment?.length == 0 ? (
            <p className="text-[20px] text-[#808080] font-bold flex justify-center items-center mt-6">
              There are no payments done with Airtel Money into the System yet,
              such payments will appear here.
            </p>
          ) : (
            <Table
              columns={columns}
              data={
                airtel_money_payment?.length > 0 ? airtel_money_payment : []
              }
              initialSize={10}
              className="mx-0"
            />
          )
        ) : (
          <SkeletonTable columns={skeletonColumns} data={skeletonData} />
        )}
      </div>
    </>
  );
};

export default AirtelMoney;
