import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { APP, config, notify } from "../../../utils/constant";
import Modal2 from "../../../components/modal/Modal2";
import Input from "../../../components/Input";
import TextArea from "../../../components/TextArea";
import Button from "../../../components/Button";

function UpdateQrInfo({
  openAddContent,
  handleUpdateDetails,
  data,
  setData,
  getQrCodes,
  getSingleQr,
}) {
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm();

  const onSubmit = async () => {
    setLoading(true);

    await axios
      .put(
        APP.API_MAIN_URL + `/admin/qr-codes/${data.id}`,
        { name: data.name, details: data.details, status: data.status },
        config(token)
      )
      .then((res) => {
        reset();
        setLoading(false);
        notify("success", " QR code information successfully updated!");
        getQrCodes();
        getSingleQr();
        handleUpdateDetails(false);
      })
      .catch((error) => {
        setLoading(false);
        notify("error", error?.response?.data?.message);
        console.log("Error in updating qr info", error);
      });
  };

  return (
    <div>
      <Modal2
        isOpen={openAddContent}
        onClose={() => {
          handleUpdateDetails(false);
          // resetData();
        }}
        Style={"w-fit m-4 flex m-auto lg:w-[40%] lg:h-max px-3 "}
      >
        {/* <Modal2.Header className={"sticky top-0 z-20 bg-white px-15"}>
          <div className="font-bold text-[20px]">
            <span>Update Qr Code Details</span>
          </div>
        </Modal2.Header> */}
        <Modal2.Body>
          <div>
            <h2 className="font-[900] text-center border-b-2 pb-2 border-light">
              Update Qr Code Details
            </h2>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col space-y-2 w-full px-[1rem]"
            >
              <div className="flex flex-col space-y-2 border-primary border-opacity-50 p-4 rounded-lg">
                <Input
                  labelText={"Name"}
                  name={`name`}
                  type={"text"}
                  placeholder={"enter name"}
                  isRequired={true}
                  customClass="w-full text-sm mx-auto"
                  register={register}
                  errors={errors}
                  value={data?.name}
                  onChange={(e) => {
                    clearErrors("name");
                    setData((prevData) => ({
                      ...prevData,
                      name: e.target.value,
                    }));
                  }}
                  isDisabled={loading}
                />
                <TextArea
                  labelText={"Details"}
                  name={`details `}
                  placeholder={"enter details"}
                  customClass="w-full text-sm mx-auto"
                  register={register}
                  value={data?.details}
                  onChange={(e) => {
                    clearErrors("details");
                    // console.log("ttara", e);
                    setData((prevData) => ({
                      ...prevData,
                      details: e.target.value,
                    }));
                  }}
                  isDisabled={loading}
                />
              </div>
              <div className="flex space-x-10  px-3">
                <Button
                  content={
                    <h1 className="text-white font-nunito text-base">Close</h1>
                  }
                  loading={false}
                  btnColor="black"
                  Style={"text-primary h-[40px] ml-0 w-full"}
                  onClick={handleUpdateDetails}
                />
                <Button
                  type="submit"
                  content={
                    <h1 className="text-white font-nunito text-base">Submit</h1>
                  }
                  loading={loading}
                  btnColor="black"
                  Style={"text-primary h-[40px] ml-0 w-full"}
                />
              </div>
            </form>
          </div>
        </Modal2.Body>
        <div className="flex justify-end items-center space-x-4 p-2"></div>
      </Modal2>
    </div>
  );
}

export default UpdateQrInfo;
