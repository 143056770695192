import React from "react";

const BlastSmsDetails = ({ data, highlightedDays, fileDisplay }) => {
  console.log(data, "data to display");
  return (
    <div className="flex flex-col border border-gray rounded-lg p-2 mb-4 text-xl">
      <div className="flex flex-col mt-1 gap-1 md:grid md:grid-cols-2 ">
        <h1 className="text-light  font-bold  w-fit m-0 p-0 text-sm">title</h1>
        <h2 className="text-black  m-0 p-0 text-lg">{data[0]?.title}</h2>
      </div>

      <div className={"flex flex-col mt-1 gap-1 md:grid md:grid-cols-2"}>
        <h1 className="text-light  font-bold text-sm w-fit">Sms Text:</h1>
        <h2 className="text-black text-lg">{data[0]?.content}</h2>
      </div>
      <div className={"flex flex-col mt-1 gap-1 md:grid md:grid-cols-2"}>
        <h1 className="text-light  font-bold text-sm w-fit">Sms Type:</h1>
        <h2 className="text-black text-lg">{data[0]?.sms_type}</h2>
      </div>
      <div className="flex flex-col mt-1 gap-1 md:grid md:grid-cols-2">
        <h1 className="text-light  font-bold text-sm w-fit my-2">
          Recipients:
        </h1>
        <h2 className="text-black text-lg "> {data[0].total_recipients}</h2>
      </div>

      <div className={`flex flex-col`}>
        <div className="flex flex-col mt-1 gap-1 md:grid md:grid-cols-2">
          <h1 className="text-light  font-bold text-sm w-fit my-2">
            Schedule type:
          </h1>

          <h2 className="text-black text-lg "> {data[0].blast_type}</h2>
        </div>
        {data[0].scheduled_at !== null && (
          <div className="flex lg:flex-row flex-col lg:gap-8">
            <div className="flex flex-col mt-1 gap-1 md:grid md:grid-cols-2">
              <h1 className="text-light  font-bold text-sm w-fit my-2">
                Scheduled At
              </h1>
              <h2 className="text-black  text-sm"> {data[0].scheduled_at}</h2>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col space-y-2 mt-2">
        <div className="flex flex-col mt-1 gap-1 md:grid md:grid-cols-2">
          <h1 className="text-light font-bold text-sm w-fit">Total Sms:</h1>{" "}
          <p className="text-sm">{data[0].total_sms}</p>
        </div>
        <div className="flex flex-col mt-1 gap-1 md:grid md:grid-cols-2">
          <h1 className="text-light font-bold text-sm w-fit">Price:</h1>{" "}
          <p className="text-sm">{data[0].price_per_sms}</p>
        </div>
        <div className="flex flex-col mt-1 gap-1 md:grid md:grid-cols-2">
          <h1 className="text-light font-bold text-sm w-fit">Total Amount:</h1>{" "}
          <p className="text-sm">{data[0].total_amount}</p>
        </div>
      </div>
    </div>
  );
};

export default BlastSmsDetails;
