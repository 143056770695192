import {
  SET_IS_FETCHING_CREDIT_WALLETS,
  SET_IS_FETCHING_CREDIT_WALLET_DEPOSITS,
  SET_IS_FETCHING_CREDIT_WALLET_TRANSACTIONS,
  SET_IS_FETCHING_CREDIT_WALLET_TRANSFERS,
  SET_IS_FETCHING_PAYMENT_WALLETS,
  SET_IS_FETCHING_PAYMENT_WALLET_DEPOSITS,
  SET_IS_FETCHING_PAYMENT_WALLET_TRANSACTIONS,
  SET_IS_FETCHING_PAYMENT_WALLET_TRANSFERS,
  SET_IS_FETCHING_MOBILE_WITHDRAWS,
  SET_IS_FETCHING_BANK_WITHDRAWS,
  FETCH_CREDIT_WALLETS,
  FETCH_CREDIT_WALLET_DEPOSITS,
  FETCH_CREDIT_WALLET_TRANSACTIONS,
  FETCH_CREDIT_WALLET_TRANSFERS,
  FETCH_PAYMENT_WALLETS,
  FETCH_PAYMENT_WALLET_DEPOSITS,
  FETCH_PAYMENT_WALLET_TRANSACTIONS,
  FETCH_PAYMENT_WALLET_TRANSFERS,
  FETCH_MOBILE_WITHDRAWS,
  FETCH_BANK_WITHDRAWS,
} from '../actions/wallet';

const initialState = {
  is_fetching_credit_wallets: false,
  is_fetching_credit_wallet_deposits: false,
  is_fetching_credit_wallet_transactions: false,
  is_fetching_credit_wallet_transfers: false,
  is_fetching_payment_wallets: false,
  is_fetching_payment_wallet_deposits: false,
  is_fetching_payment_wallet_transactions: false,
  is_fetching_payment_wallet_transfers: false,
  is_fetching_mobile_withdraws: false,
  is_fetching_bank_withdraws: false,
  credit_wallets: [],
  credit_wallet_deposits: [],
  credit_wallet_transfers: [],
  credit_wallet_transactions: [],
  payment_wallets: [],
  payment_wallet_deposits: [],
  payment_wallet_transfers: [],
  payment_wallet_transactions: [],
  mobile_withdraws: [],
  bank_withdraws: [],
};

export const wallet = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_CREDIT_WALLETS:
      return {
        ...state,
        is_fetching_credit_wallets: action.payload,
      };

    case SET_IS_FETCHING_CREDIT_WALLET_DEPOSITS:
      return {
        ...state,
        is_fetching_credit_wallet_deposits: action.payload,
      };

    case SET_IS_FETCHING_CREDIT_WALLET_TRANSACTIONS:
      return {
        ...state,
        is_fetching_credit_wallet_transactions: action.payload,
      };

    case SET_IS_FETCHING_CREDIT_WALLET_TRANSFERS:
      return {
        ...state,
        is_fetching_credit_wallet_tranfers: action.payload,
      };

    case SET_IS_FETCHING_PAYMENT_WALLETS:
      return {
        ...state,
        is_fetching_payment_wallets: action.payload,
      };

    case SET_IS_FETCHING_PAYMENT_WALLET_DEPOSITS:
      return {
        ...state,
        is_fetching_payment_wallet_deposits: action.payload,
      };

    case SET_IS_FETCHING_PAYMENT_WALLET_TRANSACTIONS:
      return {
        ...state,
        is_fetching_payment_wallet_transactions: action.payload,
      };

    case SET_IS_FETCHING_PAYMENT_WALLET_TRANSFERS:
      return {
        ...state,
        is_fetching_payment_wallet_tranfers: action.payload,
      };

    case SET_IS_FETCHING_MOBILE_WITHDRAWS:
      return {
        ...state,
        is_fetching_mobile_withdraws: action.payload,
      };

    case SET_IS_FETCHING_BANK_WITHDRAWS:
      return {
        ...state,
        is_fetching_bank_withdraws: action.payload,
      };

    case FETCH_CREDIT_WALLETS:
      return {
        ...state,
        credit_wallets: action.payload,
      };

    case FETCH_CREDIT_WALLET_DEPOSITS:
      return {
        ...state,
        credit_wallet_deposits: action.payload,
      };

    case FETCH_CREDIT_WALLET_TRANSFERS:
      return {
        ...state,
        credit_wallet_transfers: action.payload,
      };

    case FETCH_CREDIT_WALLET_TRANSACTIONS:
      return {
        ...state,
        credit_wallet_transactions: action.payload,
      };

    case FETCH_PAYMENT_WALLETS:
      return {
        ...state,
        payment_wallets: action.payload,
      };

    case FETCH_PAYMENT_WALLET_DEPOSITS:
      return {
        ...state,
        payment_wallet_deposits: action.payload,
      };

    case FETCH_PAYMENT_WALLET_TRANSFERS:
      return {
        ...state,
        payment_wallet_transfers: action.payload,
      };

    case FETCH_PAYMENT_WALLET_TRANSACTIONS:
      return {
        ...state,
        payment_wallet_transactions: action.payload,
      };

    case FETCH_MOBILE_WITHDRAWS:
      return {
        ...state,
        mobile_withdraws: action.payload,
      };

    case FETCH_BANK_WITHDRAWS:
      return {
        ...state,
        bank_withdraws: action.payload,
      };

    default:
      return state;
  }
};
