import React, { useState, useEffect } from 'react';
import Axios from '../../../components/Axios';
import { useForm } from 'react-hook-form';
import Modal from '../../../components/modal/Modal';
import Button from '../../../components/Button';
import { ToastComponent, ToasterComponent } from '../../../components/Toast';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { APP } from '../../../utils/constant';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBankAccounts } from '../../../redux/actions/bank';

//import { token } from '../../../Routes/AdvertiserRoutes';

const AddAccount = ({
  openAccountModal,
  handleAccountOpenModal,
  bankId,
  addBankAccountData,
  setAddBankAccountData,
  config,
  getAccounts,
}) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const { banks } = useSelector((state) => state.bank);
  const [allCurrency, setAllCurency] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setError,
  } = useForm();

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'inactive' },
  ];
  const getCurrency = () => {
    try {
      Axios.get(APP.API_MAIN_URL + '/utils/user/currencies', config).then(
        (res) => {
          setAllCurency(res.data.data);
        }
      );
    } catch (error) {
      console.log('Error in retrieving currency', error);
    }
  };

  console.log(allCurrency, 'allCurrency');

  useEffect(() => {
    getCurrency();
  }, []);

  const onSubmit = () => {
    try {
      setButtonLoading(true);
      Axios.post(
        APP.API_MAIN_URL + `/admin/banks/${bankId}/accounts`,
        addBankAccountData,
        config
      )
        .then((res) => {
          setButtonLoading(false);
          dispatch(fetchBankAccounts(bankId));
          setAddBankAccountData({
            account_name: '',
            account_number: '',
            currency_id: 1,
            status: 'active',
          });
          reset();
        })
        .catch((err) => {
          setButtonLoading(false);
          notify('error', err.response.data.message);
          console.log('error in saving bank account', err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="overflow-y-auto">
      <ToasterComponent />
      <form
        className="flex-col text-sm mx-auto w-3/4 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Modal
          isOpen={openAccountModal}
          onClose={() => {
            reset();
            handleAccountOpenModal();
          }}
          Style={'w-[90%] lg:w-1/3  flex m-auto py-4'}
        >
          <Modal.Header> Add Account</Modal.Header>
          <Modal.Body>
            <div className="flex flex-col px-4 ">
              <Input
                labelText={'Account Number'}
                labelFor={'account_number'}
                name={'accountnumber'}
                type={'text'}
                placeholder={'Enter an account number'}
                isRequired={true}
                customClass="w-full my-1"
                onChange={(e) => {
                  e.target.value.length > 0
                    ? errors.accountname && delete errors.accountname
                    : (errors.accountname = {
                        message: 'Account number is required',
                      });
                  setAddBankAccountData((prevData) => {
                    return { ...prevData, account_number: e.target.value };
                  });
                }}
                register={register}
                errors={errors}
              />
              <Input
                labelText={'Account name'}
                labelFor={'Account name'}
                name={`accountName`}
                type={'text'}
                placeholder={'enter account name'}
                onChange={(e) => {
                  e.target.value.length > 0
                    ? errors.accountName && delete errors.accountName
                    : (errors.accountName = {
                        message: 'account name is required',
                      });
                  setAddBankAccountData((prevData) => {
                    return { ...prevData, account_name: e.target.value };
                  });
                }}
                isRequired={true}
                customClass="w-full my-1"
                register={register}
                errors={errors}
              />{' '}
              <Button
                type="submit"
                content={'Submit'}
                loading={buttonLoading}
                btnColor="primary"
                Style={
                  'left-[0px] bottom-[0px] text-white w-fit p-8 flex  self-end mt-6'
                }
              />
            </div>
          </Modal.Body>
        </Modal>
      </form>
    </div>
  );
};

export default AddAccount;
