import axios from "axios";
import { useState } from "react";
import { APP, config, notify } from "../../../utils/constant";
import { useForm } from "react-hook-form";
import Modal2 from "../../../components/modal/Modal2";
import Input from "../../../components/Input";
import TextArea from "../../../components/TextArea";
import Button from "../../../components/Button";
import { useSelector } from "react-redux";

const EditEnvironment = ({
  editEnvironment,
  handleUpdateEnvirnoment,
  qrCodeId,
  setEnvironmentData,
  environmentData,
  getSingleQr,
  getQrCodes,
}) => {
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);

    axios
      .put(
        APP.API_MAIN_URL +
          `/admin/qr-codes/${qrCodeId}/environments/${environmentData.id}`,
        environmentData,
        config(token)
      )
      .then((res) => {
        setLoading(false);
        handleUpdateEnvirnoment(!editEnvironment);
        getSingleQr();
        getQrCodes();
        reset();
        notify("success", "Environment Updated successfully!");
      })
      .catch((err) => {
        setLoading(false);
        notify("Fail to update environment");
        console.log(err);
      });
  };

  // useForm methods
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
    control,
  } = useForm();
  console.log("environmentData", environmentData);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal2
        isOpen={editEnvironment}
        onClose={() => handleUpdateEnvirnoment(!editEnvironment)}
        Style={"w-fit m-4 flex m-auto lg:w-[40%] px-3"}
      >
        <Modal2.Header>
          <div className="font-bold ">Edit environment</div>
        </Modal2.Header>
        <Modal2.Body>
          <Input
            labelText={"New Environment"}
            name={`name`}
            type={"text"}
            placeholder={"name"}
            value={environmentData?.name}
            isRequired={true}
            customClass="w-full text-sm mx-auto"
            register={register}
            errors={errors}
            onChange={(e) => {
              clearErrors("name");
              setEnvironmentData((prevData) => ({
                ...prevData,
                name: e.target.value,
              }));
            }}
          />
          <TextArea
            labelText={"details"}
            labelFor={"details"}
            name={`details`}
            isRequired={true}
            value={environmentData?.details}
            placeholder={"enter details"}
            customClass="w-full"
            register={register}
            errors={errors}
            onChange={(e) => {
              clearErrors("details");
              setEnvironmentData((prevData) => ({
                ...prevData,
                details: e.target.value,
              }));
            }}
          />
        </Modal2.Body>
        <div className="flex justify-end items-center space-x-4 p-2 mb-2">
          <Button
            content="Cancel"
            loading={false}
            onClick={() => {
              handleUpdateEnvirnoment(!editEnvironment);
              reset();
            }}
            Style={"text-primary border bg-secondary  h-[40px] mt-2 ml-0 "}
          />

          <Button
            type="submit"
            content="Update"
            loading={loading}
            Style={"text-white bg-primary h-[40px] mt-2 ml-0 "}
          />
        </div>
      </Modal2>
    </form>
  );
};

export default EditEnvironment;
