import React, { useRef, useEffect } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { BsCheckLg } from 'react-icons/bs';
import { BiDollar } from 'react-icons/bi';
import { notifications } from '../seeds/Data';
const Notification = ({ showNotification, toggleNotification }) => {
  let notificationRef = useRef();
  useEffect(() => {
    const notificationHandler = (e) => {
      if (!notificationRef.current) {
        return;
      } else if (!notificationRef.current.contains(e.target)) {
        toggleNotification();
      }
    };
    document.addEventListener('mousedown', notificationHandler);

    return () => {
      document.removeEventListener('mousedown', notificationHandler);
    };
  });
  return (
    <div className="">
      {showNotification && (
        <div
          className="modal w-screen h-screen fixed top-0 left-0 z-20 px-4 bg-primary/40 "
          onClick={(e) => {}}
        >
          <div
            className="flex flex-col absolute top-[70px] right-[0] md:m-0 md:right-[50px] z-2 w-full max-w-[400px] bg-white py-4 rounded-lg max-h-[600px]"
            ref={notificationRef}
          >
            <div className="flex border-b border-light/30 w-full">
              <div className="flex justify-between w-full px-4 items-center">
                <h1 className="font-bold text-lg">Notifications</h1>
                <h1 className="cursor-pointer">Mark all as read</h1>
              </div>
            </div>
            <div className="flex flex-col overflow-y-auto">
              {notifications.map((notification) => {
                return (
                  <div className="border-b border-light/20 mt-2 cursor-pointer">
                    <div className="flex flex-row px-4  gap-2">
                      <div className=" relative w-[80px] h-[40px]">
                        <img
                          src={notification.logo}
                          alt=""
                          className="w-[100px] h-[50px] object-contain"
                        />
                        <div
                          className={`absolute bottom-[-5px] right-[-5px] h-[20px] w-[20px] rounded-full flex items-center justify-center ${
                            notification.type === 'accepted'
                              ? 'bg-[#108240]'
                              : notification.type === 'declined'
                              ? 'bg-error'
                              : 'bg-[#DDC918]'
                          }`}
                        >
                          {notification.type === 'accepted' ? (
                            <BsCheckLg color="white" size={15} />
                          ) : notification.type === 'declined' ? (
                            <RxCross2 color="white" size={15} />
                          ) : (
                            <BiDollar color="white" size={15} />
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm">
                          {' '}
                          <b>{notification.name}</b>{' '}
                          {notification.type == 'accepted'
                            ? 'has accepted your campaign offer'
                            : notification.type == 'declined'
                            ? 'has declined your campaign offer'
                            : 'has requested payment on your campaign'}{' '}
                          <b>{notification.campaign}</b>.
                        </p>
                        {notification.time.date === 'today' ? (
                          <p className="py-2 text-sm font-bold text-light">
                            {notification.time.time}
                          </p>
                        ) : (
                          <p className="flex items-center py-2 text-sm font-bold text-light">
                            {notification.time.date}
                            <span className="h-[5px] w-[5px] bg-light rounded-full mx-2"></span>
                            {notification.time.time}
                          </p>
                        )}
                      </div>
                      <div className="flex flex-col items-center justify-center gap-1">
                        <div className="h-[7px] w-[7px] rounded-full bg-black"></div>
                        <RxCross2 className="cursor-pointer" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notification;
