export const feebackSeeds = [
  {
    id: 1,
    names: 'Rolem Ipsum',
    email: 'rolem@gmail.com',
    role: 'Advertiser',
    rate: 5,
    date: '06/07/2023',
    feedback: '',
    source: 'create campaign',
    status: 'Ok',
  },
  {
    id: 2,
    names: 'Rolem Ipsum',
    email: 'rolem@gmail.com',
    role: 'Advertiser',
    rate: 4,
    date: '11/07/2023',
    feedback:
      'There were limited customization options for SMS campaigns. It would be great to have more control over the message layout and design.',
    source: 'create campaign',
    status: 'Escalated',
  },
  {
    id: 3,
    names: 'Rolem Ipsum',
    email: 'rolem@gmail.com',
    role: 'Advertiser',
    rate: 3,
    date: '13/07/2023',
    feedback:
      'I experienced difficulties in integrating the campaigns with my CRM system. The integration process was complex and required technical expertise',
    source: 'create campaign',
    status: 'Resolved',
  },
  {
    id: 4,
    names: 'Rolem Ipsum',
    email: 'rolem@gmail.com',
    role: 'Advertiser',
    rate: 5,
    date: '14/07/2023',
    feedback: '',
    source: 'create campaign',
    status: 'Ok',
  },
  {
    id: 5,
    names: 'Rolem Ipsum',
    email: 'rolem@gmail.com',
    role: 'Advertiser',
    rate: 5,
    date: '13/07/2023',
    feedback: '',
    source: 'create campaign',
    status: 'Ok',
  },
  {
    id: 6,
    names: 'Rolem Ipsum',
    email: 'rolem@gmail.com',
    role: 'Advertiser',
    rate: 4,
    date: '13/07/2023',
    feedback:
      'The campaign analytics provided are not comprehensive enough. I would like to see more detailed reports on the performance of each campaign.',
    source: 'create campaign',
    status: 'Resolved',
  },
  {
    id: 7,
    names: 'Rolem Ipsum',
    email: 'rolem@gmail.com',
    role: 'Advertiser',
    rate: 5,
    date: '20/07/2023',
    feedback: '',
    source: 'create campaign',
    status: 'Ok',
  },
  {
    id: 8,
    names: 'Rolem Ipsum',
    email: 'rolem@gmail.com',
    role: 'Advertiser',
    rate: 5,
    date: '15/07/2023',
    feedback: '',
    source: 'create campaign',
    status: 'Ok',
  },
  {
    id: 9,
    names: 'Rolem Ipsum',
    email: 'rolem@gmail.com',
    role: 'Advertiser',
    rate: 3,
    date: '21/07/2023',
    feedback:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident',
    source: 'create campaign',
    status: 'Resolved',
  },
];

export const payment = [
  {
    id: 1,
    logo: 'https://pbs.twimg.com/profile_images/1167012248553971714/NbOOQI62_400x400.jpg',
    bankName: 'BK',
    accountName: 'Konvey-Rwanda',
    number: '000253-3135-9043',
    code: 'BKIGRWRWXXX',
  },
  {
    id: 2,
    logo: 'https://upload.wikimedia.org/wikipedia/commons/1/15/Equity_Group_Logo.png',
    bankName: 'EQUITY',
    accountName: 'Konvey-Rwanda',
    number: '12025-0135-9043',
    code: ' EQIGRWRWXXX',
  },
  {
    id: 3,
    logo: 'https://www.rwandayp.com/img/rw/e/1627306441-86-kcb.jpg',
    bankName: 'KCB',
    accountName: 'Konvey-Rwanda',
    number: '44025-0135-9000',
    code: 'KCBGRWRWXXX',
  },
];

export const serviceDetails = [
  {
    hostLogo: '',
    hostName: 'TV1',
    presenters: [
      {
        presenterTitle: 'Presenter',
        presenterList: ['KNC', 'Mutabaruka Angelbert', 'Imfurayiwacu'],
      },
    ],
    serviceDescription:
      'Lörem ipsum mikroform. Ses vide jåsade fösade, innan gugisk Konar kabaning i decinebel inte potären farade Röstsamtal stöddjur, polyjyvis och fösamma ming.',
    serviceFile: '',
    serviceAvailability: [
      {
        day: 'Monday',
        time: '06:00 am - 07:30 am',
        sec: 1800,
        pps: 180,
      },
      {
        day: 'Monday',
        time: '07:00 pm - 08:30 pm',
        sec: 1000,
        pps: 250,
      },
      {
        day: 'Wednesday',
        time: '06:00 am - 07:30 am',
        sec: 1200,
        pps: 150,
      },
      {
        day: 'Sunday',
        time: '05:00 pm - 06:30 pm',
        sec: 1800,
        pps: 100,
      },
    ],
  },
];

export const campaignOverview = [
  {
    name: 'Rwanda Foam Sofas',
    description:
      'We examine the top of funnel to figure out how to make people to a subscribe newsletter then turn prospects into leads.',
    fileType: 'Videofile.mp4',
    campaignType: 'Awareness',
    publishers: [
      {
        name: 'TV1 & R1',
        spaces: [
          {
            name: 'TV Show',
            hosts: [
              {
                hostName: 'TV1',
                hostLogo: '',
                services: [
                  {
                    name: 'Rirarashe',
                  },
                  {
                    name: 'Amakuru',
                  },
                  {
                    name: 'Top 10 Iwacu',
                  },
                  {
                    name: "Urubuga rw'imikino",
                  },
                  {
                    name: 'Stand and shine',
                  },
                ],
              },
            ],
          },

          {
            name: 'Radio Show',
            hosts: [
              {
                hostName: 'Radio 1',
                hostLogo: '',
                services: [
                  {
                    name: 'Buracyeye',
                  },
                  {
                    name: 'Ikinamico',
                  },
                ],
              },
            ],
          },
          {
            name: 'Event',
            hosts: [
              {
                hostName: 'Kigali Convention Center',
                hostLogo: '',
                services: [
                  {
                    name: 'Karaoke',
                  },
                  {
                    name: 'Kwita Izina',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    konveySchedule: {
      type: 'Uniform',
      from: '04th/05/2023',
      to: '15th/06/2023',
      days: [
        {
          day: 'Monday',
          time: '08:00 am',
        },
        {
          day: 'Tuesday',
          time: '10:00 am',
        },
        {
          day: 'Wednesday',
          time: '04:00 pm',
        },
      ],
    },
  },
];

export const campaigns = [
  {
    id: 1,
    name: 'Rwanda Foam Sofas',
    publishers: [{ name: 'Konvey', spaces: ['Konvey'] }],
    type: 'SMS Broadcast',
    space: 'Konvey Direct',
    amount: '723,112 Rwf',
    status: 'completed',
  },
  {
    id: 2,
    name: 'Ayoba App',
    publishers: [
      { name: 'Konvey', spaces: ['Konvey Direct'] },
      { name: 'TV1 & R1', spaces: ['TV', 'Radio'] },
      { name: 'RBA', spaces: ['Event'] },
    ],
    type: 'Conversion',
    amount: '1,009,501 Rwf',
    status: 'ongoing',
  },
  {
    id: 3,
    name: 'Tour Du Rwanda',
    publishers: [{ name: 'Konvey', spaces: ['Konvey'] }],
    type: 'SMS Broadcast',
    space: 'Konvey Direct',
    amount: '809,319 Rwf',
    status: 'ongoing',
  },
  {
    id: 4,
    name: 'Rwanda Foam Sofas',
    publishers: [
      { name: 'Konvey', spaces: ['Konvey Direct'] },
      { name: 'RBA', spaces: ['TV', 'Radio'] },
    ],
    type: 'Awareness',
    space: 'Konvey Direct',
    amount: '409,802 Rwf',
    status: 'pending',
  },
  {
    id: 5,
    name: 'Primus Guma Guma',
    publishers: [{ name: 'Konvey', spaces: ['Konvey'] }],
    type: 'Awareness',
    space: 'Konvey Direct',
    amount: '29,802 Rwf',
    status: 'declined',
  },
];

export const notifications = [
  {
    name: 'Konvey',
    logo: 'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/4d4786119276191.609a5318efc25.jpg',
    type: 'accepted',
    campaign: 'Download the ayoba App',
    time: {
      date: 'today',
      time: 'just now',
    },
  },
  {
    name: 'RBA',
    logo: 'https://www.rba.co.rw/admin/user_data/profile/40e3a.jpg',
    type: 'payment',
    campaign: 'MTN Izihirwe',
    time: {
      date: 'today',
      time: '1h ago',
    },
  },
  {
    name: 'BTN',
    logo: 'https://play-lh.googleusercontent.com/QBZ3LPlwUF4yZS8cYHpMWtZI36ksoFmxDDBvfuCcZoXca5eQ9-j8U227GIp2N8mMYF4',
    type: 'declined',
    campaign: 'Didoz eat and win',
    time: {
      date: '18/02/23',
      time: '12:04',
    },
  },
  {
    name: 'TV1 & R1',
    logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRbsXZCy2xuM5DZvVMWw_iiv1WIGI7LiLqxZf9mOCC3A&s',
    type: 'accepted',
    campaign: 'Didoz eat and win',
    time: {
      date: '10/02/23',
      time: '08:04',
    },
  },
  {
    name: 'RBA',
    logo: 'https://www.rba.co.rw/admin/user_data/profile/40e3a.jpg',
    type: 'payment',
    campaign: 'MTN Izihirwe',
    time: {
      date: 'today',
      time: '1h ago',
    },
  },
  {
    name: 'BTN',
    logo: 'https://play-lh.googleusercontent.com/QBZ3LPlwUF4yZS8cYHpMWtZI36ksoFmxDDBvfuCcZoXca5eQ9-j8U227GIp2N8mMYF4',
    type: 'declined',
    campaign: 'Didoz eat and win',
    time: {
      date: '18/02/23',
      time: '12:04',
    },
  },
  {
    name: 'BTN',
    logo: 'https://play-lh.googleusercontent.com/QBZ3LPlwUF4yZS8cYHpMWtZI36ksoFmxDDBvfuCcZoXca5eQ9-j8U227GIp2N8mMYF4',
    type: 'declined',
    campaign: 'Didoz eat and win',
    time: {
      date: '10/02/23',
      time: '08:04',
    },
  },
  {
    name: 'RBA',
    logo: 'https://www.rba.co.rw/admin/user_data/profile/40e3a.jpg',
    type: 'payment',
    campaign: 'MTN Izihirwe',
    time: {
      date: 'today',
      time: '1h ago',
    },
  },
  {
    name: 'BTN',
    logo: 'https://play-lh.googleusercontent.com/QBZ3LPlwUF4yZS8cYHpMWtZI36ksoFmxDDBvfuCcZoXca5eQ9-j8U227GIp2N8mMYF4',
    campaign: 'Didoz eat and win',
    time: {
      date: '18/02/23',
      time: '12:04',
    },
  },
  {
    name: 'TV1 & R1',
    logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRbsXZCy2xuM5DZvVMWw_iiv1WIGI7LiLqxZf9mOCC3A&s',
    type: 'declined',
    campaign: 'Didoz eat and win',
    time: {
      date: '10/02/23',
      time: '08:04',
    },
  },
];
const campaign = {
  title: '',
  description: '',
  type: '',
  publisher_type: [{ label: 'Konvey Direct' }],
  channels: [
    {
      name: 'SMS',
      is_sender_name_requested: false,
      sender_name: 'MTN-Izihirwe',
      details: '',
      app_installation: {
        is_app_installation: true,
        app_Details: [
          {
            name: 'AYOBA app',
            link: 'https://dummylink.com',
          },
          {
            name: 'Momo app',
            link: 'https://dummylink.com',
          },
        ],
      },
      files: [],
      audience: [
        {
          name: 'Audience 1',
          maximum_audience: 1500,
        },
        {
          name: 'Konvey Base',
          maximum_audience: 20000,
        },
      ],
    },
    {
      name: 'Email',
      is_sender_name_requested: false,
      sender_name: 'MTN-Izihirwe',
      details: '',
      app_installation: {
        is_app_installation: true,
        app_Details: [
          {
            name: 'AYOBA app',
            link: 'https://dummylink.com',
          },
          {
            name: 'Momo app',
            link: 'https://dummylink.com',
          },
        ],
      },
      files: [],
      audience: [
        {
          name: 'Audience 1',
          maximum_audience: 1500,
        },
        {
          name: 'Konvey Base',
          maximum_audience: 20000,
        },
      ],
    },
    {
      name: 'Social Media',
      platform: 'Twitter',
      option: 'Existing post',
      post_links: [
        'https://dummylink.com/',
        'https://dummylink.com/',
        'https://dummylink.com/',
        'https://dummylink.com/',
      ],
      post_details: {
        description: '',
        files: [],
      },
      audience_target_type: [
        {
          name: 'clicks',
          target_nbr: 1500,
        },
        {
          name: 'expansion',
          target_nbr: 20000,
        },
      ],
    },
  ],
  from: '',
  to: '',
  is_scheduled: true,
  sms_email_schedule: {
    is_uniform: true,
    is_custom: false,
    uniform: [
      {
        from: '',
        to: '',
        days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
      },
    ],
    custom: [
      {
        days: [
          {
            day: 'Mon',
            date: '12/05/2023',
          },
          {
            day: 'Wed',
            date: '12/05/2023',
          },
          {
            day: 'Fri',
            date: '12/05/2023',
          },
        ],
      },
    ],
  },
};

export const skeletonData = [
  {
    names: 'James',
    email: 'james@gmail.com',
    phone: 1234567890,
    gender: 'male',
    age: 25,
    location: 'New York',
  },
  {
    names: 'James',
    email: 'james@gmail.com',
    phone: 1234567890,
    gender: 'male',
    age: 25,
    location: 'New York',
  },
  {
    names: 'James',
    email: 'james@gmail.com',
    phone: 1234567890,
    gender: 'male',
    age: 25,
    location: 'New York',
  },
  {
    names: 'James',
    email: 'james@gmail.com',
    phone: 1234567890,
    gender: 'male',
    age: 25,
    location: 'New York',
  },
  {
    names: 'James',
    email: 'james@gmail.com',
    phone: 1234567890,
    gender: 'male',
    age: 25,
    location: 'New York',
  },
];

export const skeletonColumns = [
  {
    Header: (
      <div className="w-full h-[10px] bg-gray animate-pulse rounded-md"></div>
    ),
    accessor: 'names',
    Cell: ({ row }) => {
      return (
        <div className="w-full h-[10px] bg-gray animate-pulse rounded-md"></div>
      );
    },
  },
  {
    Header: (
      <div className="w-full h-[10px] bg-gray animate-pulse rounded-md"></div>
    ),
    accessor: 'email',
    Cell: ({ row }) => {
      return (
        <div className="w-full h-[10px] bg-gray animate-pulse rounded-md"></div>
      );
    },
  },
  {
    Header: (
      <div className="w-full h-[10px] bg-gray animate-pulse rounded-md"></div>
    ),
    accessor: 'phone',
    Cell: ({ row }) => {
      return (
        <div className="w-full h-[10px] bg-gray animate-pulse rounded-md"></div>
      );
    },
  },
  {
    Header: (
      <div className="w-full h-[10px] bg-gray animate-pulse rounded-md"></div>
    ),
    accessor: 'gender',
    Cell: ({ row }) => {
      return (
        <div className="w-full h-[10px] bg-gray animate-pulse rounded-md"></div>
      );
    },
  },
  {
    Header: (
      <div className="w-full h-[10px] bg-gray animate-pulse rounded-md"></div>
    ),
    accessor: 'age',
    Cell: ({ row }) => {
      return (
        <div className="w-full h-[10px] bg-gray animate-pulse rounded-md"></div>
      );
    },
  },
  {
    Header: (
      <div className="w-full h-[10px] bg-gray animate-pulse rounded-md"></div>
    ),
    accessor: 'location',
    Cell: ({ row }) => {
      return (
        <div className="w-full h-[10px] bg-gray animate-pulse rounded-md"></div>
      );
    },
  },
];
const currentDate = new Date();
const yesterdayDate = new Date(currentDate);
yesterdayDate.setDate(yesterdayDate.getDate() - 1);

export const NotificationsData = [
  {
    id: 1,
    type: 'approval',
    sender: 'MTN Rwanda',
    image:
      'https://upload.wikimedia.org/wikipedia/commons/9/93/New-mtn-logo.jpg',
    message: 'has created campaign',
    subject: 'Download the Ayoba app ',
    subjectId: '2',
    created_at: new Date(),
  },
  {
    id: 10,
    type: 'approval',
    sender: 'Airtel Rwanda',
    image:
      'https://www.careersinafrica.com/wp-content/uploads/2013/07/airtel.jpg',
    message: 'has created campaign',
    subject: 'CHATINGA',
    subjectId: '2',
    created_at: yesterdayDate,
  },

  {
    id: 5,
    type: 'info',
    sender: '',
    image:
      'https://cdn.shopify.com/app-store/listing_images/d401b285ff06fcd1904d93a1e3263407/icon/CIu4lt70lu8CEAE=.png',
    message: 'Your file has been uploaded successfully',
    subject: '',
    subjectId: '2',
    created_at: new Date('2023-01-23T18:20:00Z'),
  },
  {
    id: 12,
    type: 'info',
    sender: 'Great news!',
    image:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEel7ZYV_Kg94IDchCseBi8TCP-lvjPPyEnA&usqp=CAU',
    message:
      'We are launching a new version of our application and will be available on the 15th, July. We will have a product demo followed by an AMA (Ask Me Anything) on the passion for our desire to build next generation products.',
    subject: '',
    subjectId: '2',
    created_at: new Date('2023-01-22T18:20:00Z'),
  },
  {
    id: 6,
    type: 'approval',
    image:
      'https://rwandafoam.com/wp-content/uploads/2022/09/aosk6Jq-600x600-2.png',
    sender: 'Rwanda Foam',
    message: 'has created campaign',
    subject: 'STAY COMFY',
    subjectId: '2',
    created_at: new Date('2023-05-24T10:00:00Z'),
  },
  {
    id: 7,
    type: 'payment',
    sender: 'MTN Rwanda',
    image:
      'https://upload.wikimedia.org/wikipedia/commons/9/93/New-mtn-logo.jpg',
    message: 'has loaded their account with an amount of',
    subject: '110,000,000 Rwf',
    subjectId: '2',
    created_at: new Date(),
  },
  {
    id: 8,
    type: 'payment',
    sender: 'Airtel Rwanda',
    image:
      'https://www.careersinafrica.com/wp-content/uploads/2013/07/airtel.jpg',
    message: 'has loaded their account with an amount of',
    subject: '109,000,000 Rwf',
    subjectId: '2',
    created_at: yesterdayDate,
  },
  {
    id: 9,
    type: 'payment',
    image:
      'https://rwandafoam.com/wp-content/uploads/2022/09/aosk6Jq-600x600-2.png',
    sender: 'Rwanda Foam',
    message: 'has loaded their account with an amount of',
    subject: '22,000,000 Rwf',
    subjectId: '2',
    created_at: new Date('2023-05-24T10:00:00Z'),
  },
  {
    id: 10,
    type: 'support',
    image:
      'https://rwandafoam.com/wp-content/uploads/2022/09/aosk6Jq-600x600-2.png',
    sender: 'Rwanda Foam',
    message:
      'I am having trouble logging into my account.  I have tried resetting my password multiple times but I keep getting an error message saying that my email is not recognized. I have double checked and I am sure that I am using the correct email address. Could you please assist me in resolving this issue as soon as possible?',
    subject: '',
    subjectId: '2',
    created_at: new Date('2023-05-24T10:00:00Z'),
  },
];
