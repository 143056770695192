import React, { useEffect, useRef, useState } from "react";

import Button from "../../components/Button";

import { HiLocationMarker } from "react-icons/hi";

import KONVEYcolorful from "../../Assets/KONVEYColorful.png";

import EXCEL from "../../Assets/excel.png";
import PDF from "../../Assets/pdf.jpg";
import NoData from "../../Assets/noData.svg";
import Carausal from "../../components/Carausal";

import axios from "axios";
import { APP } from "../../utils/constant";
import { useParams } from "react-router-dom";

import Slider2 from "../../components/Slider2";
import ViewQrContent from "../resourcesItems/qrCode/ViewQrContent";
import ViewFiles from "../resourcesItems/qrCode/ViewFiles";

const ScrollToTopOnScrollBottom = () => {
  const scrollableDivRef = useRef(null);

  const handleScroll = () => {
    const scrollableDiv = scrollableDivRef.current;

    if (
      scrollableDiv.scrollTop + scrollableDiv.clientHeight >=
      scrollableDiv.scrollHeight
    ) {
      // Scroll to the top when reaching the bottom
      scrollableDiv.scrollTop = 0;
    }
  };
  return (
    <div
      ref={scrollableDivRef}
      className="h-48 overflow-y-scroll bg-gray-200"
      onScroll={handleScroll}
    >
      {/* Your content goes here */}
      <div className="h-96 bg-blue-200 mb-10">Scrollable content...</div>
    </div>
  );
};

function QrView() {
  const [subscribe, setSubscribe] = useState(false);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [scannedDoc, setScannedDoc] = useState({});
  const [data, setData] = useState([]);
  const [openFile, setOpenFile] = useState(false);
  const [pdfLink, setPdfLink] = useState("");
  const [excelLink, setExcelLink] = useState("");
  const [fileType, setFileType] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [profContent, setProfContent] = useState([]);

  const [viewContent, setViewContent] = useState(false);
  const [content, setContent] = useState({
    url: "",
    title: "",
    details: "",
  });

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + " ... " : str;
  };
  // console.log("Current", currentSlide);
  const { reference } = useParams();
  const scrollableDivRef = useRef(null);

  const handleScroll = () => {
    const scrollableDiv = scrollableDivRef.current;

    if (
      scrollableDiv.scrollTop + scrollableDiv.clientHeight >=
      scrollableDiv.scrollHeight
    ) {
      // Scroll to the top when reaching the bottom
      scrollableDiv.scrollTop = 0;
    }
  };
  const handleViewQrContent = () => {
    setViewContent(!viewContent);
  };
  const refId = reference;
  const getScannedContent = () => {
    console.log("retriving....");
    axios
      .get(APP.API_MAIN_URL + "/public/qr-codes/contents/standard/" + reference)
      .then((res) => {
        console.log("After retrieving", res);

        const lastRes =
          res.data.datax.standard_qr_code_placement.standard_qr_code_environment
            .standard_qr_code;

        const userProfile = lastRes.profile;
        const profileContent = lastRes.profile_contents;

        // let selectedProfile = [];

        // profileContent.forEach((item) => {
        //   if (item.profile_content_type.name === "Links") {
        //     selectedProfile.push({
        //       link: userProfile.links,
        //     });
        //   }
        //   if (item.profile_content_type.name === "Phone") {
        //     selectedProfile.push({
        //       phone: 0 + userProfile.phone,
        //     });
        //   }
        //   if (item.profile_content_type.name === "What We Do") {
        //     selectedProfile.push({
        //       profile_services: userProfile.profile_services,
        //     });
        //   }
        //   if (item.profile_content_type.name === "Social Media") {
        //     selectedProfile.push({
        //       profile_social_media: userProfile.profile_social_media,
        //     });
        //   }
        //   if (item.profile_content_type.name === "Website") {
        //     selectedProfile.push({
        //       website: userProfile.website,
        //     });
        //   }
        //   if (item.profile_content_type.name === "Address") {
        //     selectedProfile.push({
        //       address: userProfile.addresses,
        //     });
        //   }
        //   if (item.profile_content_type.name === "Email") {
        //     selectedProfile.push({
        //       email: userProfile.email,
        //     });
        //   }
        //   if (item.profile_content_type.name === "About") {
        //     selectedProfile.push({
        //       about: userProfile.description,
        //     });
        //   }
        // });

        if (lastRes !== null && lastRes !== undefined) {
          const combinedArray = [
            ...(lastRes.files || []).map((file) => ({ type: "file", ...file })),
            ...(lastRes.texts || []).map((text) => ({ type: "text", ...text })),
            ...(lastRes.urls || []).map((url) => ({ type: "url", ...url })),
          ];

          setData(combinedArray);
        }

        setScannedDoc(lastRes);
        // setProfContent(selectedProfile);
      })
      .catch((error) =>
        console.log("Error in retriving scanned content", error)
      );
  };

  useEffect(() => {
    getScannedContent();
  }, [refId]);

  console.log("Profile content", profContent);
  // const query = "Nature";
  console.log("data++++++", data);
  // find thumbNail
  const videoRef = useRef(null);

  const onVideoLoaded = () => {
    const video = videoRef.current;

    // Seek to a specific time (e.g., 5 seconds) in the video
    video.currentTime = 3;

    // Event listener to capture frame at the specified time
    const onSeeked = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert the canvas to a data URL and set it as the thumbnail
      setThumbnail(canvas.toDataURL());
      video.removeEventListener("seeked", onSeeked);
    };

    video.addEventListener("seeked", onSeeked);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="flex flex-col space-y-2  bg-secondary h-full font-nunito bottom-[50%] overflow-y-scroll w-full overflow-x-hidden ">
      <ViewFiles
        pdfUrl={pdfLink}
        open={openFile}
        setOpen={setOpenFile}
        excelLink={excelLink}
        type={fileType}
      />
      <section className="flex flex-col lg:w-[90%] w-full mx-auto sticky top-0 z-20 bg-secondary">
        <div className="flex flex-col lg:flex-row p-4 justify-between" id="top">
          <img
            src={KONVEYcolorful}
            alt="profile logo"
            srcset=""
            id="bchome"
            width={100}
            height={50}
          />
          <div className="flex flex-row space-x-4">
            <Button
              type="button"
              content="sign in"
              loading={false}
              btnColor="primary"
              onClick={() => {
                setSubscribe(!subscribe);
              }}
              Style={"text-white w-max h-[40px] mt-2 ml-0 cursor-pointer"}
            />
            <Button
              type="button"
              content="Explore konvey"
              loading={false}
              btnColor="transparent text-primary border border-primary"
              onClick={() => {
                window.open("https://konvey.rw/", "_blank");
              }}
              Style={"text-primary w-max h-[40px] mt-2 ml-0 cursor-pointer"}
            />
          </div>
        </div>
      </section>
      <div className="relative bg-[#f0f2f5]">
        <div className="flex space-y-4 lg:flex-row lg:space-x-12 w-[90%] lg:w-[90%] mx-auto ">
          <div className="w-full flex-col lg:flex-row mx-auto md:h-[75vh] flex  my-5 gap-4 ">
            <div className="w-full lg:w-[60%] bg-white pt-3 md:pt-14 rounded-md">
              <Carausal
                slidesToShow={1}
                slidesToScroll={1}
                Style={"w-full md:w-[90%] m-auto md:h-[70vh]  "}
                setCurrentSlide={setCurrentSlide}
                currentSlide={currentSlide}
              >
                {data?.map((item, i) => (
                  <div key={i} className="h-[40vh] md:h-[50vh]">
                    {item.type === "text" ? (
                      <div
                        data-tooltip-id="qr"
                        class="w-full flex flex-col justify-center items-center md:p-6 md:w-[95%] mx-auto overflow-auto h-[100%] md:mt-3 text-[16px] rounded-lg"
                      >
                        <h5 class="mb-2 text-2xl text-center  font-bold tracking-tight text-gray-900 dark:text-[#575656]">
                          {item.title}
                        </h5>
                        <p class="font-normal text-gray-700 text-[18px] dark:text-gray-400 text-justify">
                          {item.text}
                        </p>
                      </div>
                    ) : item.type === "file" ? (
                      <div
                        data-tooltip-id="qr"
                        class="w-full flex flex-col justify-center items-center md:ml-5 md:p-6 md:w-[95%] overflow-auto h-[100%] md:mt-3 text-[16px] rounded-lg "
                      >
                        {item.media[0].mime_type
                          .toLowerCase()
                          .includes("image") ? (
                          <>
                            <div className=" w-full ">
                              <img
                                src={item.file}
                                alt=""
                                srcset=""
                                className="object-cover rounded-md w-full h-80 mt-5"
                              />
                            </div>
                            <h5 class="my-2 text-2xl text-center  font-bold tracking-tight text-gray-900 dark:text-[#575656]">
                              {item.title}
                            </h5>
                            <p class="font-normal text-gray-700 text-[18px]  dark:text-gray-400 text-justify">
                              {item.details}
                            </p>
                          </>
                        ) : item.media[0].mime_type
                            .toLowerCase()
                            .includes("spreadsheet") ? (
                          <>
                            <div
                              className=" w-full "
                              onClick={() => {
                                setContent((prevData) => ({
                                  ...prevData,
                                  url: item?.file,
                                  title: item?.title,
                                  details: item?.details,
                                }));
                                handleViewQrContent();
                              }}
                            >
                              <img
                                src={EXCEL}
                                alt=""
                                srcset=""
                                className="object-cover rounded-md w-full h-96"
                              />
                            </div>
                            <h5 class="my-2 text-2xl text-center  font-bold tracking-tight text-gray-900 dark:text-[#575656]">
                              {item.title}
                            </h5>
                            <p class="font-normal text-gray-700 text-[18px]  dark:text-gray-400 text-justify">
                              {item.details}
                            </p>
                          </>
                        ) : item.media[0].mime_type
                            .toLowerCase()
                            .includes("pdf") ? (
                          <>
                            <div
                              className=" w-full "
                              onClick={() => {
                                setContent((prevData) => ({
                                  ...prevData,
                                  url: item?.file,
                                  title: item?.title,
                                  details: item?.details,
                                }));
                                handleViewQrContent();
                              }}
                            >
                              <img
                                src={PDF}
                                alt=""
                                srcset=""
                                className="object-cover rounded-md w-full h-96"
                              />
                            </div>
                            <h5 class="my-2 text-2xl text-center  font-bold tracking-tight text-gray-900 dark:text-[#575656]">
                              {item.title}
                            </h5>
                            <p class="font-normal text-gray-700 text-[18px]  dark:text-gray-400 text-justify">
                              {item.details}
                            </p>
                          </>
                        ) : item.media[0].mime_type
                            .toLowerCase()
                            .includes("video") ? (
                          <>
                            <div className=" w-full">
                              <video
                                controls
                                // muted
                                // onCanPlayThrough={onVideoLoaded}
                              >
                                <source src={item.file} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                            <h5 class="my-2 text-2xl text-center  font-bold tracking-tight text-gray-900 dark:text-[#575656]">
                              {item.title}
                            </h5>
                            <p class="font-normal text-gray-700 text-[18px]  dark:text-gray-400 text-justify">
                              {item.details}
                            </p>
                          </>
                        ) : (
                          <>
                            <div
                              className=" w-full "
                              onClick={() => {
                                setContent((prevData) => ({
                                  ...prevData,
                                  url: item?.file,
                                  title: item?.title,
                                  details: item?.details,
                                }));
                                handleViewQrContent();
                              }}
                            >
                              <img
                                src={
                                  "https://media.istockphoto.com/id/598176230/photo/online-internet-shopping.webp?s=2048x2048&w=is&k=20&c=4NaJOCi6X1WHyRlODuvSnigzrwGrkUvG4aucQN-7JV0="
                                }
                                alt=""
                                srcset=""
                                className="object-cover rounded-md w-full h-96"
                              />
                            </div>
                            <h5 class="my-2 text-2xl text-center  font-bold tracking-tight text-gray-900 dark:text-[#575656]">
                              {item.title}
                            </h5>
                            <p class="font-normal text-gray-700 text-[18px]  dark:text-gray-400 text-justify">
                              {item.details}
                            </p>
                          </>
                        )}
                      </div>
                    ) : item.type === "url" ? (
                      <div
                        data-tooltip-id="qr"
                        class=" flex flex-col justify-center items-center p-6 w-[95%] overflow-auto h-[100%] mt-3 text-[16px] rounded-lg  "
                      >
                        <div className=" w-full">
                          {/* <img
                          src={item.file}
                          alt=""
                          srcset=""
                          className="object-cover rounded-md w-full h-auto"
                        /> */}
                        </div>
                        <h5 class="mb-2 text-2xl text-center  font-bold tracking-tight text-gray-900 dark:text-[#575656]">
                          {item.title}
                        </h5>
                        <span
                          className="text-[#3daef9] text-[18px] cursor-pointer"
                          onClick={() => {
                            setContent((prevData) => ({
                              ...prevData,
                              url: item?.url,
                              title: item?.title,
                              details: item?.details,
                            }));
                            handleViewQrContent();
                          }}
                        ></span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </Carausal>
            </div>
            <div
              className="hidden lg:w-[40%] bg-white h-[50vh] overflow-auto lg:h-[40rem] pb-5  border-[#e3e3e3] md:flex flex-col items-center rounded-md"
              // ref={scrollableDivRef}
            >
              <div className="p-5 bg-primary sticky top-0 z-10 w-full justify-between shadow-[inset_-12px_-8px_40px_#46464620]">
                <h2 className="text-center text-[#fff] font-[700]  capitalize text-2xl xl:text-2xl">
                  Contents
                </h2>
              </div>
              {data?.map((item, i) => (
                <>
                  {item.type === "text" ? (
                    <div
                      onScroll={currentSlide > 3 && handleScroll}
                      key={i}
                      className={`flex items-center lg:w-[95%] lg:h-60 rounded-[5px] mt-3 cursor-pointer transform hover:-translate-y-1 transition-all duration-200 shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] hover:h-64  hover:shadow-[0px_20px_20px_0px_#00000024,0px_3px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024] ${
                        currentSlide === i
                          ? ` h-64 border-2 border-[#ffc72d] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] hover:h-64  hover:shadow-[0px_20px_20px_0px_#00000024,0px_3px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024]`
                          : ""
                      }`}
                      onClick={() => setCurrentSlide(i)}
                    >
                      {currentSlide === i ? (
                        /*<BsCaretRightFill size={20} />*/ <div className="flex flex-col bg-[rgb(72,72,72)] h-full w-3 rounded-sm mr-2"></div>
                      ) : (
                        <div className="ml-2"></div>
                      )}

                      <div className=" w-[15%]  my-4">
                        <div className="bg-primary text-white opacity-70 flex justify-center items-center h-20 w-20 rounded-full">
                          <span className="text-2xl italic font-semibold capitalize ">
                            {item.type}
                          </span>
                        </div>
                      </div>
                      <div className=" pl-4 w-3/4 py-3">
                        <span className="font-bold text-[18px] capitalize text-[rgb(72,72,72)] ">
                          {truncate(item.title, 40)}
                        </span>

                        <p className="border-t-2 text-[16px] rounded-t-[3px] px-1 text-justify pt-4 mt-3 border-[#d9d7d7]">
                          {truncate(item.text, 150)}
                        </p>
                        <div class="text-sm flex items-center text-gray-700 uppercase tracking-wide font-semibold mt-4 text-[#515151] pl-2"></div>
                      </div>
                    </div>
                  ) : item.type === "file" ? (
                    <div
                      onScroll={currentSlide > 3 && handleScroll}
                      key={i}
                      className={`flex items-center lg:w-[95%] lg:h-60 rounded-[5px]  mt-3 cursor-pointer transform hover:-translate-y-1 transition-all duration-200 shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] hover:h-64  hover:shadow-[0px_20px_20px_0px_#00000024,0px_3px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024] ${
                        currentSlide === i
                          ? ` h-64 border-2 border-[#ffc72d] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] hover:h-64  hover:shadow-[0px_20px_20px_0px_#00000024,0px_3px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024]`
                          : ""
                      }`}
                      onClick={() => setCurrentSlide(i)}
                    >
                      {currentSlide === i ? (
                        /*<BsCaretRightFill size={20} />*/ <div className="flex flex-col bg-[rgb(72,72,72)] h-full w-3 rounded-sm mr-2"></div>
                      ) : (
                        <div className="ml-2"></div>
                      )}

                      <div className=" w-[30%]">
                        <div className=" text-white opacity-70 flex justify-center items-center h-20 w-full ">
                          <img
                            src={item.file}
                            alt=""
                            className="h-full object-cover w-full rounded-md"
                          />
                        </div>
                        <span>{item.type}</span>
                      </div>
                      <div className=" pl-4 w-3/4 py-3">
                        <span className="font-bold text-[18px] capitalize text-[rgb(72,72,72)]">
                          {truncate(item.title, 40)}
                        </span>

                        <p className="border-t-2 text-[16px] rounded-t-[3px] px-1 text-justify pt-4 mt-3 border-[#d9d7d7]">
                          {truncate(item.details, 120)}
                        </p>
                        <div class="text-sm flex items-center text-gray-700 uppercase tracking-wide font-semibold mt-4 text-[#515151] pl-2"></div>
                      </div>
                    </div>
                  ) : item.type === "url" ? (
                    <div
                      onScroll={currentSlide > 3 && handleScroll}
                      key={i}
                      className={`flex items-center lg:w-[95%] lg:h-60 rounded-[5px]  mt-3 cursor-pointer transform hover:-translate-y-1 transition-all duration-200 shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] hover:h-64  hover:shadow-[0px_20px_20px_0px_#00000024,0px_3px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024] ${
                        currentSlide === i
                          ? ` h-64 border-2 border-[#ffc72d] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] hover:h-64  hover:shadow-[0px_20px_20px_0px_#00000024,0px_3px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_0px_0px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024]`
                          : ""
                      }`}
                      onClick={() => setCurrentSlide(i)}
                    >
                      {currentSlide === i ? (
                        <div className="flex flex-col bg-[rgb(72,72,72)] h-full w-3 rounded-sm mr-2"></div>
                      ) : (
                        <div className="ml-2"></div>
                      )}

                      <div className=" w-[15%]  ">
                        <div className="bg-primary text-white opacity-70 flex justify-center items-center h-20 w-20 rounded-full">
                          <span className="text-2xl italic font-semibold capitalize">
                            {item.type}
                          </span>
                        </div>
                      </div>
                      <div className=" pl-4 w-3/4">
                        <span className="font-bold text-[18px] capitalize text-[rgb(72,72,72)] ">
                          {truncate(item.title, 40)}
                        </span>

                        <p className="border-t-2 text-[16px] rounded-t-[3px] px-1 text-justify pt-4 mt-3 border-[#d9d7d7]">
                          <a href={item.url}> Click here to visit my link</a>
                        </p>
                        <div class="text-sm flex items-center text-gray-700 uppercase tracking-wide font-semibold mt-4 text-[#515151] pl-2"></div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
        {profContent.length > 0 && (
          <div className="h-72 mx-2 md:mx-0 lg:mx-[5%]  border-t-2 border-slate-400 mb-20  ">
            <h3 className="font-nunito font-bold text-2xl my-3">
              More contents
            </h3>
            <div className="flex overflow-x-auto ">
              <Slider2 data={profContent} />
            </div>
          </div>
        )}

        <div className=" bg-primary/90 absolute text-white w-full h-max m-0 flex flex-row lg:px-[15%] p-2  lg:py-2  mt-10 justify-between">
          <div className="flex flex-col items-center justify-center w-full space-y-2">
            <div className="flex space-x-2 ">
              <HiLocationMarker size={20} className="text-white" />
              <p className="text-sm font-bold">Find us on this address</p>
            </div>
            <div className="flex flex-row px-8 md:w-1/3 flex-wrap items-center justify-center font-nunito">
              Gikondo | Rwamagana | Nyarugenge
            </div>
            {/* <a href="www.facebook.com"> Visit me</a> */}
          </div>
        </div>
      </div>
      <ViewQrContent
        open={viewContent}
        handleViewQrContent={handleViewQrContent}
        content={content}
      />
    </div>
  );
}

export default QrView;
