import { ToastComponent } from "../components/Toast";

export const APP = {
  API_MAIN_URL: process.env.REACT_APP_API_MAIN_URL,
  ACCOUNT_URL: process.env.REACT_APP_ACCOUNT_URL,
  ADVERTISER_API_URL: process.env.REACT_APP_API_ADVERTISER,
  ADVERTISER_BASE_URL: process.env.REACT_APP_ADVERTISER_BASE_URL,
  ADMIN_BASE_URL: process.env.REACT_APP_ADMIN_BASE_URL,
};

export const notify = (type, message) => {
  ToastComponent({
    message: message,
    type: type,
  });
};

export const config = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
};
