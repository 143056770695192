import React, { useState } from 'react';
import Axios from '../../../components/Axios';
import { useForm } from 'react-hook-form';
import Modal from '../../../components/modal/Modal';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';

const SupportModal = ({ openModal, handleOpenModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
  } = useForm();
  return (
    <Modal
      isOpen={openModal}
      onClose={handleOpenModal}
      Style={'w-[50%]  m-auto flex mx-auto items-center justify-center'}
    >
      <Modal.Body>
        <div className="flex flex-col ">
          <div className="w-full flex items-center justify-center mb-4">
            <h1 className="font-bold text-2xl">Support</h1>
          </div>
          <div className="flex flex-col">
            <Input
              labelText={'Email '}
              labelFor={'email'}
              name={'email'}
              type={'text'}
              isRequired={true}
              placeholder={'Email'}
              onChange={(e) => {
                // handleEditAudienceName(e.target.value);
              }}
              customClass="w-full"
              // value={editAudienceName}
              register={register}
              errors={errors}
            />{' '}
            <TextArea
              labelText={'Support'}
              labelFor={'Support Description'}
              name={'Support Description'}
              type={'text'}
              isRequired={true}
              placeholder={'Support Description'}
              customClass="w-full mb-2"
              inputStyle={'h-[80px]'}
              value={''}
              setValue={setValue}
              onChange={(e) => {}}
              register={register}
              errors={errors}
            />
          </div>
          <div className={'flex justify-between mt-8'}>
            <div className={`flex w-fit h-fit`}>
              <Button
                type="button"
                content="Close"
                loading={false}
                btnColor="primary"
                onClick={() => {
                  handleOpenModal();
                  reset();
                }}
                Style={
                  'text-black w-max h-[40px] mt-2 ml-0 bg-white border rounded-sm cursor-pointer'
                }
              />
            </div>

            <div className="w-fit h-fit ml-auto">
              <Button
                type="submit"
                content={
                  <div className="flex items-center justify-center">
                    <h1 className="text-white font-nunito text-base">Submit</h1>
                  </div>
                }
                loading={false}
                onClick={() => {
                  handleOpenModal();
                  reset();
                }}
                btnColor="primary"
                Style={'text-white w-[100px] h-[40px] mt-2 ml-0'}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SupportModal;
