import { SET_ALL_BLASTS, RESET_BLAST } from "../actions/blast";

const initialState = {
  blasts: {},
};

const blast = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_BLASTS:
      return { ...state, blasts: action.payload };

    case RESET_BLAST:
      return initialState;

    default:
      return state;
  }
};

export default blast;
