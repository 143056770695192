import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { APP, config, notify } from "../../../utils/constant";
import { useForm } from "react-hook-form";
import Modal2 from "../../../components/modal/Modal2";
import Input from "../../../components/Input";
import TextArea from "../../../components/TextArea";
import Button from "../../../components/Button";

const EditPlacement = ({
  editPlacement,
  handleUpdatePlacement,
  qrCodeId,
  setPlacementData,
  placementData,
  getSingleQr,
}) => {
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    console.log("EDIT PLACEMENT", placementData);
    axios
      .put(
        APP.API_MAIN_URL +
          `/admin/qr-codes/${qrCodeId}/environments/${placementData.standard_qr_code_environment_id}/placements/${placementData.id}`,
        placementData,
        config(token)
      )
      .then((res) => {
        setLoading(false);
        handleUpdatePlacement(!editPlacement);
        getSingleQr();
        reset();
        notify("success", "Placement Updated successfully!");
      })
      .catch((err) => {
        setLoading(false);
        notify("error", "Fail to update Placement");
        console.log(err);
      });
  };

  // useForm methods
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
    control,
  } = useForm();
  console.log("placementDatayyyyyy", placementData);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal2
        isOpen={editPlacement}
        onClose={() => handleUpdatePlacement(!editPlacement)}
        Style={"w-fit m-4 flex m-auto lg:w-[40%] px-3"}
      >
        <Modal2.Header>
          <div className="font-bold ">Edit Placement</div>
        </Modal2.Header>
        <Modal2.Body>
          <Input
            labelText={"Name"}
            name={`name ${placementData.id}`}
            type={"text"}
            placeholder={"name"}
            value={placementData?.name}
            isRequired={true}
            customClass="w-full text-sm mx-auto"
            register={register}
            errors={errors}
            onChange={(e) => {
              clearErrors("name");
              setPlacementData((prevData) => ({
                ...prevData,
                name: e.target.value,
              }));
            }}
          />
          <Input
            labelText={"Price"}
            name={`price ${placementData.id}`}
            type={"text"}
            placeholder={"Enter price"}
            value={placementData?.price}
            isRequired={true}
            customClass="w-full text-sm mx-auto"
            register={register}
            errors={errors}
            onChange={(e) => {
              clearErrors("price");
              setPlacementData((prevData) => ({
                ...prevData,
                price: e.target.value,
              }));
            }}
          />

          <TextArea
            labelText={"description"}
            labelFor={"description"}
            name={`description ${placementData.id}`}
            isRequired={true}
            value={placementData?.description}
            placeholder={"enter description"}
            customClass="w-full"
            register={register}
            errors={errors}
            onChange={(e) => {
              clearErrors("description");
              setPlacementData((prevData) => ({
                ...prevData,
                description: e.target.value,
              }));
            }}
          />
        </Modal2.Body>
        <Modal2.Footer className={"h-14"}>
          <div className="flex justify-end items-center space-x-4 my-5 ">
            <Button
              content="Cancel"
              loading={false}
              onClick={() => {
                handleUpdatePlacement(!editPlacement);
                reset();
              }}
              Style={"text-primary border bg-secondary  h-[40px] ml-0 "}
            />

            <Button
              type="submit"
              content="Update"
              loading={loading}
              Style={"text-white bg-primary h-[40px]ml-0 "}
            />
          </div>
        </Modal2.Footer>
      </Modal2>
    </form>
  );
};

export default EditPlacement;
