import React, { Fragment, useEffect, useRef, useState } from "react";

import axios from "axios";

import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { useSelector } from "react-redux";

import Modal2 from "../../../components/modal/Modal2";
import { APP, config, notify } from "../../../utils/constant";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Select from "../../../components/Select";
import Drag from "../../../components/Drag";
import qrIcon from "../../../Assets/QrImage.png";
import { MdArrowDropDown, MdGradient } from "react-icons/md";
import { TbDropletHalfFilled } from "react-icons/tb";
import { Popover, Transition } from "@headlessui/react";

// const GradientColorEyeDiv = ({ gradientColors }) => {
//   const startColor = `rgb(${gradientColors.inner_red}, ${gradientColors.inner_green}, ${gradientColors.inner_blue})`;
//   const endColor = `rgb(${gradientColors.outer_red}, ${gradientColors.outer_green}, ${gradientColors.outer_blue})`;

//   const gradientStyle = {
//     backgroundImage: `linear-gradient(to bottom, ${startColor}, ${endColor})`,
//     width: "30px",
//     height: "20px",
//   };

//   return <div className="rounded-sm" style={gradientStyle}></div>;
// };

function GenerateQrCode({ qrToGenerate, open, handleOpen, qrCodeId }) {
  const { token } = useSelector((state) => state.user);
  const [qrSize, setQrSize] = useState("medium");
  const [qrStyle, setQrStyle] = useState("square");
  const [reference, setReference] = useState("");
  const [qrMargin, setQrmargin] = useState(2);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [environment, setEnvironment] = useState({});
  const [qrImage, setQrImage] = useState("");
  const [generatedImage, setGeneratedImage] = useState("");
  const [colorBackground, setColorBackground] = useColor("rgb(255 255 255)");
  const [colorModal, setColorModal] = useState(false);
  const [color1Modal, setColor1Modal] = useState(false);

  const [startGradientModal, setStartGradientModal] = useState(false);
  const [endGradientModal, setEndGradientModal] = useState(false);
  const [eye1InnerModal, setEye1InnerModal] = useState(false);
  const [Eye1OuterModal, setEye1OuterModal] = useState(false);

  const [eye2InnerModal, setEye2InnerModal] = useState(false);
  const [Eye2OuterModal, setEye2OuterModal] = useState(false);

  const [eye3InnerModal, setEye3InnerModal] = useState(false);
  const [Eye3OuterModal, setEye3OuterModal] = useState(false);
  const [defaultColor, setDefaultColor] = useState("rgb(0 0 0)");
  const [colors, setColors] = useColor("rgb(0 0 0)");
  const [startColors, setStartColors] = useColor("rgb(0 0 0)");
  const [endColors, setEndColors] = useColor("rgb(0 0 0)");
  const [eyeOneInner, setEyeOneInner] = useColor("rgb(0 0 0)");
  const [eyeOneOuter, setEyeOneOuter] = useColor("rgb(0 0 0)");
  const [eyeTwoInner, setEyeTwoInner] = useColor("rgb(0 0 0)");
  const [eyeTwoOuter, setEyeTwoOuter] = useColor("rgb(0 0 0)");
  const [eyeThreeInner, setEyeThreeInner] = useColor("rgb(0 0 0)");
  const [eyeThreeOuter, setEyeThreeOuter] = useColor("rgb(0 0 0)");

  const [selectedQrLocation, setSelectedQrLocation] = useState([]);
  const [selectedQrEnvironment, setSelectedQrEnvironment] = useState([]);
  const [selectedPlacement, setSelectedPlacement] = useState([]);

  const [tempLocation, setTempLocation] = useState(null);
  const [tempEnvironment, setTempEnvironment] = useState(null);
  const [tempPlacement, setTempPlacement] = useState([]);
  const [openAddContent, setOpenAddContent] = useState(false);
  const [colorType, setColorType] = useState("color");
  const [qrNumber, setQrNumber] = useState(1);
  // const rgbaColor1 = `rgba(${colors.red}, ${colors.green}, ${colors.blue}, ${
  //   colors.alpha / 100
  // })`;

  const backgroundColor = `rgba(${colorBackground.rgb.r}, ${colorBackground.rgb.g}, ${colorBackground.rgb.b}, ${colorBackground.rgb.a})`;

  const rgbaColor1 = `rgba(${colors.rgb.r}, ${colors.rgb.g}, ${colors.rgb.b}, ${colors.rgb.a})`;
  const rgbaStartGradientColor = `rgba(${startColors.rgb.r}, ${startColors.rgb.g}, ${startColors.rgb.b}, ${startColors.rgb.a})`;
  const rgbaEndGradientColor = `rgba(${endColors.rgb.r}, ${endColors.rgb.g}, ${endColors.rgb.b}, ${endColors.rgb.a})`;
  const eye1InnerColor = `rgba(${eyeOneInner.rgb.r}, ${eyeOneInner.rgb.g}, ${eyeOneInner.rgb.b}, ${eyeOneInner.rgb.a})`;
  const eye1OuterColor = `rgba(${eyeOneOuter.rgb.r}, ${eyeOneOuter.rgb.g}, ${eyeOneOuter.rgb.b}, ${eyeOneOuter.rgb.a})`;
  const eye2InnerColor = `rgba(${eyeTwoInner.rgb.r}, ${eyeTwoInner.rgb.g}, ${eyeTwoInner.rgb.b}, ${eyeTwoInner.rgb.a})`;
  const eye2OuterColor = `rgba(${eyeTwoOuter.rgb.r}, ${eyeTwoOuter.rgb.g}, ${eyeTwoOuter.rgb.b}, ${eyeTwoOuter.rgb.a})`;
  const eye3InnerColor = `rgba(${eyeThreeInner.rgb.r}, ${eyeThreeInner.rgb.g}, ${eyeThreeInner.rgb.b}, ${eyeThreeInner.rgb.a})`;
  const eye3OuterColor = `rgba(${eyeThreeOuter.rgb.r}, ${eyeThreeOuter.rgb.g}, ${eyeThreeOuter.rgb.b}, ${eyeThreeOuter.rgb.a})`;

  // const handleQrCodeSize = () => {

  // };

  const handleSubmitPreview = () => {
    setLoading(true);
    const colorConfigObject = {
      background_color: {
        red: colorBackground.rgb.r,
        green: colorBackground.rgb.g,
        blue: colorBackground.rgb.b,
        alpha: colorBackground.rgb.a * 100,
      },
      gradient:
        colorType === "gradient"
          ? {
              start_red: startColors.rgb.r,
              start_green: startColors.rgb.g,
              start_blue: startColors.rgb.b,
              end_red: endColors.rgb.r,
              end_green: endColors.rgb.g,
              end_blue: endColors.rgb.b,
              type: "vertical",
            }
          : "",
      first_eye_color: {
        inner_red: eyeOneInner.rgb.r,
        inner_green: eyeOneInner.rgb.g,
        inner_blue: eyeOneInner.rgb.b,
        outer_red: eyeOneOuter.rgb.r,
        outer_green: eyeOneOuter.rgb.g,
        outer_blue: eyeOneOuter.rgb.b,
      },
      second_eye_color: {
        inner_red: eyeTwoInner.rgb.r,
        inner_green: eyeTwoInner.rgb.g,
        inner_blue: eyeTwoInner.rgb.b,
        outer_red: eyeTwoOuter.rgb.r,
        outer_green: eyeTwoOuter.rgb.g,
        outer_blue: eyeTwoOuter.rgb.b,
      },
      third_eye_color: {
        inner_red: eyeThreeInner.rgb.r,
        inner_green: eyeThreeInner.rgb.g,
        inner_blue: eyeThreeInner.rgb.b,
        outer_red: eyeThreeOuter.rgb.r,
        outer_green: eyeThreeOuter.rgb.g,
        outer_blue: eyeTwoOuter.rgb.b,
      },
      color:
        colorType === "color"
          ? {
              red: colors.rgb.r,
              green: colors.rgb.g,
              blue: colors.rgb.b,
              alpha: colors.rgb.a * 100,
            }
          : "",
      style: qrStyle,
      logo: files[0],
      preview: true,
      size: qrSize,
      margin: qrMargin,
    };
    console.log("color objects config", colorConfigObject);
    axios
      .post(
        APP.API_MAIN_URL +
          `/admin/qr-codes/${qrCodeId}/environments/${qrToGenerate?.environmentId}/placements/${qrToGenerate?.placementId}/generate`,
        colorConfigObject,
        config(token)
      )
      .then((res) => {
        console.log("performedTT", res);
        setQrImage(res.data.data);
        setGeneratedImage(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error in previwing qr code", error);
      });
  };
  const handleGenerate = () => {
    const colorConfigObject = {
      background_color: {
        red: colorBackground.rgb.r,
        green: colorBackground.rgb.g,
        blue: colorBackground.rgb.b,
        alpha: colorBackground.rgb.a,
      },
      gradient: {
        start_red: startColors.rgb.r,
        start_green: startColors.rgb.g,
        start_blue: startColors.rgb.b,
        end_red: endColors.rgb.r,
        end_green: endColors.rgb.g,
        end_blue: endColors.rgb.b,
        type: "vertical",
      },
      first_eye_color: {
        inner_red: eyeOneInner.rgb.r,
        inner_green: eyeOneInner.rgb.g,
        inner_blue: eyeOneInner.rgb.b,
        outer_red: eyeOneOuter.rgb.r,
        outer_green: eyeOneOuter.rgb.g,
        outer_blue: eyeOneOuter.rgb.b,
      },
      second_eye_color: {
        inner_red: eyeTwoInner.rgb.r,
        inner_green: eyeTwoInner.rgb.g,
        inner_blue: eyeTwoInner.rgb.b,
        outer_red: eyeTwoOuter.rgb.r,
        outer_green: eyeTwoOuter.rgb.g,
        outer_blue: eyeTwoOuter.rgb.b,
      },
      third_eye_color: {
        inner_red: eyeThreeInner.rgb.r,
        inner_green: eyeThreeInner.rgb.g,
        inner_blue: eyeThreeInner.rgb.b,
        outer_red: eyeThreeOuter.rgb.r,
        outer_green: eyeThreeOuter.rgb.g,
        outer_blue: eyeTwoOuter.rgb.b,
      },
      color: colors,
      style: qrStyle,
      logo: files[0],
      preview: false,
      size: qrSize,
      margin: qrMargin,
      reference: reference,
    };
    console.log("HERE WE ARE GOING TO G", colorConfigObject);
    axios
      .post(
        APP.API_MAIN_URL +
          `/admin/qr-codes/${qrCodeId}/environments/${qrToGenerate?.environmentId}/placements/${qrToGenerate?.placementId}/generate`,
        colorConfigObject,
        config(token)
      )
      .then((res) => {
        setGeneratedImage(res.data.data);
        console.log("yuyu", res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error in generating qr code", error);
        notify("error", error.response.data.message);
      });
  };

  const qrCodeSize = [
    { label: "small", value: "small" },
    { label: "medium", value: "medium" },
    { label: "large", value: "large" },
  ];
  const qrCodeStyle = [
    { label: "Square", value: "square" },
    { label: "Round", value: "round" },
    { label: "Dot", value: "dot" },
  ];
  console.log("qrImage", qrImage);

  useEffect(() => {
    handleSubmitPreview();
  }, [
    colorBackground,
    eyeOneInner,
    eyeOneOuter,
    eyeTwoInner,
    eyeTwoOuter,
    eyeThreeInner,
    eyeThreeOuter,
    startColors,
    endColors,
    colors,
    qrSize,
    qrStyle,
    qrMargin,
    files,
    reference,
    qrToGenerate,
  ]);
  useEffect(() => {
    handleSubmitPreview();
  }, [qrToGenerate]);
  const handleDownload = async () => {
    const response = await axios.get(generatedImage.file_url);
    const blob = await response.blob();

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "generated_image.png"; // Change the filename as needed
    link.click();
  };
  const nber = [1, 2, 3];
  const resetData = () => {
    setQrSize("medium");
    setQrStyle("square");
    setReference("");
    setQrmargin(2);
    setFiles([]);
    setColorBackground(defaultColor);
    setColors(defaultColor);
    setStartColors(defaultColor);
    setEndColors(defaultColor);
    setEyeOneInner(defaultColor);
    setEyeOneOuter(defaultColor);
    setEyeTwoInner(defaultColor);
    setEyeTwoOuter(defaultColor);
    setEyeThreeInner(defaultColor);
    setEyeThreeOuter(defaultColor);
  };
  const handleOpenColorPicker = () => {
    setColorModal(!colorModal);
  };

  const handleOpenColor = () => {
    setColorModal(false);
    setColor1Modal(!color1Modal);
  };
  const handleOpenStartColor = () => {
    setStartGradientModal(!startGradientModal);
  };
  const handleOpenEndColor = () => {
    setEndGradientModal(!endGradientModal);
  };
  const handleOpenInnnerColor = () => {
    setEye1InnerModal(!eye1InnerModal);
  };
  const handleOpenOuterColor = () => {
    setEye1OuterModal(!Eye1OuterModal);
  };
  const handleOpenInner2Color = () => {
    setEye2InnerModal(!eye2InnerModal);
  };
  const handleOpenOuter2Color = () => {
    setEye2OuterModal(!Eye2OuterModal);
  };

  const handleOpenInner3Color = () => {
    setEye3InnerModal(!eye3InnerModal);
  };
  const handleOpenOuter3Color = () => {
    setEye3OuterModal(!Eye3OuterModal);
  };

  const colorPickerRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target)
      ) {
        // Click is outside the color picker, close it
        setColorModal(false);
        setColor1Modal(false);
        setStartGradientModal(false);
        setStartGradientModal(false);
        setEndGradientModal(false);
        setEye1InnerModal(false);
        setEye2OuterModal(false);
        setEye3OuterModal(false);
        setEye1OuterModal(false);
        setEye2InnerModal(false);
        setEye3InnerModal(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <Modal2
        isOpen={open}
        onClose={() => {
          handleOpen(!open);
          // resetData();
        }}
        Style={"w-fit m-4 flex m-auto lg:h-[90%] lg:w-[80%] md:px-5"}
      >
        <Modal2.Header className={"sticky top-0 z-50 bg-secondary"}>
          <div className="font-bold text-[20px]">
            <span>Generate Qr Code</span> for <span>placements</span>{" "}
          </div>
        </Modal2.Header>
        <Modal2.Body>
          <div className="flex justify-between  flex-col-reverse lg:flex-row md:mx-5 ">
            <div className=" lg:w-[60%] border-2 border-[#ddd] md:border-none p-2">
              <h2 className="font-[900] text-[18px] mb-4">
                QR Code Customization
              </h2>
              <div className=" border lg:flex border-b-2 pb-4 px-2 rounded-sm border-[#efeeee] w-[95%]">
                <div className="flex flex-col  w-full  p-2 pt-5 ">
                  <div className="md:w-[75%] relative border rounded-md self-end">
                    <span className="absolute left-5 -top-3 px-1 bg-[#ffffff] ">
                      Background color
                    </span>
                    <input
                      type="text"
                      className="border border-gray-300 text-gray-900 text-sm rounded-md  block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none "
                      placeholder={colorBackground?.hex}
                      // onChange={(e) => setColorBackground(e.target.value)}
                      value={colorBackground?.hex}
                    />

                    {colorModal && (
                      <div
                        className="absolute left-96 z-10"
                        ref={colorPickerRef}
                      >
                        <ColorPicker
                          color={colorBackground}
                          onChange={setColorBackground}
                          hideInput={["hsv"]}
                          hideAlpha={true}
                          height={100}
                        />
                      </div>
                    )}
                    <div
                      className={
                        " w-7 h-7 border rounded-[5px] absolute inset-y-0 end-0 flex items-center pe-3 right-2 top-2"
                      }
                      style={{
                        backgroundColor: backgroundColor,
                      }}
                      onClick={handleOpenColorPicker}
                    ></div>
                  </div>
                  <div className=" flex flex-col md:flex-row relative mt-2 pr-2 w-full justify-between items-center ">
                    <div className="flex  w-40 items-center justify-between ">
                      <div className="border border-[#ddd] flex items-center w-20">
                        <div
                          className={`cursor-pointer w-[50%] p-2 text-[#7b7070] ${
                            colorType === "color" && "bg-[#ddd]"
                          }`}
                          title="Solid Color"
                          onClick={() => setColorType("color")}
                        >
                          <TbDropletHalfFilled size={20} />
                        </div>
                        <div
                          className={`cursor-pointer w-[50%] p-2 text-[#7b7070] ${
                            colorType === "gradient" && "bg-[#ddd]"
                          }`}
                          title="Gradient color"
                          onClick={() => setColorType("gradient")}
                        >
                          <MdGradient size={20} />
                        </div>
                      </div>
                    </div>
                    {colorType === "color" && (
                      <>
                        <div className="w-full md:w-[80%] relative border rounded-md  mt-5">
                          <span className="absolute left-5 -top-3 px-1 bg-white">
                            Color
                          </span>
                          <input
                            type="text"
                            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-md  block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none "
                            placeholder={colorBackground?.hex}
                            // onChange={(e) => setColorBackground(e.target.value)}
                            value={colors?.hex}
                          />
                          <div
                            className={
                              " absolute w-7 h-7 border rounded-[5px] top-2 right-2"
                            }
                            style={{
                              backgroundColor: rgbaColor1,
                            }}
                            onClick={handleOpenColor}
                          ></div>
                        </div>

                        {color1Modal && (
                          <div
                            className="absolute right-36 z-10 "
                            ref={colorPickerRef}
                          >
                            <ColorPicker
                              color={colors}
                              onChange={setColors}
                              hideInput={["hsv"]}
                              hideAlpha={true}
                              height={100}
                            />
                          </div>
                        )}
                        {/* <span
                          className="cursor-pointer font-bold capitalize"
                          onClick={handleOpenColor}
                        >
                          Change
                        </span> */}
                      </>
                    )}
                    {colorType === "gradient" && (
                      <>
                        <div className=" w-full md:w-[80%] flex justify-between mt-5 rounded-[5px] space-x-2">
                          <div className=" relative rounded-md w-[50%]  ">
                            <span className="absolute left-5 -top-3 px-1 bg-[#ffffff]">
                              First color
                            </span>
                            <input
                              type="text"
                              className="bg-[#ffffff] border border-gray-300 text-gray-900 text-sm rounded-md  block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none "
                              placeholder={colorBackground?.hex}
                              // onChange={(e) =>
                              //   setColorBackground(e.target.value)
                              // }
                              value={colorBackground?.hex}
                            />

                            <div
                              className={
                                "w-7 h-7 border border-gray-100 rounded-[5px] absolute top-2 right-2 "
                              }
                              style={{
                                backgroundColor: rgbaStartGradientColor,
                              }}
                              onClick={handleOpenStartColor}
                            ></div>
                            {/* <span
                              className="cursor-pointer font-bold capitalize"
                              onClick={handleOpenStartColor}
                            >
                              Change
                            </span> */}
                          </div>
                          <div className=" relative rounded-md w-[50%] ">
                            <span className="absolute left-5 -top-3 px-1 bg-[#ffffff]">
                              Second color
                            </span>
                            <input
                              type="text"
                              className="bg-[#ffffff] border border-gray-300 text-gray-900 text-sm rounded-md  block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none "
                              placeholder={colorBackground?.hex}
                              // onChange={(e) =>
                              //   setColorBackground(e.target.value)
                              // }
                              value={colorBackground?.hex}
                            />

                            <div
                              className={
                                "absolute w-7 h-7 border  border-gray-100 rounded-[5px] top-2 right-2"
                              }
                              style={{
                                backgroundColor: rgbaEndGradientColor,
                              }}
                              onClick={handleOpenEndColor}
                            ></div>
                          </div>

                          {endGradientModal && (
                            <div
                              className="absolute right-36 z-10"
                              ref={colorPickerRef}
                            >
                              <span className="text-[#ddd]">
                                gradient end color
                              </span>
                              <ColorPicker
                                color={endColors}
                                onChange={setEndColors}
                                hideInput={["hex", "hsv"]}
                                hideAlpha={true}
                                height={100}
                              />
                            </div>
                          )}
                          {startGradientModal && (
                            <div
                              className="absolute left-36 z-20 top-1"
                              ref={colorPickerRef}
                            >
                              <span className="text-[#ddd]">
                                gradient start color
                              </span>
                              <ColorPicker
                                color={startColors}
                                onChange={setStartColors}
                                hideInput={["hex", "hsv"]}
                                hideAlpha={true}
                                height={100}
                              />
                            </div>
                          )}
                        </div>
                        {/* <span
                          className="cursor-pointer font-bold capitalize"
                          onClick={handleOpenEndColor}
                        >
                          Change
                        </span> */}
                      </>
                    )}
                  </div>

                  <div className=" flex flex-col md:flex-row relative mt-5 pr-2 w-full justify-between  items-center">
                    <div className="flex  w-40 items-center justify-between ">
                      <div className="flex">
                        <img
                          src={qrIcon}
                          alt=""
                          srcset=""
                          className="h-10 w-10"
                        />
                        <Popover className="relative ">
                          <Popover.Button>
                            <div className="relative w-8 h-10 border flex items-center">
                              <span className="ml-1">{qrNumber}</span>
                              <MdArrowDropDown size={20} />
                            </div>
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute z-10 right-0 ">
                              <div
                                className="flex bg-white shadow-md border border-black/10  rounded-sm text-xs "
                                onClick={() => {}}
                              >
                                {nber.map((item, i) => (
                                  <span
                                    className="px-4 py-2 w-full hover:bg-[#D9D9D9] text-center cursor-pointer font-bold"
                                    key={i}
                                    onClick={() => setQrNumber(item)}
                                  >
                                    {item}
                                  </span>
                                ))}
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </Popover>
                      </div>
                    </div>

                    {qrNumber === 1 && (
                      <div className="w-full md:w-[80%] flex flex-col md:flex-row justify-between h-6 rounded-[5px] space-x-2  ">
                        <div className=" w-full relative rounded-md md:w-[50%] ">
                          <span className="absolute left-5 -top-3 px-1 bg-[#ffffff]">
                            Inner color
                          </span>
                          <input
                            type="text"
                            className="bg-[#ffffff] border border-gray-300 text-gray-900 text-sm rounded-md  block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none "
                            placeholder={colorBackground?.hex}
                            // onChange={(e) =>
                            //   setColorBackground(e.target.value)
                            // }
                            value={eyeOneInner?.hex}
                          />
                          <div
                            className={
                              "absolute top-2 right-2  w-7 h-7 border border-gray-100 rounded-[5px]"
                            }
                            style={{
                              backgroundColor: eye1InnerColor,
                            }}
                            onClick={handleOpenInnnerColor}
                          ></div>
                        </div>

                        <div className="w-full relative rounded-md md:w-[50%] ">
                          <span className="absolute left-5 -top-3 px-1 bg-[#ffffff]">
                            Outer color
                          </span>
                          <input
                            type="text"
                            className="bg-[#ffffff] border border-gray-300 text-gray-900 text-sm rounded-md  block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none "
                            placeholder={colorBackground?.hex}
                            // onChange={(e) =>
                            //   setColorBackground(e.target.value)
                            // }
                            value={eyeOneOuter?.hex}
                          />

                          <div
                            className={
                              "absolute w-7 h-7 border border-gray-100 rounded-[5px] top-2 right-2"
                            }
                            style={{
                              backgroundColor: eye1OuterColor,
                            }}
                            onClick={handleOpenOuterColor}
                          ></div>
                        </div>

                        {eye1InnerModal && (
                          <div
                            className="absolute left-36 z-20 top-1"
                            ref={colorPickerRef}
                          >
                            <span className="text-[#272727] bg-[#ddd]/30 rounded-sm px-2">
                              eye 1 inner color
                            </span>
                            <ColorPicker
                              color={eyeOneInner}
                              onChange={setEyeOneInner}
                              hideInput={["hex", "hsv"]}
                              hideAlpha={true}
                              height={100}
                            />
                          </div>
                        )}
                        {Eye1OuterModal && (
                          <div
                            className="absolute right-36 z-10"
                            ref={colorPickerRef}
                          >
                            <span className="text-[#272727] bg-[#ddd]/30 rounded-sm px-2">
                              Eye 1 outer color
                            </span>
                            <ColorPicker
                              color={eyeOneOuter}
                              onChange={setEyeOneOuter}
                              hideInput={["hex", "hsv"]}
                              hideAlpha={true}
                              height={100}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {qrNumber === 2 && (
                      <div className="w-[80%] flex justify-between h-6 rounded-[5px] space-x-2">
                        <div className=" relative rounded-md w-[50%]  ">
                          <span className="absolute left-5 -top-3 px-1 bg-[#ffffff]">
                            Inner color
                          </span>
                          <input
                            type="text"
                            className="bg-[#ffffff] border border-gray-300 text-gray-900 text-sm rounded-md  block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none "
                            placeholder={colorBackground?.hex}
                            value={eyeTwoInner?.hex}
                          />
                          <div
                            className={
                              " absolute w-7 h-7 border border-gray-100 rounded-[5px] top-2 right-2"
                            }
                            style={{
                              backgroundColor: eye2InnerColor,
                            }}
                            onClick={handleOpenInner2Color}
                          ></div>
                        </div>

                        <div className=" relative rounded-md w-[50%]">
                          <span className="absolute left-5 -top-3 px-1 bg-[#ffffff]">
                            Outer color
                          </span>
                          <input
                            type="text"
                            className="bg-[#ffffff] border border-gray-300 text-gray-900 text-sm rounded-md  block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none "
                            placeholder={colorBackground?.hex}
                            value={eyeTwoInner?.hex}
                          />
                          <div
                            className={
                              " absolute w-7 h-7 border border-gray-100 rounded-[5px] top-2 right-2"
                            }
                            style={{
                              backgroundColor: eye2OuterColor,
                            }}
                            onClick={handleOpenOuter2Color}
                          ></div>
                        </div>

                        {eye2InnerModal && (
                          <div
                            className="absolute left-36 z-20 top-1"
                            ref={colorPickerRef}
                          >
                            <span className="text-[#272727] bg-[#ddd]/30 rounded-sm px-2">
                              eye 2 inner color
                            </span>
                            <ColorPicker
                              color={eyeTwoInner}
                              onChange={setEyeTwoInner}
                              hideInput={["hex", "hsv"]}
                              hideAlpha={true}
                              height={100}
                            />
                          </div>
                        )}
                        {Eye2OuterModal && (
                          <div
                            className="absolute right-36 z-10 "
                            ref={colorPickerRef}
                          >
                            <span className="text-[#272727] bg-[#ddd]/30 rounded-sm px-2">
                              eye 2 outer color
                            </span>
                            <ColorPicker
                              color={eyeTwoOuter}
                              onChange={setEyeTwoOuter}
                              hideInput={["hex", "hsv"]}
                              hideAlpha={true}
                              height={100}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {qrNumber === 3 && (
                      <div className="w-[80%] flex justify-between h-6 rounded-[5px] space-x-2">
                        <div className=" relative rounded-md w-[50%]  ">
                          <span className="absolute left-5 -top-3 px-1 bg-[#ffffff]">
                            Inner color
                          </span>
                          <input
                            type="text"
                            className="bg-[#ffffff] border border-gray-300 text-gray-900 text-sm rounded-md  block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none "
                            placeholder={colorBackground?.hex}
                            value={eyeThreeInner?.hex}
                          />
                          <div
                            className={
                              " absolute w-7 h-7 border border-gray-100 rounded-[5px] top-2 right-2"
                            }
                            style={{
                              backgroundColor: eye3InnerColor,
                            }}
                            onClick={handleOpenInner3Color}
                          ></div>
                        </div>
                        <div className=" relative rounded-md w-[50%]">
                          <span className="absolute left-5 -top-3 px-1 bg-[#ffffff]">
                            Outer color
                          </span>
                          <input
                            type="text"
                            className="bg-[#ffffff] border border-gray-300 text-gray-900 text-sm rounded-md  block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none "
                            placeholder={colorBackground?.hex}
                            value={eyeTwoInner?.hex}
                          />
                          <div
                            className={
                              " absolute w-7 h-7 border border-gray-100 rounded-[5px] top-2 right-2"
                            }
                            style={{
                              backgroundColor: eye3OuterColor,
                            }}
                            onClick={handleOpenOuter3Color}
                          ></div>
                        </div>

                        {eye3InnerModal && (
                          <div
                            className="absolute left-36 z-20 top-1"
                            ref={colorPickerRef}
                          >
                            <span className="text-[#272727] bg-[#ddd]/30 rounded-sm px-2">
                              eye 3 inner color
                            </span>
                            <ColorPicker
                              color={eyeThreeInner}
                              onChange={setEyeThreeInner}
                              hideInput={["hex", "hsv"]}
                              hideAlpha={true}
                              height={100}
                            />
                          </div>
                        )}
                        {Eye3OuterModal && (
                          <div
                            className="absolute right-36 z-10"
                            ref={colorPickerRef}
                          >
                            <span className="text-[#272727] bg-[#ddd]/30 rounded-sm px-2">
                              eye 3 outer color
                            </span>
                            <ColorPicker
                              color={eyeThreeOuter}
                              onChange={setEyeThreeOuter}
                              hideInput={["hex", "hsv"]}
                              hideAlpha={true}
                              height={100}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {/* <div className=" flex relative mt-5 pr-2 w-full justify-between  items-center">
                    <div className="flex  w-40 items-center justify-between ">
                      <img
                        src={qrIcon}
                        alt=""
                        srcset=""
                        className="h-10 w-10"
                      />
                    </div>
                    <div className="w-[80%] flex justify-between h-6 rounded-[5px] space-x-2">
                      <div className=" relative rounded-md w-[50%] ">
                        <span className="absolute left-5 -top-3 px-1 bg-[#ffffff]">
                          Inner color
                        </span>
                        <input
                          type="text"
                          className="bg-[#ffffff] border border-gray-300 text-gray-900 text-sm rounded-md  block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none "
                          placeholder={colorBackground?.hex}
                          // onChange={(e) =>
                          //   setColorBackground(e.target.value)
                          // }
                          value={eyeOneInner?.hex}
                        />
                        <div
                          className={
                            "absolute top-2 right-2  w-7 h-7 border border-gray-100 rounded-[5px]"
                          }
                          style={{
                            backgroundColor: eye1InnerColor,
                          }}
                          onClick={handleOpenInnnerColor}
                        ></div>
                      </div>

                      <div className=" relative rounded-md w-[50%] ">
                        <span className="absolute left-5 -top-3 px-1 bg-[#ffffff]">
                          Outer color
                        </span>
                        <input
                          type="text"
                          className="bg-[#ffffff] border border-gray-300 text-gray-900 text-sm rounded-md  block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none "
                          placeholder={colorBackground?.hex}
                          // onChange={(e) =>
                          //   setColorBackground(e.target.value)
                          // }
                          value={eyeOneOuter?.hex}
                        />

                        <div
                          className={
                            "absolute w-7 h-7 border border-gray-100 rounded-[5px] top-2 right-2"
                          }
                          style={{
                            backgroundColor: eye1OuterColor,
                          }}
                          onClick={handleOpenOuterColor}
                        ></div>
                      </div>

                      {eye1InnerModal && (
                        <div
                          className="absolute left-36 z-20 top-1"
                          ref={colorPickerRef}
                        >
                          <span className="text-[#272727] bg-[#ddd]/30 rounded-sm px-2">
                            eye 1 inner color
                          </span>
                          <ColorPicker
                            color={eyeOneInner}
                            onChange={setEyeOneInner}
                            hideInput={["hex", "hsv"]}
                            hideAlpha={true}
                            height={100}
                          />
                        </div>
                      )}
                      {Eye1OuterModal && (
                        <div
                          className="absolute right-36 z-10"
                          ref={colorPickerRef}
                        >
                          <span className="text-[#272727] bg-[#ddd]/30 rounded-sm px-2">
                            Eye 1 outer color
                          </span>
                          <ColorPicker
                            color={eyeOneOuter}
                            onChange={setEyeOneOuter}
                            hideInput={["hex", "hsv"]}
                            hideAlpha={true}
                            height={100}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex relative mt-10 pr-2 w-full justify-between">
                    <div className="flex  w-40 items-center justify-between ">
                      <img
                        src={qrIcon}
                        alt=""
                        srcset=""
                        className="h-10 w-10"
                      />
                    </div>
                    <div className="w-[80%] flex justify-between h-6 rounded-[5px] space-x-2">
                      <div className=" relative rounded-md w-[50%]  ">
                        <span className="absolute left-5 -top-3 px-1 bg-[#ffffff]">
                          Inner color
                        </span>
                        <input
                          type="text"
                          className="bg-[#ffffff] border border-gray-300 text-gray-900 text-sm rounded-md  block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none "
                          placeholder={colorBackground?.hex}
                          // onChange={(e) =>
                          //   setColorBackground(e.target.value)
                          // }
                          value={eyeTwoInner?.hex}
                        />
                        <div
                          className={
                            " absolute w-7 h-7 border border-gray-100 rounded-[5px] top-2 right-2"
                          }
                          style={{
                            backgroundColor: eye2InnerColor,
                          }}
                          onClick={handleOpenInner2Color}
                        ></div>
                      </div>

                      <div className=" relative rounded-md w-[50%]">
                        <span className="absolute left-5 -top-3 px-1 bg-[#ffffff]">
                          Outer color
                        </span>
                        <input
                          type="text"
                          className="bg-[#ffffff] border border-gray-300 text-gray-900 text-sm rounded-md  block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none "
                          placeholder={colorBackground?.hex}
                          // onChange={(e) =>
                          //   setColorBackground(e.target.value)
                          // }
                          value={eyeTwoInner?.hex}
                        />
                        <div
                          className={
                            " absolute w-7 h-7 border border-gray-100 rounded-[5px] top-2 right-2"
                          }
                          style={{
                            backgroundColor: eye2OuterColor,
                          }}
                          onClick={handleOpenOuter2Color}
                        ></div>
                      </div>

                      {eye2InnerModal && (
                        <div
                          className="absolute left-36 z-20 top-1"
                          ref={colorPickerRef}
                        >
                          <span className="text-[#272727] bg-[#ddd]/30 rounded-sm px-2">
                            eye 2 inner color
                          </span>
                          <ColorPicker
                            color={eyeTwoInner}
                            onChange={setEyeTwoInner}
                            hideInput={["hex", "hsv"]}
                            hideAlpha={true}
                            height={100}
                          />
                        </div>
                      )}
                      {Eye2OuterModal && (
                        <div
                          className="absolute right-36 z-10 "
                          ref={colorPickerRef}
                        >
                          <span className="text-[#272727] bg-[#ddd]/30 rounded-sm px-2">
                            eye 2 outer color
                          </span>
                          <ColorPicker
                            color={eyeTwoOuter}
                            onChange={setEyeTwoOuter}
                            hideInput={["hex", "hsv"]}
                            hideAlpha={true}
                            height={100}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex relative mt-10 pr-2 w-full justify-between">
                    <div className="flex  w-40 items-center justify-between ">
                      <img
                        src={qrIcon}
                        alt=""
                        srcset=""
                        className="h-10 w-10"
                      />
                    </div>
                    <div className="w-[80%] flex justify-between h-6 rounded-[5px] space-x-2">
                      <div className=" relative rounded-md w-[50%]  ">
                        <span className="absolute left-5 -top-3 px-1 bg-[#ffffff]">
                          Inner color
                        </span>
                        <input
                          type="text"
                          className="bg-[#ffffff] border border-gray-300 text-gray-900 text-sm rounded-md  block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none "
                          placeholder={colorBackground?.hex}
                          // onChange={(e) =>
                          //   setColorBackground(e.target.value)
                          // }
                          value={eyeThreeInner?.hex}
                        />
                        <div
                          className={
                            " absolute w-7 h-7 border border-gray-100 rounded-[5px] top-2 right-2"
                          }
                          style={{
                            backgroundColor: eye3InnerColor,
                          }}
                          onClick={handleOpenInner3Color}
                        ></div>
                      </div>
                      <div className=" relative rounded-md w-[50%]">
                        <span className="absolute left-5 -top-3 px-1 bg-[#ffffff]">
                          Outer color
                        </span>
                        <input
                          type="text"
                          className="bg-[#ffffff] border border-gray-300 text-gray-900 text-sm rounded-md  block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none "
                          placeholder={colorBackground?.hex}
                          // onChange={(e) =>
                          //   setColorBackground(e.target.value)
                          // }
                          value={eyeTwoInner?.hex}
                        />
                        <div
                          className={
                            " absolute w-7 h-7 border border-gray-100 rounded-[5px] top-2 right-2"
                          }
                          style={{
                            backgroundColor: eye3OuterColor,
                          }}
                          onClick={handleOpenOuter3Color}
                        ></div>
                      </div>

                      {eye3InnerModal && (
                        <div
                          className="absolute left-36 z-20 top-1"
                          ref={colorPickerRef}
                        >
                          <span className="text-[#272727] bg-[#ddd]/30 rounded-sm px-2">
                            eye 3 inner color
                          </span>
                          <ColorPicker
                            color={eyeThreeInner}
                            onChange={setEyeThreeInner}
                            hideInput={["hex", "hsv"]}
                            hideAlpha={true}
                            height={100}
                          />
                        </div>
                      )}
                      {Eye3OuterModal && (
                        <div
                          className="absolute right-36 z-10"
                          ref={colorPickerRef}
                        >
                          <span className="text-[#272727] bg-[#ddd]/30 rounded-sm px-2">
                            eye 3 outer color
                          </span>
                          <ColorPicker
                            color={eyeThreeOuter}
                            onChange={setEyeThreeOuter}
                            hideInput={["hex", "hsv"]}
                            hideAlpha={true}
                            height={100}
                          />
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="w-[95%]">
                {/* <div className="flex w-full mt-3"> */}
                <Select
                  label="Size"
                  customClass="w-full mb-2 "
                  // placeholder={"Select Qr Code size"}
                  options={qrCodeSize}
                  value={{ value: qrSize, label: qrSize }}
                  onChange={(e) => {
                    setQrSize(e.value);
                  }}
                />
                <Select
                  label="Style"
                  customClass="w-full mb-2"
                  placeholder={"Select Style for Qr code"}
                  options={qrCodeStyle}
                  value={{ value: qrStyle, label: qrStyle }}
                  onChange={(e) => {
                    setQrStyle(e.value);
                  }}
                />
                {/* </div> */}
                {/* <Input
                  labelText="Reference"
                  name="reference"
                  placeholder="Enter reference EX: 777777"
                  isRequired={true}
                  customClass="py-2"
                  inputStyle={"py-2"}
                  onChange={(e) => {
                    setReference(e.target.value);
                  }}
                /> */}
                <div className="flex flex-col mt-8">
                  <h2>Upload logo</h2>
                  <Drag
                    style="w-full"
                    flexStyle="flex flex-col space-y-8"
                    uploadLimit={1}
                    files={files}
                    setFiles={setFiles}
                  />
                </div>
              </div>
              {/* <div className="flex justify-between w-[80%] my-5 pr-3 pl-2">
                <Button
                  content="Preview"
                  btnColor="primary"
                  type="button"
                  onClick={handleSubmitPreview}
                  Style={
                    "text-white h-[40px] lg:w-[20%] mt-2 ml-0 outline-none focus:ring-inset shadow-lg transform active:scale-x-95 transition-transform"
                  }
                />
              </div> */}
            </div>

            <div className="flex flex-col lg:w-[40%] h-[40vh]  md:h-max pb-40 border-l-2 border-[#e2e2e2] shadow-[0_8px_30px_rgb(0,0,0,0.12)] rounded-md">
              <div className="flex justify-center items-center border-b-2 border-[#e2e2e2] sticky top-16 bg-secondary">
                <span className="font-bold text-2xl py-2"> Preview</span>
              </div>
              <div className="flex flex-col items-center justify-center">
                <div className=" ">
                  {qrImage ? (
                    <div className="mt-5 pb-12 flex flex-col justify-center items-center bg-transparent text-primary rounded-sm shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px]">
                      <span
                        className={` h-full text-6xl  font-[900]  opacity-100 capitalize  w-full flex items-center justify-center  align-middle ${
                          loading ? "animate-pulse opacity-0 cursor-wait" : ""
                        }`}
                      >
                        <img
                          src={qrImage?.file}
                          alt=""
                          className="object-cover"
                        />
                      </span>

                      {qrImage && (
                        <div className=" px-3">
                          <h3 className="text-4xl uppercase text-center font-[900] text-[#424242] mt-0">
                            {/* Scan me */}
                          </h3>
                        </div>
                      )}
                    </div>
                  ) : (
                    <h1 className="font-bold text-[#ddd] text-2xl mt-10 capitalize">
                      preview
                    </h1>
                  )}

                  {/* {qrImage && (
                    <Button
                      type="button"
                      content="Generate"
                      btnColor="primary"
                      onClick={handleGenerate}
                      Style={
                        "text-white h-[40px] w-full mt-5 ml-0 outline-none focus:ring-inset shadow-lg transform active:scale-x-95 transition-transform"
                      }
                    />
                  )} */}
                  {generatedImage && (
                    <div>
                      <a
                        href={`${APP?.API_MAIN_URL}/admin/files/${qrImage?.media[0]?.uuid}/download`}
                      >
                        <Button
                          type="button"
                          content="Download"
                          btnColor="primary"
                          Style={
                            "text-white h-[40px] w-full mt-2 ml-0 outline-none focus:ring-inset shadow-lg transform active:scale-x-95 transition-transform"
                          }
                        />
                      </a>

                      {/* <Button
                        type="button"
                        content="Add Content"
                        btnColor="primary"
                        onClick={() => handleAddContent()}
                        Style={
                          "text-white h-[40px] w-full mt-2 ml-0 outline-none focus:ring-inset shadow-lg transform active:scale-x-95 transition-transform"
                        }
                      /> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal2.Body>
        <div className="flex justify-end items-center space-x-4 p-2"></div>
      </Modal2>
    </>
  );
}

export default GenerateQrCode;
