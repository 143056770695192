import React, { useEffect, useState } from "react";
import Modal from "../../../../components/modal/Modal";
import { RxDotFilled } from "react-icons/rx";
import axios from "axios";
import { APP } from "../../../../utils/constant";
import { config } from "@react-spring/web";

function ViewCategory({
  handleViewcategoryModal,
  openViewCat,
  categoryIdToView,
  selectedLanguage,
  allLanguage,
}) {
  const [fetchedCategory, setFetchedCategory] = useState({});
  const getSingleCategory = async () => {
    await axios
      .get(
        `${APP.API_MAIN_URL}/admin/vas/article-categories/${categoryIdToView}`,
        config
      )

      .then((res) => {
        setFetchedCategory(res.data.data);
      })
      .catch((error) => error);
  };

  useEffect(() => {
    getSingleCategory();
  }, [categoryIdToView]);

  return (
    <div>
      <Modal
        isOpen={openViewCat}
        onClose={() => {
          handleViewcategoryModal();
        }}
        Style={"w-[90%] lg:w-[90%] h-full flex m-auto"}
      >
        <Modal.Body>
          {fetchedCategory.name !== undefined ? (
            <>
              <div className="flex justify-between items-center w-full p-2 text-[18px] font-[900] border-b-8  border-[#636363] ">
                <h1 className="">
                  {fetchedCategory.name[selectedLanguage]}
                  <span className="text-sm font-extralight text-primary opacity-50 ml-3">
                    | on {fetchedCategory?.created_at.split("T")[0]}
                  </span>
                </h1>
                <span className="flex items-center flex-row-reverse capitalize">
                  {fetchedCategory.status}
                  <RxDotFilled size={38} className="text-green-500" />
                </span>
              </div>
              <div className="mt-4 relative h-48 text-[18px]">
                <div className="flex justify-between items-center mb-5">
                  <h1 className="font-medium">Parent Category</h1>
                  <span className="ml-10 pr-10">Null</span>
                </div>
                <div className="flex items-center justify-between">
                  <h1 className="font-medium">Child Category</h1>
                  <span className="ml-10 pr-10">
                    {fetchedCategory.children_count}
                  </span>
                </div>

                <div
                  className="absolute top-full cursor-pointer right-0 hover:bg-primary hover:text-white border border-[#444] py-2 px-6 rounded-md text-[16px] font-bold"
                  onClick={() => handleViewcategoryModal()}
                >
                  Close
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ViewCategory;
