import { useDispatch } from "react-redux";
import { resetUser } from "../redux/actions/user";
import { APP } from "./constant";

export const useLogout = () => {
  const dispatch = useDispatch();
  return (payload) => {
    //dispatch actions
    dispatch(resetUser());
    window.location = APP.ACCOUNT_URL;
  };
};
