import React, { useState, useEffect } from 'react';
import CreditWallet from './CreditWallet/CreditWallet';
import PaymentWallet from './PaymentWallet/PaymentWallet';
import { Tab } from '@headlessui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchCreditWallets,
  fetchPaymentWallets,
} from '../../../redux/actions/wallet';

const Wallet = () => {
  const dispatch = useDispatch();

  const [selectedContent, setSelectedContent] = useState('credit');

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    dispatch(fetchCreditWallets());
    dispatch(fetchPaymentWallets());
  }, []);

  return (
    <div className="flex flex-col w-full h-full ">
      <Tab.Group>
        <Tab.List className="w-full overflow-x-auto mb-4 flex flex-row gap-2">
          <Tab
            className={({ selected }) =>
              classNames(
                'flex w-fit items-center justify-between rounded-lg p-2 text-[16px] text-primary px-4',
                'ring-white ring-opacity-60 outline-none',
                selected ? 'bg-zinc-200 text-primary' : 'hover:bg-zinc-200'
              )
            }
            onClick={() => {
              setSelectedContent('credit');
            }}
          >
            <div className="flex gap-4 cursor-pointer items-center">
              {/* <MdProductionQuantityLimits size={20} className="" /> */}
              <h1 className="font-bold">Credit Wallets</h1>
            </div>
          </Tab>

          <Tab
            className={({ selected }) =>
              classNames(
                'flex w-fit items-center justify-between rounded-lg p-2 text-[16px] text-primary px-4',
                'ring-white ring-opacity-60 outline-none',
                selected ? 'bg-zinc-200 text-primary' : 'hover:bg-zinc-200'
              )
            }
            onClick={() => {
              setSelectedContent('payment');
            }}
          >
            <div className="flex gap-4 cursor-pointer items-center">
              {/* <MdProductionQuantityLimits size={20} className="" /> */}
              <h1 className="font-bold">Payment Wallets</h1>
            </div>
          </Tab>
        </Tab.List>

        <div className="w-full px-4">
          <Tab.Panels>
            <Tab.Panel className={selectedContent === 'credit' ? '' : 'hidden'}>
              <CreditWallet />
            </Tab.Panel>

            <Tab.Panel
              className={selectedContent === 'payment' ? '' : 'hidden'}
            >
              <PaymentWallet />
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  );
};

export default Wallet;
