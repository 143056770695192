import React, { useState } from "react";
import Button from "../../../components/Button";
import axios from "axios";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal2 from "../../../components/modal/Modal2";
import Select from "../../../components/Select";
import { APP, config, notify } from "../../../utils/constant";
import { useSelector } from "react-redux";

const AddLocation = ({ open, handleAddLocation, getSingleQr, qrCodeId }) => {
  const { token } = useSelector((state) => state.user);
  const [provinces, setProvinces] = useState([]);
  const [provinceId, setProvinceId] = useState();
  const [districts, setDistricts] = useState([]);
  const [districtId, setDistrictId] = useState();
  const [sectors, setSectors] = useState([]);
  const [sectorId, setSectorId] = useState();
  const [cells, setCells] = useState([]);
  const [cellId, setCellId] = useState();
  const [villages, setVillages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const onSubmit = () => {
    setLoading(true);
    console.log("GOGOGOGOGOGAL", data);
    axios
      .post(
        APP.API_MAIN_URL + `/admin/qr-codes/${qrCodeId}/locations`,
        data,
        config(token)
      )
      .then((res) => {
        setLoading(false);
        handleAddLocation(!open);
        getSingleQr();
        reset();
        notify("success", res.data.message);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        notify("error", "Failed to updated!");
      });
  };

  const getProvinces = () => {
    try {
      axios
        .get(
          "https://api.account.konvey.rw/api/utils/location/provinces",
          config
        )
        .then((res) => {
          console.log(res?.data?.data, "provinces");
          setProvinces(res?.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // api to fetch districts

  const getDistricts = () => {
    try {
      axios
        .get(
          `https://api.account.konvey.rw/api/utils/location/province/${provinceId}/districts`,
          config
        )
        .then((res) => {
          setDistricts(res?.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // api to fetch sectors

  const getSectors = () => {
    try {
      axios
        .get(
          `https://api.account.konvey.rw/api/utils/location/district/${districtId}/sectors`,
          config
        )
        .then((res) => {
          setSectors(res?.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // api to fetch cells

  const getCells = () => {
    try {
      axios
        .get(
          `https://api.account.konvey.rw/api/utils/location/sector/${sectorId}/cells`,
          config
        )
        .then((res) => {
          setCells(res?.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // api to fetch villages

  const getVillages = () => {
    try {
      axios
        .get(
          `https://api.account.konvey.rw/api/utils/location/cell/${cellId}/villages`,
          config
        )
        .then((res) => {
          setVillages(res?.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProvinces();
    getDistricts();
    getSectors();
    getCells();
    getVillages();
  }, [provinceId, districtId, sectorId, cellId]);

  // useForm methods
  const {
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
    control,
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal2
        isOpen={open}
        onClose={() => {
          handleAddLocation(!open);
          reset();
        }}
        Style={"w-full md:w-[40%] m-4 flex m-auto"}
      >
        <Modal2.Header>
          <div className="font-bold">Add location</div>
        </Modal2.Header>
        <Modal2.Body>
          <div className="flex flex-col px-2">
            <Select
              label="Province"
              customClass="w-full mb-2"
              isRequired={true}
              placeholder={"Select province"}
              defaultValue={""}
              options={provinces.map((province) => {
                return { value: province.id, label: province.name };
              })}
              register={{
                control,
                name: `province_id`,
              }}
              handleChange={(e) => {
                clearErrors("province_id");
                setProvinceId(e.value);
                setData((prevData) => ({
                  ...prevData,
                  province_id: e.value,
                  province: { id: e.value, name: e.label },
                }));
              }}
              error={errors[`province_id`]?.message}
            />
            <Select
              label="District"
              customClass="w-full"
              isLoading={false}
              placeholder={"Select district"}
              defaultValue={""}
              options={districts.map((district) => {
                return { value: district.id, label: district.name };
              })}
              register={{
                control,
                name: `district_id`,
              }}
              handleChange={(e) => {
                clearErrors("district_id");
                setDistrictId(e.value);
                setData((prevData) => ({
                  ...prevData,
                  district_id: e.value,
                  district: {
                    id: e.value,
                    province_id: provinceId,
                    name: e.label,
                  },
                }));
              }}
            />
            <Select
              label="Sector"
              customClass="w-full mb-2"
              isLoading={false}
              placeholder={"Select sector"}
              defaultValue={""}
              options={sectors.map((sector) => {
                return { value: sector.id, label: sector.name };
              })}
              register={{
                control,
                name: `sector_id`,
              }}
              handleChange={(e) => {
                clearErrors("sector");
                setSectorId(e.value);
                setData((prevData) => ({
                  ...prevData,
                  sector_id: e.value,
                  sector: {
                    id: e.value,
                    province_id: provinceId,
                    district_id: districtId,
                    name: e.label,
                  },
                }));
              }}
            />
            <Select
              label="Cell"
              customClass="w-full mb-2"
              isLoading={false}
              placeholder={"Select cell"}
              defaultValue={""}
              options={cells.map((cell) => {
                return { value: cell.id, label: cell.name };
              })}
              register={{
                control,
                name: `cell_id`,
              }}
              handleChange={(e) => {
                clearErrors("cell");
                setCellId(e.value);
                setData((prevData) => ({
                  ...prevData,
                  cell_id: e.value,
                  cell: {
                    id: e.value,
                    province_id: provinceId,
                    district_id: districtId,
                    sector_id: sectorId,
                    name: e.label,
                  },
                }));
              }}
            />
            <Select
              label="Village"
              customClass="w-full mb-2 "
              isLoading={false}
              placeholder={"Select village"}
              options={villages.map((village) => {
                return { value: village.id, label: village.name };
              })}
              register={{
                control,
                name: `village_id`,
              }}
              defaultValue={""}
              handleChange={(e) => {
                clearErrors("village");
                setData((prevData) => ({
                  ...prevData,
                  village_id: e.value,
                  village: {
                    id: e.value,
                    province_id: provinceId,
                    district_id: districtId,
                    cell_id: cellId,
                    name: e.label,
                  },
                }));
              }}
            />
          </div>
        </Modal2.Body>
        <div className="flex justify-end items-center space-x-6 mx-5 mb-5 ">
          {/* <Button
            type="submit"
            content={"Cancel"}
            loading={false}
            // btnColor="secondary"
            onClick={() => {
              handleAddLocation(!open);
            }}
            Style={"text-primary bg-white border h-[40px] mt-2 ml-0"}
          /> */}

          <Button
            type="submit"
            content="Save"
            loading={loading}
            btnColor="primary"
            Style={"text-white h-[40px] mt-2 ml-0 border w-full"}
          />
        </div>
      </Modal2>
    </form>
  );
};

export default AddLocation;
