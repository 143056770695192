export const SET_ALL_BLASTS = "SET_ALL_BLASTS";
export const RESET_BLAST = "RESET_BLAST";

export const setAllBlasts = (blasts) => (dispatch) => {
  dispatch({
    type: SET_ALL_BLASTS,
    payload: blasts,
  });
};

export const resetBlasts = () => ({ type: RESET_BLAST });
