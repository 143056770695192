export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const RESET_USER = "RESET_USER";
export const PROFILE_TYPE = "PROFILE_TYPE";
export const PROFILE_ID = "PROFILE_ID";

export const SET_SELECTED_TAB = "SET_SELECTED_TAB";

export const setUserDetails = (datails) => (dispatch) => {
  dispatch({
    type: SET_USER_DETAILS,
    payload: datails,
  });
};
export const setUserProfileType = (type) => (dispatch) => {
  dispatch({
    type: PROFILE_TYPE,
    payload: type,
  });
};
export const setUserProfileId = (id) => (dispatch) => {
  dispatch({
    type: PROFILE_ID,
    payload: id,
  });
};
export const setUserToken = (token) => (dispatch) => {
  dispatch({
    type: SET_USER_TOKEN,
    payload: token,
  });
};
export const setSelectedTab = (tab) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_TAB,
    payload: tab,
  });
};

export const resetUser = () => ({ type: RESET_USER });
