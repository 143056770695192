import { combineReducers } from "redux";
import user from "./user";
import blast from "./blasts";
import subscription from "./subscription";
import { bank } from "./bank";
import { wallet } from "./wallet";
import { mobileMoney } from "./mobileMoney";
import articleCategory from "./articles";

const rootReducer = combineReducers({
  user,
  blast,
  subscription,
  bank,
  wallet,
  mobileMoney,
  articleCategory,
});

export default rootReducer;
