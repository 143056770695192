import React, { useState } from 'react';
import MtnMobileMoney from './MtnMobileMoney';
import AirtelMoney from './AirtelMoney';
import { Tab } from '@headlessui/react';
import { useSelector, useDispatch } from 'react-redux';

const MobileMoney = () => {
  const dispatch = useDispatch();
  const [selectedContent, setSelectedContent] = useState('mtn');

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div className="flex flex-col w-full h-full">
      <Tab.Group>
        <Tab.List className="w-full overfloe-x-auto mb-4 flex flex-row gap-2">
          <Tab
            className={({ selected }) =>
              classNames(
                'flex w-fit px-6 items-center justify-between rounded-lg p-2 text-[16px] text-primary',
                'ring-white ring-opacity-60 outline-none',
                selected ? 'bg-zinc-200 text-primary' : 'hover:bg-zinc-200'
              )
            }
            onClick={() => {
              setSelectedContent('mtn');
            }}
          >
            <div className="flex gap-4 cursor-pointer items-center">
              {/* <MdProductionQuantityLimits size={20} className="" /> */}
              <h1 className="font-bold">Mtn</h1>
            </div>
          </Tab>

          <Tab
            className={({ selected }) =>
              classNames(
                'flex w-fit px-6 items-center justify-between rounded-lg p-2 text-[16px] text-primary',
                'ring-white ring-opacity-60 outline-none',
                selected ? 'bg-zinc-200 text-primary' : 'hover:bg-zinc-200'
              )
            }
            onClick={() => {
              setSelectedContent('airtel');
            }}
          >
            <div className="flex gap-4 cursor-pointer items-center">
              {/* <MdProductionQuantityLimits size={20} className="" /> */}
              <h1 className="font-bold">Airtel</h1>
            </div>
          </Tab>
        </Tab.List>

        <div className="w-full px-4">
          <Tab.Panels>
            <Tab.Panel className={selectedContent === 'mtn' ? '' : 'hidden'}>
              <MtnMobileMoney />
            </Tab.Panel>

            <Tab.Panel className={selectedContent === 'airtel' ? '' : 'hidden'}>
              <AirtelMoney />
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  );
};

export default MobileMoney;
