import React, { useEffect, useState } from 'react';
import Axios from '../../components/Axios';
import Modal from '../../components/modal/Modal';
import Button from '../../components/Button';
import { ToastComponent, ToasterComponent } from '../../components/Toast';
import { RiErrorWarningFill } from 'react-icons/ri';
import { APP } from '../../utils/constant';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBanks } from '../../redux/actions/bank';
// import { token } from '../../Routes/AdvertiserRoutes';
const DeleteBank = ({
  openModal,
  handleDeleteBankModal,
  bankId,
  getBanks,
  getAccounts,
}) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const handleDeleteBank = () => {
    setLoading(true);
    Axios.delete(APP.API_MAIN_URL + `/admin/banks/${bankId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        setLoading(false);
        dispatch(fetchBanks());
        handleDeleteBankModal();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        notify('error', 'Something went wrong');
      });
  };

  return (
    <Modal
      isOpen={openModal}
      onClose={handleDeleteBankModal}
      Style={'w-max h-max m-auto flex mx-auto items-center justify-center'}
    >
      <Modal.Body>
        <ToasterComponent />
        <div className="flex flex-col space-y-4 font-bold">
          <div className="w-full flex items-center justify-center">
            <RiErrorWarningFill size={40} color="#FF3333" />
          </div>
          <div>
            <h2>Confirm if you want to delete this bank</h2>
          </div>
          <div className="flex flex-row w-full justify-between">
            <Button
              type="button"
              content="Cancel"
              loading={false}
              btnColor="primary"
              Style={
                'text-black w-max h-[40px] mt-2 ml-0 bg-white border rounded-sm cursor-pointer'
              }
              onClick={() => {
                handleDeleteBankModal();
              }}
            />
            <Button
              type="button"
              content="Delete"
              loading={loading}
              btnColor="primary"
              onClick={() => {
                handleDeleteBank();
              }}
              Style={'text-white w-max h-[40px] mt-2 ml-0 cursor-pointer'}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteBank;
