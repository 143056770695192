import React, { useState, useEffect } from "react";
import Modal from "../../components/modal/Modal";
import { useForm } from "react-hook-form";
import { HiPlus } from "react-icons/hi";
import { AiFillMinusCircle } from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { ToastComponent, ToasterComponent } from "../../components/Toast";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Axios from "../../components/Axios";
import TextArea from "../../components/TextArea";
import { APP } from "../../utils/constant";

const Create_Qr_code = ({ open, setOpen, config, toggle, setToggle }) => {
  const [loading, setLoading] = useState(false);
  const [provinces, setPronvinces] = useState([]);
  const [provinceId, setProvinceId] = useState();
  const [districts, setDistricts] = useState([]);
  const [districtId, setDistrictId] = useState();
  const [sectors, setSectors] = useState([]);
  const [sectorId, setSectorId] = useState();
  const [villages, setVillages] = useState([]);
  const [cells, setCells] = useState([]);
  const [cellId, setCellId] = useState();
  const [location, setLocation] = useState([
    {
      province_id: "",
      district_id: "",
      sector_id: "",
      cell_id: "",
      village_id: "",
      price_per_environment: "",
      environments: [
        {
          name: "",
          placements: [
            {
              name: "",
              description: "",
            },
          ],
        },
      ],
    },
  ]);

  const [data, setData] = useState({
    name: "",
  });

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  // operations on the Locations, add, remove and handle change

  const addLocation = () => {
    location.push({
      province_id: "",
      district_id: "",
      sector_id: "",
      cell_id: "",
      village_id: "",
      environments: [
        {
          name: "",
          placements: [
            {
              name: "",
              price: "",
              description: "",
            },
          ],
        },
      ],
    });
    return setLocation([...location]);
  };

  const removeLocation = (index) => {
    setLocation((prev) => {
      return prev.filter((_, i) => i !== index);
    });
  };

  const handleProvince = (e, index) => {
    const { value } = e;
    setLocation((prev) => {
      const newLocation = [...prev];
      newLocation[index].province_id = value;
      return newLocation;
    });
  };

  const handleDistrict = (e, index) => {
    const { value } = e;
    setLocation((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            district_id: value,
          };
        }
        return item;
      });
    });
  };

  const handleSector = (e, index) => {
    const { value } = e;
    setLocation((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            sector_id: value,
          };
        }
        return item;
      });
    });
  };

  const handleCell = (e, index) => {
    const { value } = e;
    setLocation((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            cell_id: value,
          };
        }
        return item;
      });
    });
  };

  const handleVillage = (e, index) => {
    const { value } = e;
    setLocation((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            village_id: value,
          };
        }
        return item;
      });
    });
  };

  const handleEnvironment = (e, index, i) => {
    const { value } = e.target;
    location[index].environments[i].name = value;
    return setLocation([...location]);
  };

  const addEnvironment = (index) => {
    location[index].environments.push({
      name: "",
      placements: [
        {
          name: "",
          description: "",
        },
      ],
    });
    return setLocation([...location]);
  };

  const removeEnvironment = (index, i) => {
    location[index].environments.splice(i, 1);
    return setLocation([...location]);
  };

  const addPlacement = (index, i) => {
    location[index].environments[i].placements.push({
      name: "",
      description: "",
    });
    return setLocation([...location]);
  };

  const removePlacement = (index, i, placement_index) => {
    location[index].environments[i].placements.splice(placement_index, 1);
    return setLocation([...location]);
  };

  const handleAddPlacementName = (e, index, i, placement_index) => {
    const { value } = e.target;
    location[index].environments[i].placements[placement_index].name = value;
    return setLocation([...location]);
  };

  const handleAddPlacementPrice = (e, index, i, placement_index) => {
    const { value } = e.target;
    location[index].environments[i].placements[placement_index].price = value;
    return setLocation([...location]);
  };

  const handleAddPlacementDescription = (e, index, i, placement_index) => {
    const { value } = e.target;
    location[index].environments[i].placements[placement_index].description =
      value;
    return setLocation([...location]);
  };

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
    control,
  } = useForm();

  // submit
  useEffect(() => {
    console.log({ ...data, location: location });
  }, []);

  const onSubmit = () => {
    setLoading(true);
    Axios.post(
      "https://api.account.konvey.rw/api/admin/qr-codes",
      { ...data, qr_code_locations: location, currency_id:1, status: "active" },
      config
    )
      .then((res) => {
        console.log(res);
        setLoading(false);
        notify("success", "QR code created successfully");
        setToggle(!toggle);
        setOpen(!open);
        reset();
        setLoading(false);
        setToggle(!toggle);
        notify("success", "QR code created successfully");
        setOpen(!open);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        notify("error", err?.response?.data?.message);
      });
  };

  // api to fetch pronvinces

  const getPronvinces = () => {
    try {
      Axios.get(APP.API_MAIN_URL + "/utils/location/provinces", config).then(
        (res) => {
          setPronvinces(res?.data?.data);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  // api to fetch districts

  const getDistricts = () => {
    try {
      Axios.get(
        APP.API_MAIN_URL + `/utils/location/province/${provinceId}/districts`,
        config
      ).then((res) => {
        setDistricts(res?.data?.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // api to fetch sectors

  const getSectors = () => {
    try {
      Axios.get(
        APP.API_MAIN_URL + `/utils/location/district/${districtId}/sectors`,
        config
      ).then((res) => {
        setSectors(res?.data?.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // api to fetch cells

  const getCells = () => {
    try {
      Axios.get(
        APP.API_MAIN_URL + `/utils/location/sector/${sectorId}/cells`,
        config
      ).then((res) => {
        setCells(res?.data?.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // api to fetch villages

  const getVillages = () => {
    try {
      Axios.get(
        APP.API_MAIN_URL + `/utils/location/cell/${cellId}/villages`,
        config
      ).then((res) => {
        setVillages(res?.data?.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPronvinces();
    getDistricts();
    getSectors();
    getVillages();
    getCells();
  }, [provinceId, districtId, sectorId, cellId]);
  return (
    <Modal
      isOpen={open}
      onClose={() => setOpen(!open)}
      Style={"w-[90%] lg:w-1/2 mx-auto h-[90%] m-4 flex z-[10]"}
    >
      <Modal.Header>
        <div className="font-bold">Add QR code</div>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col space-y-2 w-full"
        >
          <ToasterComponent />
          <Input
            labelText={"QR Code Name"}
            labelFor={"QR Code Name"}
            name={`name`}
            type={"text"}
            placeholder={"enter QR code name"}
            isRequired={true}
            customClass="w-full text-sm mx-auto"
            register={register}
            errors={errors}
            onChange={(e) => {
              clearErrors("name");
              setData({ ...data, name: e.target.value });
            }}
          />{" "}
          <div className=" flex-col w-full justify-between space-y-2">
            <p>Location</p>
            {location.map((loc, index) => {
              return (
                <div className="flex flex-col">
                  <div className="border border-primary border-opacity-50 rounded-lg p-4 flex flex-col">
                    <div className="flex lg:flex-row flex-col w-full lg:space-x-2">
                      <Select
                        label="Province"
                        customClass="lg:w-1/2 w-full mb-2 text-sm"
                        isRequired={false}
                        placeholder={"province"}
                        options={provinces.map((pronvice) => {
                          return { value: pronvice.id, label: pronvice.name };
                        })}
                        register={{
                          control,
                          name: `province_id ${index}`,
                          rules: { required: "province is required" },
                        }}
                        handleChange={(e) => {
                          clearErrors("province_id");
                          setProvinceId(e.value);
                          handleProvince(e, index);
                        }}
                        error={errors[`province_id ${index}`]?.message}
                      />
                      <Select
                        label="District"
                        customClass="lg:w-1/2 w-full text-sm"
                        isLoading={false}
                        placeholder={"district"}
                        options={districts.map((district) => {
                          return { value: district.id, label: district.name };
                        })}
                        register={{
                          control,
                          name: `district_id ${index}`,
                        }}
                        handleChange={(e) => {
                          clearErrors("district_id");
                          setDistrictId(e.value);
                          handleDistrict(e, index);
                        }}
                      />
                    </div>
                    <div className="flex lg:flex-row flex-col w-full lg:space-x-2">
                      <Select
                        label="Sector"
                        customClass="lg:w-1/3 w-full mb-2 text-sm"
                        isLoading={false}
                        placeholder={"sector"}
                        options={sectors.map((sector) => {
                          return { value: sector.id, label: sector.name };
                        })}
                        register={{
                          control,
                          name: `sector_id ${index}`,
                        }}
                        defaultValue={""}
                        handleChange={(e) => {
                          clearErrors("sector");
                          setSectorId(e.value);
                          handleSector(e, index);
                        }}
                      />
                      <Select
                        label="Cell"
                        customClass="lg:w-1/3 w-full mb-2 text-sm"
                        isLoading={false}
                        placeholder={"cell"}
                        options={cells.map((cell) => {
                          return { value: cell.id, label: cell.name };
                        })}
                        register={{
                          control,
                          name: `cell_id ${index}`,
                        }}
                        defaultValue={""}
                        handleChange={(e) => {
                          clearErrors("cell");
                          setCellId(e.value);
                          handleCell(e, index);
                        }}
                      />
                      <Select
                        label="Village"
                        customClass="lg:w-1/3 w-full mb-2 text-sm"
                        isLoading={false}
                        placeholder={"Village"}
                        options={villages.map((village) => {
                          return { value: village.id, label: village.name };
                        })}
                        register={{
                          control,
                          name: `village_id ${index}`,
                        }}
                        defaultValue={""}
                        handleChange={(e) => {
                          clearErrors("village");
                          handleVillage(e, index);
                        }}
                      />
                    </div>

                    {loc?.environments?.map((environment, i) => {
                      return (
                        <div className="flex flex-col">
                          <div className="flex flex-col border p-4 border-primary rounded-lg border-opacity-20 my-2 shadow-inner bg-[#FFFFFF]">
                            <Input
                              labelText={"Environment"}
                              labelFor={"environment"}
                              name={`environment ${index} ${i}`}
                              type={"text"}
                              placeholder={"ex: home, office, etc"}
                              isRequired={true}
                              customClass="w-full"
                              register={register}
                              errors={errors}
                              onChange={(e) => {
                                clearErrors("environment");
                                handleEnvironment(e, index, i);
                              }}
                            />
                            {environment?.placements?.map(
                              (place, placement_index) => {
                                return (
                                  <div className="flex flex-col space-x-2 item-center">
                                    {/* Placement division*/}
                                    <div className="flex flex-col space-y-2 border border-primary border-opacity-50 p-4 w-full rounded-lg mt-2">
                                      <Input
                                        labelText={"Placement name"}
                                        labelFor={"placement name"}
                                        name={`placement ${index} ${i} ${placement_index}`}
                                        type={"text"}
                                        placeholder={"ex: door, window, etc"}
                                        customClass="w-full"
                                        register={register}
                                        onChange={(e) => {
                                          clearErrors("placement name");
                                          handleAddPlacementName(
                                            e,
                                            index,
                                            i,
                                            placement_index
                                          );
                                        }}
                                      />
                                      <Input
                                        labelText={"Price"}
                                        labelFor={"price"}
                                        name={`price ${index} ${i} ${placement_index}`}
                                        type={"number"}
                                        placeholder={"ex: 100"}
                                        customClass="w-full"
                                        register={register}
                                        required={true}
                                        onChange={(e) => {
                                          clearErrors("price");
                                          handleAddPlacementPrice(
                                            e,
                                            index,
                                            i,
                                            placement_index
                                          );
                                        }}
                                      />
                                      <TextArea
                                        labelText={"Placement description"}
                                        labelFor={"placement description"}
                                        name={`placement description ${index} ${i} ${placement_index}`}
                                        placeholder={
                                          "enter placement description"
                                        }
                                        customClass="w-full"
                                        register={register}
                                        onChange={(e) => {
                                          clearErrors("placement description");
                                          handleAddPlacementDescription(
                                            e,
                                            index,
                                            i,
                                            placement_index
                                          );
                                        }}
                                      />
                                    </div>
                                    {/* end of placement*/}

                                    <div className="flex items-center justify-end">
                                      <div
                                        data-tooltip-id="tooltip12"
                                        data-tooltip-content="remove placement"
                                      >
                                        <AiFillMinusCircle
                                          color="#BDBDBD"
                                          size={20}
                                          onClick={() =>
                                            removePlacement(
                                              index,
                                              i,
                                              placement_index
                                            )
                                          }
                                          className={`cursor-pointer ${
                                            placement_index === 0
                                              ? "hidden"
                                              : "visible"
                                          }`}
                                        />
                                      </div>
                                      <div
                                        data-tooltip-id="tooltip12"
                                        data-tooltip-content="add placement"
                                      >
                                        <Button
                                          type="button"
                                          content={
                                            <HiPlus color="#FBFBFB" size={15} />
                                          }
                                          loading={false}
                                          onClick={() => addPlacement(index, i)}
                                          btnColor="primary"
                                          Style={`right-[0px] py-[1px] bottom-[0px] text-white w-fit px-[2px] m-2 ${
                                            environment?.placements?.length ===
                                            placement_index + 1
                                              ? "visible"
                                              : "hidden"
                                          }`}
                                        />
                                      </div>
                                      <Tooltip id="tooltip12" />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className="flex items-center justify-end">
                            <div
                              data-tooltip-id="tooltip03"
                              data-tooltip-content="add environment"
                            >
                              <Button
                                type="button"
                                content={<HiPlus color="#FBFBFB" size={18} />}
                                loading={false}
                                onClick={() => addEnvironment(index)}
                                btnColor="primary"
                                Style={`right-[0px] py-[1px] bottom-[0px] text-white w-fit px-[2px] m-2`}
                              />
                            </div>
                            <div
                              data-tooltip-id="tooltip03"
                              data-tooltip-content="remove environment"
                            >
                              <AiFillMinusCircle
                                color="#BDBDBD"
                                size={20}
                                onClick={() => removeEnvironment(index)}
                                className={`cursor-pointer ${
                                  i === 0 ? "hidden" : "visible"
                                }`}
                              />
                            </div>
                            <Tooltip id="tooltip03" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex items-center justify-end">
                    <div
                      data-tooltip-id="tooltip12"
                      data-tooltip-content="add location"
                    >
                      <Button
                        type="button"
                        content={<HiPlus color="#FBFBFB" size={20} />}
                        loading={false}
                        onClick={addLocation}
                        btnColor="primary"
                        Style={`right-[0px] py-[1px] bottom-[0px] text-white w-fit px-[2px] m-2 ${
                          location.length === index + 1 ? "visible" : "hidden"
                        }`}
                      />
                    </div>
                    <div
                      data-tooltip-id="tooltip12"
                      data-tooltip-content="remove location"
                    >
                      <AiFillMinusCircle
                        color="#BDBDBD"
                        size={25}
                        onClick={() => removeLocation(index)}
                        className={`cursor-pointer ${
                          index === 0 ? "hidden" : "visible"
                        }`}
                      />
                    </div>
                    <Tooltip id="tooltip12" />
                  </div>
                </div>
              );
            })}
          </div>
          <Button
            type="submit"
            content={
              <h1 className="text-white font-nunito text-base">Submit</h1>
            }
            loading={loading}
            btnColor="black"
            Style={"text-white  ml-0 w-full mt-4"}
          />
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default Create_Qr_code;
