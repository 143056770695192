import React, { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Modal from '../../../components/modal/Modal';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';
import { useSelector, useDispatch } from 'react-redux';
import { APP } from '../../../utils/constant';
import { ToastComponent, ToasterComponent } from '../../../components/Toast';
import Button from '../../../components/Button';
import { fetchBankAccountPayment } from '../../../redux/actions/bank';

const RejectReason = ({
  openModal,
  handleOpenModal,
  bankId,
  bankAccountId,
  id,
}) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
  } = useForm();

  const { token } = useSelector((state) => state.user);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const handleRejectPayment = () => {
    setRejectLoading(true);
    axios
      .put(
        APP.API_MAIN_URL +
          `/admin/banks/${bankId}/accounts/${bankAccountId}/payments/${id}/reject`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        setRejectLoading(false);
        dispatch(fetchBankAccountPayment(bankId, bankAccountId));
      })
      .catch((error) => {
        setRejectLoading(false);
        notify('error', error.response.data.message);
        console.log(error);
      });
  };

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  return (
    <Modal
      isOpen={openModal}
      onClose={() => {
        handleOpenModal();
      }}
      Style={'w-[90%] lg:w-1/3  flex m-auto py-4'}
    >
      <Modal.Header>
        <h1 className="font-bold">Reject Reason</h1>
      </Modal.Header>

      <div className="flex flex-col p-4">
        <TextArea
          labelText={'Reason'}
          labelFor={'Reason'}
          name={'Reason'}
          type={'text'}
          isRequired={true}
          placeholder={'Enter rejection reason ...'}
          customClass="w-full mb-2"
          inputStyle={'h-[80px]'}
          value={rejectReason}
          onChange={(e) => {
            setRejectReason(e.target.value);
          }}
          register={register}
          errors={errors}
        />
        <Button
          type="button"
          content="Reject"
          loading={rejectLoading}
          onClick={() => {
            handleRejectPayment();
          }}
          btnColor="error"
          Style={`text-white w-fit h-[40px] mt-8 ml-0 font-bold self-end`}
        />
      </div>
    </Modal>
  );
};

export default RejectReason;
