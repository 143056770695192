import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carausal from "./Carausal";

const Slider2 = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  return (
    <div className="w-full h-72  bg-white p-5 ">
      <Carausal
        slidesToShow={3}
        slidesToScroll={1}
        Style={"w-[98%] m-auto h-[20vh]  "}
        setCurrentSlide={setCurrentSlide}
        autoplay={true}
        infinite={true}
        dots={false}
      >
        {data.map((item, index) => (
          <div
            key={index}
            className={`w-full h-48 ml-4 shadow-md transform-gpu transition-transform mx-8  ${
              index === currentSlide ? "scale-102 " : "scale-100"
            }`}
          >
            {item.about && (
              <div className="text-center w-full h-full rounded-md  p-2 capitalize  bg-[#ebf1f5] ">
                <h3 className="p-5 font-bold text-[18px]">About me</h3>

                <div className="border-b-2 border-light w-full mt-2"></div>

                <span className="absolute bottom-0 capitalize px-2 bg-[#ddd] h-16 opacity-50 text-primary text-[18px] font-bold rounded-b-md right-0 left-0">
                  {item.about}
                </span>
              </div>
            )}
            {item.phone && (
              <div className="text-center w-full h-full rounded-md  p-2 capitalize bg-[#ebf1f5] ">
                <h3 className="p-5 font-bold text-[18px]">Contacts </h3>

                <div className="border-b-2 border-light w-full mt-2"></div>

                <div className="absolute bottom-0 capitalize px-2 bg-[#ddd] h-16 opacity-50 text-primary text-[18px] font-bold rounded-b-md right-0 left-0">
                  <p>{item.phone}</p>
                </div>
              </div>
            )}
            {item.website && (
              <div className="w-full h-full rounded-md  p-2 capitalize text-center bg-[#ebf1f5]">
                <div>
                  <h3 className="p-5 font-bold text-[18px]">
                    Visit my website{" "}
                  </h3>
                </div>

                <div className="border-b-2 border-light w-full mt-2"></div>

                <span className="absolute bottom-0 px-2 bg-[#ddd] h-16 opacity-50 text-primary text-[18px] font-bold rounded-b-md right-0 left-0">
                  <a href={item.website}>Visit website</a>
                  {}
                </span>
              </div>
            )}
            {item.email && (
              <div className="w-full h-full rounded-md  p-2 capitalize text-center bg-[#ebf1f5] ">
                <div>
                  <div className="p-5 font-bold text-[18px]">Email </div>
                </div>

                <div className="border-b-2 border-light w-full mt-2"></div>

                <span className="absolute bottom-0 px-2 bg-[#ddd] h-16 opacity-50 text-primary text-[18px] font-bold rounded-b-md right-0 left-0">
                  {item.email}
                </span>
              </div>
            )}
            {item?.link?.length > 0 && (
              <>
                <div className=" w-full h-full rounded-md  p-2 capitalize text-center bg-[#ebf1f5] ">
                  <h3 className="p-5 font-bold text-[18px]">My links</h3>
                  <div className="border-b-2 border-light w-full mt-2"></div>
                  <div className="absolute bg-[#ddd] bottom-0 px-2 h-16 opacity-50 text-primary text-[18px] font-bold rounded-b-md right-0 left-0">
                    {item.link.map((link, index) => (
                      <a key={index} href={link.link}>
                        {link.title}
                      </a>
                    ))}
                  </div>
                </div>
              </>
            )}
            {item?.profile_services?.length > 0 && (
              <>
                <div className=" w-full h-full rounded-md  p-2 capitalize text-center bg-[#ebf1f5] ">
                  <div className="p-5 font-bold text-[18px]">What we do</div>
                  <div className="border-b-2 border-light w-full mt-2"></div>
                  {item.profile_services.map((service, index) => (
                    <div key={index} className="">
                      <span className="absolute bg-[#ddd] bottom-0 px-2 h-16 opacity-50 text-primary text-[18px] font-bold rounded-b-md right-0 left-0">
                        {service.name}
                      </span>
                    </div>
                  ))}
                </div>
              </>
            )}
            {item?.profile_social_media?.length > 0 && (
              <div className="w-full h-full rounded-md  p-2  text-center bg-[#ebf1f5]">
                <h3 className="p-5 font-bold text-[18px] capitalize">
                  Follow us
                </h3>
                <div className="border-b-2 border-light w-full mt-2"></div>
                <span className="absolute bg-[#ddd] bottom-0 px-2 h-16 opacity-50 text-primary text-[18px] font-bold rounded-b-md right-0 left-0">
                  {item.profile_social_media.map((social, index) => (
                    <a href={social.link}> {social?.social_media?.name}</a>
                  ))}
                </span>
              </div>
            )}
            {item?.address?.length > 0 && (
              <div className="w-full h-full rounded-md  p-2  text-center bg-[#ebf1f5]">
                <h3 className="p-5 font-bold text-[18px] capitalize">
                  Address
                </h3>
                <div className="border-b-2 border-light w-full mt-2"></div>
                <span className="absolute bg-[#ddd] bottom-0 px-2 h-16 opacity-50 text-primary text-[18px] font-bold rounded-b-md right-0 left-0">
                  {item.address.map((address, index) => (
                    <p>{address.name}</p>
                  ))}
                </span>
              </div>
            )}
          </div>
        ))}
      </Carausal>
    </div>
  );
};

export default Slider2;
