import {
  SET_IS_FETCHING_MTN_MOBILE_MONEY_PAYMENT,
  SET_IS_FETCHING_AIRTEL_MONEY_PAYMENT,
  FETCH_AIRTEL_MONEY_PAYMENT,
  FETCH_MTN_MOBILE_MONEY_PAYMENT,
} from '../actions/mobileMoney';

const initialState = {
  is_fetching_mtn_mobile_money_payment: false,
  is_fetching_airtel_money_payment: false,
  mtn_mobile_money_payment: [],
  airtel_money_payment: [],
};

export const mobileMoney = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_MTN_MOBILE_MONEY_PAYMENT:
      return {
        ...state,
        is_fetching_mtn_mobile_money_payment: action.payload,
      };

    case SET_IS_FETCHING_AIRTEL_MONEY_PAYMENT:
      return {
        ...state,
        is_fetching_airtel_money_payment: action.payload,
      };

    case FETCH_MTN_MOBILE_MONEY_PAYMENT:
      return {
        ...state,
        mtn_mobile_money_payment: action.payload,
      };

    case FETCH_AIRTEL_MONEY_PAYMENT:
      return {
        ...state,
        airtel_money_payment: action.payload,
      };
    default:
      return state;
  }
};
