import axios from "axios";
import { APP, config } from "../../utils/constant";

export const SET_ARTICLE_CATEGORY = "SET_ARTICLE_CATEGORY";
export const SET_ARTICLES = "SET_ARTICLES";
export const SET_SELECTED_LANGUAGE = "SET_SELECTED_LANGUAGE";
export const SET_SELECTED_CATEGORY_LANGUAGE = "SET_SELECTED_CATEGORY_LANGUAGE";

export const SET_IS_LOADING_CATEGORY = "SET_IS_LOADING_CATEGORY";
export const SET_IS_LOADING_ARTICLES = "SET_IS_LOADING_ARTICLES";

export const RESET_ARTICLES = "RESET_ARTICLES";

export const setCategory = (category) => (dispatch) => {
  dispatch({
    type: SET_ARTICLE_CATEGORY,
    payload: category,
  });
};
export const setArticles = (article) => (dispatch) => {
  dispatch({
    type: SET_ARTICLES,
    payload: article,
  });
};

export const setSelecteLanguage = (language) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_LANGUAGE,
    payload: language,
  });
};
export const setSelectedCategoryLanguage = (catLan) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_CATEGORY_LANGUAGE,
    payload: catLan,
  });
};

export const setIsLoadingCategory = (trueOrFalse) => (dispatch) => {
  dispatch({
    type: SET_IS_LOADING_CATEGORY,
    payload: trueOrFalse,
  });
};
export const setIsLoadingArticles = (trueOrFalse) => (dispatch) => {
  dispatch({
    type: SET_IS_LOADING_ARTICLES,
    payload: trueOrFalse,
  });
};

export const resetArticles = () => ({ type: RESET_ARTICLES });

export const getCategory = () => (dispatch, getState) => {
  const { token } = getState().user;

  dispatch(setIsLoadingCategory(true));
  console.log("Fetching category....");
  axios
    .get(APP.API_MAIN_URL + `/admin/vas/categories`, config(token))
    .then((res) => {
      dispatch(setCategory(res.data.data));
      dispatch(setIsLoadingCategory(false));
    })
    .catch((error) => {
      console.log("Error in retrieving article categories", error);
      dispatch(setIsLoadingCategory(false));
      // error.response.status === 403 &&
      //   (window.location.href = 'https://account.konvey.rw/');
    });
};

export const getArticles = (categoryId) => (dispatch, getState) => {
  const { token } = getState().user;

  dispatch(setIsLoadingArticles(true));
  axios
    .get(
      APP.API_MAIN_URL + `/admin/vas/categories/${categoryId}/articles`,
      config(token)
    )
    .then((res) => {
      dispatch(setArticles(res.data.data));
      dispatch(setIsLoadingArticles(false));
    })
    .catch((error) => {
      console.log(
        `Error in retrieving plans for category id of ${categoryId}`,
        error
      );
      dispatch(setIsLoadingArticles(false));
      // error.response.status === 403 &&
      //   (window.location.href = 'https://account.konvey.rw/');
    });
};
