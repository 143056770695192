import React, { useState } from "react";
import Modal2 from "../../../../components/modal/Modal2";
import { useSelector } from "react-redux";
import Button from "../../../../components/Button";
import { FcPaid } from "react-icons/fc";
import { BsInfoLg } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { MdModeEdit } from "react-icons/md";
import { HiMinus, HiPlus } from "react-icons/hi";
import DeleteBenefit from "../benefits/DeleteBenefit";
import AddBenefits from "../benefits/AddBenefits";

function ViewPlan({ openModal, handleModal, data }) {
  const { selectedCatLanguage } = useSelector((state) => state.subscription);
  const [isHovered, setIsHovered] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [modal, setModal] = useState(false);
  const [ids, setIds] = useState({ planId: "", benId: "" });

  const defaultLanguage = selectedCatLanguage?.find(
    (item) => item?.is_default === 1
  )?.code;

  const handleEditClick = (item) => {};
  const handleDeleteClick = () => {
    setOpenDelete(!openDelete);
  };
  const handleOpenModal = () => {
    setModal(!modal);
  };
  return (
    <div>
      <Modal2
        isOpen={openModal}
        onClose={() => {
          handleModal();
        }}
        Style={"w-[90%] lg:w-1/3 lg:h-max flex m-auto py-4 "}
      >
        <Modal2.Header>
          <h1 className="font-bold capitalize">
            {`${data?.name[defaultLanguage ? defaultLanguage : "en"]}`}
          </h1>
        </Modal2.Header>
        <Modal2.Body>
          <>
            <DeleteBenefit
              openModal={openDelete}
              handleModal={handleDeleteClick}
              categoryId={data.subscription_plan_category_id}
              planId={ids.planId}
              benId={ids.benId}
            />
            <AddBenefits
              openModal={modal}
              handleModal={handleOpenModal}
              categoryId={data.subscription_plan_category_id}
              planId={data.id}
              selectedLangue={selectedCatLanguage}
            />
            <div className="flex items-center justify-center ">
              <div className=" w-[80%] pt-4 rounded-xl space-y-6 overflow-hidden transition-all duration-500 transform shadow-xl hover:shadow-2xl ">
                <div className="px-8 flex justify-between items-center">
                  <h1 className="text-xl font-bold text-gray-800 capitalize">
                    {data?.subscription_type}
                  </h1>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-gray-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                </div>
                <h1 className="text-4xl text-center font-bold">{`${data?.amount} Rwf`}</h1>
                <p className="px-4 text-center text-[16px] ">
                  {`${
                    data?.description[defaultLanguage ? defaultLanguage : "en"]
                  }`}
                </p>
                {data?.benefits.length === 0 ? (
                  <h1 className="mx-5">No Benefis available in this plan</h1>
                ) : (
                  <ul className="text-left mx-5 text-[18px]">
                    <h1 className="font-bold pb-2"> Benefits</h1>

                    {data?.benefits?.map((item, i) => (
                      <div
                        key={i}
                        className="flex items-center"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        <div className="flex w-max items-center border-b-2 py-1 my-2">
                          <FcPaid size={20} />
                          <span className="ml-3">
                            {item.name[defaultLanguage]}
                          </span>

                          {/* <div className="bg-primary/30 text-white text-[12px]  rounded-full ml-2 p-1 cursor-pointer ">
                        <BsInfoLg
                          data-tooltip-id="tooltip12"
                          data-tooltip-content={`${item?.description[defaultLanguage]}`}
                        />
                      </div> */}
                        </div>
                        {isHovered && (
                          <div className="ml-3">
                            {/* Add your delete and edit buttons here */}
                            {/* <MdModeEdit
                              onClick={(item) => {
                                setIds((prevData) => ({
                                  planId: item.subscription_plan_id,
                                  benId: item.id,
                                }));
                                handleEditClick();
                              }}
                            /> */}
                            <div className="text-white bg-red-400 mt-1 rounded-full">
                              <HiMinus
                                onClick={() => {
                                  setIds((prevData) => ({
                                    planId: item.subscription_plan_id,
                                    benId: item.id,
                                  }));
                                  handleDeleteClick();
                                }}
                              />
                            </div>
                          </div>
                        )}
                        <Tooltip id="tooltip12" />
                      </div>
                    ))}
                  </ul>
                )}

                <div className="text-center bg-gray-200 ">
                  <Button
                    type="submit"
                    content={
                      <div className="flex flex-row-reverse items-center justify-between">
                        <h1>Add benefit</h1>
                        <div className="text-white font-extrabold rounded-full  border-[#e2e2e2]">
                          <HiPlus size={20} />
                        </div>
                      </div>
                    }
                    loading={false}
                    onClick={() => {
                      handleOpenModal();
                    }}
                    Style={
                      "text-white w-max h-[40px] my-2 ml-0 bg-primary rounded-l-full rounded-r-full cursor-pointer"
                    }
                  />
                </div>
              </div>
            </div>
          </>
        </Modal2.Body>
      </Modal2>
    </div>
  );
}

export default ViewPlan;
