import React from "react";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const params = new URLSearchParams(window.location.search);
  const { token } = useSelector((state) => state.user);
  React.useEffect(() => {
    localStorage.setItem("isLoggedIn", true);
  }, []);
  return <div className="content">Dashboard</div>;
};

export default Dashboard;
