import React, { useState } from "react";
import Modal from "../../components/modal/Modal";
import Axios from "../../components/Axios";
import { ToastComponent, ToasterComponent } from "../../components/Toast";
import TextArea from "../../components/TextArea";
import { useForm } from "react-hook-form";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Button from "../../components/Button";
import { APP } from "../../utils/constant";

const AddSenderName = ({
  show,
  setShow,
  config,
  handleTriggerFetchSenderNames,
}) => {
  const [loading, setLoading] = useState(false);

  const senderNameTypeOptions = [
    { value: "sms", label: "SMS" },
    { value: "email", label: "Email" },
  ];
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const onSubmit = (data) => {
    console.log(data, "data");
    setLoading(true);
    const senderNameData = {
      name: data.name,
      type: data.senderNameType.value,
    };
    Axios.post(
      APP.API_MAIN_URL + "/advertiser/sender-names",
      senderNameData,
      config
    )
      .then((res) => {
        notify("success", "Request sent successfully");
        handleTriggerFetchSenderNames();
        reset();
        setLoading(false);
        setShow();
      })
      .catch((error) => {
        console.log(error);
        notify("error", "Something went wrong");
      });
  };
  return (
    <Modal
      isOpen={show}
      onClose={() => setShow()}
      Style={"w-1/3 m-auto h-max  p-8 flex z-[10]"}
    >
      <Modal.Header>
        <h1 className="font-bold">Request Sendername</h1>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col p-4 h-fit">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Select
              label=" Sender name type"
              className="w-full mb-2"
              isLoading={false}
              placeholder={"Select sender name type"}
              options={senderNameTypeOptions}
              register={{
                control,
                name: "senderNameType",
                rules: { required: "Sender name type is required" },
              }}
              defaultValue={""}
              handleChange={(e) => {
                // handleCampaignType(e.label);
              }}
              error={errors["senderNameType"]?.message}
            />

            <Input
              labelText={"Specify sender name"}
              labelFor={"name"}
              name={"name"}
              type={"text"}
              isRequired={true}
              placeholder={"New sender name"}
              customClass="w-full mt-4 mb-8"
              register={register}
              errors={errors}
            />
            <Button
              type="submit"
              content={
                <h1 className="text-white font-nunito text-base">Submit</h1>
              }
              loading={loading}
              btnColor="black"
              Style={"text-white h-[40px] ml-0 w-full "}
            />
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddSenderName;
