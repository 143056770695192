import React from "react";
import Button from "../../../components/Button";

import Modal2 from "../../../components/modal/Modal2";

const ViewFiles = ({ open, setOpen, pdfUrl, type, excelLink }) => {
  return (
    <Modal2
      isOpen={open}
      onClose={() => setOpen(!open)}
      Style={
        "w-full m-4 flex m-auto md:w-[60%] md:h-[60%] md:m-auto lg:w-[60%] lg:h-[60%] lg:m-auto"
      }
    >
      <Modal2.Body>
        {/* <h1>Preview your document</h1> */}
        <div className="h-[100vh]">
          {type === "pdf" ? (
            // <PdfPreview pdfUrl={pdfUrl} />
            <iframe
              src={pdfUrl}
              title="pdf"
              className="w-full h-full border-none overflow-hidden"
            ></iframe>
          ) : type === "excel" ? (
            // <ExcelPreview excelUrl={excelLink} />
            <iframe
              src={excelLink}
              title="Excel"
              className="w-full h-full border-none overflow-hidden"
            ></iframe>
          ) : (
            "File type selected not supported"
          )}
          <div className="flex justify-end items-center space-x-4 p-2 sticky bottom-0">
            <Button
              type="submit"
              content="Close"
              loading={false}
              btnColor="primary"
              onClick={() => {
                setOpen(!open);
              }}
              Style={"text-white h-[40px] mt-2 ml-0"}
            />
          </div>
        </div>
      </Modal2.Body>
    </Modal2>
  );
};

export default ViewFiles;
