import React, { useState } from "react";
import Button from "../../../components/Button";
import Modal2 from "../../../components/modal/Modal2";
import { RiDeleteBin6Line, RiErrorWarningFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import axios from "axios";
import { APP, config, notify } from "../../../utils/constant";

function DeleteEnvironment({
  open,
  handleDeleteEnvironment,
  qrCodeId,
  getSingleQr,
  getQrCodes,
  environmentId,
}) {
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    setLoading(true);
    axios
      .delete(
        APP.API_MAIN_URL +
          `/admin/qr-codes/${qrCodeId}/environments/${environmentId}`,
        config(token)
      )
      .then((res) => {
        getSingleQr();
        getQrCodes();
        notify("success", "Environment deleted successfully");
        console.log("Res in deleting Environment", res);
        handleDeleteEnvironment();
        setLoading(false);
      })
      .catch((error) => {
        notify("error", error.response.data.message);
        console.log("Error in deleting Environment", error);
        setLoading(false);
      });
  };
  return (
    <Modal2
      isOpen={open}
      onClose={handleDeleteEnvironment}
      Style={"w-max h-max m-auto flex mx-auto items-center justify-center"}
    >
      <Modal2.Body>
        <div className="flex flex-col space-y-4 font-bold">
          <div className="w-full flex items-center justify-center">
            <RiDeleteBin6Line size={40} color="#FF3333" />
          </div>
          <div>
            <h2>Confirm if you want to delete this Environment</h2>
          </div>
          <div className="flex flex-row w-full justify-between">
            <Button
              type="button"
              content="Cancel"
              loading={false}
              btnColor="primary"
              Style={
                "text-black w-max h-[40px] mt-2 ml-0 bg-white border rounded-sm cursor-pointer"
              }
              onClick={() => {
                handleDeleteEnvironment();
              }}
            />
            <Button
              type="button"
              content="Delete"
              loading={loading}
              btnColor="primary"
              onClick={() => {
                handleDelete();
              }}
              Style={"text-white w-max h-[40px] mt-2 ml-0 cursor-pointer"}
            />
          </div>
        </div>
      </Modal2.Body>
    </Modal2>
  );
}

export default DeleteEnvironment;
