import axios from 'axios';
import { APP } from '../../utils/constant';

export const SET_IS_FETCHING_CREDIT_WALLETS = 'SET_IS_FETCHING_CREDIT_WALLETS';
export const SET_IS_FETCHING_CREDIT_WALLET_DEPOSITS =
  'SET_IS_FETCHING_CREDIT_WALLET_DEPOSITS';
export const SET_IS_FETCHING_CREDIT_WALLET_TRANSFERS =
  'SET_IS_FETCHING_CREDIT_WALLET_TRANSFERS';
export const SET_IS_FETCHING_CREDIT_WALLET_TRANSACTIONS =
  'SET_IS_FETCHING_CREDIT_WALLET_TRANSACTIONS';

export const SET_IS_FETCHING_PAYMENT_WALLETS =
  'SET_IS_FETCHING_PAYMENT_WALLETS';
export const SET_IS_FETCHING_PAYMENT_WALLET_DEPOSITS =
  'SET_IS_FETCHING_PAYMENT_WALLET_DEPOSITS';
export const SET_IS_FETCHING_PAYMENT_WALLET_TRANSFERS =
  'SET_IS_FETCHING_PAYMENT_WALLET_TRANSFERS';
export const SET_IS_FETCHING_PAYMENT_WALLET_TRANSACTIONS =
  'SET_IS_FETCHING_PAYMENT_WALLET_TRANSACTIONS';

export const FETCH_CREDIT_WALLETS = 'FETCH_CREDIT_WALLETS';
export const FETCH_CREDIT_WALLET_DEPOSITS = 'FETCH_CREDIT_WALLET_DEPOSITS';
export const FETCH_CREDIT_WALLET_TRANSACTIONS =
  'FETCH_CREDIT_WALLET_TRANSACTIONS';
export const FETCH_CREDIT_WALLET_TRANSFERS = 'FETCH_CREDIT_WALLET_TRANSFERS';

export const FETCH_PAYMENT_WALLETS = 'FETCH_PAYMENT_WALLETS';
export const FETCH_PAYMENT_WALLET_DEPOSITS = 'FETCH_PAYMENT_WALLET_DEPOSITS';
export const FETCH_PAYMENT_WALLET_TRANSACTIONS =
  'FETCH_PAYMENT_WALLET_TRANSACTIONS';
export const FETCH_PAYMENT_WALLET_TRANSFERS = 'FETCH_PAYMENT_WALLET_TRANSFERS';

export const SET_IS_FETCHING_MOBILE_WITHDRAWS =
  'SET_IS_FETCHING_MOBILE_WITHDRAWS';
export const SET_IS_FETCHING_BANK_WITHDRAWS = 'SET_IS_FETCHING_BANK_WITHDRAWS';
export const FETCH_MOBILE_WITHDRAWS = 'FETCH_MOBILE_WITHDRAWS';
export const FETCH_BANK_WITHDRAWS = 'FETCH_BANK_WITHDRAWS';

export const setIsFetchingCreditWallets = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_CREDIT_WALLETS,
    payload,
  });
};

export const setIsFetchingPaymentWallets = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_PAYMENT_WALLETS,
    payload,
  });
};

export const setIsFetchingCreditWalletDeposits = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_CREDIT_WALLET_DEPOSITS,
    payload,
  });
};

export const setIsFetchingPaymentWalletDeposits = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_PAYMENT_WALLET_DEPOSITS,
    payload,
  });
};

export const setIsFetchingCreditWalletTransfers = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_CREDIT_WALLET_TRANSFERS,
    payload,
  });
};

export const setIsFetchingPaymentWalletTransfers = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_PAYMENT_WALLET_TRANSFERS,
    payload,
  });
};

export const setIsFetchingCreditWalletTransactions =
  (payload) => (dispatch) => {
    dispatch({
      type: SET_IS_FETCHING_CREDIT_WALLET_TRANSACTIONS,
      payload,
    });
  };

export const setIsFetchingPaymentWalletTransactions =
  (payload) => (dispatch) => {
    dispatch({
      type: SET_IS_FETCHING_PAYMENT_WALLET_TRANSACTIONS,
      payload,
    });
  };

export const setIsFetchingMobileWithdraws = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_MOBILE_WITHDRAWS,
    payload,
  });
};

export const setIsFetchingBankWithdraws = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_BANK_WITHDRAWS,
    payload,
  });
};

export const setFetchedMobileWithdraws = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_MOBILE_WITHDRAWS,
    payload,
  });
};

export const setFetchedBankWithdraws = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_BANK_WITHDRAWS,
    payload,
  });
};

export const setFetchedCreditWallets = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_CREDIT_WALLETS,
    payload,
  });
};

export const setFetchedPaymentWallets = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_PAYMENT_WALLETS,
    payload,
  });
};

export const setFetchedCreditWalletDeposits = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_CREDIT_WALLET_DEPOSITS,
    payload,
  });
};

export const setFetchedPaymentWalletDeposits = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_PAYMENT_WALLET_DEPOSITS,
    payload,
  });
};

export const setFetchedCreditWalletTransactions = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_CREDIT_WALLET_TRANSACTIONS,
    payload,
  });
};

export const setFetchedPaymentWalletTransactions = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_PAYMENT_WALLET_TRANSACTIONS,
    payload,
  });
};

export const setFetchedCreditWalletTransfers = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_CREDIT_WALLET_TRANSFERS,
    payload,
  });
};

export const setFetchedPaymentWalletTransfers = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_PAYMENT_WALLET_TRANSFERS,
    payload,
  });
};

export const fetchCreditWallets = () => (dispatch, getState) => {
  const { token } = getState().user;
  dispatch(setIsFetchingCreditWallets(true));

  axios
    .get(APP.API_MAIN_URL + `/admin/wallets/credit`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      dispatch(setIsFetchingCreditWallets(false));
      dispatch(setFetchedCreditWallets(res.data.data));
    })
    .catch((error) => {
      dispatch(setIsFetchingCreditWallets(false));
      console.log(error);
    });
};

export const fetchPaymentWallets = () => (dispatch, getState) => {
  const { token } = getState().user;
  dispatch(setIsFetchingPaymentWallets(true));

  axios
    .get(APP.API_MAIN_URL + `/admin/wallets/payout`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      dispatch(setIsFetchingPaymentWallets(false));
      dispatch(setFetchedPaymentWallets(res.data.data));
    })
    .catch((error) => {
      dispatch(setIsFetchingPaymentWallets(false));
      console.log(error);
    });
};

export const fetchCreditWalletDeposits =
  (creditWalletId) => (dispatch, getState) => {
    const { token } = getState().user;
    dispatch(setIsFetchingCreditWalletDeposits(true));

    axios
      .get(
        APP.API_MAIN_URL + `/admin/wallets/credit/${creditWalletId}/deposits`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(setIsFetchingCreditWalletDeposits(false));
        dispatch(setFetchedCreditWalletDeposits(res.data.data));
      })
      .catch((error) => {
        dispatch(setIsFetchingCreditWalletDeposits(false));
        console.log(error);
      });
  };

export const fetchPaymentWalletDeposits =
  (creditWalletId) => (dispatch, getState) => {
    const { token } = getState().user;
    dispatch(setIsFetchingPaymentWalletDeposits(true));

    axios
      .get(
        APP.API_MAIN_URL + `/admin/wallets/payout/${creditWalletId}/deposits`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(setIsFetchingPaymentWalletDeposits(false));
        dispatch(setFetchedPaymentWalletDeposits(res.data.data));
      })
      .catch((error) => {
        dispatch(setIsFetchingPaymentWalletDeposits(false));
        console.log(error);
      });
  };

export const fetchCreditWalletTransfers =
  (creditWalletId) => (dispatch, getState) => {
    const { token } = getState().user;
    dispatch(setIsFetchingCreditWalletTransfers(true));

    axios
      .get(
        APP.API_MAIN_URL + `/admin/wallets/credit/${creditWalletId}/transfers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(setIsFetchingCreditWalletTransfers(false));
        dispatch(setFetchedCreditWalletTransfers(res.data.data));
      })
      .catch((error) => {
        dispatch(setIsFetchingCreditWalletTransfers(false));
        console.log(error);
      });
  };

export const fetchPaymentWalletTransfers =
  (creditWalletId) => (dispatch, getState) => {
    const { token } = getState().user;
    dispatch(setIsFetchingPaymentWalletTransfers(true));

    axios
      .get(
        APP.API_MAIN_URL + `/admin/wallets/credit/${creditWalletId}/transfers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(setIsFetchingPaymentWalletTransfers(false));
        dispatch(setFetchedPaymentWalletTransfers(res.data.data));
      })
      .catch((error) => {
        dispatch(setIsFetchingPaymentWalletTransfers(false));
        console.log(error);
      });
  };

export const fetchCreditWalletTransactions =
  (creditWalletId) => (dispatch, getState) => {
    const { token } = getState().user;
    dispatch(setIsFetchingCreditWalletTransactions(true));

    axios
      .get(
        APP.API_MAIN_URL +
          `/admin/wallets/credit/${creditWalletId}/transactions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(setIsFetchingCreditWalletTransactions(false));
        dispatch(setFetchedCreditWalletTransactions(res.data.data));
      })
      .catch((error) => {
        dispatch(setIsFetchingCreditWalletTransactions(false));
        console.log(error);
      });
  };

export const fetchPaymentWalletTransactions =
  (creditWalletId) => (dispatch, getState) => {
    const { token } = getState().user;
    dispatch(setIsFetchingPaymentWalletTransactions(true));

    axios
      .get(
        APP.API_MAIN_URL +
          `/admin/wallets/payout/${creditWalletId}/transactions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(setIsFetchingPaymentWalletTransactions(false));
        dispatch(setFetchedPaymentWalletTransactions(res.data.data));
      })
      .catch((error) => {
        dispatch(setIsFetchingPaymentWalletTransactions(false));
        console.log(error);
      });
  };

export const fetchMobileWithdraws =
  (paymentWalletId) => (dispatch, getState) => {
    const { token } = getState().user;

    dispatch(setIsFetchingMobileWithdraws(true));

    axios
      .get(
        APP.API_MAIN_URL +
          `/admin/wallets/payout/${paymentWalletId}/withdraws/mobile-withdraws`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(setIsFetchingMobileWithdraws(false));
        dispatch(setFetchedMobileWithdraws(res.data.data));
      })
      .catch((error) => {
        dispatch(setIsFetchingMobileWithdraws(false));
        console.log(error);
      });
  };

export const fetchBankWithdraws = (paymentWalletId) => (dispatch, getState) => {
  const { token } = getState().user;
  dispatch(setIsFetchingBankWithdraws(true));

  axios
    .get(
      APP.API_MAIN_URL +
        `/admin/wallets/payout/${paymentWalletId}/withdraws/bank-withdraws`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => {
      dispatch(setIsFetchingBankWithdraws(false));
      dispatch(setFetchedBankWithdraws(res.data.data));
    })
    .catch((error) => {
      dispatch(setIsFetchingBankWithdraws(false));
      console.log(error);
    });
};
