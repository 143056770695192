import React, { useEffect } from "react";
import { useState } from "react";

import Modal2 from "../../../components/modal/Modal2";

import { useSelector } from "react-redux";
import { GoBrowser } from "react-icons/go";
import { TbClockHour4 } from "react-icons/tb";
import { IoIosLink } from "react-icons/io";
import { ImPriceTags } from "react-icons/im";
import Button from "../../../components/Button";

import ModifyDownLoad from "./ModifyDownLoad";
import { APP } from "../../../utils/constant";

const ViewGenerated = ({ open, handleOpen, data }) => {
  const { token, profileId } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [openQr, setOpenQr] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  // convert date to readable format

  const date = new Date(data.created_at);
  const formattedDate = date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const chartData = [
    { day: "Monday", scans: 10 },
    { day: "Tuesday", scans: 15 },
    { day: "Wednesday", scans: 8 },
    { day: "Thursday", scans: 12 },
    { day: "Friday", scans: 16 },
    { day: "Saturday", scans: 20 },
    { day: "Sunday", scans: 10 },
  ];

  console.log("THIS IS GENERATED QR CODE", data);
  const handleOpenQr = () => {
    setOpenQr(!openQr);
  };

  // const getGeneratedHistory = () => {
  //   axios
  //     .get(
  //       `${APP.API_MAIN_URL}/advertiser/profile/${profileId}/custom-qr-codes/${data.qrId}/environments/${data.envId}/placements/${data.placementId}/generated-qr-code/${data.id}`,
  //       config(token)
  //     )
  //     .then((res) => {
  //       setHistoryData(res.data.data);
  //       console.log("Generated history", res.data.data);
  //     })
  //     .catch((err) => console.log("error in fetching generated history", err));
  // };
  // useEffect(() => {
  //   getGeneratedHistory();
  // }, [data]);
  return (
    <Modal2
      isOpen={open}
      onClose={() => handleOpen(!open)}
      Style={"w-full flex m-auto lg:w-[60%] lg:h-[70vh] md:px-3  "}
    >
      <Modal2.Header className={" sticky top-0 bg-[#f0fafc] z-10"}>
        <div className="font-bold ">Generated qr code</div>
      </Modal2.Header>
      <Modal2.Body className={"bg-[#f0fafc] h-full "}>
        <div className="w-full">
          <ModifyDownLoad handleOpen={handleOpenQr} open={openQr} data={data} />
          <div className="flex flex-col items-center md:flex-row w-full md:justify-between  md:items-center bg-white p-5 ">
            <div className="text-[#7F8386]">
              <div>
                <div className=" flex justify-between py-2 mb-5 font-bold border-b-2">
                  <div className="flex">
                    <span>Name</span>
                    <span className="ml-3 capitalize">{data.name}</span>
                  </div>
                  <span className="capitalize">{data.status}</span>
                </div>
                <div className="flex ">
                  <span className="">
                    <GoBrowser size={25} />
                  </span>
                  <a
                    href={`${APP.ADMIN_BASE_URL}/qr-code/c/${data.reference}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="text-xl  ml-3 text-blue-400">
                      {`${APP.ADMIN_BASE_URL}/qr-code/c/${data.reference}`}
                    </span>
                  </a>
                </div>
              </div>
              <div className="mt-5  flex items-center ">
                <TbClockHour4 />
                <span className="ml-5">{formattedDate}</span>
                <div className="flex items-center ml-10">
                  <IoIosLink /> <span className="ml-5">qrco.shortlink</span>
                </div>
              </div>
              <div className="mt-5  flex items-center ">
                <ImPriceTags />
                <span className="ml-5">{`${data.price} Rwf`}</span>
                <div className="flex items-center ml-10 ">
                  {/* <a
                    href={`${APP.API_ADVERTISER_URL}/advertiser/profile/${profileId}/files/${data?.uuid}/download`}
                  > */}
                  <Button
                    type="button"
                    content="Print"
                    Style={
                      " bg-white border border-[#7F8386] hover:bg-primary hover:text-white h-[40px] w-full mt-2 ml-0 outline-none focus:ring-inset transform active:scale-x-95 transition-transform rounded-[2px]"
                    }
                    onClick={() =>
                      // window.open(
                      //   `${APP.API_ADVERTISER_URL}/advertiser/profile/${profileId}/files/${data?.uuid}/download`,
                      //   "_blank"
                      // )
                      handleOpenQr()
                    }
                  />
                  {/* </a> */}
                </div>
              </div>
            </div>
            <div className="hover:shadow-md">
              <img src={data.file} alt="" srcset="" />
            </div>
          </div>
          <div className="bg-white mt-5 p-5">
            <h3 className="text-[#595858] font-bold uppercase">Total scan</h3>
            <span>{data.scan}</span>
          </div>
          {/* <div className="bg-white mt-5 p-5">
            <h3 className="text-[#595858] font-bold uppercase">
              Scans by operating system
            </h3>
            <div>
              <div className="grid grid-cols-3 text-[#595858] font-semibold">
                <div>
                  <h4>OS</h4>
                </div>
                <div>
                  <h4>Scans</h4>
                </div>
                <div>
                  <h4>%</h4>
                </div>
              </div>
              <div className="grid grid-cols-3 ">
                <div>
                  <span>Android</span>
                </div>
                <div>
                  <div className="h-5 w-[75.3%] bg-green-400 transition-all ease-out duration-1000"></div>
                </div>
                <div>
                  <span>75.3</span>
                </div>
              </div>
              <div className="grid grid-cols-3 ">
                <div>
                  <span>IOS</span>
                </div>
                <div>
                  <div className="h-5 w-[64.7%] bg-green-400"></div>
                </div>
                <div>
                  <span>64.7</span>
                </div>
              </div>
              <div className="grid grid-cols-3 ">
                <div>
                  <span>Windows</span>
                </div>
                <div>
                  <div className="h-5 w-[40%] bg-green-400"></div>
                </div>
                <div>
                  <span>40</span>
                </div>
              </div>
              <div className="grid grid-cols-3 ">
                <div>
                  <span>ChromeOS</span>
                </div>
                <div>
                  <div className="h-5 w-[30.7%] bg-green-400"></div>
                </div>
                <div>
                  <span>30.7</span>
                </div>
              </div>

              <div className="grid grid-cols-3 ">
                <div>
                  <span>Apple</span>
                </div>
                <div>
                  <div className="h-5 w-[1%] bg-green-400"></div>
                </div>
                <div>
                  <span>1</span>
                </div>
              </div>

              <div className="grid grid-cols-3 ">
                <div>
                  <span>Mac</span>
                </div>
                <div>
                  <div className="h-5 w-[5%] bg-green-400"></div>
                </div>
                <div>
                  <span>5</span>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="bg-white mt-5 p-5">
            <h3 className="text-[#595858] font-bold uppercase">
              Scans over time
            </h3>
            <div className=""> <BarChart data={chartData} /> </div>
            <div>
              <div class="mx-auto w-11/12 overflow-hidden md:w-3/5">
                <canvas
                  data-te-chart="bar"
                  data-te-dataset-label="Traffic"
                  data-te-labels="['Monday', 'Tuesday' , 'Wednesday' , 'Thursday' , 'Friday' , 'Saturday' , 'Sunday ']"
                  data-te-dataset-data="[2112, 2343, 2545, 3423, 2365, 1985, 987]"
                ></canvas>
              </div>
            </div>
          </div> */}
        </div>
      </Modal2.Body>
    </Modal2>
  );
};

export default ViewGenerated;
