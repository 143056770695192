import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from '../../../components/modal/Modal';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { APP } from '../../../utils/constant';
import Table from '../../../components/tableComponent/Table';
import SkeletonTable from '../../../components/SkeletonTable';
import { skeletonData, skeletonColumns } from '../../../seeds/Data';
import { useSelector, useDispatch } from 'react-redux';

import PaymentDetails from './PaymentDetails';

const BankAccountPayment = ({
  openModal,
  handleOpenModal,
  bankId,
  bankAccountId,
}) => {
  const dispatch = useDispatch();
  const { is_fetching_bank_account_payment, bank_account_payment } =
    useSelector((state) => state.bank);

  const [detailsModal, setDetailsModal] = useState(false);
  const [paymentDetailsData, setPaymentDetailsData] = useState({
    reference: '',
    created_at: '',
    total_amount: '',
    status: '',
    processed_by: '',
    payment_slip: '',
  });

  const columns = [
    {
      Header: 'Reference',
      accessor: 'reference',
    },
    {
      Header: 'Date',
      accessor: '',
      Cell: ({ row }) => {
        return <div>{row.original.created_at.split('T')[0]}</div>;
      },
    },
    {
      Header: 'Total Amount',
      accessor: 'total_amount',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Processed By',
      accessor: '',
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.processed_by ? row.original.processed_by : 'Not Yet'}
          </div>
        );
      },
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  font-bold">
            <div
              className="flex items-center bg-black rounded-md w-fit cursor-pointer px-2 py-1"
              onClick={() => {
                setPaymentDetailsData({
                  id: row.original.id,
                  reference: row.original.reference,
                  created_at: row.original.created_at,
                  total_amount: row.original.total_amount,
                  status: row.original.status,
                  processed_by: row.original.processed_by,
                  payment_slip: '',
                });
                setDetailsModal(true);
              }}
            >
              <h1
                className="
          font-bold text-white"
              >
                Details
              </h1>
            </div>
          </div>
        );
      },
    },
  ];

  const handleOpenDetailsModal = () => {
    setDetailsModal(!detailsModal);
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        onClose={() => {
          handleOpenModal();
        }}
        Style={'w-[90%] lg:w-5/6  h-[90%] flex m-auto py-4'}
      >
        <Modal.Header>
          <h1 className="font-bold">Bank Account Payments</h1>
        </Modal.Header>

        <Modal.Body>
          <div className="flex flex-col items-center justify-center">
            {!is_fetching_bank_account_payment ? (
              bank_account_payment?.length === 0 ? (
                <p className="text-[20px] text-[#808080] font-bold flex justify-center items-center">
                  No recent bank payment done on this account yet.
                </p>
              ) : (
                <Table
                  columns={columns}
                  data={bank_account_payment ? bank_account_payment : []}
                />
              )
            ) : (
              <SkeletonTable columns={skeletonColumns} data={skeletonData} />
            )}
          </div>
        </Modal.Body>
      </Modal>

      <PaymentDetails
        paymentDetailsData={paymentDetailsData}
        openModal={detailsModal}
        handleOpenModal={handleOpenDetailsModal}
        bankId={bankId}
        bankAccountId={bankAccountId}
      />
    </>
  );
};

export default BankAccountPayment;
