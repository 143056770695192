import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { BsBell } from 'react-icons/bs';
import Notification from '../modals/Notification';

const TopBar = ({
  responsiveSideBar,
  setShowSidebar,
  toggleNotification,
  showNotification,
}) => {  
  return (
    <>
        <Notification
          toggleNotification={toggleNotification}
          showNotification={showNotification}
        />
      <div className="top-0 z-10  bg-white flex flex-row justify-between items-center h-[64px] shadow-md topbar font-nunito px-8">
        <div className="flex flex-row gap-8 items-center">
          <GiHamburgerMenu
            className="cursor-pointer lg:hidden"
            onClick={() => {
              responsiveSideBar() && setShowSidebar(true);
            }}
          />
        </div>
        <div className="flex items-center gap-4">
          <BsBell
            size={20}
            className="cursor-pointer"
            onClick={() => {
              toggleNotification();
            }}
          />
        </div>
      </div>
      
    </>
  );
};

export default TopBar;
