import React, { useEffect, useState } from "react";
import Modal from "../../../../components/modal/Modal";
import { RxDotFilled } from "react-icons/rx";
import { FcApproval, FcCancel } from "react-icons/fc";
import { BsFillDiamondFill } from "react-icons/bs";
import axios from "axios";
import { APP } from "../../../../utils/constant";
import { useSelector } from "react-redux";
import TextArea from "../../../../components/TextArea";
import { useForm } from "react-hook-form";
import Button from "../../../../components/Button";
import Modal2 from "../../../../components/modal/Modal2";

function ViewContent({
  handleViewContent,
  openViewContent,
  contentToView,
  notify,
  selectedLanguage,
  allLanguage,
}) {
  const { token } = useSelector((state) => state.user);
  const [requests, setRequests] = useState([]);
  const [reason, setReason] = useState({});
  const [isReasonVisible, setReasonVisible] = useState(false);
  const [approving, setApproving] = useState(false);
  const getRequests = async () => {
    await axios
      .get(
        APP.API_MAIN_URL +
          `/admin/vas/categories/${contentToView?.article_category_id}/articles/${contentToView?.id}/requests/`
      )
      .then((res) => {
        setRequests(res.data.data);
      })
      .catch((error) => console.log("error in fetching requests", error));
  };

  useEffect(() => {
    getRequests();
  }, [contentToView?.id]);

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const handleApprove = async (id) => {
    setApproving(true);
    await axios
      .put(
        APP.API_MAIN_URL +
          `/admin/vas/categories/${contentToView.article_category_id}/articles/${contentToView.id}/requests/${id}/approve`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setApproving(false);
        getRequests();
        notify("success", "Request Approved");
      })
      .catch((error) => {
        setApproving(false);
        notify("error", error.response.data.message);
      });
  };

  const handleReject = () => {
    setReasonVisible(!isReasonVisible);
    //
  };
  const handleSubmit = async (requestId) => {
    await axios
      .put(
        APP.API_MAIN_URL +
          "/admin/vas/categories/" +
          contentToView.article_category_id +
          "/articles/" +
          contentToView.id +
          "/requests/" +
          requestId +
          "/reject",
        { reason },
        config
      )
      .then((res) => {
        getRequests();
        notify("success", "Request rejected!");
        setReason("");
        handleReject();
      })
      .catch((error) => {
        notify("error", error?.response?.data?.message);
        console.log("Error in rejectiing post", error);
      });
  };

  const {
    register,
    clearErrors,
    formState: { errors },
    reset,
    control,
  } = useForm();

  return (
    <div>
      <Modal2
        isOpen={openViewContent}
        onClose={() => handleViewContent()}
        Style={"w-fit md:w-1/2 lg:1/2  h-fit relative flex m-auto py-4 "}
      >
        <Modal2.Header>
          <div className="flex items-center">
            <h1 className="font-nunito text-[18px] capitalize md:text-[26px] font-[900] md:ml-10">
              {selectedLanguage ? contentToView?.title[selectedLanguage] : ""}
            </h1>
          </div>
        </Modal2.Header>
        <Modal2.Body>
          <div className=" px-4 flex flex-col-reverse  md:flex-row">
            {contentToView && (
              <div className="md:border-r-2 border-[#BDBDBD] md:w-[60%] md:mr-10 ">
                <div className="flex justify-end pr-10 pl-6">
                  <h1 className="text-[18px] font-bold text-[#989797] capitalize">
                    {contentToView?.category?.name[selectedLanguage] ? (
                      contentToView?.category?.name[selectedLanguage]
                    ) : (
                      <h1 className="text-sm line-through">
                        No
                        {
                          allLanguage?.find(
                            (lan) => lan?.code === selectedLanguage
                          )?.name
                        }
                        Version available
                      </h1>
                    )}
                  </h1>
                </div>

                <div className="grid grid-cols-1 grid-rows-1  md:grid-rows-1 ml-5 mr-10 pb-6 ">
                  <div className="relative px-2 rounded-[5px] py-2 shadow-md">
                    <h1 className="font-semibold absolute right-0 pr-5 text-amber-700">
                      {selectedLanguage}
                    </h1>
                    <div className="flex flex-col">
                      <span className="capitalize font-bold">
                        {contentToView?.title[selectedLanguage]}
                      </span>

                      <span className="normal-case py-2">
                        {contentToView?.body[selectedLanguage]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-col md:w-[47%]">
              {requests.length > 0 ? (
                requests.map((req, i) => (
                  <div
                    key={i}
                    className="border rounded-lg border-[#BDBDBD] px-2 py-2 shadow-[-1px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024,0px_3px_8px_0px_#00000024]"
                  >
                    <div className="flex justify-between items-center  py-4 border-b-2 border-[#BDBDBD]">
                      <h1 className="text-[18px] font-semibold">
                        {`Request ${i + 1}`}
                      </h1>
                      <div className="flex items-center pr-5">
                        <RxDotFilled size={40} className="text-green-500" />
                        <span className="capitalize text-[18px]">
                          {req.status}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col  ">
                      {req.reason && (
                        <div className="flex flex-col items-center text-[16px] ]">
                          <span className="font-semibold underline">
                            Reason:
                          </span>
                          <span className="ml-2 bg-[#e3e3e3] px-2 p-1 mt-1 w-full rounded-md capitalize text-justify shadow-[inset_-1px_3px_8px_0px_#00000024,inset_0px_1px_8px_0px_#00000024,inset_0px_-3px_8px_0px_#00000024]">
                            {req.reason}
                          </span>
                        </div>
                      )}
                      <div>
                        {req.status === "pending" && (
                          <>
                            <div className="flex text-[18px] justify-between my-4 border-b-2 border-[#e2e2e2] opacity-90 pb-4 ">
                              <span
                                className="flex items-center pr-3 py-1 border-b-2 cursor-pointer border-[#8BC34A] hover:bg-[#fff] hover:opacity-60 rounded-3xl border-l-4 "
                                onClick={() => handleApprove(req.id)}
                              >
                                <FcApproval className="mr-4" />
                                {approving ? "waiting..." : "Approve"}
                              </span>
                              <span
                                className=" opacity-90   ml-6 flex items-center border-l-4 border-b-2 border-[#cc2a09] pr-4 cursor-pointer hover:opacity-60 rounded-3xl"
                                onClick={() => handleReject()}
                              >
                                <FcCancel className="mr-4" />
                                Reject
                              </span>
                            </div>

                            {isReasonVisible && (
                              <div
                                className={`transition-transform duration-300 ${
                                  isReasonVisible
                                    ? "scale-100 opacity-100"
                                    : "scale-0 opacity-0 transform scale-y-0"
                                }`}
                              >
                                <TextArea
                                  labelText={"Reason"}
                                  labelFor={"reason"}
                                  isRequired={true}
                                  name={`reason ${i}`}
                                  placeholder={"Enter reason for rejection"}
                                  customClass="w-full mb-3 "
                                  inputStyle="text-[18px] overflow-hidden"
                                  register={register}
                                  errors={errors}
                                  onChange={(e) => {
                                    setReason(e.target.value);
                                  }}
                                />
                                <Button
                                  type="submit"
                                  content={"Submit"}
                                  loading={false}
                                  onClick={() => handleSubmit(req.id)}
                                  btnColor="primary"
                                  Style={
                                    "mr-3 text-[16px] text-white w-full py-2 mb-4  "
                                  }
                                />
                              </div>
                            )}
                          </>
                        )}

                        {req.processed_by ? (
                          <div
                            className={`py-1  flex h-10 relative ${
                              isReasonVisible ? "border-t-2" : ""
                            } `}
                          >
                            <span className="font-bold text-[16px] opacity-70">
                              Verified by
                            </span>
                            <span className=" flex items-center text-[18px] absolute bottom-0 right-0 text-[#504f4f]">
                              <RxDotFilled
                                className="text-[#828181]"
                                size={16}
                              />
                              {req?.processed_by?.first_name +
                                " " +
                                req?.processed_by?.last_name}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="py-1  flex h-10 relative">
                          <span className="font-bold text-[16px] capitalize opacity-70">
                            requested by
                          </span>
                          <span className="flex items-center text-[18px] capitalize absolute right-0 text-[#504f4f]">
                            <RxDotFilled className="text-[#828181]" size={16} />
                            {req.user.first_name + " " + req.user?.last_name}
                          </span>
                        </div>
                        <div className="italic flex items-center justify-between font-extralight border-t mt-3 border-[#e3e3e3] ">
                          <i>Requested on</i>
                          <BsFillDiamondFill
                            className="text-[#828181] mx-4"
                            size={5}
                          />
                          <span className="text-[#5a5555]">
                            {
                              req.created_at.split("T")[0]
                              //  +
                              // " " +
                              // req.created_at.split("T")[1].split(".")[0]
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-18px font-[900] text-[#606060] mt-32">
                  No Request Found
                </div>
              )}
            </div>
          </div>
        </Modal2.Body>
      </Modal2>
    </div>
  );
}

export default ViewContent;
