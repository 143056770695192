import React, { Fragment, useEffect } from "react";
import Table from "../components/tableComponent/Table";
import { Popover, Transition } from "@headlessui/react";
import { FiMoreVertical } from "react-icons/fi";
import Button from "../components/Button";
import NewCreateUser from "./modals/NewCreateUser";
import Modal from "../components/modal/Modal";
import { useForm } from "react-hook-form";
import Select from "../components/Select";
import Axios from "../components/Axios";
import { TailSpin } from "react-loader-spinner";
import { ToastComponent, ToasterComponent } from "../components/Toast";
import { act } from "react-dom/test-utils";
import axios from "axios";
import { APP } from "../utils/constant";

const SysUsers = ({
  options,
  profile,
  toggleUsers,
  userFetching,
  config,
  SkeletonTable,
  skeletonData,
  skeletonColumns,
}) => {
  const [data, setData] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [approve, setApprove] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [userId, setUserId] = React.useState();
  const [sysUser, setSysUser] = React.useState([]);
  const [activate, setActivate] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const getSystemUsers = () => {
    axios
      .get(APP.API_MAIN_URL + `/admin/users/system`, config)
      .then((res) => {
        console.log(res?.data?.data, "system users");
        setSysUser(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const activateUser = (id) => {
    setActivate(true);
    axios
      .put(APP.API_MAIN_URL + `/admin/users/system/${id}/activate`, {}, config)
      .then((res) => {
        setActivate(false);
        setToggle(!toggle);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const disactivateUser = (id) => {
    setActivate(true);
    Axios.put(
      APP.API_MAIN_URL + `/admin/users/system/${id}/deactivate`,
      {},
      config
    )
      .then((res) => {
        setActivate(false);
        console.log(res.data);
        setToggle(!toggle);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteSystemUser = (id) => {
    axios
      .delete(APP.API_MAIN_URL + `/admin/users/system/${id}`, config)
      .then((res) => {
        console.log(res, "delete");
        setToggle(!toggle);
        notify("success", "User deleted successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = () => {
    setLoading(true);
    try {
      Axios.post(
        APP.ADVERTISER_API_URL + `/admin/confirm/user/${userId}`,
        {},
        config
      )
        .then((res) => {
          setLoading(false);
          setApprove(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const confirmUpdate = (data) => {
    console.log(data, "qery");
    setLoading(true);
    Axios.put(
      APP.ADVERTISER_API_URL + `/admin/update/user`,
      { ...data, perm: data?.perm?.value, id: userId },
      config
    )
      .then((res) => {
        setLoading(false);
        setEdit(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      Header: "First name",
      accessor: "first_name",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  w-max">
            <h1 className="font-bold">{row.original.first_name}</h1>
          </div>
        );
      },
    },
    {
      Header: "Last name",
      accessor: "last_name",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  w-max">
            <h1 className="font-bold">{row.original.last_name}</h1>
          </div>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  w-max">
            <h1 className="font-bold">{row.original.email}</h1>
          </div>
        );
      },
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  w-max">
            <h1 className="font-bold">{row.original.phone}</h1>
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        return (
          <div
            className={`flex flex-row  w-full p-2 items-center justify-center bg-opacity-20 ${
              row?.original?.status === "active" ? "bg-success" : "bg-error"
            }`}
          >
            <h1 className="font-bold">{row.original.status}</h1>
          </div>
        );
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  font-bold">
            <Popover className="relative">
              <Popover.Button>
                <div className="flex">
                  <FiMoreVertical size={30} className="cursor-pointer" />
                </div>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 font-bold">
                  <div className="flex flex-col bg-white  shadow-md border border-black/10  rounded-sm text-xs w-max">
                    {row?.original?.status === "active" ? (
                      <div
                        className="px-4 py-1 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                        onClick={() => {
                          disactivateUser(row?.original?.id);
                        }}
                      >
                        dis-activate
                      </div>
                    ) : (
                      <div
                        className="px-4 py-1 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                        onClick={() => {
                          activateUser(row?.original?.id);
                        }}
                      >
                        activate
                      </div>
                    )}
                    <div
                      className="px-4 py-1 w-full text-error hover:bg-[#D9D9D9] text-center cursor-pointer"
                      onClick={() => {
                        deleteSystemUser(row?.original?.id);
                      }}
                    >
                      delete
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getSystemUsers();
  }, [toggle]);

  return (
    <div className="content p-4 over h-full overflow-y-scroll">
      <ToasterComponent />
      <NewCreateUser open={open} setOpen={setOpen} />
      <Modal
        isOpen={approve}
        onClose={() => setApprove(!approve)}
        Style={"lg:w-1/4 w-3/4 m-auto h-max flex z-[10]"}
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col space-y-4"
          >
            <div>Confirm approval of the user</div>
            <div className="flex flex-row justify-end">
              <Button
                content="Confirm"
                type="submit"
                btnColor="black"
                Style="text-white w-max ml-0"
                loading={loading}
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/*modal edit permission*/}
      <Modal
        isOpen={edit}
        onClose={() => setEdit(!edit)}
        Style={"lg:w-1/4 w-3/4 m-auto h-max flex z-[10]"}
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(confirmUpdate)}
            className="flex flex-col space-y-4"
          >
            <div>
              <Select
                className="w-full mt-0"
                placeholder={"Select permission"}
                label="Set permission"
                register={{
                  control,
                  name: "perm",
                  rules: { required: "permission is required" },
                }}
                error={errors["perm"]?.message}
              />
            </div>
            <div className="flex flex-row justify-end">
              <Button
                content="Update"
                type="submit"
                btnColor="black"
                Style="text-white w-max ml-0"
                loading={loading}
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/*end of modal*/}

      <div className="w-full mx-auto flex top-0 h-max items-center justify-end m-8 pr-4">
        <Button
          type="button"
          content="Add User"
          loading={false}
          btnColor="black"
          Style={`text-white w-fit h-[40px] mt-8 ml-0 border border-primary`}
          onClick={() => setOpen(!open)}
        />
      </div>
      {userFetching ? (
        <SkeletonTable columns={skeletonColumns} data={skeletonData} />
      ) : (
        <div>
          <Table columns={columns} data={sysUser} />
        </div>
      )}
    </div>
  );
};

export default SysUsers;
