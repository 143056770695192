import React, { useState } from 'react';
import Modal from '../../../../components/modal/Modal';
import axios from 'axios';
import { ToastComponent, ToasterComponent } from '../../../../components/Toast';
import TextArea from '../../../../components/TextArea';
import { APP } from '../../../../utils/constant';
import Button from '../../../../components/Button';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCreditWallets } from '../../../../redux/actions/wallet';

const DeactivateCreditWallet = ({
  openModal,
  handleOpenModal,
  creditWalletId,
  status,
}) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const [deactivateData, setDeactivateData] = useState({
    reason: '',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const onSubmit = () => {
    if (status === 'active') {
      setLoading(true);
      axios
        .put(
          APP.API_MAIN_URL + `/admin/wallets/credit/${creditWalletId}`,
          {
            status: 'inactive',
            reason: deactivateData.reason,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => {
          setLoading(false);
          setDeactivateData({
            reason: '',
          });
          dispatch(fetchCreditWallets());
          handleOpenModal();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          notify('error', error.response.data.message);
        });
    } else if (status === 'inactive') {
      setLoading(true);
      axios
        .put(
          APP.API_MAIN_URL + `/admin/wallets/credit/${creditWalletId}`,
          {
            status: 'active',
            reason: '',
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => {
          setLoading(false);
          setDeactivateData({
            reason: '',
          });
          dispatch(fetchCreditWallets());
          handleOpenModal();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          notify('error', error.response.data.message);
        });
    }
  };

  return (
    <Modal
      isOpen={openModal}
      onClose={handleOpenModal}
      Style={'w-[90%] lg:w-1/3  flex m-auto py-4'}
    >
      <Modal.Header>
        <h1 className="font-bold">Deactivate Credit Wallet</h1>
      </Modal.Header>
      <Modal.Body>
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          {status === 'active' ? (
            <TextArea
              labelText={'Reason'}
              labelFor={'Reason'}
              name={'Reason'}
              type={'text'}
              isRequired={true}
              placeholder={'Enter reason ...'}
              customClass="w-full mb-2"
              inputStyle={'h-[80px]'}
              value={deactivateData.reason}
              onChange={(e) => {
                setDeactivateData((prevData) => {
                  return {
                    ...prevData,
                    reason: e.target.value,
                  };
                });
              }}
              register={register}
              errors={errors}
            />
          ) : (
            <h1 className="text-center">
              Click Activate to activate this account
            </h1>
          )}

          <Button
            type="submit"
            content={status === 'active' ? 'Deactivate' : 'Activate'}
            loading={loading}
            btnColor={status === 'active' ? 'error' : 'primary'}
            Style={`text-white w-fit h-[40px] mt-8 ml-0 font-bold self-end`}
          />
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default DeactivateCreditWallet;
