import React, { useState, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { Test } from "./Test";
import Test2 from "./Test2";
import AdvertiserRoutes from "./Routes/AdvertiserRoutes";
import ProtectedRoutes from "./Routes/ProtectedRoutes/ProtectedRoutes";
import { APP } from "./utils/constant";
import Auth from "./components/auth/Auth";
import { ToasterComponent } from "./components/Toast";
import QrView from "./pages/modals/QrView";

function App() {
  return (
    <Router>
      <ToasterComponent />
      <Routes>
        <Route path="/auth/:token" element={<Auth />} />
        <Route
          path="/*"
          element={
            <ProtectedRoutes redirectTo={APP.ACCOUNT_URL}>
              <AdvertiserRoutes />
            </ProtectedRoutes>
          }
        />
        <Route path="/qr-code/c/:reference" element={<QrView />} />
        <Route path="/test2" element={<Test2 />} />
        <Route path="/test" element={<Test />} />
      </Routes>
    </Router>
  );
}

export default App;
