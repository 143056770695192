import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import TopBar from "../components/TopBar";
import SideBar from "../components/SideBar";
import Dashboard from "../pages/Dashboard";
import Advertisers from "../pages/Advertisers";
import Campaigns from "../pages/Campaigns.jsx";
import Resources from "../pages/Resources";
import Notifications from "../pages/Notifications";
import Axios from "../components/Axios";
import SysUsers from "../pages/SysUsers";
import { NotificationsData } from "../seeds/Data";
import SkeletonTable from "../components/SkeletonTable";
import { skeletonData, skeletonColumns } from "../seeds/Data";
import { APP } from "../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import Articles from "../pages/resourcesItems/Articles/Articles";
import BankInformation from "../pages/resourcesItems/BankInformation";
import QrCode from "../pages/resourcesItems/QrCode";
import Remember from "../pages/vas/Remember";
import SenderName from "../pages/resourcesItems/SenderName";
import Blast from "../pages/Blast";
import { resetUser, setUserDetails } from "../redux/actions/user.js";
import { useLogout } from "../utils/logout.js";

import PlanCategory from "../pages/vas/subscription/PlanCategory.jsx";

const AdvertiserRoutes = () => {
  const { token, userDetails } = useSelector((state) => state.user);
  const [activePage, setActivePage] = useState("Home");

  const [toggleCreateAd, setToggleCreateAd] = useState(false);
  const [responsive, setResponsive] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [hideSideBar, setHideSideBar] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [audience, setAudience] = useState([]);
  const [toggleCampaigns, setToggleCampaigns] = useState(false);
  const [options, setOptions] = React.useState([]);
  const [toggle, setToggle] = React.useState(false);
  const [profile, setProfile] = React.useState({});
  const [toggleItem, setToggleItem] = useState(false);
  const [campaignFetching, setCampaignFetching] = useState(false);
  const [toggleUsers, setToggleUsers] = useState(false);
  const [userFetching, setUserFetching] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [approvalNotifications, setApprovalNotifications] = useState([]);
  const [paymentNotifications, setPaymentNotifications] = useState([]);
  const [supportNotifications, setSupportNotifications] = useState([]);
  const [infoNotifications, setInfoNotifications] = useState([]);
  const [othersNotifications, setOthersNotifications] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [notificationLoading, setNotificationLoading] = useState(false);

  const loginStatus = localStorage.getItem("isLoggedIn");
  const navigate = useNavigate();

  const logout = useLogout();
  const loggedInUser = userDetails;
  const toggleNotification = () => {
    setShowNotification(!showNotification);
  };
  const responsiveSideBar = () => {
    setResponsive(!responsive);
  };
  useEffect(() => {
    getLoggedInUserProfile();
  }, []);
  // function to sort data to get newly created data
  const getLatest = (a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA; // Sort in descending order of creation date
  };

  // config

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const hasDesiredRole = () => {
    return userDetails.user_roles.some((role) =>
      [1, 2, 3].includes(role.role_id)
    );
  };

  //get permissions

  const getPermission = () => {
    try {
      Axios.get(APP.ADVERTISER_API_URL + "/admin/get/permissions", config)
        .then((res) => {
          navigate(`${APP.ACCOUNT_URL}`);
          setOptions(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  // get campaigns

  const getCampaigns = async () => {
    setCampaignFetching(true);
    try {
      await Axios.get(APP.API_MAIN_URL + "/admin/campaigns", config)
        .then((res) => {
          setCampaigns(res.data.data.sort(getLatest));
          setCampaignFetching(false);
        })
        .catch((err) => {});
    } catch (error) {
      console.log(error);
    }
  };

  const getAudience = async () => {
    try {
      const response = await Axios.get(
        APP.ADVERTISER_API_URL + "/admin/get/audience",
        config
      );
      setAudience(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLoggedInUserProfile = () => {
    try {
      Axios.get("https://api.account.konvey.rw/api/auth/me", config)
        .then((res) => {
          setProfile(res.data);
          console.log(res.data, "logged in useer");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  const getFeedbacks = () => {
    Axios.get(APP.API_MAIN_URL + "/admin/feedbacks", config)
      .then((res) => {
        setFeedbacks(res.data.data);
        //console.log(res.data, 'logged in useer')
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNotifications = () => {
    setNotificationLoading(true);
    Axios.get(APP.API_MAIN_URL + "/utils/admin/notifications", config)
      .then((res) => {
        setNotifications(res.data.data.sort(getLatest));
        res.data.data
          .sort(getLatest)
          .sort((a, b) => b.created_at - a.created_at)
          .forEach((notification) => {
            switch (notification.notification_category.name) {
              case "approvals":
                setApprovalNotifications((prevData) => {
                  const existingNotification = prevData.find(
                    (data) => data.id === notification.id
                  );

                  if (existingNotification) {
                    return prevData; // Notification with the same ID already exists, no need to update the array
                  } else {
                    return [...prevData, notification]; // Add the new notification to the array
                  }
                });
                // approval.push(notification);
                break;
              case "payment":
                setPaymentNotifications((prevData) => {
                  const existingNotification = prevData.find(
                    (data) => data.id === notification.id
                  );

                  if (existingNotification) {
                    return prevData; // Notification with the same ID already exists, no need to update the array
                  } else {
                    return [...prevData, notification]; // Add the new notification to the array
                  }
                });
                break;
              case "support":
                setSupportNotifications((prevData) => {
                  const existingNotification = prevData.find(
                    (data) => data.id === notification.id
                  );

                  if (existingNotification) {
                    return prevData; // Notification with the same ID already exists, no need to update the array
                  } else {
                    return [...prevData, notification]; // Add the new notification to the array
                  }
                });
                break;
              case "info":
                setInfoNotifications((prevData) => {
                  const existingNotification = prevData.find(
                    (data) => data.id === notification.id
                  );

                  if (existingNotification) {
                    return prevData; // Notification with the same ID already exists, no need to update the array
                  } else {
                    return [...prevData, notification]; // Add the new notification to the array
                  }
                });
                break;
              default:
                setOthersNotifications((prevData) => {
                  const existingNotification = prevData.find(
                    (data) => data.id === notification.id
                  );

                  if (existingNotification) {
                    return prevData; // Notification with the same ID already exists, no need to update the array
                  } else {
                    return [...prevData, notification]; // Add the new notification to the array
                  }
                });
            }
          });
        setNotificationLoading(false);
      })
      .catch((err) => {
        setNotificationLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (!token) {
      window.location.replace(APP.ACCOUNT_URL);
    } else if (token && !hasDesiredRole()) {
      logout();
    }
  }, [token]);

  useEffect(() => {
    getCampaigns();
  }, [toggleCampaigns]);

  useEffect(() => {
    getAudience();
  }, [toggleUsers]);

  useEffect(() => {
    getFeedbacks();
  }, []);

  useEffect(() => {
    getNotifications();
  }, []);

  // Filter and categorize the notifications
  const categorizeNotifications = () => {};

  useEffect(() => {
    categorizeNotifications();
  }, []);

  useEffect(() => {
    getPermission();
  }, [toggle]);

  return (
    <>
      <TopBar
        activePage={activePage}
        toggleCreateAd={toggleCreateAd}
        setToggleCreateAd={() => setToggleCreateAd(!toggleCreateAd)}
        responsiveSideBar={responsiveSideBar}
        setShowSidebar={setShowSidebar}
        showNotification={showNotification}
        toggleNotification={toggleNotification}
      />
      <SideBar
        logoutModal={logoutModal}
        setLogoutModal={() => setLogoutModal(!logoutModal)}
        responsive={responsive}
        responsiveSideBar={responsiveSideBar}
        hideSideBar={hideSideBar}
        setHideSideBar={setHideSideBar}
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        toggleItem={toggleItem}
        setToggleItem={setToggleItem}
        profile={profile}
        loggedInUser={loggedInUser}
      />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route
          path="/advertisers"
          element={
            <Advertisers
              audience={audience}
              userFetching={userFetching}
              config={config}
            />
          }
        />
        <Route
          path="/engagement/campaigns"
          element={
            <Campaigns
              campaigns={campaigns}
              toggleCampaigns={toggleCampaigns}
              setToggleCampaigns={setToggleCampaigns}
              profile={profile}
              campaignFetching={campaignFetching}
              config={config}
              role={loggedInUser ? loggedInUser?.user_roles[0]?.role_id : null}
            />
          }
        />
        <Route
          path="/engagement/blast"
          element={
            <Blast
              campaigns={campaigns}
              toggleCampaigns={toggleCampaigns}
              setToggleCampaigns={setToggleCampaigns}
              profile={profile}
              campaignFetching={campaignFetching}
              config={config}
              role={loggedInUser ? loggedInUser?.user_roles[0]?.role_id : null}
            />
          }
        />
        <Route
          path="/resources/"
          element={
            <Resources
              toggleItem={toggleItem}
              setToggleItem={setToggleItem}
              config={config}
              SkeletonTable={SkeletonTable}
              skeletonData={skeletonData}
              skeletonColumns={skeletonColumns}
              feedbacks={feedbacks}
            />
          }
        />
        <Route path="/vas/content" element={<Articles config={config} />} />
        <Route path="/vas/plan" element={<PlanCategory />} />
        <Route path="/vas/remember" element={<Remember config={config} />} />
        <Route
          path="/vas/content/:catId"
          element={<Articles config={config} />}
        />
        <Route
          path="/vas/content/:catId/view"
          element={<Articles config={config} />}
        />

        <Route
          path="/notifications"
          element={
            <Notifications
              notificationLoading={notificationLoading}
              approval={approvalNotifications}
              payment={paymentNotifications}
              support={supportNotifications}
              info={infoNotifications}
            />
          }
        />
        <Route
          path="/sysusers"
          element={
            <SysUsers
              options={options}
              profile={profile}
              setToggleUsers={setToggleUsers}
              toggleUsers={toggleUsers}
              userFetching={userFetching}
              config={config}
              SkeletonTable={SkeletonTable}
              skeletonData={skeletonData}
              skeletonColumns={skeletonColumns}
            />
          }
        />
      </Routes>
    </>
  );
};

export default AdvertiserRoutes;
