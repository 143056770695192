import React from 'react';
import parse from 'html-react-parser';

const EmailDetails = ({ data, highlightedDays, channelValue }) => {
  return (
    <div className="flex flex-col border border-gray rounded-lg p-2 mb-4 text-base">
      <div className="flex flex-row items-center gap-2 mb-4">
        <h1 className="font-bold text-base">Email</h1>
      </div>
      <div className="flex flex-row  mt-1 gap-1 items-center">
        <h1 className="text-light  font-bold  w-fit m-0 p-0">sender name</h1>
        <h2 className="text-black  m-0 p-0">{data[0]?.sender_name?.name}</h2>
      </div>

      {/* <div
        className={`${
          item?.is_app_installation ? 'flex' : 'hidden'
        } flex-col  gap-1  mt-2`}
      >
        {item?.app_installation.app_details?.map((app, i) => {
          return (
            <div className="flex flex-row gap-2">
              <div className="flex flex-row ">
                <h1 className="text-light  font-bold  w-fit ">App name:</h1>
                <h2 className="text-black  ">{app.name} ,</h2>
              </div>
              <div className="flex flex-row ">
                <h1 className="text-light  font-bold  w-fit ">App link:</h1>
                <h2 className="text-black  ">{app.link}.</h2>
              </div>
            </div>
          );
        })}
      </div> */}
      <div className={`flex flex-row  gap-1 mt-2`}>
        <h1 className="text-light  font-bold  w-fit">Subject:</h1>
        <h2 className="text-black  ">{data[0]?.subject}</h2>
      </div>

      <div className="flex flex-row  gap-1 items-center">
        <h1 className="text-light  font-bold  w-fit my-2">Target audience:</h1>
        {data[0]?.audiences?.map((audience, i) => {
          return (
            <h2 className="flex text-black  m-0 p-0 gap-1">
              {audience.audience?.name}
              <span className="text-light ">
                {data[0]?.audiences?.length - 1 === i ? '.' : ','}
              </span>
            </h2>
          );
        })}
      </div>
      <div>
        <div className="flex flex-col  gap-1 items-start space-y-4 h-max w-full">
          <h1>Email content</h1>
          {data[0]?.body !== null ? (
            <div className="border p-12 m-0 rounded-lg bg-secondary w-full flex flex-row flex-wrap break-words h-max">
              {parse(data[0]?.body)}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>

      {/* <div
        className={` ${item?.is_scheduled ? 'hidden' : 'flex'} flex-row gap-8`}
      >
        <div className="flex flex-row  gap-1 items-center">
          <h1 className="text-light  font-bold  w-fit my-2">From:</h1>
          <h2 className="text-black  "> {item?.from}</h2>
        </div>
        <div className="flex flex-row  gap-1 items-center">
          <h1 className="text-light  font-bold  w-fit my-2">To:</h1>
          <h2 className="text-black  "> {item?.to}</h2>
        </div>
      </div> */}

      <div className={` flex flex-col`}>
        <div className="flex flex-row  gap-1 items-center">
          <h1 className="text-light  font-bold  w-fit my-2">Schedule type:</h1>

          <h2 className="text-black  "> {data[0].schedule_type}</h2>
        </div>

        <div className="flex flex-row gap-8">
          <div className="flex flex-row  gap-1 items-center">
            <h1 className="text-light  font-bold  w-fit my-2">From:</h1>
            <h2 className="text-black  "> {data[0].start_date}</h2>
          </div>
          <div className="flex flex-row  gap-1 items-center">
            <h1 className="text-light  font-bold  w-fit my-2">To:</h1>
            <h2 className="text-black  "> {data[0].end_date}</h2>
          </div>
        </div>

        <div className={`flex flex-col`}>
          <h1 className="text-light  font-bold  w-fit my-2">Dates and time</h1>
          <div className="flex flex-row flex-wrap">
            {data[0]?.schedules?.map((day, i) => {
              return (
                <h2 className="flex text-black  m-0 p-0 gap-2">
                  {day?.date}
                  <span className="text-light mr-2">
                    ({day?.time})
                    {data[0]?.schedules.length - 1 === i ? '.' : ','}
                  </span>
                </h2>
              );
            })}
          </div>
        </div>

        {/* <div
          className={`${
            item?.schedule?.schedule_type === 'custom' ? 'flex' : 'hidden'
          } flex-col`}
        >
          <h1 className="text-light  font-bold  w-fit my-2">Dates and time</h1>
          <div className="flex flex-row flex-wrap">
            {highlightedDays?.days?.map((day, i) => {
              return (
                <h2 className="flex text-black  m-0 p-0 gap-1">
                  {day?.date}
                  <span className="text-light ">
                    ({day?.time})
                    {highlightedDays?.days?.length - 1 === i ? '.' : ','}
                  </span>
                </h2>
              );
            })}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default EmailDetails;
