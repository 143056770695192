import React, { Fragment, useEffect, useState } from 'react';
import Table from '../../components/tableComponent/Table';
import { Popover, Transition } from '@headlessui/react';
import { FiMoreVertical } from 'react-icons/fi';
import { feebackSeeds } from '../../seeds/Data';
import FeedbackModal from '../modals/FeedbackModal';
import Engage from '../modals/Engage';
import Escalate from '../modals/Escalate';

const Feedback = ({ feedbacks }) => {
  const [details, setDetails] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [escalateModal, setEscalateModal] = useState(false);
  const [engageModal, setEngageModal] = useState(false);

  const columns = [
    {
      Header: 'First Name',
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  w-max">
            <h1 className="font-bold">{row.original.user.first_name}</h1>
          </div>
        );
      },
    },
    {
      Header: 'Last Name',
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  w-max">
            <h1 className="font-bold">{row.original.user.last_name}</h1>
          </div>
        );
      },
    },
    {
      Header: 'Email',
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  w-max">
            <h1 className="font-bold">{row.original.user.email}</h1>
          </div>
        );
      },
    },
    {
      Header: 'Role',
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  w-max">
            <h1 className="font-bold">{row.original.source.name}</h1>
          </div>
        );
      },
    },
    {
      Header: 'Rate',
      accessor: 'rate',
    },
    {
      Header: 'Source',
      accessor: 'feedback_source_id',
    },
    {
      Header: 'Feedback',
      accessor: 'message',
      Cell: ({ row }) => {
        let truncatedFeedback;
        if (row.original.rate === 5) {
          truncatedFeedback = 'Satisfied';
        } else {
          truncatedFeedback = row?.original?.message?.substring(0, 20) + '...';
        }

        return (
          <div className="flex flex-row  w-max">
            <h1 className="">{truncatedFeedback}</h1>
          </div>
        );
      },
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row">
            {row.original.created_at.split('T')[0]}
          </div>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  font-bold">
            <Popover className="relative">
              <Popover.Button>
                <FiMoreVertical size={30} className="cursor-pointer" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 font-bold">
                  <div className="flex flex-col bg-white  shadow-md border border-black/10  rounded-sm text-xs w-max">
                    {row?.original?.rate < 5 ? (
                      <div className="flex flex-col">
                        <div
                          className="px-4 py-1 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                          onClick={() => {
                            // setApprove(!approve);
                            setFeedbackMessage(row.original.feedback);
                            setDetails(!details);
                          }}
                        >
                          Details
                        </div>

                        <div
                          className="px-4 py-1 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                          onClick={() => {
                            setEngageModal(!engageModal);
                          }}
                        >
                          Engage
                        </div>

                        <div
                          className="px-4 py-1 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                          onClick={() => {
                            setEscalateModal(!escalateModal);
                          }}
                        >
                          {row.original.status === 'Escalated'
                            ? 'Re-escalate'
                            : 'Escalate'}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <FeedbackModal
        open={details}
        setOpen={setDetails}
        data={feedbackMessage}
      />
      <Escalate open={escalateModal} setOpen={setEscalateModal} />
      <Engage open={engageModal} setOpen={setEngageModal} />
      <Table columns={columns} data={feedbacks} />
    </div>
  );
};

export default Feedback;
