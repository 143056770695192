import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { AiFillDelete } from "react-icons/ai";
import { FaPaste } from "react-icons/fa";
import { FiUpload } from "react-icons/fi";
import shortid from "shortid";

const FileUpload = ({ notify, files64, setFiles64 }) => {
  const [files, setFiles] = useState([]);
  const [shortLinks, setShortLinks] = useState([]);
  const [copiedText, setCopiedText] = useState("");
  const [base64Files, setBase64Files] = useState([]);

  const onDrop = async (acceptedFiles) => {
    const newFiles = [...files, ...acceptedFiles];

    const newShortLinks = newFiles.map((file) => {
      const fileId = shortid.generate();
      return `{{${fileId}}}`;
    });

    setFiles(newFiles);
    setShortLinks(newShortLinks);
    const base64Data = [...base64Files];
    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i];
      const fileId = newShortLinks[i].substring(2, newShortLinks[i].length - 2);
      const base64String = await readFileAsBase64(file);
      base64Data.push({ tag: fileId, file: base64String });
    }
    setBase64Files(base64Data);
    setFiles64(base64Data);
  };

  const copyToClipboard = (shortLink) => {
    navigator.clipboard.writeText(shortLink);

    setCopiedText(`${shortLink}`);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "*/*",
    multiple: true,
  });

  const readFileAsBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.readAsDataURL(file);
    });
  };

  const deleteFile = (tag) => {
    // Filter out the deleted file from state

    const filteredFiles = files.filter((file, index) => {
      const fileId = shortLinks[index].substring(
        2,
        shortLinks[index].length - 2
      );
      return fileId !== tag;
    });

    const filteredShortLinks = shortLinks.filter((shortLink) => {
      const fileId = shortLink.substring(2, shortLink.length - 2);
      return fileId !== tag;
    });

    const filteredBase64Files = base64Files.filter((fileObj) => {
      return fileObj.tag !== tag;
    });

    setFiles(filteredFiles);
    setShortLinks(filteredShortLinks);
    setBase64Files(filteredBase64Files);
  };

  return (
    <div>
      <div
        {...getRootProps()}
        className="border-dotted border-[1.5px] text-[18px] flex flex-col items-center justify-center w-full py-[29px] px-10 bg-white rounded-lg"
      >
        <input {...getInputProps()} />
        <i>
          <FiUpload size={20} />
        </i>
        Drag & drop files here or click to select multiple files.
      </div>
      {files.length > 0 && (
        <div className="bg-white border border-[#BDBDBD] p-1 rounded-lg mt-2 md:text-[16px]">
          <h2>Uploaded Files</h2>
          {files.map((file, index) => (
            <div
              key={index}
              className="relative flex px-10 h-20 justify-between items-center shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] bg-white rounded-lg mb-4"
            >
              <div>
                <p>
                  File {index + 1}: {file.name}
                </p>
                <p>
                  Short Link:{" "}
                  <a
                    href="#"
                    onClick={() => copyToClipboard(shortLinks[index])}
                  >
                    {shortLinks[index]}
                  </a>
                </p>
              </div>

              <FaPaste
                size={30}
                onClick={() => copyToClipboard(shortLinks[index])}
              />
              <button
                type="button"
                className="absolute right-0 top-0 text-red-500"
                onClick={() => {
                  deleteFile(shortLinks[index]);
                }}
              >
                <AiFillDelete className="h-6 w-6" />
              </button>

              {file.type.startsWith("image/") && (
                <div className="h-20 w-20  flex items-center">
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${file.name}`}
                    className="object-cover "
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {copiedText && (
        <div className="text-center mt-4">
          <p>Copied Text: {copiedText}</p>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
