import axios from 'axios';
import { APP } from '../../utils/constant';

export const SET_IS_FETCHING_MTN_MOBILE_MONEY_PAYMENT =
  'SET_IS_FETCHING_MTN_MOBILE_MONEY_PAYMENT';

export const FETCH_MTN_MOBILE_MONEY_PAYMENT = 'FETCH_MTN_MOBILE_MONEY_PAYMENT';

export const SET_IS_FETCHING_AIRTEL_MONEY_PAYMENT =
  'SET_IS_FETCHING_AIRTEL_MONEY_PAYMENT';

export const FETCH_AIRTEL_MONEY_PAYMENT = 'FETCH_AIRTEL_MONEY_PAYMENT';

export const setIsFetchingMtnMobileMoneyPayment = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_MTN_MOBILE_MONEY_PAYMENT,
    payload,
  });
};

export const setFetchedMtnMobileMoneyPayment = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_MTN_MOBILE_MONEY_PAYMENT,
    payload,
  });
};

export const setIsFetchingAirtelMoneyPayment = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_AIRTEL_MONEY_PAYMENT,
    payload,
  });
};

export const setFetchedAirtelMoneyPayment = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_AIRTEL_MONEY_PAYMENT,
    payload,
  });
};

export const fetchMtnMobileMoneyPayment = () => (dispatch, getState) => {
  const { token } = getState().user;
  dispatch(setIsFetchingMtnMobileMoneyPayment(true));
  axios
    .get(APP.API_MAIN_URL + `/admin/mobile-money/mtn/payments`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      dispatch(setIsFetchingMtnMobileMoneyPayment(false));

      dispatch(setFetchedMtnMobileMoneyPayment(res.data.data));
    })
    .catch((error) => {
      dispatch(setIsFetchingMtnMobileMoneyPayment(false));
      console.log(error);
    });
};

export const fetchAirtelMoneyPayment = () => (dispatch, getState) => {
  const { token } = getState().user;
  dispatch(setIsFetchingAirtelMoneyPayment(true));
  axios
    .get(APP.API_MAIN_URL + `/admin/mobile-money/airtel/payments`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      dispatch(setIsFetchingAirtelMoneyPayment(false));

      dispatch(setFetchedAirtelMoneyPayment(res.data.data));
    })
    .catch((error) => {
      dispatch(setIsFetchingAirtelMoneyPayment(false));
      console.log(error);
    });
};
