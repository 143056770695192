import React, { useState } from "react";
import Modal2 from "../../../components/modal/Modal2";
import Button from "../../../components/Button";
import { useForm } from "react-hook-form";
import axios from "axios";
import { APP, config, notify } from "../../../utils/constant";

import Input from "../../../components/Input";
import TextArea from "../../../components/TextArea";
import { HiMinus, HiPlus } from "react-icons/hi";
import { useSelector } from "react-redux";

const AddPlacement = ({
  handleAddPlacement,
  openAddPlacement,
  qrCodeId,
  environmentId,
  getSingleQr,
  getQrCodes,
}) => {
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [placements, setPlacements] = useState([
    {
      name: "",
      description: "",
      is_active: true,
    },
  ]);

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const onSubmit = () => {
    setLoading(true);

    axios
      .post(
        APP.API_MAIN_URL +
          `/admin/qr-codes/${qrCodeId}/environments/${environmentId}/placements`,
        { placements },
        config(token)
      )
      .then((res) => {
        setLoading(false);
        handleAddPlacement(!openAddPlacement);
        getSingleQr();
        getQrCodes();
        reset();
        setPlacements([
          {
            name: "",
            description: "",
            price: "",
            is_active: true,
          },
        ]);
        notify("success", "Placement Added successfully!");
      })
      .catch((err) => {
        setLoading(false);
        notify("error", "Fail to Add Placement");
        console.log(err);
      });
  };
  const handleRemovePlacements = (index) => {
    setPlacements((prevData) => {
      const newData = prevData.filter((_, i) => i !== index);

      return newData;
    });
  };

  const handleAddPlacements = (index) => {
    setPlacements((prevData) => {
      // Check if the current object is not empty
      const isCurrentObjectNotEmpty = prevData.some(
        (placement) =>
          placement.name || placement.description || placement.price
      );

      // If the current object is not empty, add a new object
      if (isCurrentObjectNotEmpty) {
        return [
          ...prevData,
          { name: "", price: "", description: "", is_active: true },
        ];
      } else {
        // If the current object is empty, return the previous state
        return prevData;
      }
    });
  };
  console.log("Placements", environmentId, placements);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal2
        isOpen={openAddPlacement}
        onClose={() => {
          reset();
          handleAddPlacement(!openAddPlacement);
        }}
        Style={"w-fit m-4 flex h-max m-auto md:w-[40%] px-3"}
      >
        <Modal2.Header className={"sticky top-0 "}>
          <div className="font-bold ">Add Placement</div>
        </Modal2.Header>
        <Modal2.Body>
          {placements?.map((place, index) => (
            <div
              className="p-3 border border-light rounded-md mb-5"
              key={index}
            >
              <Input
                labelText={"Name"}
                name={`name ${index}`} // Ensure unique name attributes
                type={"text"}
                placeholder={"name"}
                value={placements[index]?.name}
                isRequired={true}
                customClass="w-full text-sm mx-auto"
                register={register}
                errors={errors}
                onChange={(e) => {
                  setPlacements((prevPlacements) => {
                    const updatedPlacements = [...prevPlacements];
                    updatedPlacements[index] = {
                      ...updatedPlacements[index],
                      name: e.target.value,
                    };
                    return updatedPlacements;
                  });
                  clearErrors("name");
                }}
              />
              <Input
                labelText={"Price"}
                name={`price ${index}`} // Ensure unique name attributes
                type={"number"}
                placeholder={"Price"}
                value={placements[index]?.price}
                isRequired={true}
                customClass="w-full text-sm mx-auto"
                register={register}
                errors={errors}
                onChange={(e) => {
                  setPlacements((prevPlacements) => {
                    const updatedPlacements = [...prevPlacements];
                    updatedPlacements[index] = {
                      ...updatedPlacements[index],
                      price: e.target.value,
                    };
                    return updatedPlacements;
                  });
                  clearErrors("price");
                }}
              />
              <TextArea
                labelText={"Description"}
                labelFor={"description"}
                name={`description ${index}`}
                isRequired={true}
                value={placements[index]?.description}
                placeholder={"Enter description"}
                customClass="w-full"
                register={register}
                errors={errors}
                onChange={(e) => {
                  setPlacements((prevPlacements) => {
                    const updatedPlacements = [...prevPlacements];
                    updatedPlacements[index] = {
                      ...updatedPlacements[index],
                      description: e.target.value,
                    };
                    return updatedPlacements;
                  });
                  clearErrors("description");
                }}
              />
              <div className="flex items-center justify-end w-[full] space-x-2 mt-4">
                <div
                  onClick={() => handleRemovePlacements(index)}
                  className={`flex items-center p-1 justify-center cursor-pointer bg-red-500 rounded-full ${
                    index === 0 ? "hidden" : "visible"
                  }`}
                >
                  <HiMinus
                    color="white"
                    size={18}
                    className={`cursor-pointer `}
                  />
                </div>
                <div
                  className={`flex items-center p-1 justify-center cursor-pointer bg-primary rounded-full  ${
                    index === placements.length - 1 &&
                    placements[placements.length - 1].name !== "" &&
                    placements[placements.length - 1].description !== ""
                      ? "visible"
                      : "hidden"
                  }`}
                  onClick={() => handleAddPlacements(index)}
                >
                  <HiPlus color="white" size={18} />
                </div>
              </div>
            </div>
          ))}
        </Modal2.Body>
        <Modal2.Footer className={"h-14 px-0"}>
          <Button
            type="submit"
            content="Save"
            loading={loading}
            Style={"text-white bg-primary h-[40px] w-full  "}
          />
        </Modal2.Footer>
      </Modal2>
    </form>
  );
};

export default AddPlacement;
