import React from "react";
import { HiMinus, HiPlus } from "react-icons/hi";
import { Tooltip } from "react-tooltip";
import TextArea from "../../../../../components/TextArea";
import Input from "../../../../../components/Input";
import Select from "../../../../../components/Select";
import CircularLoader from "../../../../../components/CircularLoader";
import { RiErrorWarningLine } from "react-icons/ri";

function AddPlanRw({
  clearErrors,
  register,
  errors,
  planData,
  setPlanData,
  translationLoading,
  isTranslated,
}) {
  const durationOptions = [
    { value: "daily", label: "Buri munsi" },
    { value: "weekly", label: "Buri Cyumweru" },
    { value: "monthly", label: "Buri Kwezi" },
    { value: "annually", label: "Buri Mwaka" },
  ];
  const handleName = (e) => {
    const { value } = e.target;

    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      name: { ...prevPlanData.name, rw: value },
    }));
  };
  const handleDescription = (e) => {
    const { value } = e.target;
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      description: { ...prevPlanData, rw: value },
    }));
  };
  const handlePrice = (e) => {
    const { value } = e.target;
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      amount: value,
    }));
  };

  const handleDuration = (value) => {
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      subscription_type: value,
    }));
  };

  const handleMaxDailyArticlesChange = (e) => {
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      max_daily_articles: e,
    }));
  };
  const handleBenefitDescriptionChange = (e, index) => {
    const { value } = e.target;

    setPlanData((prevPlanData) => {
      const updatedBenefits = [...prevPlanData.benefits];
      updatedBenefits[index] = {
        ...updatedBenefits[index],
        description: { ...updatedBenefits[index].description, rw: value },
      };

      return {
        ...prevPlanData,
        benefits: updatedBenefits,
      };
    });
  };

  const handleBenefitNameChange = (e, index) => {
    const { value } = e.target;

    setPlanData((prevPlanData) => {
      const updatedBenefits = [...prevPlanData.benefits];
      updatedBenefits[index] = {
        ...updatedBenefits[index],
        name: { ...updatedBenefits[index].name, rw: value },
      };

      return {
        ...prevPlanData,
        benefits: updatedBenefits,
      };
    });
  };

  const addBenefit = () => {
    // Create a new benefit object with default values
    const newBenefit = { name: {}, description: {} };

    setPlanData((prevState) => ({
      ...prevState,
      benefits: [...prevState.benefits, newBenefit],
    }));
  };
  const removeBenefit = (index) => {
    const updatedBenefits = planData.benefits.filter((_, i) => i !== index);

    setPlanData((prevState) => ({
      ...prevState,
      benefits: updatedBenefits,
    }));
  };
  return (
    <div>
      {translationLoading ? (
        <div className="flex items-center w-full mt-4">
          <div className="flex gap-2 items-center m-auto">
            <CircularLoader />
            <p>Translating...</p>
          </div>
        </div>
      ) : null}
      {isTranslated ? (
        <div className="flex gap-2 mt-6 mb-2">
          <RiErrorWarningLine size={30} color="blue" />
          <h1>Suzuma neza ibyahinduwe</h1>
        </div>
      ) : (
        ""
      )}
      <Input
        labelText={"Izina"}
        labelFor={"name"}
        name={`name `}
        type={"text"}
        isRequired={true}
        placeholder={"Andika izina rya Plan"}
        onChange={(e) => {
          handleName(e);
        }}
        value={planData.name["rw"]}
        customClass="w-full mb-2 mt-1"
        register={register}
        errors={errors}
      />
      <TextArea
        labelText={"Ubusobanuro"}
        labelFor={"description"}
        name={`description`}
        type={"text"}
        isRequired={true}
        placeholder={"Andika ubusobanuro bwa plan"}
        value={planData.description["rw"]}
        onChange={(e) => {
          handleDescription(e);
        }}
        customClass="w-full mb-4"
        register={register}
        errors={errors}
      />
      <Input
        labelText={"Amafaranga"}
        labelFor={"price"}
        name={`price`}
        type={"number"}
        isRequired={true}
        placeholder={"Andika amafaranga"}
        onChange={(e) => {
          handlePrice(e);
        }}
        value={planData.amount}
        customClass="w-full mb-4"
        inputStyle={"no-spinner"}
        register={register}
        errors={errors}
        min={0}
      />
      <Input
        labelText={"Daily articles"}
        labelFor={`daily_articles`}
        name={`daily_articles`}
        type={"number"}
        isRequired={true}
        value={planData.max_daily_articles}
        placeholder={"Enter maximum daily articles"}
        onChange={(e) => {
          handleMaxDailyArticlesChange(e.target.value);
        }}
        customClass="w-full mb-4"
        register={register}
        errors={errors}
      />
      <Select
        label={"Subscription type"}
        labelFor={"content_type"}
        className="w-full mb-4"
        name={`content_type`}
        isLoading={false}
        placeholder={"Select subscription type"}
        value={{
          value: planData.subscription_type,
          label: durationOptions.find(
            (item) => item?.value === planData?.subscription_type
          )?.label,
        }}
        onChange={(e) => {
          handleDuration(e.value);
        }}
        options={durationOptions}
      />
      <div className="flex justify-between border border-[#ddd] rounded-md mt-3">
        <div className="w-full p-1 rounded-md">
          <h1 className="my-2 text-lg ml-5">Add benefits</h1>
          {planData.benefits.map((benefit, index) => (
            <div key={index} className="flex">
              <div className="w-full flex flex-col items-center mb-2 border border-[#ddd] p-2 ml-5">
                <Input
                  labelText={"Izina"}
                  labelFor={"name"}
                  name={`name ${index}`}
                  type={"text"}
                  placeholder={"Andika izina rya Benefit"}
                  isRequired={true}
                  customClass=" w-full text-[18px] "
                  value={benefit.name["rw"]}
                  onChange={(e) => {
                    clearErrors("name");
                    handleBenefitNameChange(e, index);
                  }}
                  register={register}
                  errors={errors}
                />

                <TextArea
                  labelFor={"description"}
                  labelText="Ubusobanuro"
                  name={`description ${index}`}
                  errors={errors}
                  placeholder={`Andika Ubusobanuro`}
                  customClass={"w-full mt-4 ml-2 text-[18px]"}
                  isRequired={true}
                  register={register}
                  value={benefit.description["rw"]}
                  onChange={(e) => {
                    clearErrors("description");
                    handleBenefitDescriptionChange(e, index);
                  }}
                />
              </div>
              <div className="flex items-end justify-between mt-5 w-[8%] ml-5 ">
                <div
                  data-tooltip-id="tooltip12"
                  data-tooltip-content="remove this Name"
                  className={`flex items-center p-1 justify-center cursor-pointer bg-[#BDBDBD] rounded-full text-white ${
                    index === 0 ? "hidden" : "visible"
                  }`}
                  onClick={(e) => removeBenefit(index)}
                >
                  <HiMinus />
                </div>
                <div
                  data-tooltip-id="tooltip12"
                  data-tooltip-content="Add Benefit"
                  className={`flex items-center p-1  justify-center cursor-pointer bg-primary rounded-full text-white ${
                    planData.benefits?.length === index + 1
                      ? "visible"
                      : "hidden"
                  }`}
                  onClick={addBenefit}
                >
                  <HiPlus />
                </div>
                <Tooltip id="tooltip12" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AddPlanRw;
