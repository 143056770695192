import React from "react";
import Input from "../../../../../components/Input";
import TextArea from "../../../../../components/TextArea";
import Select from "../../../../../components/Select";
import { RiErrorWarningLine } from "react-icons/ri";
import CircularLoader from "../../../../../components/CircularLoader";

function AddFrenchCategory({
  clearErrors,
  register,
  errors,
  name,
  setNames,
  isTranslated,
  translatedFr,
  translationLoading,
  lastMod,
  setLastMod,
  typeOptions,
  control,
}) {
  const handleAddName = (e) => {
    const newName = e.target.value;
    setNames({ ...name, name: newName });
    setLastMod((prev) => ({ ...prev, name: { ...prev.name, fr: newName } }));
  };

  const handleAddDesc = (e) => {
    const newDesc = e.target.value;
    setNames({ ...name, description: newDesc });

    setLastMod((prev) => ({
      ...prev,
      description: { ...prev.description, fr: newDesc },
    }));
  };
  return (
    <div>
      {translationLoading ? (
        <div className="flex items-center w-full mt-4">
          <div className="flex gap-2 items-center m-auto">
            <CircularLoader />
            <p>Traduction en cours...</p>
          </div>
        </div>
      ) : null}
      {isTranslated ? (
        <div className="flex gap-2 mt-6 mb-2">
          <RiErrorWarningLine size={30} color="blue" />
          <h1>Vérifie bien les textes traduits</h1>
        </div>
      ) : (
        ""
      )}
      <Input
        labelText={"Nom"}
        labelFor={"name"}
        name={`name`}
        type={"text"}
        placeholder={"Entrez le nom de la catégorie"}
        isRequired={true}
        customClass="w-full mb-2 w-[75%] ml-1"
        onChange={(e) => {
          clearErrors("name");
          handleAddName(e);
        }}
        value={lastMod?.name["fr"]}
        register={register}
        errors={errors}
      />
      <TextArea
        labelFor={"description"}
        labelText="Description"
        name={`description`}
        errors={errors}
        placeholder={`Entrez la description de la catégorie`}
        customClass={"w-full mb-2 text-[18px]"}
        isRequired={true}
        register={register}
        value={lastMod?.description["fr"]}
        onChange={(e) => {
          clearErrors("description");
          handleAddDesc(e);
        }}
      />
      <Select
        label="Content type"
        customClass="w-full mb-2 text-[18px]"
        isRequired={false}
        placeholder={"Content type"}
        options={typeOptions}
        register={{
          control,
          name: `contentType`,
          rules: { required: "contentType is required" },
        }}
        defaultValue={lastMod?.content_type}
        handleChange={(e) => {
          setLastMod((prev) => ({
            ...prev,
            content_type: e,
          }));
        }}
        error={errors[`contentType`]?.message}
      />
    </div>
  );
}

export default AddFrenchCategory;
