import React, { useEffect, useState } from "react";
import Select from "../../../../components/Select";
import MultiSelect from "../../../../components/MultiSelect";
import axios from "axios";
import { APP, config } from "../../../../utils/constant";
import { useSelector } from "react-redux";

function AddLanguage({ name, setNames, control, errors }) {
  const { token } = useSelector((state) => state.user);
  const [languageLoading, setLanguageLoading] = useState(false);
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    setLanguageLoading(true);
    axios
      .get(APP.API_MAIN_URL + `/utils/languages`, config(token))
      .then((res) => {
        setLanguageLoading(false);
        setLanguages(
          res.data.data.map((value) => {
            return {
              value: value.id,
              label: value.name,
            };
          })
        );
      })
      .catch((error) => {
        setLanguageLoading(false);
        console.log("Error in retrieving language", error);
      });
  }, []);

  const handleLanguage = (languageArray) => {
    setNames((prevData) => {
      return {
        ...prevData,
        lan: languageArray.map((language) => {
          return {
            language_id: language.value,
            is_default: false,
            status: "active",
          };
        }),
      };
    });
  };

  const handleDefaultLanguage = (languageObject) => {
    const updatedLanguages = name.lan.map((transformLanguage) => {
      if (transformLanguage.language_id === languageObject.value) {
        return {
          ...transformLanguage,
          is_default: true,
        };
      } else {
        return {
          ...transformLanguage,
          is_default: false,
        };
      }
    });

    setNames((prevData) => {
      return {
        ...prevData,
        lan: updatedLanguages,
      };
    });
  };

  return (
    <div className="mt-5">
      <MultiSelect
        label="Choose Languages"
        customClass={"w-full mb-2 "}
        className="w-full mb-2"
        isLoading={false}
        placeholder={"Select languages"}
        handleChange={(e) => {
          handleLanguage(e.map((e) => e));
        }}
        options={languages}
        loading={false}
        register={{
          control,
          name: "languages",
          rules: { required: "Languages are required" },
        }}
        error={errors["languages"]?.message}
      />
      <div className="mt-5">
        <Select
          label={"Default Language"}
          labelFor={"default_language"}
          className="w-full"
          isLoading={false}
          placeholder={"Select default language"}
          handleChange={(e) => {
            handleDefaultLanguage(e);
          }}
          options={languages
            .map((language) => {
              if (
                name?.lan?.some(
                  (selected) => selected.language_id === language.value
                )
              ) {
                return {
                  value: language.value,
                  label: language.label,
                };
              }
              //  return statement here for cases where the condition is not met.
              return null;
            })
            .filter(Boolean)}
          customClass={"mb-2"}
          register={{
            control,
            name: "Default_language",
            rules: { required: "Default language is required" },
          }}
          error={errors["Default_language"]?.message}
        />
      </div>
    </div>
  );
}

export default AddLanguage;
