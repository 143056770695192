import React, { useState, useEffect, Fragment } from 'react';
import Modal from '../../components/modal/Modal';
import SmsDetails from './modals/SmsDetails';
import EmailDetails from './modals/EmailDetails';
import Qr_code_details from './modals/Qr_code_details';
import Axios from '../../components/Axios';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import { Popover, Transition } from '@headlessui/react';
import { FiMoreVertical } from 'react-icons/fi';
import { ToastComponent, ToasterComponent } from '../../components/Toast';
import { APP } from '../../utils/constant';
import { useSelector } from 'react-redux';

const CampaignData = ({ show, setShow, campaignId }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  // delete campaign

  const params = new URLSearchParams(window.location.search);
  const { token } = useSelector((state) => state.user);
  // const token = params.get('auth');
  // const token =
  //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLmFjY291bnQua29udmV5LnJ3XC9hcGlcL2F1dGhcL2xvZ2luIiwiaWF0IjoxNjg1MzU3NjUzLCJleHAiOjE2ODU0NDQwNTMsIm5iZiI6MTY4NTM1NzY1MywianRpIjoiWlpoV2swWWVBcHNtRllMNSIsInN1YiI6MSwicHJ2IjoiODdlMGFmMWVmOWZkMTU4MTJmZGVjOTcxNTNhMTRlMGIwNDc1NDZhYSJ9.XHBeeAw6VcB8tgdM9Wpr5DEaaGIvfYsZ_6OfwJrzYdg';

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const deleteCampaign = () => {
    Axios.delete(
      APP.ADVERTISER_API_URL + `/campaign/delete/${data?.id}`,
      config
    )
      .then((res) => {
        console.log(res);
        setOpen(!open);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const approveCampaign = () => {
    Axios.put(
      APP.ADVERTISER_API_URL + `/admin/confirm/campaign/${campaignId}`,
      config
    )
      .then((res) => {
        console.log(res);
        setShow();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    Axios.get(APP.ADVERTISER_API_URL + `/admin/campaigns/${campaignId}`, config)
      .then((res) => {
        setData(res.data);
        console.log(res.data, 'campaign fetching');
        setLoading(false);
      })
      .catch((err) => {
        console.log('sth went wrong');
        console.log(err);
      });
  }, [show]);

  return (
    <div className="font-nunito">
      <ToasterComponent />
      {/* delete campaign modal */}
      <Modal
        isOpen={open}
        onClose={() => setOpen(!open)}
        Style={'w-fit m-4 flex m-auto z-[10]'}
      >
        <Modal.Body>
          <div className="flex flex-col ">
            <h1 className="font-nunito text-2xl text-start text-black font-bold mb-4 mr-8">
              Confirm the deletion of this campaign?
            </h1>
            <div className="flex justify-between">
              <Button
                type="submit"
                content="Cancel"
                loading={false}
                btnColor="primary"
                onClick={() => {
                  setOpen(!open);
                  setShow(!show);
                }}
                Style={'text-white h-[40px] mt-2 ml-0'}
              />

              <Button
                type="submit"
                content="Confirm"
                loading={false}
                btnColor="secondary"
                onClick={() => {
                  deleteCampaign();
                }}
                Style={'text-fontcolor h-[40px] mt-2 ml-0 border'}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*end of modal*/}
      <Modal
        isOpen={show}
        onClose={() => setShow()}
        Style={
          'h-[95%] lg:h-[90%] w-[90%] lg:w-3/4 m-auto pb-0 font-nunito flex items-center justify-center'
        }
      >
        <Modal.Body>
          {loading ? (
            <Loader loader={true} />
          ) : (
            <div className=" p-4 text-lg">
              <div className="w-full flex flex-col space-y-4">
                <div className="flex flex-row justify-between w-full items-center">
                  <div className="flex flex-row gap-2 items-center">
                    <div className="max-w-[250px] h-[100px]">
                      <img
                        src={
                          data?.user?.profile === null
                            ? data?.user?.company.logo
                              ? data?.user?.company.logo
                              : 'https://img.favpng.com/2/25/14/computer-icons-business-avatar-png-favpng-s80isPnK6mLpsyKTze84xFwpw.jpg'
                            : data?.user?.profile?.photo
                            ? data?.user?.profile?.photo
                            : 'https://img.favpng.com/2/25/14/computer-icons-business-avatar-png-favpng-s80isPnK6mLpsyKTze84xFwpw.jpg'
                        }
                        className="h-full w-full object-contain"
                        alt="company logo"
                      />
                    </div>
                    <h2 className=" font-bold ">
                      {data?.user?.profile === null
                        ? data?.user?.company.name
                        : data?.user?.names}
                    </h2>
                  </div>
                  <div>
                    <div className="flex flex-row  font-bold">
                      <Popover className="relative">
                        <Popover.Button>
                          <FiMoreVertical
                            size={30}
                            className="cursor-pointer"
                          />
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute z-10 right-0">
                            <div
                              className="flex flex-col bg-white shadow-md border border-black/10  rounded-sm text-xs "
                              onClick={() => {}}
                            >
                              <h1
                                className="px-4 py-2 hover:bg-[#D9D9D9] text-center cursor-pointer"
                                onClick={() => {}}
                              >
                                Approve
                              </h1>

                              <h1
                                className="px-4 py-2 hover:bg-[#D9D9D9] text-center cursor-pointer"
                                onClick={() => {}}
                              >
                                Reject
                              </h1>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </Popover>
                    </div>
                    {/* <h2 className="font-bold  px-4">
                      {data?.created_at}
                    </h2> */}
                  </div>
                </div>
                <hr />
                <h1 className=" font-bold text-2xl">{data?.title}</h1>
                <h2 className="">{data?.description}</h2>
                <div className="flex flex-row  gap-1 items-center mt-4  ">
                  <h1 className="text-light text-lg font-bold w-fit">
                    {' '}
                    Status:
                  </h1>
                  <h2 className="text-black text-lg font-bold">
                    {data?.status}
                  </h2>
                </div>
                {/* <video controls>
            <source src="https://video-links.b-cdn.net/media/videolinks/video/PrismFX.mp4" type="video/mp4" />
            Your browser does not support the video element.
          </video> */}
                {/* {modalContent.type === 'image' ? (
          <div className="w-full 1/3">
            <img
              src={modalContent.sample}
              alt="sample"
              className="w-full h-full"
            />
          </div>
        ) : modalContent.type === 'video' ? (
         
        ) : (
          <div className="flex flex-row items-end justify-center h-[300px] w-full bg-center bg-[url('https://img.freepik.com/free-vector/music-background-with-equalizer-speaker_1017-32860.jpg?w=1380&t=st=1678352704~exp=1678353304~hmac=acaff577f8fc12c73bce66d1a409bc739ba6b889e5b8db6e90f658277bbff229')] bg-cover">
            <audio controls>
              <source src={modalContent.sample} type="audio/ogg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        )} */}
              </div>

              <div className="flex flex-col  mt-2  w-3/4">
                <div className="flex flex-row  gap-1 items-center mt-1 ">
                  <h1 className="text-light  font-bold w-fit  ">
                    Campaign type:
                  </h1>
                  <h2 className="text-black  ">{data?.type}</h2>
                </div>
                <div className="flex flex-row  gap-1 items-center mt-1 ">
                  <h1 className="text-light  font-bold w-fit ">
                    Selected channels:
                  </h1>
                  <div className="flex">
                    {data?.selected_channels &&
                      JSON.parse(data?.selected_channels).map((element) => {
                        const channelName = element.label;
                        let channelValue = element.value;
                        return <h2 className="text-black ">{channelName}</h2>;
                      })}
                  </div>
                </div>
                {/* <div className="flex flex-row  gap-1 items-center mt-1 ">
            <h1 className="text-light  font-bold w-fit ">File format:</h1>
            <h2 className="text-black ">mp4</h2>
          </div> */}

                {data?.selected_channels &&
                  JSON.parse(data?.selected_channels).map((element) => {
                    const channelName = element.label;
                    let channelValue = element.value;
                    if (channelValue === 'sms') {
                      return <SmsDetails data={[element]} />;
                    } else if (channelValue === 'email') {
                      return (
                        <EmailDetails
                          data={[element]}
                          channelValue={channelValue}
                        />
                      );
                    } else if (channelValue === 'qr_code') {
                      return (
                        <Qr_code_details
                          data={[element]}
                          channelValue={channelValue}
                        />
                      );
                    }
                  })}

                {/* {data?.status === 'PENDING' &&()} */}
                {/* <div className="flex flex-col border border-error w-full p-4 rounded-lg ">
            <h1 className="text-light  font-bold w-fit">Attention : ⚠️</h1>
            <p>This action can not be reversed, be aware !</p>
            <button className="rounded-lg text-white bg-error p-2 font-bold w-fit mt-4" onClick={()=> {
              setShow(!show)
              setOpen(!open)}}>
              Cancel campaign
            </button>
          </div> */}
              </div>
              <div className="flex flex-row justify-between">
                <Button
                  type="submit"
                  content={
                    <div className="flex items-center justify-center">
                      <h1 className="text-black font-nunito text-base">
                        Close
                      </h1>
                    </div>
                  }
                  loading={false}
                  onClick={() => setShow()}
                  btnColor="primary"
                  Style={
                    'text-black w-[100px] h-[40px] mt-2 ml-0 bg-white border rounded-sm'
                  }
                />

                <div className="flex flex-row gap-2">
                  <Button
                    type="submit"
                    content={
                      <div className="flex items-center justify-center">
                        <h1 className="text-white font-nunito text-base">
                          Reject
                        </h1>
                      </div>
                    }
                    loading={false}
                    onClick={() => {}}
                    btnColor="error"
                    Style={'text-white w-[100px] h-[40px] mt-2 ml-0 bg-error'}
                  />
                  <Button
                    type="submit"
                    content={
                      <div className="flex items-center justify-center">
                        <h1 className="text-white font-nunito text-base">
                          Approve
                        </h1>
                      </div>
                    }
                    loading={false}
                    onClick={() => approveCampaign()}
                    btnColor="primary"
                    Style={'text-white w-[100px] h-[40px] mt-2 ml-0'}
                  />
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Modal.DismissButton>
            <div className="w-fit h-fit ml-auto"></div>
          </Modal.DismissButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CampaignData;
