import React from 'react';
import axios from 'axios';
import Modal from '../../../../components/modal/Modal';
import { FiDownload } from 'react-icons/fi';

const PaymentProof = ({
  openModal,
  handleOpenModal,
  proofDetails,
  isFetchingPaymentProof,
}) => {
  const classes = {
    display: 'inline-flex',
    margin: '0 4px 4px 0',
  };

  const InnerClasses = {
    display: 'flex',
    minWidth: '100%',
    overflow: 'hidden',
  };

  const img = {
    display: 'hidden',
    width: '100%',
    height: '100%',
  };

  const displayFile = (file) => {
    if (file) {
      return (
        <div style={classes} className="w-full h-full" key={file.file_name}>
          <div style={InnerClasses} className="w-full h-auto">
            {file?.mime_type.startsWith('image/') ? (
              <img src={file.original_url} style={img} />
            ) : (
              <span>{file.file_name}</span>
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Modal
      isOpen={openModal}
      onClose={() => {
        handleOpenModal();
      }}
      Style={'w-[90%] lg:w-2/3  h-[90%] flex m-auto py-4'}
    >
      <Modal.Header>
        <h1 className="font-bold">Payment Proof</h1>
      </Modal.Header>
      <Modal.Body>
        {isFetchingPaymentProof ? (
          'Loading data...'
        ) : (
          <div className="flex flex-col gap-2">
            <div className="flex lg:flex-row gap-2 justify-start items-center">
              <p className="font-bold">Ref:</p>
              <p className="">{proofDetails[0]?.reference}</p>
            </div>

            <div className="flex lg:flex-row gap-2 justify-start items-center">
              <p className="font-bold">Date:</p>
              <p className="">{proofDetails[0]?.created_at?.split('T')[0]}</p>
            </div>

            <div className="flex lg:flex-row gap-2 justify-start items-center">
              <p className="font-bold">Amount:</p>
              <p className="">{proofDetails[0]?.amount} Rwf</p>
            </div>

            <div className="flex lg:flex-row gap-2 justify-start items-center">
              <p className="font-bold">Description:</p>
              <p className="">{proofDetails[0]?.description}</p>
            </div>

            {/*proofDetails[0]?.wallet_bank_withdraw */}

            <div className="flex flex-col gap-2">
              <div className="flex flex-col lg:flex-row gap-2">
                <p className="font-bold">Bank Name:</p>
                <p>{}</p>
              </div>

              <div className="flex flex-col lg:flex-row gap-2">
                <p className="font-bold">Account Number:</p>
                <p>
                  {
                    proofDetails[0]?.wallet_bank_withdraw?.bank_account_detail
                      ?.account_number
                  }
                </p>
              </div>

              <div className="flex flex-col lg:flex-row gap-2">
                <p className="font-bold">Account Name:</p>
                <p>
                  {
                    proofDetails[0]?.wallet_bank_withdraw?.bank_account_detail
                      ?.account_name
                  }
                </p>
              </div>
            </div>

            <div className="flex flex-col  gap-2">
              <div className="flex flex-row justify-between items-center">
                <p className="font-bold">File:</p>
                <a
                  href={proofDetails[0]?.document_file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiDownload className="cursor-pointer" size={20} />
                </a>
              </div>

              <div className="w-full h-[500px] border border-black/25">
                {displayFile(proofDetails[0]?.media[0])}
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PaymentProof;
