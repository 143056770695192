import React, { useState, useEffect, Fragment } from "react";

import Button from "../../components/Button";
import axios from "axios";
import Modal2 from "../../components/modal/Modal2";
import { Popover, Transition } from "@headlessui/react";
import { BsQrCodeScan, BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import UpdateQrInfo from "./QRcode_Modals/UpdateQrInfo";
import EditLocation from "./QRcode_Modals/EditLocation";
import EditEnvironment from "./QRcode_Modals/EditEnvironment";
import EditPlacement from "./QRcode_Modals/EditPlacement";
import AddPlacement from "./QRcode_Modals/AddPlacement";
import DeletePlacement from "./QRcode_Modals/DeletePlacement";
import DeleteLocation from "./QRcode_Modals/DeleteLocation";
import DeleteEnvironment from "./QRcode_Modals/DeleteEnvironment";
import AddLocation from "./QRcode_Modals/AddLocation";
import AddEnvironment from "./QRcode_Modals/AddEnvironment";
import GenerateQrCode from "./QRcode_Modals/GenerateQrCode";
import { APP } from "../../utils/constant";
import { useSelector } from "react-redux";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { RiDeleteBack2Line, RiEyeFill, RiPencilLine } from "react-icons/ri";
import ViewQrContent from "../resourcesItems/qrCode/ViewQrContent";
import ViewGenerated from "../resourcesItems/qrCode/ViewGenerated";

const Qrcode_Details = ({ show, setShow, data, config, getQrCodes }) => {
  // state management
  const { token } = useSelector((state) => state.user);
  const [QRcode_data, setQRcode_data] = useState([]);

  // new
  const [updateQrInfo, setUpdateQrInfo] = useState(false);
  const [editLocation, setEditLocation] = useState(false);
  const [openEditEnv, setOpenEditEnv] = useState(false);
  const [openEditPlacement, setOpenEditPlacement] = useState(false);
  const [openAddPlacement, setOpenAddPlacement] = useState(false);
  const [selectedEnv, setSelectedEnv] = useState(null);
  const [locationId, setLocationID] = useState(null);
  const [openAddEnvironment, setOpenAddEnvironment] = useState(false);
  const [openAddLocation, setOpenAddlocation] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState(null);
  const [environmentToDelete, setEnvironmentToDelete] = useState(null);
  const [environmentPlacementToDelete, setEnvironmentPlacementToDelete] =
    useState(null);
  const [placementId, setPlacementId] = useState(null);
  const [openGenerate, setOpenGenerate] = useState(false);
  const [openGenerated, setOpenGenerated] = useState(false);
  const [status, setStatus] = useState("active");

  const [deleteLocation, setDeleteLocation] = useState(false);
  const [deleteEnvironment, setDeleteEnvironment] = useState(false);
  const [deletePlacement, setDeletePlacement] = useState(false);
  const [qrToGenerate, setQrToGenerate] = useState({
    environmentId: null,
    placementId: null,
  });
  const [locationToEdit, setLocationToEdit] = useState({
    province: {},
    district: {},
    Sector: {},
    cell: {},
    village: {},
  });
  const [environmentData, setEnvironmentData] = useState({
    id: "",
    name: "",
    details: "",
    is_active: "",
    qr_code_location_id: "",
  });
  const [placementData, setPlacementData] = useState({
    id: "",
    name: "",
    description: "",
    price: 0,
    standard_qr_code_environment_id: "",
    is_active: "",
  });
  const [viewContent, setViewContent] = useState(false);
  const [content, setContent] = useState({
    url: "",
    title: "",
    details: "",
  });

  const [generatedQrData, setGeneratedQrData] = useState({
    scan: 0,
    price: 0,
    status: "",
    reference: "",
    created_at: "",
    file: "",
    id: "",
  });

  const [displayContent, setDisplayContent] = useState(false);
  const [textToDelete, setTextToDelete] = useState(null);

  // fetch a particular QRcode
  const getSingleQr = () => {
    axios
      .get(
        `https://api.account.konvey.rw/api/admin/qr-codes/${data?.id}`,
        config
      )
      .then((res) => {
        console.log(res?.data?.data, "mmmmmm");

        setQRcode_data(res?.data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getSingleQr();
  }, [data]);

  const handleUpdateDetails = (data) => {
    setUpdateQrInfo(!updateQrInfo);
  };
  const handleUpdateLocation = () => {
    setEditLocation(!editLocation);
  };
  const handleUpdateEnvirnoment = () => {
    setOpenEditEnv(!openEditEnv);
  };
  const handleUpdatePlacement = () => {
    setOpenEditPlacement(!openEditPlacement);
  };
  const handleAddPlacement = () => {
    setOpenAddPlacement(!openAddPlacement);
  };
  const handleAddEnvironment = () => {
    setOpenAddEnvironment(!openAddEnvironment);
  };
  const handleAddLocation = () => {
    setOpenAddlocation(!openAddLocation);
  };
  const handleDeleteLocation = () => {
    setDeleteLocation(!deleteLocation);
  };
  const handleDeleteEnvironment = () => {
    setDeleteEnvironment(!deleteEnvironment);
  };
  const handleDeletePlacement = () => {
    setDeletePlacement(!deletePlacement);
  };
  const handleGenerate = () => {
    setOpenGenerate(!openGenerate);
  };

  const handleViewQrContent = () => {
    setViewContent(!viewContent);
  };

  const handleViewGenerated = () => {
    setOpenGenerated(!openGenerated);
  };
  const handleChangeStatus = (e) => {
    axios
      .put(
        APP.API_MAIN_URL + `/admin/qr-codes/${QRcode_data.id}`,
        {
          name: QRcode_data.name,
          status: e,
          details: QRcode_data.details,
        },
        config
      )
      .then((res) => console.log("Data updated", res))
      .catch((error) => console.log("Error in changing statuus", error));
  };

  return (
    <Modal2
      isOpen={show}
      onClose={() => setShow(!show)}
      Style={"md:w-[80%] mx-auto h-[90%] flex z-[10]"}
    >
      <Modal2.Header className={"sticky top-0 bg-secondary z-10"}>
        <div className="font-bold">QR code details</div>
      </Modal2.Header>
      <Modal2.Body>
        <div className="flex flex-col space-y-2 mx-auto">
          {/*div for name and status*/}

          {/*end of action*/}

          <div className="flex flex-row w-[95%] m-auto  text-primary items-center  px-2 justify-end">
            <div className="flex space-x-2">
              <div className="flex">
                <div className="font-bold">Status:&nbsp;</div>
              </div>
              <div>
                <div class="inline-flex items-center">
                  <div class="relative inline-block w-8 h-4 rounded-full cursor-pointer bg-[#e2e2e2] ">
                    <input
                      checked={QRcode_data?.status === "active"}
                      id="switch-3"
                      type="checkbox"
                      onChange={(e) => {
                        if (QRcode_data?.status === "active") {
                          setQRcode_data((prev) => ({
                            ...prev,
                            status: "inactive",
                          }));
                          handleChangeStatus("inactive");
                        }
                        if (QRcode_data?.status === "inactive") {
                          setQRcode_data((prev) => ({
                            ...prev,
                            status: "active",
                          }));
                          handleChangeStatus("active");
                        }
                      }}
                      class="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-blue-gray-100 checked:bg-green-500 peer-checked:border-green-500 peer-checked:before:bg-green-500"
                    />
                    <label
                      htmlFor="switch-3"
                      class="before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-[#898787] bg-white shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity hover:before:opacity-10 peer-checked:translate-x-full peer-checked:border-green-500 peer-checked:before:bg-green-500"
                    >
                      <div
                        class="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"
                        data-ripple-dark="true"
                      ></div>
                    </label>
                  </div>
                  <span className="ml-3 capitalize">{QRcode_data?.status}</span>
                </div>
              </div>
            </div>
          </div>
          {/*end*/}

          {/*qr code location*/}
          <div className="flex flex-col w-full text-[16px]">
            <div className=" m-auto text-primary py-2 rounded-md border border-light px-3 my-5 w-[95%]">
              <div className=" flex items-center justify-between w-full border-b-2 border-[#858484] mb-2">
                <h2 className="font-[900] text-xl pb-2 opacity-70">
                  Qr code information
                </h2>
                <Popover className="relative">
                  <Popover.Button className={"font-bold text-xl text-light"}>
                    <BsThreeDotsVertical
                      className="h-4 w-4 cursor-pointer"
                      color=""
                      size={5}
                    />
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 -translate-x-40"
                    enterTo="opacity-100 -translate-x-30"
                    leave="transition ease-in duration-500"
                    leaveFrom="opacity-100 translate-x-0"
                    leaveTo="opacity-0 translate-x-1"
                  >
                    <Popover.Panel className="absolute right-4 z-10 top-2 ">
                      <div
                        className="flex flex-col bg-white shadow-md border border-black/10  rounded-sm mt-1"
                        onClick={() => {}}
                      >
                        <div
                          className="flex flex-row items-center hover:bg-[#D9D9D9] px-4 py-2 gap-2 cursor-pointer"
                          onClick={() => handleUpdateDetails(QRcode_data)}
                        >
                          <span className=" text-sm">Update</span>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              </div>

              <div className="flex justify-between px-3">
                <div className=" text-[17px] font-[500]">QR code name:</div>
                <div>{QRcode_data?.name}</div>
              </div>
              <div className="flex justify-between px-3">
                <div className="text-[17px] font-[500]">Status:</div>
                <div className="capitalize ">{QRcode_data?.status}</div>
              </div>
              <div className="flex justify-between text-[14px] mt-2 px-3">
                <span>Created at</span>
                <span>{QRcode_data?.created_at?.split("T")[0]}</span>
              </div>
            </div>

            {/* Standard qr code contents */}
            <div className="flex flex-col m-auto text-primary py-2 rounded-md border border-light px-3 my-5 w-[95%] transition duration-700">
              <button
                className="flex items-center justify-between mb-2 bg-primary text-white p-5 border rounded-[10px] outline-none"
                onClick={() => {
                  setDisplayContent(!displayContent);
                }}
              >
                <div className="font-[900] text-md opacity-70 ">
                  View QR code content
                </div>
                <div>
                  {
                    <MdOutlineArrowForwardIos
                      className={`${
                        displayContent
                          ? "origin-center rotate-90 duration-100"
                          : ""
                      }`}
                    />
                  }
                  {/* <MdOutlineArrowForwardIos size={30} />
              <MdOutlineArrowBackIosNew size={30} /> */}
                </div>
              </button>
              <div className="w-1/3 sm:w-full bg-[#eaf3fd]"></div>
              {/* content right*/}
              <div
                className={`w-full md:w-full transition duration-1000  ${
                  displayContent ? "visible" : "hidden"
                }  `}
              >
                {QRcode_data?.texts?.length > 0 &&
                  (QRcode_data?.texts || [])?.map((text, index) => {
                    return (
                      <div
                        className="flex flex-col  w-[95%] bg-[#eef7fd] mb-2 p-2 rounded-md border border-[#ddd]"
                        key={index}
                      >
                        <div className=" flex w-full items-center border-b justify-between">
                          <div>
                            <span className="font-bold"> {text.title}</span>
                          </div>
                          <RiDeleteBack2Line
                            size={20}
                            title="Delete content"
                            className="hover:text-red-400 cursor-pointer"
                            // onClick={() => {
                            //   setTextToDelete(text.id);
                            //   handleDeleteText();
                            // }}
                          />
                        </div>
                        {/* <h1 className="font-bold mt-5 w-full">Text content</h1> */}

                        <div>
                          <h2 className="font-bold mt-5 w-full">Content</h2>
                          <p>{text.text}</p>
                        </div>
                        <div>
                          <h2 className="font-bold mt-5 w-full">Details:</h2>
                          <p>{text.details}</p>
                        </div>
                        {/* <div className="w-full text-white flex md:items-end md:justify-end">
                          <button
                            className="cursor-pointer bg-primary bg-opacity-50 p-2 border rounded-[5px] md:w-[50%] my-2"
                            onClick={() => {
                              setContentType("Text");
                              setOpenEditContent(!openEditContent);
                              setTextId(text.id);
                            }}
                          >
                            Edit content
                          </button>
                        </div> */}
                      </div>
                    );
                  })}

                {QRcode_data?.urls?.length > 0 &&
                  (QRcode_data?.urls || [])?.map((url, index) => {
                    return (
                      <div
                        className="flex flex-col justify-between items-center w-[95%] border border-[#ddd] mb-3 p-3"
                        key={index}
                      >
                        <div className=" flex w-full items-center justify-between">
                          <div
                            // onClick={() => {
                            //   setContent((prevData) => ({
                            //     ...prevData,
                            //     url: url?.url,
                            //     title: url?.title,
                            //     details: url?.details,
                            //   }));
                            //   handleViewQrContent();
                            // }}
                            className="cursor-pointer"
                          >
                            <span className="font-bold">{url.title}</span>
                          </div>
                          <div className="flex items-center  space-x-3">
                            <RiEyeFill
                              title="View content"
                              onClick={() => {
                                setContent((prevData) => ({
                                  ...prevData,
                                  url: url?.url,
                                  title: url?.title,
                                  details: url?.details,
                                }));
                                handleViewQrContent();
                              }}
                              className="cursor-pointer"
                            />
                            {/* <RiPencilLine
                              title="Edit url content"
                              onClick={() => {
                                setContentType("Url");
                                setOpenEditContent(!openEditContent);
                                setUrlId(url.id);
                              }}
                              className="cursor-pointer"
                            /> */}

                            {/* <RiDeleteBack2Line
                              size={20}
                              title="Delete content"
                              className="hover:text-red-400 cursor-pointer"
                              onClick={() => {
                                setUrlToDelete(url.id);
                                handleDeleteUrl();
                              }}
                            /> */}
                          </div>
                        </div>

                        {/* <div className="w-full text-white flex md:items-end md:justify-end">
                      <button
                        className="cursor-pointer bg-primary bg-opacity-50 p-2 border rounded-sm md:w-[50%] my-2"
                        onClick={() => {
                          setContentType("Url");
                          setOpenEditContent(!openEditContent);
                          setUrlId(url.id);
                        }}
                      >
                        Edit content
                      </button>
                    </div> */}
                      </div>
                    );
                  })}

                {QRcode_data?.files?.length > 0 &&
                  (QRcode_data?.files || [])?.map((file, index) => {
                    return (
                      <div className="flex flex-col justify-between items-center w-[95%] border border-[#ddd] mb-3 p-3 cursor-pointer hover:bg-[#f3f3f3]">
                        <div className=" flex w-full items-center justify-between">
                          <div className="">
                            <span className="font-bold capitalize">
                              {file.title}
                            </span>
                            <span> </span>
                          </div>
                          <div className="flex items-center  space-x-3">
                            <RiEyeFill
                              title="View content"
                              onClick={() => {
                                console.log("Cocontent", file);
                                setContent((prevData) => ({
                                  ...prevData,
                                  url: file?.media[0]?.original_url,
                                  title: file?.title,
                                  details: file?.details,
                                }));
                                handleViewQrContent();
                              }}
                            />
                            {/* <RiPencilLine
                              title="Edit content"
                              onClick={() => {
                                setContentType("File");
                                setOpenEditContent(!openEditContent);
                                setFileId(file.id);
                              }}
                            /> */}
                            {/* <RiDeleteBack2Line
                              size={20}
                              title="Delete content"
                              className="hover:text-red-400 cursor-pointer"
                              onClick={() => {
                                setFileToDelete(file.id);
                                handleDeleteFile();
                              }}
                            /> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            {QRcode_data?.locations?.map((location, index) => (
              <div
                key={index}
                className="flex flex-col w-full flex-wrap items-center "
              >
                <h2 className="font-bold mt-5 w-[95%]">Location {index + 1}</h2>
                <div className="flex shadow-lg flex-col w-[95%] pt-2 h-max px-1 md:px-3 py-3 border border-light  rounded-lg">
                  <div className="flex flex-col justify-between border border-primary border-opacity-30 p-4 mt-5 rounded-lg">
                    <div className="w-full flex space-x-2 justify-end">
                      <Popover className="relative">
                        <Popover.Button
                          className={"font-bold text-xl text-light"}
                        >
                          <BsThreeDotsVertical
                            className="h-4 w-4 cursor-pointer"
                            color=""
                            size={5}
                          />
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 -translate-x-40"
                          enterTo="opacity-100 -translate-x-30"
                          leave="transition ease-in duration-500"
                          leaveFrom="opacity-100 translate-x-0"
                          leaveTo="opacity-0 translate-x-1"
                        >
                          <Popover.Panel className="absolute right-4 z-10 top-2 ">
                            <div
                              className="flex flex-col bg-white shadow-md border border-black/10  rounded-sm mt-1"
                              onClick={() => {}}
                            >
                              <div
                                className="flex flex-row items-center hover:bg-[#D9D9D9] px-4 py-2 gap-2 cursor-pointer"
                                onClick={() => {
                                  setLocationToEdit(location);
                                  handleUpdateLocation();
                                }}
                              >
                                <span className=" text-sm">Update</span>
                              </div>
                              <div
                                className="flex flex-row items-center hover:bg-[#D9D9D9] px-4 py-2 gap-2 cursor-pointer"
                                onClick={() => {
                                  setLocationToDelete(location.id);
                                  handleDeleteLocation();
                                }}
                              >
                                <span className=" text-sm text-red-600">
                                  Delete
                                </span>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </Popover>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between">
                      <div className="flex ">
                        <div className="font-bold">Province:&nbsp;</div>
                        <div>{location?.province?.name}</div>
                      </div>
                      <div className="flex">
                        <div className="font-bold">District:&nbsp;</div>
                        <div>{location?.district?.name}</div>
                      </div>
                      <div className="flex ">
                        <div className="font-bold">Sector:&nbsp;</div>
                        <div>{location?.sector?.name}</div>
                      </div>
                      <div className="flex ">
                        <div className="font-bold">Cell:&nbsp;</div>
                        <div>{location?.cell?.name}</div>
                      </div>
                      <div className="flex ">
                        <div className="font-bold">Village:&nbsp;</div>
                        <div>{location?.village?.name}</div>
                      </div>
                    </div>
                  </div>
                  {/*environments*/}
                  <div className="my-4">
                    <div className="w-full flex">Environments</div>
                    {location?.environments.length === 0 ? (
                      <h3 className="text-light">
                        No Environment found in this location
                      </h3>
                    ) : (
                      location?.environments?.map((env, index) => (
                        <div
                          key={index}
                          className="flex flex-col space-y-2 border border-primary border-opacity-20 p-3 rounded-lg my-2"
                        >
                          <div className="font-bold flex justify-between  ">
                            <h3> Environment {index + 1} </h3>

                            <Popover className="relative">
                              <Popover.Button
                                className={"font-bold text-xl text-light"}
                              >
                                <BsThreeDotsVertical
                                  className="h-4 w-4 cursor-pointer"
                                  color=""
                                  size={5}
                                />
                              </Popover.Button>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 -translate-x-40"
                                enterTo="opacity-100 -translate-x-30"
                                leave="transition ease-in duration-500"
                                leaveFrom="opacity-100 translate-x-0"
                                leaveTo="opacity-0 translate-x-1"
                              >
                                <Popover.Panel className="absolute right-4 z-10 top-2 ">
                                  <div
                                    className="flex flex-col bg-white shadow-md border border-black/10  rounded-sm mt-1"
                                    onClick={() => {}}
                                  >
                                    <div
                                      className="flex flex-row items-center hover:bg-[#D9D9D9] px-4 py-2 gap-2 cursor-pointer"
                                      onClick={() => {
                                        setEnvironmentData((prevData) => ({
                                          ...prevData,
                                          id: env?.id,
                                          name: env?.name,
                                          details: env?.details,
                                          is_active: env?.is_active,
                                          qr_code_location_id:
                                            env?.standard_qr_code_location_id,
                                        }));
                                        handleUpdateEnvirnoment(env);
                                      }}
                                    >
                                      <span className=" text-sm">Update</span>
                                    </div>
                                    <div
                                      className="flex flex-row items-center hover:bg-[#D9D9D9] px-4 py-2 gap-2 cursor-pointer"
                                      onClick={() => {
                                        setEnvironmentToDelete(env?.id);
                                        handleDeleteEnvironment();
                                      }}
                                    >
                                      <span className=" text-sm text-red-400">
                                        Delete
                                      </span>
                                    </div>
                                  </div>
                                </Popover.Panel>
                              </Transition>
                            </Popover>
                          </div>
                          <div className="flex flex-col">
                            <div className="flex flex-row bg-[#e2e2e2] border border-primary border-opacity-10 rounded-md p-2">
                              <div className="w-full flex py-2">
                                <strong className="font-semibold capitalize">
                                  name:&nbsp;
                                </strong>
                                <p>{env?.name}</p>
                              </div>
                            </div>
                            <div className=" lg:grid lg:grid-cols-3 space-x-2 bg-[#f1f0f0]">
                              {env?.placements?.length === 0 ? (
                                <h2 className="font-light text-light p-3">
                                  No Placement found in this Environment
                                </h2>
                              ) : (
                                env?.placements?.map((placement, indexx) => {
                                  return (
                                    <div
                                      key={indexx}
                                      className="flex flex-col space-y-2 border border-primary border-opacity-20 p-2 rounded-lg my-2 "
                                    >
                                      <div className="flex justify-between flex-row-reverse">
                                        <div className="flex w-12 items-center justify-between">
                                          <BsQrCodeScan
                                            title="Generate Qr code"
                                            onClick={() => {
                                              handleGenerate();
                                              setQrToGenerate((prevData) => ({
                                                ...prevData,
                                                environmentId:
                                                  placement.standard_qr_code_environment_id,
                                                placementId: placement.id,
                                              }));
                                            }}
                                            className="cursor-pointer"
                                          />
                                          <Popover className="relative">
                                            <Popover.Button
                                              className={
                                                "font-bold text-xl text-light"
                                              }
                                            >
                                              <BsThreeDotsVertical
                                                className="h-4 w-4 cursor-pointer"
                                                color=""
                                                size={5}
                                              />
                                            </Popover.Button>
                                            <Transition
                                              as={Fragment}
                                              enter="transition ease-out duration-200"
                                              enterFrom="opacity-0 -translate-x-40"
                                              enterTo="opacity-100 -translate-x-30"
                                              leave="transition ease-in duration-500"
                                              leaveFrom="opacity-100 translate-x-0"
                                              leaveTo="opacity-0 translate-x-1"
                                            >
                                              <Popover.Panel className="absolute right-4 z-10 top-2 ">
                                                <div
                                                  className="flex flex-col bg-white shadow-md border border-black/10  rounded-sm mt-1"
                                                  onClick={() => {}}
                                                >
                                                  <div
                                                    className="flex flex-row items-center hover:bg-[#D9D9D9] px-4 py-2 gap-2 cursor-pointer"
                                                    onClick={() => {
                                                      console.log(
                                                        "Gen data",
                                                        placement
                                                      );
                                                      setGeneratedQrData(
                                                        (prevData) => ({
                                                          ...prevData,
                                                          id: placement
                                                            ?.generated_standard_qr_code
                                                            ?.id,
                                                          name: QRcode_data?.name,
                                                          file: placement
                                                            ?.generated_standard_qr_code
                                                            ?.file,
                                                          reference:
                                                            placement
                                                              ?.generated_standard_qr_code
                                                              ?.reference,
                                                          status:
                                                            placement
                                                              ?.generated_standard_qr_code
                                                              ?.status,
                                                          price:
                                                            placement?.price ||
                                                            0,
                                                          scan:
                                                            placement
                                                              ?.generated_standard_qr_code
                                                              ?.scan || 0,
                                                          uuid: placement
                                                            ?.generated_standard_qr_code
                                                            ?.media[0]?.uuid,
                                                          envId:
                                                            placement.standard_qr_code_environment_id,
                                                          placementId:
                                                            placement
                                                              ?.generated_standard_qr_code
                                                              ?.standard_qr_code_placement_id,
                                                          qrId: QRcode_data?.id,
                                                          created_at:
                                                            placement
                                                              ?.generated_standard_qr_code
                                                              ?.media[0]
                                                              ?.created_at,
                                                        })
                                                      );

                                                      handleViewGenerated();
                                                    }}
                                                  >
                                                    <div className="flex">
                                                      <span className=" text-sm">
                                                        View
                                                      </span>
                                                      <BsQrCodeScan className="ml-2" />
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="flex flex-row items-center hover:bg-[#D9D9D9] px-4 py-2 gap-2 cursor-pointer"
                                                    onClick={() => {
                                                      setPlacementData(
                                                        (prevData) => ({
                                                          ...prevData,
                                                          id: placement.id,
                                                          name: placement.name,
                                                          price:
                                                            placement.price,
                                                          description:
                                                            placement.description,
                                                          is_active:
                                                            placement.is_active,
                                                          standard_qr_code_environment_id:
                                                            placement.standard_qr_code_environment_id,
                                                        })
                                                      );

                                                      handleUpdatePlacement();
                                                    }}
                                                  >
                                                    <span className=" text-sm">
                                                      Update
                                                    </span>
                                                  </div>

                                                  <div
                                                    className="flex flex-row items-center hover:bg-[#D9D9D9] px-4 py-2 gap-2 cursor-pointer"
                                                    onClick={() => {
                                                      setPlacementId(
                                                        placement.id
                                                      );
                                                      setEnvironmentPlacementToDelete(
                                                        placement.standard_qr_code_environment_id
                                                      );
                                                      handleDeletePlacement();
                                                    }}
                                                  >
                                                    <span className=" text-sm text-red-400">
                                                      Delete
                                                    </span>
                                                  </div>
                                                </div>
                                              </Popover.Panel>
                                            </Transition>
                                          </Popover>
                                        </div>
                                        <h4 className="font-bold">
                                          Placement {indexx + 1}:&nbsp;
                                        </h4>
                                      </div>
                                      <div>
                                        <div className="flex py-1 ">
                                          <strong className="font-semibold capitalize">
                                            name:&nbsp;
                                          </strong>
                                          <p>{placement?.name}</p>
                                        </div>
                                        <div className="flex ">
                                          <strong className="font-semibold capitalize">
                                            description:&nbsp;
                                          </strong>
                                          <p>{placement?.description}</p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              )}
                            </div>
                          </div>
                          <div className="flex items-end justify-end space-x-2">
                            <Button
                              content="Add Placement"
                              type="button"
                              Style="text-white bg-[#F8F9FA] w-max ml-0 p-0 h-8 text-xs bg-opacity-20"
                              onClick={() => {
                                handleAddPlacement();
                                setSelectedEnv(env.id);
                              }}
                            />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                  <div className="flex flex-col justify-between items-end">
                    <Button
                      content="Add Environment"
                      type="button"
                      btnColor="black"
                      Style="text-white w-max ml-0 p-0 h-8 text-xs "
                      onClick={() => {
                        handleAddEnvironment();
                        setLocationID(location?.id);
                      }}
                    />
                  </div>
                  {/*end*/}
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-col justify-between items-end mt-5 mr-20">
            <Button
              content="Add Location"
              type="button"
              btnColor="black"
              Style="text-white w-max ml-0 p-0 h-8 text-xs "
              onClick={() => {
                handleAddLocation();
              }}
            />
          </div>

          <UpdateQrInfo
            handleUpdateDetails={handleUpdateDetails}
            openAddContent={updateQrInfo}
            data={QRcode_data}
            setData={setQRcode_data}
            getQrCodes={getQrCodes}
            getSingleQr={getSingleQr}
          />
          <EditLocation
            open={editLocation}
            handleUpdateLocation={handleUpdateLocation}
            locationToEdit={locationToEdit}
            setLocationToEdit={setLocationToEdit}
            id={QRcode_data?.id}
            getQrCodes={getQrCodes}
            getSingleQr={getSingleQr}
          />
          <EditEnvironment
            editEnvironment={openEditEnv}
            handleUpdateEnvirnoment={handleUpdateEnvirnoment}
            setEnvironmentData={setEnvironmentData}
            environmentData={environmentData}
            qrCodeId={QRcode_data?.id}
            getQrCodes={getQrCodes}
            getSingleQr={getSingleQr}
          />
          <EditPlacement
            editPlacement={openEditPlacement}
            handleUpdatePlacement={handleUpdatePlacement}
            setPlacementData={setPlacementData}
            placementData={placementData}
            qrCodeId={QRcode_data?.id}
            getQrCodes={getQrCodes}
            getSingleQr={getSingleQr}
          />
          <AddPlacement
            handleAddPlacement={handleAddPlacement}
            openAddPlacement={openAddPlacement}
            environmentId={selectedEnv}
            qrCodeId={QRcode_data?.id}
            getQrCodes={getQrCodes}
            getSingleQr={getSingleQr}
          />
          <AddEnvironment
            handleAddEnvironment={handleAddEnvironment}
            openAddEnvironment={openAddEnvironment}
            qrCodeId={QRcode_data?.id}
            getSingleQr={getSingleQr}
            getQrCodes={getQrCodes}
            locationId={locationId}
          />
          <AddLocation
            handleAddLocation={handleAddLocation}
            open={openAddLocation}
            qrCodeId={QRcode_data?.id}
            getQrCodes={getQrCodes}
            getSingleQr={getSingleQr}
          />

          <DeleteEnvironment
            open={deleteEnvironment}
            handleDeleteEnvironment={handleDeleteEnvironment}
            qrCodeId={QRcode_data?.id}
            getQrCodes={getQrCodes}
            getSingleQr={getSingleQr}
            environmentId={environmentToDelete}
          />
          <DeleteLocation
            handleDeleteLocation={handleDeleteLocation}
            open={deleteLocation}
            qrCodeId={QRcode_data?.id}
            getSingleQr={getSingleQr}
            getQrCodes={getQrCodes}
            locationId={locationToDelete}
          />

          <DeletePlacement
            open={deletePlacement}
            handleDeletePlacement={handleDeletePlacement}
            qrCodeId={QRcode_data?.id}
            getSingleQr={getSingleQr}
            getQrCodes={getQrCodes}
            environmentId={environmentPlacementToDelete}
            placementId={placementId}
          />
          <GenerateQrCode
            qrToGenerate={qrToGenerate}
            open={openGenerate}
            handleOpen={handleGenerate}
            qrCodeId={QRcode_data?.id}
          />
          <ViewQrContent
            handleViewQrContent={handleViewQrContent}
            open={viewContent}
            content={content}
          />
          <ViewGenerated
            open={openGenerated}
            handleOpen={handleViewGenerated}
            data={generatedQrData}
          />
        </div>
      </Modal2.Body>
    </Modal2>
  );
};

export default Qrcode_Details;
