import React, { useEffect} from 'react'
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';

Quill.register('modules/imageResize', ImageResize);


const RichTextEditor = ({style, placeholder,onChange, value}) => {
    const [editorHtml, setEditorHtml] = React.useState('');
    const handleChange = (html) => {
        setEditorHtml(html);
        //console.log(html)
    }

    // console.log(editorHtml)
    
      return (
        <div className={style}>
         <ReactQuill
        onChange={onChange}
        value={value}
        modules={RichTextEditor.modules}
        formats={RichTextEditor.formats}
        bounds={'#root'}
        placeholder={placeholder}
        className='w-full h-full'
        theme='snow'
      />
        </div>
      );
  
}


RichTextEditor.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    ['link', 'image', 'video'],
    ['clean'],
    [{width:['10%','20%','30%','40%','50%','60%','70%','80%','90%','100%']}]
  ],
  clipboard: {
    matchVisual: false
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  }
};

RichTextEditor.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'width'
];

export default RichTextEditor