import React from 'react';
import { RxCross2 } from 'react-icons/rx';

const Payment = ({ paymentNotifications }) => {
  
  const currentDate = new Date();
  const yesterdayDate = new Date(currentDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  const groupedNotifications = {};
  paymentNotifications.forEach((notification) => {
    const created_at = notification.created_at;
    const dateKey = created_at.toDateString(); // Use the date as the key for grouping

    if (!groupedNotifications[dateKey]) {
      groupedNotifications[dateKey] = [];
    }

    groupedNotifications[dateKey].push(notification);
  });

  const today = new Date().toDateString();
  const yesterday = new Date(
    new Date().getTime() - 24 * 60 * 60 * 1000
  ).toDateString();

  const previousDaysNotifications = Object.keys(groupedNotifications).reduce(
    (accumulator, dateKey) => {
      if (dateKey !== today && dateKey !== yesterday) {
        accumulator.push(...groupedNotifications[dateKey]);
      }
      return accumulator;
    },
    []
  );
  
  return (
    <div className="flex flex-col">
      {today in groupedNotifications && (
        <React.Fragment key={today}>
          <h2 className="font-bold p-4 border border-light/30 text-light">
            Today
          </h2>
          {groupedNotifications[today].map((notification) => {
            const dateObj = new Date(notification.created_at);
            const formattedDate = dateObj.toLocaleDateString('en', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            });

            const formattedTime = dateObj.toLocaleTimeString('en', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            });
            return (
              <div
                className="border border-light/30 rounded p-4 cursor-pointer"
                onClick={() => {
                  // handleNotificationType(notification.type);
                  // setCampaignId(notification.subjectId);
                }}
                key={notification.id}
              >
                <div className="flex flex-row items-start gap-2 w-full">
                  <img
                    src={notification.image}
                    alt=""
                    className="w-[100px] h-[50px] object-contain"
                  />
                  <div className="flex justify-between items-center w-full">
                    <div className="flex flex-col">
                      <p className="text-sm">
                        <b className="px-1">{notification.sender}</b>
                        {notification.message}
                        <b className="pl-1">{notification.subject}</b>
                      </p>
                      <p className="flex items-center py-2 text-sm font-bold text-light">
                        {formattedTime}
                        <span className="h-[5px] w-[5px] bg-light rounded-full mx-2"></span>
                        {formattedDate}
                      </p>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-1">
                      <div className="h-[7px] w-[7px] rounded-full bg-gray"></div>
                      <RxCross2 className="cursor-pointer" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </React.Fragment>
      )}

      {yesterday in groupedNotifications && (
        <React.Fragment key={yesterday}>
          <h2 className="font-semibold  p-4 border border-light/30 text-light">
            Yesterday
          </h2>
          {groupedNotifications[yesterday].map((notification) => {
            const dateObj = new Date(notification.created_at);
            const formattedDate = dateObj.toLocaleDateString('en', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            });

            const formattedTime = dateObj.toLocaleTimeString('en', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            });
            return (
              <div
                className="border-b border-light/30 rounded p-4 w-full cursor-pointer"
                onClick={() => {
                  // handleNotificationType(
                  //   notification.type,
                  //   notification.subjectId
                  // );
                }}
                key={notification.id}
              >
                <div className="flex flex-row items-start gap-2 w-full">
                  <img
                    src={notification.image}
                    alt=""
                    className="w-[100px] h-[50px] object-contain"
                  />
                  <div className="flex items-center justify-between w-full">
                    <div className="flex flex-col">
                      <p className="text-sm">
                        <b className="px-1">{notification.sender}</b>
                        {notification.message}
                        <b className="pl-1">{notification.subject}</b>
                      </p>
                      <p className="flex items-center py-2 text-sm font-bold text-light">
                        {formattedTime}
                        <span className="h-[5px] w-[5px] bg-light rounded-full mx-2"></span>
                        {formattedDate}
                      </p>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-1">
                      <div className="h-[7px] w-[7px] rounded-full bg-gray"></div>
                      <RxCross2 className="cursor-pointer" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </React.Fragment>
      )}

      {previousDaysNotifications.length > 0 && (
        <React.Fragment key="previousDays">
          <h2 className="font-semibold p-4 border border-light/30 text-light">
            Previous Days
          </h2>
          {previousDaysNotifications.map((notification) => {
            const dateObj = new Date(notification.created_at);
            const formattedDate = dateObj.toLocaleDateString('en', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            });

            const formattedTime = dateObj.toLocaleTimeString('en', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            });

            return (
              <div
                className="border-b border-light/30 rounded p-4 cursor-pointer"
                key={notification.id}
                onClick={() => {
                  // handleNotificationType(
                  //   notification.type,
                  //   notification.subjectId
                  // );
                }}
              >
                <div className="flex flex-row items-start gap-2">
                  <img
                    src={notification.image}
                    alt=""
                    className="w-[100px] h-[50px] object-contain"
                  />
                  <div className="flex items-center justify-between w-full">
                    <div className="flex flex-col">
                      <p className="text-sm">
                        <b className="px-1">{notification.sender}</b>
                        {notification.message}
                        <b className="pl-1">{notification.subject}</b>
                      </p>

                      <p className="flex items-center py-2 text-sm font-bold text-light">
                        {formattedTime}
                        <span className="h-[5px] w-[5px] bg-light rounded-full mx-2"></span>
                        {formattedDate}
                      </p>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-1">
                      <div className="h-[7px] w-[7px] rounded-full bg-gray"></div>
                      <RxCross2 className="cursor-pointer" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </React.Fragment>
      )}
    </div>
  );
};

export default Payment;
