import React, { Fragment, useEffect, useState } from 'react';
import Input from '../../components/Input';
import { useForm } from 'react-hook-form';
import Button from '../../components/Button';
import Table from '../../components/tableComponent/Table';
import { BiEdit, BiSearch } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';
import Drag from '../../components/Drag';
import Axios from '../../components/Axios';
import { ToastComponent, ToasterComponent } from '../../components/Toast';
import Modal from '../../components/modal/Modal';
import Select from '../../components/Select';
import { Popover, Transition } from '@headlessui/react';
import { FiMoreVertical } from 'react-icons/fi';
import AddBank from './addBank/AddBank';
import AddAccount from './addBankAccount/AddAccount';
import DeleteBank from './DeleteBank';
import EditBank from './EditBank';
import DeleteBankAccount from './DeleteAccount';
import EditBankAccount from './EditBankAccount';
import Confirm from '../modals/Confirm';
import BankAccountPayment from './bankPayment/BankAccountPayment';
import { APP } from '../../utils/constant';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchBanks,
  fetchBankAccounts,
  fetchBankAccountPayment,
} from '../../redux/actions/bank';

const BankInformation = ({
  config,
  SkeletonTable,
  skeletonData,
  skeletonColumns,
}) => {
  const dispatch = useDispatch();

  const { is_fetching_banks, is_fetching_bank_accounts, banks, bank_accounts } =
    useSelector((state) => state.bank);

  console.log(banks, 'banks console');
  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const [edit, setEdit] = useState(false);
  const [files, setFiles] = useState([]);
  const [isError, setIsError] = useState(false);
  const [bankId, setBankId] = useState(banks.length > 0 ? banks[0].id : '');
  const [bankData, setBankData] = useState([]);
  const [bankOptions, setBankOptions] = useState([]);

  const [bankAccountId, setBankAccountId] = useState('');
  const [bankAccounts, setBankAccounts] = useState([]);
  const [editBankAccountModal, setEditBankAccountModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [triggerFetchBankAccount, setTriggerFetchBankAccount] = useState(false);
  const [activeBank, setActiveBank] = useState('');
  const [isBankEmpty, setIsBankEmpty] = useState(false);

  const [toggleData, setToggleData] = useState(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  // const [fetchBanks, setFetchBanks] = useState(false);
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openAccountModal, setOpenAccountModal] = React.useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editBankModal, setEditBankModal] = useState(false);
  const [deleteBank, setDeleteBank] = useState(false);
  const [currency, setCurency] = useState([]);

  const [countries, setCountries] = useState([]);
  const [bankPaymentModal, setBankPaymentModal] = useState(false);

  const [data, setData] = useState({
    name: '',
    swift_code: '',
    country_id: 1,
    logo: '',
  });

  useEffect(() => {
    if (bankData.length > 0) {
      const options = bankData.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setBankOptions(options);
    }
  }, [bankData]);

  const [addBankAccountData, setAddBankAccountData] = useState({
    account_name: '',
    account_number: '',
    currency_id: 1,
    status: 'active',
  });

  const [editBankAccountData, setEditBankAccountData] = useState({
    account_number: '',
    account_name: '',
    bank_id: '',
    status: '',
    currency_id: 1,
  });

  const [editBankData, setEditBankData] = useState({
    name: '',
    country_id: 1,
    swift_code: '',
    status: '',
    logo: '',
  });
  const handleEditBankAccountModal = () => {
    setEditBankAccountModal(!editBankAccountModal);
  };
  const handleDeleteBankAccountModal = () => {
    setConfirmDelete(!confirmDelete);
  };
  const handleConfirmAddAccountModal = () => {
    setConfirm(!confirm);
  };

  const handleDeleteBankModal = () => {
    setDeleteBank(!deleteBank);
  };

  const handleTriggerFetchBankAccount = () => {
    setTriggerFetchBankAccount(!triggerFetchBankAccount);
  };

  const handleOpenModal1 = () => {
    setOpenModal(!openModal);
  };
  const handleOpenAccountModal = () => {
    setOpenAccountModal(!openAccountModal);
  };
  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };
  const handleEditBankModal = () => {
    setEditBankModal(!editBankModal);
  };

  const getCurrency = () => {
    try {
      Axios.get(APP.API_MAIN_URL + '/utils/user/currencies', config).then(
        (res) => {
          setCurency(res.data.data);
        }
      );
    } catch (error) {
      console.log('Error in retrieving currency', error);
    }
  };

  const getCountry = () => {
    try {
      Axios.get(APP.API_MAIN_URL + '/utils/location/countries', config).then(
        (res) => {
          setCountries(res.data.data);
        }
      );
    } catch (error) {
      console.log('Error in retrieving countries', error);
    }
  };
  // bank account table
  const columns = [
    {
      Header: 'Account Name',
      accessor: 'account_name',
    },
    {
      Header: 'Account Number',
      accessor: 'account_number',
    },
    {
      Header: 'Bank Name',
      Cell: ({ row }) => {
        const matchingBank = bankData.find(
          (item) => item.id === row.original.bank_id
        );
        return <div>{matchingBank ? matchingBank.name : 'N/A'}</div>;
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Currency',
      Cell: ({ row }) => {
        const matchingCurrency = currency.find(
          (item) => item.id == row.original.currency_id
        );

        return (
          <div>
            {matchingCurrency
              ? `${matchingCurrency.name}(${matchingCurrency.symbol})`
              : 'N/A'}
          </div>
        );
      },
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  font-bold">
            <Popover className="relative">
              <Popover.Button>
                <FiMoreVertical size={30} className="cursor-pointer" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10">
                  <div
                    className="flex flex-col bg-white shadow-md border border-black/10  rounded-sm text-xs "
                    onClick={() => {
                      setBankAccountId(row.original.id);
                    }}
                  >
                    <h1
                      className="px-4 py-2 hover:bg-[#D9D9D9] text-center"
                      onClick={() => {
                        console.log(
                          bankId,
                          row.original.id,
                          'testing bank Account Id'
                        );
                        dispatch(
                          fetchBankAccountPayment(bankId, row.original.id)
                        );
                        setBankPaymentModal(true);
                      }}
                    >
                      Payments
                    </h1>

                    <h1
                      className="px-4 py-2 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                      onClick={() => {
                        setEditBankAccountData({
                          account_name: row.original.account_name,
                          account_number: row.original.account_number,
                          status: row.original.status,
                          currency_id: row.original.currency_id,
                          bank_id: row.original.bank_id,
                        });
                        handleEditBankAccountModal();
                      }}
                    >
                      Edit
                    </h1>

                    <h1
                      className="px-4 py-2 hover:bg-[#D9D9D9] text-center"
                      onClick={() => {
                        setConfirmDelete(true);
                      }}
                    >
                      Delete
                    </h1>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
        );
      },
    },
  ];

  const handleBankPaymentModal = () => {
    setBankPaymentModal(!bankPaymentModal);
  };

  // bank account table
  const getBankAccounts = (bankId) => {
    Axios.get(APP.API_MAIN_URL + `/admin/banks/${bankId}/accounts`, config)
      .then((res) => {
        if (res.data.data.length === 0) {
        } else {
          setBankAccounts(res.data.data);
        }
        setIsDataFetching(false);
      })
      .catch((error) => {
        console.log(error);
        // notify('error', error.message);
      });
  };

  useEffect(() => {
    getBanks();
  }, [bankId, triggerFetchBankAccount]);

  useEffect(() => {
    dispatch(fetchBanks());
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const onSubmit = () => {
    try {
      if (files?.length === 0) {
        setIsError(true);
        setTimeout(() => {
          setIsError(false);
        }, 5000);
        console.log(isError, 'isError');
      } else {
        edit
          ? Axios.put(
              APP.ADVERTISER_API_URL + `/account/bank-details`,
              { ...data, logo: files[0] },
              config
            )
              .then((res) => {
                notify('success', 'updated successfully!');

                reset();
                setEdit(false);
                setToggleData(!toggleData);
                setFiles([]);
              })
              .catch((err) => {
                notify('error', 'request failed, please try again');
                console.log(err);
              })
          : Axios.post(
              APP.ADVERTISER_API_URL + '/admin/banks',
              { ...data, logo: files[0] },
              config
            )
              .then((res) => {
                notify('success', 'added successfully');

                reset();
                setToggleData(!toggleData);
              })
              .catch((err) => {
                notify('error', 'request failed, please try again');
                console.log(err);
              });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBanks = () => {
    // setFetchBanks(true);
    Axios.get(APP.ADVERTISER_API_URL + '/admin/banks', config)
      .then((res) => {
        setBankData(res.data.data);
        // setFetchBanks(false);
        setIsBankEmpty(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const delete_Info = () => {
    Axios.delete(APP.ADVERTISER_API_URL + `/account/bank-details/${id}`, config)
      .then((res) => {
        notify('success', 'deleted successfully!');
        setToggleData(!toggleData);
        setOpen(!open);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBanks();
  }, [toggleData, data]);

  useEffect(() => {
    getCountry();
    getCurrency();
  }, []);

  return (
    <div className="bg-secondary border border-white shadow-lg w-full h-max">
      <div className="flex flex-col space-y-12 lg:w-full w-[95%] mx-auto py-4"></div>
      <div className="w-full h-full">
        <Modal
          isOpen={open}
          onClose={() => setOpen(!open)}
          Style={'lg:w-1/4 w-3/4 m-auto h-max flex z-[10]'}
        >
          <Modal.Body>
            <div>
              <p>Confirm the deletion ?</p>
            </div>
            <div className="flex flex-row justify-between w-full mt-4">
              <Button
                type="button"
                content={'Confirm'}
                loading={false}
                btnColor="primary"
                Style={'right-[0px] bottom-[0px] text-white w-fit p-8'}
                onClick={() => delete_Info()}
              />
              <Button
                type="button"
                content={'Close'}
                loading={false}
                btnColor="primary"
                Style={'right-[0px] bottom-[0px] text-white w-fit p-8'}
                onClick={() => setOpen(!open)}
              />
            </div>
          </Modal.Body>
        </Modal>

        <AddBank
          openModal={openModal}
          handleOpenModal={handleOpenModal}
          data={data}
          setData={setData}
          files={files}
          setFiles={setFiles}
          isError={isError}
          setIsError={setIsError}
          edit={edit}
          config={config}
          setEdit={setEdit}
          setToggleData={setToggleData}
          toggleData={toggleData}
          handleOpenAccountModal={handleOpenAccountModal}
          openModal2={confirm}
          handleConfirmAddAccountModal={handleConfirmAddAccountModal}
        />
        <AddAccount
          openAccountModal={openAccountModal}
          handleAccountOpenModal={handleOpenAccountModal}
          addBankAccountData={addBankAccountData}
          setAddBankAccountData={setAddBankAccountData}
          bankId={bankId}
          config={config}
          getBanks={getBanks}
        />

        <DeleteBank
          openModal={deleteBank}
          handleDeleteBankModal={handleDeleteBankModal}
          bankId={bankId}
          getBanks={getBanks}
        />
        <DeleteBankAccount
          openModal1={confirmDelete}
          handleDeleteBankAccountModal={handleDeleteBankAccountModal}
          bankId={bankId}
          bankAccountId={bankAccountId}
        />
        <EditBank
          openModal={editBankModal}
          handleEditBankModal={handleEditBankModal}
          bankId={bankId}
          files={files}
          setFiles={setFiles}
          data={data}
          setData={setData}
          isError={isError}
          setIsError={setIsError}
          edit={edit}
          config={config}
          setEdit={setEdit}
          setToggleData={setToggleData}
          toggleData={toggleData}
          bankData={bankData}
          countries={countries}
          editBankData={editBankData}
          setEditBankData={setEditBankData}
        />
        <EditBankAccount
          handleEditBankAccountModal={handleEditBankAccountModal}
          openModal={editBankAccountModal}
          bankAccountId={bankAccountId}
          getBanks={getBanks}
          editBankAccountData={editBankAccountData}
          setEditBankAccountData={setEditBankAccountData}
        />
        <Confirm
          openModal2={confirm}
          handleConfirmAddAccountModal={handleConfirmAddAccountModal}
          handleOpenAccountModal={handleOpenAccountModal}
        />

        <BankAccountPayment
          openModal={bankPaymentModal}
          handleOpenModal={handleBankPaymentModal}
          bankId={bankId}
          bankAccountId={bankAccountId}
        />

        {bankData.length > 0 ? (
          <div className="overflow-y-auto">
            <div className="w-full flex flex-col mb-4 ">
              <h2 className="text-2xl font-bold">Banks ({bankData.length})</h2>
            </div>
            <div className="w-full h-full flex flex-col lg:flex-row gap-6 ">
              <div className="lg:hidden flex flex-col border border-black/10 rounded p-4">
                <div className="flex gap-4 justify-between items-center ">
                  <Popover className="relative">
                    <Popover.Button>
                      <FiMoreVertical size={20} className="cursor-pointer" />
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 top-[50%]">
                        <div className="flex flex-col bg-white shadow-md border border-black/10  rounded-sm text-xs absolute right-0 top-full font-bold">
                          <h1
                            className="px-4 py-2 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                            onClick={() => {
                              //   if (activeBank) {
                              //     setEditBankName(activeBank.label);
                              //   } else {
                              //     setEditBankName(bankOptions[0].label);
                              //   }
                              //   handleEditBankModal();
                              // console.log(activeBank);
                            }}
                          >
                            Edit
                          </h1>
                          <h1
                            className="px-4 py-2 hover:bg-[#D9D9D9] text-center"
                            onClick={() => {
                              setDeleteBank(true);
                            }}
                          >
                            Delete
                          </h1>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                </div>
                <Button
                  content="+ Add bank"
                  loading={false}
                  onClick={handleOpenModal}
                  btnColor="primary"
                  Style="text-white w-full h-[40px] mr-4 ml-0 mt-8"
                />
              </div>

              <div className="hidden lg:flex w-full lg:min-w-[239px] lg:max-w-[239px] flex-col mx-auto mb-8 lg:mb-0 ">
                <div className="w-full max-h-[450px]  flex flex-col bg-white border border-black/10 rounded-lg pb-4 ">
                  <div className="w-full flex flex-col justify-between border  border-white border-b-[0.5px]  px-5 ">
                    <p className="italic text-[#414141] mt-[21px]">All Banks</p>
                    <div className="flex  w-full border rounded border-[#5E5B5B] min-h-[40px] mb-4 justify-center items-center px-2">
                      <BiSearch size={20} />
                      <input
                        defaultValue=""
                        placeholder="Search"
                        className="w-full rounded outline-none font-nunito"
                      />
                    </div>
                    <hr className="mt-2" />
                  </div>
                  <div className="pt-2 mx-[20px] overflow-y-auto">
                    <div className="  text-base ">
                      {banks.map((bank, index) => (
                        <div
                          className="flex flex-row items-center mb-1"
                          onClick={() => {
                            setBankId(bank.id);
                            dispatch(fetchBankAccounts(bank.id));
                            // console.log("Set bank id:", bankId);
                          }}
                          key={index}
                        >
                          <h1
                            className={`hover:bg-primary cursor-pointer hover:text-white py-2 w-full px-5 font-bold rounded-sm ${
                              bank.id === bankId ? 'bg-primary text-white' : ''
                            }`}
                            onClick={() => {
                              if (bank.id !== bankId) {
                                // setIsDataFetching(true);
                              }
                            }}
                          >
                            {bank.name}
                          </h1>
                          <Popover className="relative">
                            <Popover.Button>
                              <FiMoreVertical
                                size={20}
                                className="cursor-pointer"
                              />
                            </Popover.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="absolute z-10 top-[50%]">
                                <div className="flex flex-col bg-white shadow-md border border-black/10  rounded-sm text-xs absolute right-0 top-full font-bold">
                                  <h1
                                    className="px-4 py-2 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                                    onClick={() => {
                                      setEditBankData((prevData) => {
                                        return {
                                          ...prevData,
                                          name: bank.name,
                                          swift_code: bank.swift_code,
                                          status: bank.status,
                                          country_id: 1,
                                        };
                                      });
                                      handleEditBankModal();
                                    }}
                                  >
                                    Edit
                                  </h1>
                                  <h1
                                    className="px-4 py-2 hover:bg-[#D9D9D9] text-center cursor-pointer"
                                    onClick={() => {
                                      setDeleteBank(true);
                                    }}
                                  >
                                    Delete
                                  </h1>
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </Popover>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <Button
                  content="+ Add Bank"
                  loading={false}
                  onClick={handleOpenModal}
                  btnColor="primary"
                  Style="text-white w-full h-[40px] mr-4 ml-0 mt-8"
                />
              </div>

              <div className="flex flex-col w-full px-0">
                <div className="overflow-x-auto my-auto">
                  {!isDataFetching ? (
                    banks.length === 0 ? (
                      <p className="text-[20px] text-[#808080] font-bold flex justify-center items-center">
                        No recent Bank account added yet, click on add Account
                        button to add your Bank account
                      </p>
                    ) : bank_accounts.length > 0 ? (
                      <Table
                        columns={columns}
                        data={
                          bank_accounts
                          // .length > 0 ? bankAccounts : bankData
                        }
                        title="bank file"
                        className="mx-0"
                      />
                    ) : (
                      <p className="text-[20px] text-[#808080] font-bold flex justify-center items-center">
                        No recent Bank account added yet, click on add Account
                        button to add your Bank account
                      </p>
                    )
                  ) : (
                    <SkeletonTable
                      columns={skeletonColumns}
                      data={skeletonData}
                    />
                  )}

                  <div
                    className={`${
                      isDataFetching ? 'hidden' : 'flex'
                    }  flex-row justify-end items-end pr-4`}
                  >
                    <Button
                      type="submit"
                      content="Add Account"
                      loading={false}
                      btnColor="primary"
                      Style={'text-white w-max h-[40px] ml-0'}
                      onClick={() => {
                        handleOpenAccountModal();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex flex-col space-y-[24px] my-auto">
              <h1 className="font-[600] text-3xl leading-[44px]">Banks</h1>
              <p className="font-[600] text-[20px] text-[#808080]">
                No recent bank added yet, click below to add your first Bank
              </p>
              <Button
                type="submit"
                content="+ Add Bank"
                loading={false}
                btnColor="primary"
                Style={'text-white w-max h-[40px] mt-2 ml-0'}
                onClick={handleOpenModal1}
              />
            </div>
          </div>
        )}

        <ToasterComponent />
      </div>
    </div>
  );
};

export default BankInformation;
