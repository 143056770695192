import React from "react";
import Modal from "../../../../components/modal/Modal";
import MultiSelect from "../../../../components/MultiSelect";
import Select from "../../../../components/Select";
import { useForm } from "react-hook-form";

function UpdateSubSettings({ openModal, handleModal }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,

    control,
  } = useForm();
  return (
    <div>
      <Modal
        isOpen={openModal}
        onClose={() => {
          handleModal();
          reset();
        }}
        Style={"w-[90%] lg:w-1/3  flex m-auto py-4"}
      >
        <Modal.Header>
          <h1 className="font-bold">Update Subscription Category Settings</h1>
        </Modal.Header>
        <Modal.Body>
          {/* <MultiSelect
            label="Choose Languages"
            customClass={"w-full mb-2 "}
            className="w-full mb-2"
            isLoading={false}
            placeholder={"Select languages"}
            handleChange={(e) => {
              handleLanguage(e.map((e) => e));
            }}
            options={languages}
            loading={false}
            register={{
              control,
              name: "languages",
              rules: { required: "Languages are required" },
            }}
            error={errors["languages"]?.message}
          />
          <div className="mt-5">
            <Select
              label={"Default Language"}
              labelFor={"default_language"}
              className="w-full"
              isLoading={false}
              placeholder={"Select default language"}
              handleChange={(e) => {
                handleDefaultLanguage(e);
              }}
              options={languages
                .map((language) => {
                  if (
                    name?.lan?.some(
                      (selected) => selected.language_id === language.value
                    )
                  ) {
                    return {
                      value: language.value,
                      label: language.label,
                    };
                  }
                  //  return statement here for cases where the condition is not met.
                  return null;
                })
                .filter(Boolean)}
              customClass={"mb-2"}
              register={{
                control,
                name: "Default_language",
                rules: { required: "Default language is required" },
              }}
              error={errors["Default_language"]?.message}
            /> */}
          {/* </div> */}
          no need to updated default language
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UpdateSubSettings;
