import React, { useEffect } from "react";
import Input from "../../../../../components/Input";
import Select from "../../../../../components/Select";
import MultiSelect from "../../../../../components/MultiSelect";
import { RiErrorWarningLine } from "react-icons/ri";
import CircularLoader from "../../../../../components/CircularLoader";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../../../../redux/actions/articles";

function AddFrenchCategory({
  clearErrors,
  register,
  errors,
  control,
  name,
  setNames,
  isTranslated,
  translatedFr,
  translationLoading,
  lastMod,
  setLastMod,
  typeOptions,
}) {
  const { articleCategories } = useSelector((state) => state.articleCategory);
  const { plans, categories } = useSelector((state) => state.subscription);
  const dispatch = useDispatch();
  const handleAddName = (e) => {
    const newName = e.target.value;
    setNames({ ...name, name: newName });
    setLastMod((prev) => ({ ...prev, name: { ...prev.name, fr: newName } }));
  };
  const handleParent = (e) => {
    setLastMod((prev) => ({
      ...prev,
      parent: e,
    }));
  };
  const handleCategory = (e) => {
    setLastMod((prev) => ({ ...prev, category: e }));
  };
  const handlePlan = (array) => {
    setLastMod((prev) => ({ ...prev, subscription_plans: array }));
  };
  useEffect(() => {
    dispatch(getCategory());
  }, []);
  return (
    <div>
      {translationLoading ? (
        <div className="flex items-center w-full mt-4">
          <div className="flex gap-2 items-center m-auto">
            <CircularLoader />
            <p>Traduction en cours...</p>
          </div>
        </div>
      ) : null}
      {isTranslated ? (
        <div className="flex gap-2 mt-6 mb-2">
          <RiErrorWarningLine size={30} color="blue" />
          <h1>Vérifie bien les textes traduits</h1>
        </div>
      ) : (
        ""
      )}
      <Input
        labelText={"Nom"}
        labelFor={"name"}
        name={`name`}
        type={"text"}
        placeholder={"Entrez le nom de la catégorie"}
        isRequired={true}
        customClass="w-full mb-2 w-[75%] ml-1"
        onChange={(e) => {
          clearErrors("name");
          handleAddName(e);
        }}
        value={lastMod?.name["fr"]}
        register={register}
        errors={errors}
      />
      <Select
        label={"Catégorie Parentale"}
        labelFor={"parent"}
        className="w-full py-2 first-letter"
        isLoading={false}
        placeholder={"Sélectionner un parent"}
        onChange={(e) => {
          handleParent(e);
        }}
        value={lastMod.parent}
        options={articleCategories.map((cat) => {
          return { value: cat.id, label: cat.name.fr };
        })}
      />
      <Select
        label="Content type"
        customClass="w-full mb-2 text-[18px]"
        isRequired={false}
        placeholder={"Content type"}
        options={typeOptions}
        register={{
          control,
          name: `contentType`,
          rules: { required: "contentType is required" },
        }}
        defaultValue={lastMod?.content_type}
        handleChange={(e) => {
          setLastMod((prev) => ({
            ...prev,
            content_type: e,
          }));
        }}
        error={errors[`contentType`]?.message}
      />
      {/* <Select
        label={"Catégorie d'abonnement"}
        labelFor={"category"}
        className="w-full py-2 first-letter"
        isLoading={false}
        placeholder={"Choisir une catégorie"}
        onChange={(e) => {
          handleCategory(e);
          console.log(e);
        }}
        options={categories.map((cat) => {
          return { value: cat.id, label: cat.name["fr"] };
        })}
        value={lastMod.category}
      />
      <MultiSelect
        label="Choisir plan"
        customClass={"w-full mb-2 "}
        className="w-full mb-2"
        isLoading={false}
        placeholder={"Choisir plan"}
        handleChange={(e) => {
          handlePlan(e.map((e) => e));
        }}
        options={plans.map((plan) => {
          return {
            value: plan.id,
            label: plan.name.en,
          };
        })}
        loading={false}
        defaultValue={lastMod.subscription_plans}
        register={{
          control,
          name: "plan",
          rules: { required: "plan are required" },
        }}
        error={errors["languages"]?.message}
      /> */}
    </div>
  );
}

export default AddFrenchCategory;
