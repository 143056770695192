import React, { useEffect, useState } from "react";
import Modal from "../../../../components/modal/Modal";

import { useForm } from "react-hook-form";
import Button from "../../../../components/Button";
import { ToastComponent } from "../../../../components/Toast";
import axios from "axios";
import { APP, config } from "../../../../utils/constant";
import { useDispatch, useSelector } from "react-redux";

import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { IoArrowBackOutline } from "react-icons/io5";

import AddEnglishCategory from "./translate/AddEnglishCategory";
import AddKinyarwandaCategory from "./translate/AddKinyarwandaCategory";
import AddFrenchCategory from "./translate/AddFrenchCategory";

import Modal2 from "../../../../components/modal/Modal2";
import UpdateAddLanguage from "./updateAddLanguage";
import {
  getCategory,
  setSelecteLanguage,
} from "../../../../redux/actions/articles";

const UpdateCategory = ({ openModal, handleModal, lastMod, setLastMod }) => {
  const { token } = useSelector((state) => state.user);
  const { selectedLangauge } = useSelector((state) => state.subscription);
  const [translationLoading, setTranslationLoading] = useState(false);
  const [isTranslatedToRw, setIsTranslatedToRw] = useState(false);
  const [isTranslatedToEn, setIsTranslatedToEn] = useState(false);
  const [isTranslatedToFr, setIsTranslatedToFr] = useState(false);

  const [name, setNames] = useState({ name: "", description: "" });

  const [allLanguage, setAllLanguage] = useState([]);
  const [translatedRw, setTranslatedRw] = useState({});
  const [translatedEn, setTranslatedEn] = useState({});
  const [translatedFr, setTranslatedFr] = useState({});

  const [translatedRw2, setTranslatedRw2] = useState({});
  const [translatedEn2, setTranslatedEn2] = useState({});
  const [translatedFr2, setTranslatedFr2] = useState({});

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,

    control,
  } = useForm();

  // Steppers
  const [steps, setSteps] = useState([0]);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const handleNext = () => {
    if (activeStep === selectedName.length && selectedName.length !== 0) {
      let obj = {
        name: lastMod?.name,
        parent_id: lastMod?.parent?.value,
        status: lastMod?.status,
        languages: selectedLangauge,
        content_type: lastMod?.content_type?.value,
      };
      console.log("UPDATED OBJECT", obj);
      axios
        .put(
          APP.API_MAIN_URL + `/admin/vas/categories/${lastMod.id}`,
          obj,
          config(token)
        )
        .then((res) => {
          setLoading(false);
          reset();
          dispatch(getCategory());
          notify("success", "Category updated Successfully!!");
          handleReset();
          handleModal();
        })
        .catch((error) => {
          setLoading(false);
          notify("error", error.response.data.message);
          console.log("Error in updating category", error);
        });
    }
    let newStep = steps;
    if (activeStep === 0) {
      if (lastMod.languages && lastMod.languages.length !== 0) {
        dispatch(setSelecteLanguage(lastMod.languages));
        if (activeStep < steps.length && activeStep < selectedName.length - 1) {
          let newSkipped = skipped;
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setSkipped(newSkipped);
        }
        if (
          activeStep < steps.length &&
          steps.length !== selectedName?.length + 1
        ) {
          newStep.push(activeStep + 1);
          setSteps(newStep);
        }
      }
    }

    if (activeStep === 1) {
      const obj = {
        contents: {
          name:
            name.name === "" ? lastMod.name[selectedName[0].code] : name.name,
        },
        current_language: selectedName[0].code,
        target_language: selectedName[1].code,
      };
      const translations = [
        {
          language: selectedName[0].code,
          name:
            name.name === "" ? lastMod.name[selectedName[0].code] : name.name,
        },
      ];
      translations.forEach((translation) => {
        const { language, name } = translation;
        setLastMod((prev) => ({
          ...prev,
          name: { ...prev.name, [language]: name },
        }));
      });

      setTranslationLoading(true);
      selectedName[1].code === "rw"
        ? setIsTranslatedToRw(true)
        : selectedName[1].code === "fr"
        ? setIsTranslatedToFr(true)
        : setIsTranslatedToEn(true);
      axios
        .post(
          APP.API_MAIN_URL + "/utils/translations/translate",
          obj,
          config(token)
        )
        .then((res) => {
          setTranslationLoading(false);

          if (selectedName[1].code === "fr") {
            const backend = res.data.translated_contents;
            const translations = [
              {
                language: "fr",
                name: backend.name,
              },
            ];

            translations.forEach((translation) => {
              const { language, name } = translation;
              setLastMod((prev) => ({
                ...prev,
                name: { ...prev.name, [language]: name },
              }));
            });

            setTranslatedFr(res.data.translated_contents);
          } else if (selectedName[1].code === "rw") {
            const backend = res.data.translated_contents;

            const translations = [
              {
                language: "rw",
                name: backend.name,
              },
            ];

            translations.forEach((translation) => {
              const { language, name } = translation;
              setLastMod((prev) => ({
                ...prev,
                name: { ...prev.name, [language]: name },
              }));
            });
            setTranslatedRw(res.data.translated_contents);
          } else {
            const backend = res.data.translated_contents;

            const translations = [
              {
                language: "en",
                name: backend.name,
              },
            ];

            translations.forEach((translation) => {
              const { language, name } = translation;
              setLastMod((prev) => ({
                ...prev,
                name: { ...prev.name, [language]: name },
              }));
            });
            setTranslatedEn(res.data.translated_contents);
          }
        })
        .catch((error) => {
          setTranslationLoading(false);
          console.log("Error in retrieving translated language", error);
        });
      if (activeStep < steps.length && activeStep < selectedName.length + 1) {
        let newSkipped = skipped;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
      if (
        activeStep < steps.length &&
        steps.length !== selectedName?.length + 1
      ) {
        if (
          // newStep.length < selectedLangauge?.length &&
          activeStep <
          selectedName.length - 1
        ) {
          newStep.push(activeStep + 1);
          setSteps(newStep);
        }
      }
    }
    if (activeStep === 2) {
      const obj = {
        contents: {
          name:
            name.name === "" ? lastMod.name[selectedName[0].code] : name.name,
        },
        current_language: selectedName[0]?.code,
        target_language: selectedName[2]?.code,
      };
      setTranslationLoading(true);
      selectedName[2]?.code === "rw"
        ? setIsTranslatedToRw(true)
        : selectedName[2]?.code === "fr"
        ? setIsTranslatedToFr(true)
        : setIsTranslatedToEn(true);
      axios
        .post(
          APP.API_MAIN_URL + "/utils/translations/translate",
          obj,
          config(token)
        )
        .then((res) => {
          setTranslationLoading(false);
          console.log("Second translation", res.data.translated_contents);

          if (selectedName[2].code === "fr") {
            const backend = res.data.translated_contents;
            const translations = [
              {
                language: "fr",
                name: backend?.name,
              },
            ];

            translations.forEach((translation) => {
              const { language, name } = translation;
              setLastMod((prev) => ({
                ...prev,
                name: { ...prev.name, [language]: name },
              }));
            });

            setTranslatedFr2(res.data.translated_contents);
          } else if (selectedName[2].code === "rw") {
            const backend = res.data.translated_contents;

            const translations = [
              {
                language: "rw",
                name: backend?.name,
              },
            ];

            translations.forEach((translation) => {
              const { language, name } = translation;
              setLastMod((prev) => ({
                ...prev,
                name: { ...prev.name, [language]: name },
              }));
            });
            setTranslatedRw2(res.data.translated_contents);
          } else {
            const backend = res.data.translated_contents;

            const translations = [
              {
                language: "en",
                name: backend?.name,
              },
            ];

            translations.forEach((translation) => {
              const { language, name } = translation;
              setLastMod((prev) => ({
                ...prev,
                name: { ...prev.name, [language]: name },
              }));
            });
            setTranslatedEn2(res.data.translated_contents);
          }

          console.log("this is translated one", res);
        })
        .catch((error) => {
          setTranslationLoading(false);
          console.log("Error in retrieving translated language", error);
        });
      if (activeStep < steps.length && activeStep < selectedName.length + 1) {
        let newSkipped = skipped;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
      if (
        activeStep < steps.length &&
        steps.length !== selectedName?.length + 1
      ) {
        if (
          // newStep.length < selectedLangauge?.length &&
          activeStep <
          selectedName.length - 1
        ) {
          newStep.push(activeStep + 1);
          setSteps(newStep);
        }
      }
    }

    // if (activeStep === 4) {
    //   handleModal();
    //   handleReset();
    // }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [loading, setLoading] = useState(false);

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const selectedName = lastMod?.languages?.map(({ language_id, ...rest }) => {
    const languageInfo = allLanguage.find(
      (language) => language.id === language_id
    );
    return {
      id: languageInfo?.id,
      name: languageInfo?.name,
      code: languageInfo?.code,
      ...rest,
    };
  });

  const getLanguages = async () => {
    try {
      await axios
        .get(APP.API_MAIN_URL + "/utils/languages", config)
        .then((res) => {
          setAllLanguage(res.data.data);
        });
    } catch (error) {
      console.log("Error in retrieving languages", error);
    }
  };
  useEffect(() => {
    getLanguages();
  }, []);

  const onSubmit = () => {};

  const typeOptions = [
    { value: "chronological", label: "Chronological" },
    { value: "realtime", label: "Real time" },
    { value: "automated", label: "Automated" },
  ];

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal2
          isOpen={openModal}
          onClose={() => {
            handleModal();
            reset();
          }}
          Style={
            "'w-1/2 lg:w-1/3 h-max m-auto flex mx-auto items-center justify-center'"
          }
        >
          <Modal2.Header>
            <h1 className="font-bold">Updated Category</h1>
          </Modal2.Header>
          <Modal2.Body>
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};

                  return (
                    <Step
                      key={index}
                      {...stepProps}
                      sx={{
                        "& .MuiStepLabel-root .Mui-completed": {
                          color: "gray",
                        },
                        "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                          {
                            color: "grey.500", // Just text label (COMPLETED)
                          },

                        "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active":
                          {
                            color: "black",
                            border: "1px solid black",
                            font: "bold",
                            borderRadius: "1000px",
                            text: "black",
                          },
                        "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                          {
                            color: "black", // Just text label (ACTIVE)
                          },
                        "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                          fill: "white", // circle's number (ACTIVE)
                        },
                      }}
                    >
                      <StepLabel {...labelProps} sx={{ color: "gray" }}>
                        <h1 className="text-lg font-bold text-[#757272]">
                          {label === 0
                            ? "Select Language"
                            : label < selectedName?.length
                            ? selectedName[index - 1]?.name
                            : selectedName[index - 1]?.name}
                        </h1>
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              {/* Render step content */}
              <div className="mt-3">
                {activeStep === 0 ? (
                  <UpdateAddLanguage
                    allLanguage={allLanguage}
                    name={lastMod}
                    setNames={setLastMod}
                    control={control}
                    errors={errors}
                    lastMod={lastMod}
                    setLastMod={setLastMod}
                  />
                ) : null}

                {activeStep === 1 &&
                selectedName.length > 0 &&
                selectedName[0].code === "rw" ? (
                  //  create new category
                  <AddKinyarwandaCategory
                    name={name}
                    setNames={setNames}
                    register={register}
                    control={control}
                    errors={errors}
                    clearErrors={clearErrors}
                    translatedRw={translatedRw}
                    setTranslatedRw={setTranslatedRw}
                    translationLoading={translationLoading}
                    isTranslated={isTranslatedToRw}
                    lastMod={lastMod}
                    setLastMod={setLastMod}
                    typeOptions={typeOptions}
                  />
                ) : activeStep === 1 && selectedName[0].code === "en" ? (
                  <AddEnglishCategory
                    name={name}
                    setNames={setNames}
                    register={register}
                    control={control}
                    errors={errors}
                    clearErrors={clearErrors}
                    translatedEn={translatedEn}
                    lastMod={lastMod}
                    setLastMod={setLastMod}
                    typeOptions={typeOptions}
                  />
                ) : activeStep === 1 && selectedName[0].code === "fr" ? (
                  <AddFrenchCategory
                    name={name}
                    setNames={setNames}
                    register={register}
                    control={control}
                    errors={errors}
                    clearErrors={clearErrors}
                    translatedFr={translatedFr}
                    setTranslatedFr={setTranslatedFr}
                    translationLoading={translationLoading}
                    isTranslated={isTranslatedToFr}
                    lastMod={lastMod}
                    setLastMod={setLastMod}
                    typeOptions={typeOptions}
                  />
                ) : null}
                {activeStep === 2 && selectedName[1].code === "rw" ? (
                  //  create new category
                  <AddKinyarwandaCategory
                    name={name}
                    setNames={setNames}
                    register={register}
                    control={control}
                    errors={errors}
                    clearErrors={clearErrors}
                    translatedRw={translatedRw}
                    setTranslatedRw={setTranslatedRw}
                    translationLoading={translationLoading}
                    isTranslated={isTranslatedToRw}
                    lastMod={lastMod}
                    setLastMod={setLastMod}
                    typeOptions={typeOptions}
                  />
                ) : activeStep === 2 && selectedName[1].code === "en" ? (
                  <AddEnglishCategory
                    name={name}
                    setNames={setNames}
                    register={register}
                    control={control}
                    errors={errors}
                    clearErrors={clearErrors}
                    translatedEn={translatedEn}
                    setTranslatedRw={setTranslatedRw}
                    isTranslated={isTranslatedToEn}
                    lastMod={lastMod}
                    setLastMod={setLastMod}
                    typeOptions={typeOptions}
                  />
                ) : activeStep === 2 && selectedName[1].code === "fr" ? (
                  <AddFrenchCategory
                    name={name}
                    setNames={setNames}
                    register={register}
                    control={control}
                    errors={errors}
                    clearErrors={clearErrors}
                    translatedFr={translatedFr}
                    setTranslatedRw={setTranslatedRw}
                    translationLoading={translationLoading}
                    isTranslated={isTranslatedToFr}
                    lastMod={lastMod}
                    setLastMod={setLastMod}
                    typeOptions={typeOptions}
                  />
                ) : null}

                {activeStep === 3 && selectedName[2].code === "rw" ? (
                  //  create new category
                  <AddKinyarwandaCategory
                    name={name}
                    setNames={setNames}
                    register={register}
                    control={control}
                    errors={errors}
                    clearErrors={clearErrors}
                    translatedRw={translatedRw2}
                    setTranslatedRw={setTranslatedRw2}
                    translationLoading={translationLoading}
                    isTranslated={isTranslatedToRw}
                    lastMod={lastMod}
                    setLastMod={setLastMod}
                    typeOptions={typeOptions}
                  />
                ) : activeStep === 3 && selectedName[2].code === "en" ? (
                  <AddEnglishCategory
                    name={name}
                    setNames={setNames}
                    register={register}
                    control={control}
                    errors={errors}
                    clearErrors={clearErrors}
                    translatedEn={translatedEn2}
                    setTranslatedRw={setTranslatedRw}
                    isTranslated={isTranslatedToEn}
                    lastMod={lastMod}
                    setLastMod={setLastMod}
                    typeOptions={typeOptions}
                  />
                ) : activeStep === 3 && selectedName[2].code === "fr" ? (
                  <AddFrenchCategory
                    name={name}
                    setNames={setNames}
                    register={register}
                    control={control}
                    errors={errors}
                    clearErrors={clearErrors}
                    translatedFr={translatedFr2}
                    setTranslatedRw={setTranslatedRw}
                    translationLoading={translationLoading}
                    isTranslated={isTranslatedToFr}
                    lastMod={lastMod}
                    setLastMod={setLastMod}
                    typeOptions={typeOptions}
                  />
                ) : null}

                {/* {activeStep === 4 ? <Success /> : ""} */}
              </div>

              <React.Fragment>
                <div className="flex flex-row justify-between mt-8">
                  {activeStep === 4 ? (
                    ""
                  ) : (
                    <Button
                      type="button"
                      content={
                        <div className="flex gap-1 items-center justify-center">
                          <IoArrowBackOutline color="white" />
                          <h1 className="text-white font-nunito text-base">
                            Back
                          </h1>
                        </div>
                      }
                      Style={"h-[40px] mt-2 ml-0"}
                      loading={false}
                      btnColor="gray"
                      disabled={activeStep === 0}
                      onClick={() => {
                        handleBack();
                      }}
                    />
                  )}

                  <div className="flex">
                    &nbsp;&nbsp;
                    <Button
                      content={
                        <div className="flex items-center justify-center">
                          <h1 className="text-white font-nunito text-base">
                            {activeStep === selectedName?.length
                              ? "Submit"
                              : activeStep === 4
                              ? "Close"
                              : "Next"}
                          </h1>
                        </div>
                      }
                      loading={loading}
                      onClick={handleNext}
                      btnColor="primary"
                      Style={"text-white w-max h-[40px] mt-2 ml-0"}
                    />
                  </div>
                </div>
              </React.Fragment>
            </Box>
          </Modal2.Body>
        </Modal2>
      </form>
    </>
  );
};

export default UpdateCategory;
