import React, { useState } from "react";
import Modal from "../../components/modal/Modal";
import { ToasterComponent } from "../../components/Toast";
import { RiThumbUpFill } from "react-icons/ri";
import Button from "../../components/Button";

function Confirm({
  openModal2,
  handleConfirmAddAccountModal,
  handleOpenAccountModal,
}) {
  const [loading, setLoading] = useState(false);
  return (
    <Modal
      isOpen={openModal2}
      onClose={handleConfirmAddAccountModal}
      Style={"w-max h-max m-auto flex mx-auto items-center justify-center"}
    >
      <Modal.Body>
        <ToasterComponent />
        <div className="flex flex-col space-y-4 font-bold">
          <div className="w-full flex items-center justify-center">
            <RiThumbUpFill size={40} color="#000" />
          </div>
          <div>
            <h2>Confirm if you want to account to this bank</h2>
          </div>
          <div className="flex flex-row w-full justify-between">
            <Button
              type="button"
              content="Cancel"
              loading={false}
              btnColor="primary"
              Style={
                "text-black w-max h-[40px] mt-2 ml-0 bg-white border rounded-sm cursor-pointer"
              }
              onClick={() => {
                handleConfirmAddAccountModal();
              }}
            />
            <Button
              type="button"
              content="Next"
              loading={loading}
              btnColor="primary"
              onClick={() => {
                handleConfirmAddAccountModal();
                handleOpenAccountModal();
              }}
              Style={"text-white w-max h-[40px] mt-2 ml-0 cursor-pointer"}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Confirm;
