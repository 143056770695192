import React from "react";
import { HiMinus, HiPlus } from "react-icons/hi";
import { Tooltip } from "react-tooltip";
import TextArea from "../../../../../components/TextArea";
import Input from "../../../../../components/Input";
import Select from "../../../../../components/Select";
import CircularLoader from "../../../../../components/CircularLoader";
import { RiErrorWarningLine } from "react-icons/ri";

function AddBenefitsFr({
  clearErrors,
  register,
  errors,
  planData,
  setPlanData,
  translationLoading,
  isTranslated,
}) {
  const durationOptions = [
    { value: "daily", label: "Journalier" },
    { value: "weekly", label: "Hebdomadaire" },
    { value: "monthly", label: "Mensuelle" },
    { value: "annually", label: "Annuellement" },
  ];
  const handleName = (e) => {
    const { value } = e.target;

    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      name: { ...prevPlanData.name, fr: value },
    }));
  };
  const handleDescription = (e) => {
    const { value } = e.target;
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      description: { ...prevPlanData.description, fr: value },
    }));
  };
  const handlePrice = (e) => {
    const { value } = e.target;
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      amount: value,
    }));
  };

  const handleDuration = (value) => {
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      subscription_type: value,
    }));
  };

  const handleMaxDailyArticlesChange = (e) => {
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      max_daily_articles: e,
    }));
  };
  const handleBenefitDescriptionChange = (e, index) => {
    const { value } = e.target;

    setPlanData((prevPlanData) => {
      const updatedBenefits = [...prevPlanData.benefits];
      updatedBenefits[index] = {
        ...updatedBenefits[index],
        description: { ...updatedBenefits[index].description, fr: value },
      };

      return {
        ...prevPlanData,
        benefits: updatedBenefits,
      };
    });
  };

  const handleBenefitNameChange = (e, index) => {
    const { value } = e.target;

    setPlanData((prevPlanData) => {
      const updatedBenefits = [...prevPlanData.benefits];
      updatedBenefits[index] = {
        ...updatedBenefits[index],
        name: { ...updatedBenefits[index].name, fr: value },
      };

      return {
        ...prevPlanData,
        benefits: updatedBenefits,
      };
    });
  };

  const addBenefit = () => {
    // Create a new benefit object with default values
    const newBenefit = { name: {}, description: {} };

    setPlanData((prevState) => ({
      ...prevState,
      benefits: [...prevState.benefits, newBenefit],
    }));
  };
  const removeBenefit = (index) => {
    const updatedBenefits = planData.benefits.filter((_, i) => i !== index);

    setPlanData((prevState) => ({
      ...prevState,
      benefits: updatedBenefits,
    }));
  };
  return (
    <div>
      {translationLoading ? (
        <div className="flex items-center w-full mt-4">
          <div className="flex gap-2 items-center m-auto">
            <CircularLoader />
            <p>Traduction en cours...</p>
          </div>
        </div>
      ) : null}
      {isTranslated ? (
        <div className="flex gap-2 mt-6 mb-2">
          <RiErrorWarningLine size={30} color="blue" />
          <h1>Vérifie bien les textes traduits</h1>
        </div>
      ) : (
        ""
      )}

      <div className="flex justify-between border border-[#ddd] rounded-md mt-3">
        <div className="w-full p-1 rounded-md">
          <h1 className="my-2 text-lg ml-5">Ajouter des avantages</h1>
          {planData.benefits.map((benefit, index) => (
            <div key={index} className="flex">
              <div className="w-full flex flex-col items-center mb-2 border border-[#ddd] p-2 ml-5">
                <Input
                  labelText={"Nom"}
                  labelFor={"name"}
                  name={`name ${index}`}
                  type={"text"}
                  placeholder={"Entrez le nom de l'avantage"}
                  isRequired={true}
                  customClass=" w-full text-[18px] "
                  value={benefit.name["fr"]}
                  onChange={(e) => {
                    clearErrors("name");
                    handleBenefitNameChange(e, index);
                  }}
                  register={register}
                  errors={errors}
                />

                <TextArea
                  labelFor={"description"}
                  labelText="Description"
                  name={`description ${index}`}
                  errors={errors}
                  placeholder={`Entrez la description de l'avantage`}
                  customClass={"w-full mt-4 ml-2 text-[18px]"}
                  isRequired={true}
                  register={register}
                  value={benefit.description["fr"]}
                  onChange={(e) => {
                    clearErrors("description");
                    handleBenefitDescriptionChange(e, index);
                  }}
                />
              </div>
              <div className="flex items-end justify-between mt-5 w-[8%] ml-5 ">
                <div
                  data-tooltip-id="tooltip12"
                  data-tooltip-content="Supprimer cet avantage"
                  className={`flex items-center p-1 justify-center cursor-pointer bg-[#BDBDBD] rounded-full text-white ${
                    index === 0 ? "hidden" : "visible"
                  }`}
                  onClick={(e) => removeBenefit(index)}
                >
                  <HiMinus />
                </div>
                <div
                  data-tooltip-id="tooltip12"
                  data-tooltip-content="Ajouter un avantage"
                  className={`flex items-center p-1  justify-center cursor-pointer bg-primary rounded-full text-white ${
                    planData.benefits?.length === index + 1
                      ? "visible"
                      : "hidden"
                  }`}
                  onClick={addBenefit}
                >
                  <HiPlus />
                </div>
                <Tooltip id="tooltip12" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AddBenefitsFr;
