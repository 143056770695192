import React, { useState, useEffect, Fragment } from "react";

import { CiSettings } from "react-icons/ci";

import Button from "../../../components/Button";
import Table from "../../../components/tableComponent/Table";
import Loader from "../../../components/Loader";
import Select from "../../../components/Select";
import { Popover, Transition } from "@headlessui/react";
import { FiMoreVertical } from "react-icons/fi";
import { BiSearch } from "react-icons/bi";

import { skeletonData, skeletonColumns } from "../../../seeds/Data";
import SkeletonTable from "../../../components/SkeletonTable";

import { useDispatch, useSelector } from "react-redux";
import AddCategory from "./category/AddCategory";
import DeleteCategory from "./category/DeleteCategory";
import {
  getCategory,
  getPlans,
  setSelectedCategoryLanguage,
} from "../../../redux/actions/subscription";
import AddPlan from "./plan/AddPlan";
import UpdateCategory from "./category/UpdateCategory";
import UpdateSubSettings from "./category/UpdateSubSettings";
import DeletePlan from "./plan/DeletePlan";
import ViewPlan from "./plan/ViewPlan";
import UpdatePlan from "./plan/UpdatePlan";

const PlanCategory = () => {
  const { categories, plans, isPlanLoading, selectedCatLanguage } = useSelector(
    (state) => state.subscription
  );
  const dispatch = useDispatch();
  const [createCategory, setCreateCategory] = useState(false);
  const [updateCategory, setUpdateCategory] = useState(false);
  const [subscriptionCategoryId, setSubscriptionCategoryId] = useState("");

  const [editCategoryData, setEditCategoryData] = useState({
    id: null,
    name: {
      en: "",
      rw: "",
      fr: "",
    },
    description: {
      en: "",
      rw: "",
      fr: "",
    },
    content_type: "",
    status: "",
    languages: [
      {
        id: null,
        language_id: null,
        subscription_plan_category_id: null,
        status: "",
        is_default: null,
        language: {
          id: null,
          name: "",
          code: "",
          status: "",
        },
      },
    ],
  });
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [createPlan, setCreatePlan] = useState(false);
  const [updatePlan, setUpdatePlan] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [planId, setPlanId] = useState("");

  const [openSubSettings, setOpenSubSettings] = useState(false);
  const [deletePlan, setDeletePlan] = useState(false);
  const [viewPlan, setViewPlan] = useState(false);
  const [planToEdit, setPlanToEdit] = useState({
    id: null,
    name: {
      en: "",
      rw: "",
      fr: "",
    },
    description: {
      en: "",
      rw: "",
      fr: "",
    },
    amount: "",
    currency_id: 1,
    subscription_type: "",
    benefits: [
      {
        id: null,
        subscription_plan_id: null,
        name: {
          en: "",
          rw: "",
          fr: "",
        },
        description: {
          en: "",
          rw: "",
          fr: "",
        },
        created_at: "",
      },
    ],
  });
  const [dataToView, setDataToView] = useState({
    id: null,
    name: {
      en: "",
      rw: "",
      fr: "",
    },
    description: {
      en: "",
      rw: "",
      fr: "",
    },
    amount: "",
    currency_id: 1,
    subscription_type: "",
    benefits: [
      {
        id: 10,
        subscription_plan_id: 6,
        name: {
          en: "",
          rw: "",
          fr: "",
        },
        description: {
          en: "",
          rw: "",
          fr: "",
        },
        created_at: "",
      },
    ],
  });

  const handleCreateCategory = () => {
    setCreateCategory(!createCategory);
  };

  const handleUpdateCategory = () => {
    setUpdateCategory(!updateCategory);
  };

  const handleDeleteCategory = () => {
    setDeleteCategory(!deleteCategory);
  };

  const handleViewPlan = () => {
    setViewPlan(!viewPlan);
  };

  const filteredData = categories.filter(
    (item) => item.name && item.name.hasOwnProperty("en")
  );

  useEffect(() => {
    if (filteredData.length > 0) {
      setSubscriptionCategoryId(filteredData[0].id);
    }
  }, []);

  const subscriptionPlansLoading = false;

  const columns = [
    {
      Header: "Name",
      accessor: "",
      Cell: ({ row }) => {
        return <h1>{row?.original?.name.en}</h1>;
      },
    },
    {
      Header: "Description",
      accessor: "",
      Cell: ({ row }) => {
        return <h1>{row?.original?.description.en}</h1>;
      },
    },
    {
      Header: "Duration",
      accessor: "subscription_type",
    },
    {
      Header: "Price",
      accessor: "amount",
    },

    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  font-bold">
            <Popover className="relative">
              <Popover.Button>
                <FiMoreVertical size={30} className="cursor-pointer" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10">
                  <div
                    className="flex flex-col bg-white shadow-md border border-black/10  rounded-sm text-xs "
                    onClick={() => {}}
                  >
                    <h1
                      className="px-4 py-2 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                      onClick={() => {
                        const newData = row.original;
                        setDataToView((prevData) => ({
                          ...prevData,
                          ...newData,
                          name: { ...prevData.name, ...newData.name },
                          description: {
                            ...prevData.description,
                            ...newData.description,
                          },
                          benefits: newData.benefits.map((benefit, index) => {
                            if (prevData.benefits[index]) {
                              return {
                                ...prevData.benefits[index],
                                ...benefit,
                                name: {
                                  ...prevData.benefits[index].name,
                                  ...benefit.name,
                                },
                                description: {
                                  ...prevData.benefits[index].description,
                                  ...benefit.description,
                                },
                              };
                            } else {
                              return benefit;
                            }
                          }),
                        }));
                        handleViewPlan();
                      }}
                    >
                      View
                    </h1>
                    <h1
                      className="px-4 py-2 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                      onClick={() => {
                        setUpdatePlan(!updatePlan);
                        const newData = row.original;
                        setPlanToEdit((prevData) => ({
                          ...prevData,
                          ...newData,
                          name: { ...prevData.name, ...newData.name },
                          description: {
                            ...prevData.description,
                            ...newData.description,
                          },
                          benefits: newData.benefits.map((benefit, index) => {
                            if (prevData.benefits[index]) {
                              return {
                                ...prevData.benefits[index],
                                ...benefit,
                                name: {
                                  ...prevData.benefits[index].name,
                                  ...benefit.name,
                                },
                                description: {
                                  ...prevData.benefits[index].description,
                                  ...benefit.description,
                                },
                              };
                            } else {
                              return benefit;
                            }
                          }),
                        }));
                      }}
                    >
                      Edit
                    </h1>
                    <h1
                      className="px-4 py-2 hover:bg-[#D9D9D9] text-center"
                      onClick={() => {
                        setPlanId(row.original.id);
                        // console.log("first", row.original.id);
                        handleDeletePlan();
                      }}
                    >
                      Delete
                    </h1>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getCategory());
  }, []);
  useEffect(() => {
    dispatch(getPlans(subscriptionCategoryId));
  }, [subscriptionCategoryId]);

  const handleOpenSubSettings = () => {
    setOpenSubSettings(!openSubSettings);
  };

  const handleDeletePlan = () => {
    setDeletePlan(!deletePlan);
  };

  return (
    <>
      <AddCategory
        openModal={createCategory}
        handleModal={handleCreateCategory}
      />

      <UpdateCategory
        openModal={updateCategory}
        handleModal={handleUpdateCategory}
        editCategoryData={editCategoryData}
        setEditCategoryData={setEditCategoryData}
        lastMod={editCategoryData}
        setLastMod={setEditCategoryData}
      />
      <DeleteCategory
        openModal={deleteCategory}
        handleModal={handleDeleteCategory}
        categoryId={categoryId}
      />

      <AddPlan
        openModal={createPlan}
        handleModal={setCreatePlan}
        subscriptionCategories={categories}
        categoryId={subscriptionCategoryId}
        selectedLangue={selectedCatLanguage}
      />
      <UpdateSubSettings
        openModal={openSubSettings}
        handleModal={handleOpenSubSettings}
      />
      <DeletePlan
        categoryId={subscriptionCategoryId}
        handleModal={handleDeletePlan}
        openModal={deletePlan}
        planId={planId}
      />
      <ViewPlan
        openModal={viewPlan}
        handleModal={handleViewPlan}
        data={dataToView}
      />
      <UpdatePlan
        openModal={updatePlan}
        handleModal={setUpdatePlan}
        subscriptionCategories={categories}
        categoryId={subscriptionCategoryId}
        selectedLangue={selectedCatLanguage}
        planData={planToEdit}
        setPlanData={setPlanToEdit}
      />
      <div
        className={`w-full h-full flex flex-col content p-4 ${
          subscriptionPlansLoading ? "items-center justify-center" : ""
        }`}
      >
        {subscriptionPlansLoading ? (
          <Loader loader={true} />
        ) : filteredData.length > 0 ? (
          <div className="overflow-y-auto h-max">
            <div className="w-full flex items-center mb-4  ">
              <h2 className="text-2xl font-bold mr-5">Categories</h2>
              {/* <CiSettings
                className="font-bold text-2xl"
                onClick={handleOpenSubSettings}
              /> */}
            </div>
            <div className="w-full h-full flex flex-col lg:flex-row gap-6 ">
              <div className="lg:hidden flex flex-col border border-black/10 rounded p-4 ">
                <div className="flex gap-4 justify-between items-center ">
                  <div className="w-full">
                    <Select
                      className="w-full mb-2"
                      isLoading={false}
                      placeholder={"Select subscription plans"}
                      unControlledValue={categories?.map((plan) => {
                        if (plan.id === subscriptionCategoryId) {
                          return {
                            value: plan.id,
                            label: plan.name.en,
                          };
                        }
                      })}
                      defaultValue={categories[0]}
                      options={categories?.map((plan) => {
                        return {
                          value: plan.id,
                          label: plan.name.en,
                        };
                      })}
                      onChange={(e) => {
                        setSubscriptionCategoryId(e.value);
                      }}
                    />
                  </div>

                  <Popover className="relative">
                    <Popover.Button>
                      <FiMoreVertical size={20} className="cursor-pointer" />
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 top-[50%]">
                        <div className="flex flex-col bg-white shadow-md border border-black/10  rounded-sm text-xs absolute right-0 top-full font-bold">
                          <h1
                            className="px-4 py-2 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                            onClick={() => {
                              // handleEditAudienceModal();
                            }}
                          >
                            Edit
                          </h1>
                          <h1
                            className="px-4 py-2 hover:bg-[#D9D9D9] text-center"
                            onClick={() => {
                              setCategoryId("");
                              setDeleteCategory(!deleteCategory);
                            }}
                          >
                            Delete
                          </h1>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                </div>
                <Button
                  content="+ Add Category"
                  loading={false}
                  onClick={() => {
                    handleCreateCategory();
                  }}
                  btnColor="primary"
                  Style="text-white w-full h-[40px] mr-4 ml-0 mt-8"
                />
              </div>

              <div className="hidden lg:flex w-full lg:min-w-[239px] lg:max-w-[239px] flex-col mx-auto mb-8 lg:mb-0 ">
                <div className="w-full max-h-[500px]  flex flex-col bg-white border border-black/10 rounded-lg pb-4 ">
                  <div className="w-full flex flex-col justify-between border  border-white border-b-[0.5px]  px-5 ">
                    <p className="italic text-[#414141] mt-[21px]">
                      Categories ({filteredData.length})
                    </p>
                    <div className="flex  w-full border rounded border-[#5E5B5B] min-h-[40px] mb-4 justify-center items-center px-2">
                      <BiSearch size={20} />
                      <input
                        defaultValue=""
                        placeholder="Search"
                        className="w-full rounded outline-none font-nunito"
                      />
                    </div>
                    <hr className="mt-2" />
                  </div>
                  <div className="pt-2 mx-[20px] overflow-y-auto">
                    <div className="  text-base ">
                      {filteredData?.map((category, index) => (
                        <div
                          className="flex flex-row items-center mb-1"
                          onClick={() => {
                            setSubscriptionCategoryId(category.id);
                            dispatch(
                              setSelectedCategoryLanguage(
                                category?.languages.map(
                                  ({
                                    language_id,
                                    is_default,
                                    language: { name, code },
                                  }) => ({
                                    language_id,
                                    name,
                                    code,
                                    is_default,
                                  })
                                )
                              )
                            );
                          }}
                          key={index}
                        >
                          {(() => {
                            // console.log(
                            //   category.id,
                            //   subscriptionPlanId,
                            //   'a little check'
                            // );
                          })()}
                          <h1
                            className={`hover:bg-primary cursor-pointer hover:text-white py-2 w-full px-5 font-bold rounded-sm border-b border-[#e3e3e3] ${
                              category.id === subscriptionCategoryId
                                ? "bg-primary text-white"
                                : ""
                            }`}
                            onClick={() => {
                              if (category.id !== subscriptionCategoryId) {
                              }
                            }}
                          >
                            {category?.name["en"]}
                          </h1>
                          <Popover className="relative">
                            <Popover.Button>
                              <FiMoreVertical
                                size={20}
                                className="cursor-pointer"
                              />
                            </Popover.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="absolute z-10 top-[50%]">
                                <div className="flex flex-col bg-white shadow-md border border-black/10  rounded-sm text-xs absolute right-0 top-full font-bold">
                                  <h1
                                    className="px-4 py-2 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                                    onClick={() => {
                                      setEditCategoryData((prevData) => ({
                                        ...prevData,
                                        id: category?.id,
                                        name: {
                                          ...prevData.name,
                                          ...category?.name,
                                        },
                                        description: {
                                          ...prevData.description,
                                          ...category?.description,
                                        },
                                        content_type: {
                                          label: category.content_type,
                                          value: category.content_type,
                                        },
                                        languages: category?.languages || [],
                                      }));
                                      handleUpdateCategory();
                                    }}
                                  >
                                    Update
                                  </h1>
                                  <h1
                                    className="px-4 py-2 hover:bg-[#D9D9D9] text-center cursor-pointer"
                                    onClick={() => {
                                      setCategoryId(category.id);
                                      setDeleteCategory(!deleteCategory);
                                      console.log("Category s", categoryId);
                                    }}
                                  >
                                    Delete
                                  </h1>
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </Popover>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <Button
                  content="+ Add Category"
                  loading={false}
                  onClick={() => {
                    handleCreateCategory();
                  }}
                  btnColor="primary"
                  Style="text-white w-full h-[40px] mr-4 ml-0 mt-8"
                />
              </div>

              <div className="flex flex-col w-full px-0 ">
                <div className="overflow-x-auto my-auto h-full">
                  {!isPlanLoading ? (
                    plans.length === 0 ? (
                      <p className="text-[20px] text-[#808080] font-bold flex justify-center items-center">
                        No recent plans added yet, click on add plan button to
                        add your first plan.
                      </p>
                    ) : (
                      <Table
                        columns={columns}
                        data={plans.length > 0 ? plans : plans}
                        title="Audience file"
                        className="mx-0"
                      />
                    )
                  ) : (
                    <SkeletonTable
                      columns={skeletonColumns}
                      data={skeletonData}
                    />
                  )}

                  <div
                    className={`${
                      isPlanLoading ? "hidden" : "flex"
                    }  flex-row justify-end items-end pr-4`}
                  >
                    <Button
                      type="submit"
                      content="Add Plan"
                      loading={false}
                      btnColor="primary"
                      Style={"text-white w-max h-[40px] ml-0"}
                      onClick={() => {
                        setCreatePlan(!createPlan);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex flex-col space-y-[24px] my-auto">
              <h1 className="font-[600] text-3xl leading-[44px]">Plans</h1>
              <p className="font-[600] text-[20px] text-[#808080]">
                No recent subscription categories added yet, click below to add
                categories.
              </p>
              <Button
                type="submit"
                content="+ Add Category"
                loading={false}
                btnColor="primary"
                Style={"text-white w-max h-[40px] mt-2 ml-0"}
                onClick={() => {
                  handleCreateCategory();
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PlanCategory;
