import React, { useState } from 'react';
import axios from 'axios';
import Table from '../../../../components/tableComponent/Table';
import { skeletonColumns, skeletonData } from '../../../../seeds/Data';
import SkeletonTable from '../../../../components/SkeletonTable';
import { useSelector } from 'react-redux';
import WithdrawDetails from './WithdrawDetails';
import { APP } from '../../../../utils/constant';
import PayBankWithdraw from './PayBankWithdraw';

const BankWithdraw = () => {
  const { token } = useSelector((state) => state.user);
  const { is_fetching_bank_withdraws, bank_withdraws } = useSelector(
    (state) => state.wallet
  );
  const [withdrawId, setWithdrawId] = useState('');
  const [walletId, setWalletId] = useState('');
  const [openWithdrawDetails, setOpenWithdrawDetails] = useState(false);
  const [openPayBankWithdraw, setOpenPayBankWithdraw] = useState(false);
  const [withdrawDetailsData, setWithdrawDetailsData] = useState({});
  const [isFetchingData, setIsFetchingData] = useState(false);
  const columns = [
    {
      Header: 'Date',
      accessor: '',
      Cell: ({ row }) => {
        return <div>{row.original.created_at.split('T')[0]}</div>;
      },
    },
    {
      Header: 'Reference',
      accessor: 'reference',
    },
    {
      Header: 'Amount',
      accessor: 'total_amount',
    },
    {
      Header: 'Verification',
      accessor: 'verification_status',
    },
    {
      Header: 'Initialization',
      accessor: 'initialization_status',
      Cell: ({ row }) => {
        return (
          <div className={'relative'}>
            <div
              className={
                row.original.verification_status === 'approved' &&
                row.original.initialization_status === 'pending'
                  ? 'absolute bg-error animate-ping h-[5px] w-[5px] rounded-3xl right-[0px] mb-2'
                  : ''
              }
            ></div>

            <h1 className="mt-1">{row.original.initialization_status}</h1>
          </div>
        );
      },
    },
    {
      Header: 'Approval',
      accessor: 'approval_status',
      Cell: ({ row }) => {
        return (
          <div className={'relative'}>
            <div
              className={
                row.original.initialization_status === 'approved' &&
                row.original.approval_status === 'pending'
                  ? 'absolute bg-error animate-ping h-[5px] w-[5px] rounded-3xl right-[0px] mb-2'
                  : ''
              }
            ></div>

            <h1 className="mt-1">{row.original.approval_status}</h1>
          </div>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
    },

    {
      Header: 'Names',
      accessor: '',
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.wallet?.profile?.first_name +
              ' ' +
              row.original.wallet?.profile?.last_name}
          </div>
        );
      },
    },
    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row gap-2">
            <div
              className="bg-black py-1 px-2 rounded-md cursor-pointer"
              onClick={() => {
                fetchWithDrawDetails(row.original.wallet_id, row.original.id);
                handleOpenWithdrawDetails();
              }}
            >
              {' '}
              <h1 className="text-white">Details</h1>
            </div>
            {row.original.verification_status === 'approved' &&
            row.original.initialization_status === 'approved' &&
            row.original.approval_status === 'approved' &&
            row.original.status === 'pending' ? (
              <div
                className="bg-black py-1 px-2 rounded-md cursor-pointer"
                onClick={() => {
                  setWithdrawId(row.original.id);
                  setWalletId(row.original.wallet_id);
                  handleOpenPayBankWithdraw();
                }}
              >
                {' '}
                <h1 className="text-white">Pay</h1>
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];
  const handleOpenWithdrawDetails = () => {
    setOpenWithdrawDetails(!openWithdrawDetails);
  };

  const handleOpenPayBankWithdraw = () => {
    setOpenPayBankWithdraw(!openPayBankWithdraw);
  };
  const fetchWithDrawDetails = (paymentWalletId, withdrawId) => {
    setIsFetchingData(true);
    axios
      .get(
        APP.API_MAIN_URL +
          `/admin/wallets/payout/${paymentWalletId}/withdraws/bank-withdraws/${withdrawId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        setIsFetchingData(false);
        setWithdrawDetailsData(res.data.data);
      })
      .catch((error) => {
        setIsFetchingData(false);
        console.log(error);
      });
  };

  return (
    <>
      <WithdrawDetails
        openModal={openWithdrawDetails}
        handleOpenModal={handleOpenWithdrawDetails}
        withdrawId={withdrawId}
        withdrawDetailsData={withdrawDetailsData}
        isFetchingData={isFetchingData}
      />

      <PayBankWithdraw
        openModal={openPayBankWithdraw}
        handleOpenModal={setOpenPayBankWithdraw}
        walletId={walletId}
        withdrawId={withdrawId}
      />

      <div className="overflow-x-auto my-auto">
        {!is_fetching_bank_withdraws ? (
          bank_withdraws?.length == 0 ? (
            <p className="text-[20px] text-[#808080] font-bold flex justify-center items-center mt-6">
              There are no Payment Wallet Transactions yet for this Wallet,
              Transactions will appear here.
            </p>
          ) : (
            <Table
              columns={columns}
              initialSize={10}
              data={bank_withdraws?.length > 0 ? bank_withdraws : []}
              className="mx-0"
            />
          )
        ) : (
          <SkeletonTable columns={skeletonColumns} data={skeletonData} />
        )}
      </div>
    </>
  );
};

export default BankWithdraw;
