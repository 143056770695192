import React, { useState, useRef, useEffect } from 'react';
import Axios from '../../components/Axios';
import { useForm } from 'react-hook-form';
import Button from '../../components/Button';
import Modal from '../../components/modal/Modal';
import Input from '../../components/Input';
import { ToastComponent, ToasterComponent } from '../../components/Toast';
import Drag from '../../components/Drag';
import Select from '../../components/Select';
import { APP } from '../../utils/constant';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBankAccounts, fetchBanks } from '../../redux/actions/bank';

const EditBank = ({
  bankId,
  openModal,
  handleEditBankModal,
  isError,
  files,
  setFiles,
  config,
  bankData,
  countries,
  editBankData,
  setEditBankData,
}) => {
  const dispatch = useDispatch();
  console.log(editBankData, 'editBankData');
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm();

  const [loading, setLoading] = useState(false);

  //   const handleEditAudienceName = (name) => {
  //     setEditAudienceName(name);
  //   };

  const { token } = useSelector((state) => state.user);

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };
  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];

  const handleName = (e) => {
    const { value } = e.target;
    setEditBankData((prevData) => {
      return {
        ...prevData,
        name: value,
      };
    });
  };

  const handleSwift = (e) => {
    const { value } = e.target;
    setEditBankData((prevData) => {
      return {
        ...prevData,
        swift_code: value,
      };
    });
  };

  const handlestatus = (e) => {
    console.log('Selected status:', e.value);
    setEditBankData((prevData) => {
      return {
        ...prevData,
        status: e.value,
      };
    });
  };

  const onSubmit = () => {
    setLoading(true);
    Axios.put(
      APP.API_MAIN_URL + `/admin/banks/${bankId}`,
      { ...editBankData, logo: files[0] },
      config
    )
      .then((res) => {
        setLoading(false);
        reset();
        dispatch(fetchBanks());
        handleEditBankModal();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        notify('error', error.response.data.message);
      });
  };

  return (
    <>
      <ToasterComponent />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal
          isOpen={openModal}
          onClose={() => {
            reset();
            handleEditBankModal();
          }}
          Style={'w-[90%] lg:w-1/3  flex m-auto py-4'}
        >
          <Modal.Header>{'Edit Bank'}</Modal.Header>
          <Modal.Body>
            <div className="flex flex-col space-y-4 my-auto">
              <Input
                labelText={'Bank name'}
                labelFor={'Bank name'}
                name={`bankname`}
                type={'text'}
                customClass="w-full mb-2"
                onChange={(e) => {
                  handleName(e);
                }}
                register={register}
                errors={errors}
                value={editBankData?.name}
              />
              <Input
                labelText={'Swift code'}
                labelFor={'Swift code'}
                name={`swift_code`}
                type={'text'}
                isRequired={false}
                customClass="w-full mb-2"
                onChange={(e) => {
                  handleSwift(e);
                }}
                register={register}
                errors={errors}
                value={editBankData?.swift_code}
              />{' '}
              <Select
                label={'Status'}
                labelFor={'status'}
                className="w-full"
                name="status"
                isLoading={false}
                placeholder={'Select status'}
                value={statusOptions.find(
                  (option) => option.value === editBankData.status
                )}
                onChange={(e) => {
                  handlestatus(e);
                }}
                options={statusOptions}
              />
              <div className="flex flex-col mt-8">
                <h2>Upload logo</h2>
                <Drag
                  style="w-full "
                  flexStyle="flex flex-col space-y-8"
                  uploadLimit={9}
                  files={files}
                  setFiles={setFiles}
                />
                <h2 className="text-error text-lg">
                  {isError ? 'Logo is missing' : ''}
                </h2>
                <h2 className="text-error text-lg">
                  {isError ? 'Logo is missing' : ''}
                </h2>
              </div>
              <Button
                type="submit"
                content={'Update'}
                loading={loading}
                btnColor="primary"
                Style={'bottom-[0px] text-white w-fit p-8  self-end'}
              />
            </div>
          </Modal.Body>
        </Modal>
      </form>
    </>
  );
};

export default EditBank;
