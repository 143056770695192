import React, { Fragment, useEffect, useState } from "react";
import Button from "../../../components/Button";

import { Popover, Transition } from "@headlessui/react";
import { FiMoreVertical } from "react-icons/fi";
import { BiHide, BiSearch, BiShow } from "react-icons/bi";
import { APP, notify } from "../../../utils/constant";
import Axios from "../../../components/Axios";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import ConfirmationPop from "./ConfirmationPop";
import ConfirmDeleteContent from "./articleModals/ConfirmDeleteContent";
import Table from "../../../components/tableComponent/Table";
import { useNavigate, useParams } from "react-router-dom";
import EditCat from "./category/EditCat";
import ChildCategory from "./category/ChildCategory";
import EditModal from "./articleModals/EditModal";
import ViewContent from "./articleModals/ViewContent";

import ViewCategory from "./category/ViewCategory";
import { MdOutlineTune } from "react-icons/md";
import AddNewArticle from "./articleModals/AddNewArticle";
import { useDispatch, useSelector } from "react-redux";

import AddNewCat from "./category/AddNewCat";
import UpdateCategory from "./category/UpdateCategory";
import { getArticles, getCategory } from "../../../redux/actions/articles";
import UpdateArticle from "./articleModals/UpdateArticle";
import { HiPlus } from "react-icons/hi";
import DeleteCategory from "./category/DeleteCategory";

function CategoryItem({
  setShowConfirmation,
  category,
  setCategoryIdToDelete,
  categoryIdToDelete,
  handleEditcategoryModal,
  setEditcategoryId,
  categories,
  selectedLanguage,
  allLanguage,
  setSelectedCategory,
  selectedCategory,
  getArticlesCategory,
  clicked,
  setClicked,
  isLoadingCategory,
  setEditCategory,
  editCategory,
  openDeleteCatModal,
  setOpenDeleteCatModal,
  handleDeleteClick,
}) {
  const navigate = useNavigate();

  const [openViewCat, setOpenViewCat] = useState(false);
  const [show, setShow] = useState(false);
  const [categoryIdToView, setCategoryIdToView] = useState(null);

  const handleViewcategoryModal = () => {
    setOpenViewCat(!openViewCat);
  };

  const handleShowChild = () => setShow(!show);
  return (
    <>
      <div
        className={`flex flex-row items-center  py-1 border-b border-[#ddd] hover:bg-black hover:text-white rounded-md ${
          selectedCategory.id === category.id ? "bg-black text-white" : ""
        }  `}
        onClick={() => {
          navigate("/vas/content/" + category.id);

          setSelectedCategory(category);
          handleShowChild();
        }}
      >
        <div
          className={`flex justify-between items-center capitalize cursor-pointer py-2 w-full px-5 rounded-sm`}
        >
          {" "}
          {category.name[selectedLanguage]
            ? category.name[selectedLanguage]
            : ""}
          {show ? <IoIosArrowDown /> : <IoIosArrowForward />}
        </div>

        <Popover className="relative">
          <Popover.Button>
            <FiMoreVertical size={20} className="cursor-pointer" />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 top-[50%]">
              <div className="flex flex-col bg-white text-primary shadow-md border border-black/10  rounded-sm text-xs absolute right-0 top-full font-bold">
                {/* <h1
                  className="px-4 py-2 w-full hover:bg-[#D9D9D9] hover:text-[#000] text-center cursor-pointer"
                  onClick={() => {
                    // handleViewcategoryModal();
                    setCategoryIdToView(category.id);
                  }}
                >
                  View
                </h1> */}
                <h1
                  className="px-4 py-2 w-full hover:bg-[#D9D9D9] hover:text-[#000] text-center cursor-pointer"
                  onClick={() => {
                    handleEditcategoryModal();
                    setEditcategoryId(category.id);
                    console.log("XXXXXX", category);

                    setEditCategory((prevData) => ({
                      ...prevData,
                      id: category?.id,
                      name: {
                        ...prevData.name,
                        ...category?.name,
                      },
                      description: {
                        ...prevData.description,
                        ...category?.description,
                      },
                      status: category.status,

                      content_type: {
                        label: category.content_type,
                        value: category.content_type,
                      },
                      languages: category?.languages || [],
                    }));
                  }}
                >
                  Edit
                </h1>
                <h1
                  className="px-4 py-2 w-full hover:bg-[#D9D9D9] hover:text-[#000] text-center cursor-pointer"
                  onClick={() => {
                    setCategoryIdToDelete(category.id);
                    handleDeleteClick();
                  }}
                >
                  Delete
                </h1>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>

      {show && (
        <ChildCategory
          categories={categories}
          allLanguage={allLanguage}
          catId={category.id}
          show={show}
          setShow={setShow}
          selectedLanguage={selectedLanguage}
          handleEditcategoryModal={handleEditcategoryModal}
          setEditcategoryId={setEditcategoryId}
          handleDeleteClick={handleDeleteClick}
          clicked={clicked}
          setClicked={setClicked}
          setSelectedCategory={setSelectedCategory}
          selectedCategory={selectedCategory}
          setEditCategory={setEditCategory}
          setCategoryIdToDelete={setCategoryIdToDelete}
        />
      )}
      <ViewCategory
        handleViewcategoryModal={handleViewcategoryModal}
        openViewCat={openViewCat}
        categoryIdToView={categoryIdToView}
        selectedLanguage={selectedLanguage}
      />
    </>
  );
}

function Articles({ config }) {
  const { selectedLangauge, articleCategories, articles } = useSelector(
    (state) => state.articleCategory
  );

  const dispatch = useDispatch();
  const [openArticleModal, setOpenArticleModal] = useState(false);

  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [contentIdToDelete, setContentIdToDelete] = useState(null);
  const [confirmDeleteContent, setConfirmDeleteContent] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);
  const [openViewContent, setOpenViewContent] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openCatModal, setOpenCatModal] = useState(false);
  const [editCategoryId, setEditcategoryId] = useState(null);
  const [showStep1, setShowStep1] = useState(false);
  const [showCategoryDiv, setShowCategoryDiv] = useState(true);
  const [contentToView, setContentToView] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedContentId, setSelectedContentId] = useState(null);
  const [editContentData, setEditContentData] = useState(null);
  const [allLanguage, setAllLanguage] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [showLanguageOptions, setShowLanguageOptions] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [clicked, setClicked] = useState(null);
  const [filteredContent, setFilteredContent] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [contentFetching, setContentFetching] = useState(false);
  const [isLoadingCategory, setIsLoadingCategory] = useState(false);
  const [contentCategoryToDelete, setContentCategoryToDelete] = useState("");
  const [openDeleteCatModal, setOpenDeleteCatModal] = useState(false);
  const [editContent, setEditContent] = useState({
    title: {},
    body: {},
    article_category_id: null,
    status: "",
  });
  const [editCategory, setEditCategory] = useState({
    id: "",
    name: {
      en: "",
      fr: "",
      rw: "",
    },
    parent_id: null,
    status: "",
    languages: [],
    subscription_plan_categories: [],
  });
  const showFilterBtn = "true";
  const { catId } = useParams();
  const handleEditcategoryModal = () => {
    setOpenEditCategory(!openEditCategory);
  };

  const handleHideCategory = () => {
    setShowCategoryDiv(!showCategoryDiv);
  };

  const handleCreateArticleModal = () => {
    setOpenArticleModal(!openArticleModal);
  };
  const handleShowStep1 = () => {
    setShowStep1(!showStep1);
  };

  useEffect(() => {
    if (catId) {
      const category = articleCategories.find((item) => item.id === catId);
      if (category) {
        setSelectedCategory(category);
      }
    }
  }, [catId, articleCategories]);
  const handleCreateCategory = () => {
    setOpenCatModal(!openCatModal);
  };
  const handleDeleteClick = () => {
    setOpenDeleteCatModal(!openDeleteCatModal);
  };

  const getContent = async () => {
    // setContentFetching(true);
    // await Axios.get(
    //   APP.API_MAIN_URL +
    //     `/admin/vas/categories/${selectedCategory.id}/articles`,
    //   config
    // )
    //   .then((res) => {
    //     setContentFetching(false);
    //     setContent(res.data.data);
    //   })
    //   .catch((error) => {
    //     console.log("error in retriveing content", error);
    //     setContentFetching(false);
    //   });
    dispatch(getArticles(selectedCategory.id));
  };

  const getArticlesCategory = async () => {
    dispatch(getCategory());
  };
  useEffect(() => {
    getArticlesCategory();
  }, []);

  const handleDeleteConfirmed = async () => {
    await Axios.delete(
      APP.API_MAIN_URL + "/admin/vas/categories/" + categoryIdToDelete,
      config
    )
      .then((res) => {
        setShowConfirmation(false);
        setCategoryIdToDelete(null);
        getArticlesCategory();
        notify("success", "Category Successfully Deleted!!");
      })
      .catch((error) => {
        notify("error", error.response.data.message);
      });
  };
  const handleDeleteConfirmedContent = async () => {
    setIsDeleting(true);
    await Axios.delete(
      APP.API_MAIN_URL +
        `/admin/vas/categories/${contentCategoryToDelete}/articles/` +
        contentIdToDelete,
      config
    )
      .then((res) => {
        setIsDeleting(false);
        getContent();
        setContentIdToDelete(null);

        notify("success", "Content Successfully Deleted!!");
      })
      .catch((error) => {
        notify("error", error.response.data.message);
        console.log("Error in deleting Content", error);
        setIsDeleting(false);
      });
  };

  const handleLanguage = (language) => {
    setSelectedLanguage(language.code);
    setShowLanguageOptions(false);
  };
  useEffect(() => {
    getContent();
    if (selectedCategory !== null) {
      const res = [];
      for (const con of articles) {
        if (con.category) {
          if (
            con.category.id === selectedCategory.id ||
            con.category.parent_id === selectedCategory.id
          ) {
            res.push(con);
          }
        }
      }
      setFilteredContent(res);
    }
  }, [selectedCategory]);

  const filteredByBody = filteredContent.filter(
    (item) => item.title && item.title[selectedLanguage]
  );

  const handleDeleteContent = (id, catId) => {
    setContentIdToDelete(id);
    setContentCategoryToDelete(catId);
    setConfirmDeleteContent(true);
  };
  const handleEditContentModal = (row = undefined) => {
    if (row) {
      setEditContentData(row.original);
    }
    setOpenModal(!openModal);
  };
  const handleViewContent = (row = undefined) => {
    if (row) {
      setContentToView(row.original);
    }
    setOpenViewContent(!openViewContent);
  };

  const getLanguages = async () => {
    try {
      await Axios.get(APP.API_MAIN_URL + "/utils/languages", config).then(
        (res) => {
          setAllLanguage(res.data.data);
        }
      );
    } catch (error) {
      console.log("Error in retrieving languages", error);
    }
  };
  useEffect(() => {
    getLanguages();
  }, []);

  const columns = [
    {
      Header: "Created Date",
      Cell: ({ row }) => {
        return row.original.created_at.split("T")[0];
      },
    },
    {
      Header: "Title",
      Cell: ({ row }) => {
        const title = row.original.title;

        return title[selectedLanguage] ? title[selectedLanguage] : "----";
      },
    },
    {
      Header: "Content",
      Cell: ({ row }) => {
        const cont = row.original.body;
        if (selectedLanguage === "all") {
          return "all";
        } else if (cont[selectedLanguage]) {
          return cont[selectedLanguage].substring(0, 18) + "...";
        } else return "";
      },
    },
    {
      Header: "Category",
      Cell: ({ row }) => {
        return row.original.category.name[selectedLanguage];
      },
    },
    {
      Header: "Status",
      Cell: ({ row }) => {
        return (
          <div
            className={`py-2 md:w-28 flex justify-center rounded-[5px] px-4 capitalize ${
              row.original.status === "active" ? "bg-green-500" : "bg-[#b6a9a4]"
            }`}
          >
            {row.original.status}
          </div>
        );
      },
    },
    {
      Header: "Created By",
      Cell: ({ row }) => {
        return (
          row?.original?.created_by?.first_name +
          " " +
          row?.original?.created_by?.last_name
        );
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  font-bold">
            <Popover className="relative">
              <Popover.Button>
                <FiMoreVertical size={30} className="cursor-pointer" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10">
                  <div
                    className="flex flex-col bg-white shadow-md border border-black/10  rounded-sm text-xs "
                    onClick={() => {
                      setSelectedContentId(row.original.id);
                    }}
                  >
                    <h1
                      className="px-4 py-2 hover:bg-[#D9D9D9] text-center"
                      onClick={() => {
                        handleViewContent(row);
                      }}
                    >
                      View
                    </h1>
                    <h1
                      className="px-4 py-2 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                      onClick={() => {
                        handleEditContentModal(row);
                        console.log("row.origina", row.original);
                        setEditContent((prevData) => ({
                          ...prevData,
                          id: row.original.id,
                          title: row.original.title,
                          body: row.original.body,
                          article_category_id: row.original.article_category_id,
                          status: row.original.status,
                        }));
                      }}
                    >
                      Edit
                    </h1>
                    <h1
                      className="px-4 py-2 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                      onClick={() => {
                        handleDeleteContent(
                          row.original.id,
                          row.original.article_category_id
                        );
                        console.log("GHGH", row.original);
                      }}
                    >
                      Delete
                    </h1>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
        );
      },
    },
  ];
  // console.log("Content->", isLoadingCategory, categories);
  console.log("FILTERED CONTENT", filteredContent, "articles->", articles);
  return (
    <div className="content flex lg:flex-row flex-col space-y-8   bg-secondary h-full overflow-y-scroll">
      <div className="flex p-2 w-full h-screen">
        <div className="bg-secondary border border-white shadow-lg w-full h-max">
          <div className="w-full h-screen ">
            <div className="flex  justify-between items-center px-5">
              <div className="text-[20px] md:w-[27%] font-nunito font-bold flex justify-between w-30">
                <div className="flex items-center ">
                  {showCategoryDiv ? (
                    <BiHide
                      className="cursor-pointer "
                      size={30}
                      onClick={handleHideCategory}
                    />
                  ) : (
                    <BiShow
                      className="cursor-pointer "
                      size={30}
                      onClick={handleHideCategory}
                    />
                  )}
                  <h2 className="ml-5"> Contents</h2>
                </div>
                <div
                  className="flex items-center text-[#626161] cursor-pointer bg-[#e2e2e2] p-1 rounded-md px-2 hover:opacity-80 text-16px font-bold transition-opacity duration-300"
                  onClick={() => setShowLanguageOptions(!showLanguageOptions)}
                >
                  <span className="text-[16px] font-[600] flex items-center">
                    <span className="hidden md:flex"> Language(</span>
                    <span className="uppercase font-[900]">
                      {selectedLanguage}
                    </span>
                    <span className="hidden md:flex">)</span>
                  </span>
                </div>
              </div>
              {showLanguageOptions && (
                <div className="w-[58%] flex ">
                  <div className="mr-10 ">
                    <div
                      className={`flex flex-col lg:flex-row transition-opacity duration-700 ${
                        showLanguageOptions ? "opacity-100" : "opacity-0"
                      }`}
                    >
                      {allLanguage.map((language, index) => (
                        <div key={index} className="mt-2 ml-2">
                          <input
                            type="radio"
                            id={language}
                            name="language"
                            value={language}
                            checked={selectedLanguage === language.code}
                            onChange={() => handleLanguage(language)}
                          />
                          <label htmlFor={language} className="ml-2">
                            {language.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <Button
                content={
                  <>
                    <div className="hidden md:flex">Create Content</div>
                    <div className="visible md:hidden">
                      <HiPlus />
                    </div>
                  </>
                }
                onClick={() =>
                  selectedCategory.length === 0
                    ? notify(
                        "error",
                        "No selected category, Please choose any category!! "
                      )
                    : handleCreateArticleModal()
                }
                btnColor="primary"
                Style={`text-white w-1/7 md:text-[20px] ${
                  selectedCategory.length === 0 ? "cursor-not-allowed" : ""
                }`}
              />
            </div>
            <div className="overflow-y-auto ">
              <div
                className={`w-full h-full lg:flex-row gap-6 ${
                  showCategoryDiv ? "flex flex-col " : ""
                }`}
              >
                <div
                  className={`md:relative h-full lg:flex  w-full lg:min-w-[420px] lg:max-w-[420px]  flex-col mx-auto mb-8 lg:mb-0`}
                >
                  {showCategoryDiv && (
                    <div
                      className={`w-full max-h-[550px] flex flex-col bg-white border border-black/10 rounded-lg pb-4 transition duration-200 translate-y-2`}
                    >
                      <div className="w-full flex flex-col justify-between border  border-white border-b-[0.5px]  px-5 ">
                        <div className="flex justify-between items-baseline my-2">
                          <p className="italic text-[#414141] ">
                            All Categories
                          </p>
                          <Button
                            content="Add new"
                            onClick={handleCreateCategory}
                            btnColor="primary"
                            Style="text-white md:text-[16px]"
                          />
                        </div>
                        <div className="flex  w-full border rounded border-[#5E5B5B] min-h-[40px] mb-4 justify-center items-center px-2">
                          <BiSearch size={20} />
                          <input
                            defaultValue=""
                            placeholder="Search"
                            className="w-full rounded outline-none font-nunito"
                          />
                        </div>
                        <hr className="mt-2" />
                      </div>
                      <div className="pt-2 mx-[20px] overflow-y-auto ">
                        <div className="  text-base">
                          {articleCategories
                            .filter((item) => item.parent_id === null)
                            .map((category, index) => (
                              <CategoryItem
                                key={index}
                                category={category}
                                setEditcategoryId={setEditcategoryId}
                                setShowConfirmation={setShowConfirmation}
                                handleEditcategoryModal={
                                  handleEditcategoryModal
                                }
                                categories={articleCategories}
                                allLanguage={allLanguage}
                                setSelectedCategory={setSelectedCategory}
                                selectedCategory={selectedCategory}
                                selectedLanguage={selectedLanguage}
                                setCategoryIdToDelete={setCategoryIdToDelete}
                                categoryIdToDelete={categoryIdToDelete}
                                notify={notify}
                                openEditCategory={openEditCategory}
                                clicked={clicked}
                                setClicked={setClicked}
                                config={config}
                                getArticlesCategory={getArticlesCategory}
                                isLoadingCategory={isLoadingCategory}
                                setEditCategory={setEditCategory}
                                editCategory={editCategory}
                                openDeleteCatModal={openDeleteCatModal}
                                setOpenDeleteCatModal={setOpenDeleteCatModal}
                                handleDeleteClick={handleDeleteClick}
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className={`flex flex-col w-full px-0 ${
                    !showCategoryDiv ? "ml-0" : "visible"
                  }`}
                >
                  <div className="overflow-x-auto  ">
                    {articles.length > 0 ? (
                      selectedCategory && filteredContent.length >= 0 ? (
                        <Table
                          columns={columns}
                          data={
                            // selectedStatus !== "all"
                            //   ? filteredByBody.filter(
                            //       (item) => item.status === selectedStatus
                            //     )
                            //   : filteredByBody
                            articles
                          }
                          title="bank file"
                          className="mx-0"
                          showFilterBtn={showFilterBtn}
                          selectedLanguage={selectedLanguage}
                          setSelectedStatus={setSelectedStatus}
                          selectedStatus={selectedStatus}
                        />
                      ) : selectedCategory && filteredContent.length === 0 ? (
                        <div className="flex justify-center">
                          <h1 className="text-[36px] font-extrabold text-[#a1a0a0]">
                            No content in selected category
                          </h1>
                        </div>
                      ) : (
                        <Table
                          columns={columns}
                          data={
                            selectedStatus !== "all"
                              ? articles.filter(
                                  (item) => item.status === selectedStatus
                                )
                              : articles
                          }
                          title="bank file"
                          className="mx-0"
                          showFilterBtn={showFilterBtn}
                          selectedLanguage={selectedLanguage}
                          setSelectedLanguage={setSelectedLanguage}
                          setSelectedStatus={setSelectedStatus}
                          selectedStatus={selectedStatus}
                        />
                      )
                    ) : (
                      <div className="font-bold text-lg text-[#5b5b5b] m-auto">
                        {selectedLanguage === "en"
                          ? "No content avalaible Please click on create content to   add new"
                          : selectedLanguage === "fr"
                          ? "Aucun contenu disponible Veuillez cliquer sur créer du contenu pour en ajouter un nouveau"
                          : "Ntakintu kiboneka, Nyamuneka kanda ahagenewe kongeramo ibirimo kugirango wongeremo ibishya"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <AddNewArticle
              handleModal={handleCreateArticleModal}
              openModal={openArticleModal}
              config={config}
              openCatModal={openCatModal}
              setOpenCatModal={setOpenCatModal}
              handleCreateCategory={handleCreateCategory}
              getContent={getContent}
              selectedCategory={selectedCategory}
              selectedLanguage={selectedLanguage}
              selectedLangue={selectedLangauge}
            />

            <UpdateCategory
              handleModal={handleEditcategoryModal}
              openModal={openEditCategory}
              categories={articleCategories}
              config={config}
              catId={catId}
              selectedLanguage={selectedLanguage}
              notify={notify}
              getArticlesCategory={getArticlesCategory}
              lastMod={editCategory}
              setLastMod={setEditCategory}
            />

            <UpdateArticle
              openModal={openModal}
              handleModal={handleEditContentModal}
              editContent={editContent}
              categoryId={editContent.article_category_id}
              articleId={editContent.id}
              planData={editContent}
              setPlanData={setEditContent}
            />
            <ViewContent
              handleViewContent={handleViewContent}
              openViewContent={openViewContent}
              contentToView={contentToView}
              selectedLanguage={selectedLanguage}
              config={config}
              notify={notify}
              allLanguage={allLanguage}
            />
            <AddNewCat
              openModal={openCatModal}
              handleModal={handleCreateCategory}
              getArticlesCategory={getArticlesCategory}
              categories={articleCategories}
            />
            <DeleteCategory
              openModal={openDeleteCatModal}
              handleModal={handleDeleteClick}
              categoryId={categoryIdToDelete}
            />
          </div>
          {showConfirmation && (
            <ConfirmationPop
              onClose={() => {
                setShowConfirmation(false);
              }}
              onConfirm={() => {
                try {
                  handleDeleteConfirmed();
                  setShowConfirmation(false);
                } catch (error) {
                  console.log(error);
                }
              }}
            />
          )}
          {confirmDeleteContent && (
            <ConfirmDeleteContent
              onClose={() => {
                setConfirmDeleteContent(false);
              }}
              onConfirm={() => {
                try {
                  handleDeleteConfirmedContent();
                  setConfirmDeleteContent(false);
                } catch (error) {
                  console.log(error);
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Articles;
