import React, { useState, Fragment } from 'react';
import Modal from '../../../../components/modal/Modal';
import Table from '../../../../components/tableComponent/Table';
import { skeletonColumns, skeletonData } from '../../../../seeds/Data';
import SkeletonTable from '../../../../components/SkeletonTable';
import { useSelector } from 'react-redux';
import { FiMoreVertical } from 'react-icons/fi';
import { Popover, Transition } from '@headlessui/react';

const CreditWalletTransaction = ({ openModal, handleOpenModal }) => {
  const { is_fetching_credit_wallet_transactions, credit_wallet_transactions } =
    useSelector((state) => state.wallet);

  const columns = [
    {
      Header: 'Date',
      accessor: '',
      Cell: ({ row }) => {
        return <div>{row.original.created_at.split('T')[0]}</div>;
      },
    },
    {
      Header: 'Reference',
      accessor: 'reference',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Amount',
      accessor: 'transaction_amount',
    },
    {
      Header: 'Balance',
      accessor: 'total_balance',
    },
    {
      Header: 'Names',
      accessor: '',
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.wallet?.profile?.first_name +
              ' ' +
              row.original.wallet?.profile?.last_name}
          </div>
        );
      },
    },
  ];

  return (
    <Modal
      isOpen={openModal}
      onClose={handleOpenModal}
      Style={'w-[90%] lg:w-2/3 h-[95%] flex m-auto py-4'}
    >
      <Modal.Header>
        <h1 className="font-bold">Fetch Credit Transactions</h1>
      </Modal.Header>
      <Modal.Body>
        <div className="overflow-x-auto my-auto">
          {!is_fetching_credit_wallet_transactions ? (
            credit_wallet_transactions?.length == 0 ? (
              <p className="text-[20px] text-[#808080] font-bold flex justify-center items-center mt-6">
                There are no Credit Wallet Transactions yet for this Wallet,
                Transactions will appear here.
              </p>
            ) : (
              <Table
                columns={columns}
                initialSize={10}
                data={
                  credit_wallet_transactions?.length > 0
                    ? credit_wallet_transactions
                    : []
                }
                className="mx-0"
              />
            )
          ) : (
            <SkeletonTable columns={skeletonColumns} data={skeletonData} />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreditWalletTransaction;
