import { Popover, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function ChildItem({
  item,
  categories,
  selectedLanguage,
  allLanguage,
  handleEditcategoryModal,
  setEditcategoryId,
  handleDeleteClick,
  clicked,
  setClicked,
  setSelectedCategory,
  selectedCategory,
  setEditCategory,
  setCategoryIdToDelete,
  parentId,
}) {
  const [showChild, setShowChild] = useState(false);

  const navigate = useNavigate();
  const [childItemId, setChildItemId] = useState("");
  console.log("SELECTED CATEGORY", selectedCategory);
  return (
    <>
      <div
        className={`p-2 my-1 flex justify-between hover:rounded-md hover:bg-[#646464] text-[#646464] cursor-pointer hover:text-white border-b border-light border-opacity-40 ${
          selectedCategory?.id === item?.id ? "bg-[#646464] text-white" : ""
        }`}
        onClick={() => {
          navigate("/vas/content/" + item.id);
          setSelectedCategory(item);
          setShowChild(!showChild);
          setChildItemId(item.id);
          console.log(
            "ParentId",
            parentId,
            "item->",
            item.id,
            "selectedCategory",
            selectedCategory?.id
          );
        }}
      >
        <div className="flex justify-between w-full items-center ">
          {item.name[selectedLanguage] ? item.name[selectedLanguage] : ""}
          {!showChild ? <IoIosArrowForward /> : <IoIosArrowDown />}
        </div>

        <Popover className="relative">
          <Popover.Button>
            <FiMoreVertical size={15} className="cursor-pointer" />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-3 z-10">
              <div className="flex flex-col bg-white text-primary shadow-md border border-black/10  rounded-sm text-xs ">
                {/* <h1 className="px-4 py-2 hover:bg-[#D9D9D9] hover:text-primary text-center">
                  View
                </h1> */}
                <h1
                  className="px-4 py-2 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                  onClick={() => {
                    handleEditcategoryModal();
                    setEditcategoryId(item.id);
                    setEditCategory((prevData) => ({
                      ...prevData,
                      id: item?.id,
                      name: {
                        ...prevData.name,
                        ...item?.name,
                      },
                      description: {
                        ...prevData.description,
                        ...item?.description,
                      },
                      status: item.status,
                      parent_id: item.parent_id,
                      content_type: {
                        label: item.content_type,
                        value: item.content_type,
                      },
                      languages: item?.languages || [],
                    }));
                  }}
                >
                  Edit
                </h1>
                <h1
                  className="px-4 py-2 w-full hover:bg-[#D9D9D9] text-center cursor-pointer"
                  onClick={() => {
                    handleDeleteClick(item.id);
                    setCategoryIdToDelete(item.id);
                  }}
                >
                  Delete
                </h1>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
      {showChild && (
        <ChildCategory
          categories={categories}
          catId={item.id}
          allLanguage={allLanguage}
          handleDeleteClick={handleDeleteClick}
          selectedLanguage={selectedLanguage}
          setSelectedCategory={setSelectedCategory}
          handleEditcategoryModal={handleEditcategoryModal}
          setEditcategoryId={setEditcategoryId}
          setEditCategory={setEditCategory}
          setCategoryIdToDelete={setCategoryIdToDelete}
          childItemId={childItemId}
          setChildItemId={setChildItemId}
          selectedCategory={selectedCategory}
        />
      )}
    </>
  );
}

function ChildCategory({
  categories,
  catId,
  show,
  selectedLanguage,
  allLanguage,
  setShow,
  handleEditcategoryModal,
  setEditcategoryId,
  handleDeleteClick,
  clicked,
  setClicked,
  setSelectedCategory,
  selectedCategory,
  setEditCategory,
  setCategoryIdToDelete,
  childItemId,
  setChildItemId,
}) {
  const [childCategories, setChildCategories] = useState([]);

  useEffect(() => {
    const result = categories.filter((item) => item.parent_id === catId);
    setChildCategories(result);
  }, [categories, catId]);

  const renderItems = (childCategories, catId) => {
    if (childCategories.length === 0) {
      return null;
    }

    return (
      <div className="ml-7 ">
        {childCategories.map((item, i) => (
          <ChildItem
            key={i}
            item={item}
            allLanguage={allLanguage}
            categories={categories}
            selectedLanguage={selectedLanguage}
            handleEditcategoryModal={handleEditcategoryModal}
            setEditcategoryId={setEditcategoryId}
            handleDeleteClick={handleDeleteClick}
            clicked={clicked}
            setClicked={setClicked}
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
            setEditCategory={setEditCategory}
            setCategoryIdToDelete={setCategoryIdToDelete}
            childItemId={childItemId}
            setChildItemId={setChildItemId}
            parentId={catId}
          />
        ))}
      </div>
    );
  };

  return <> {renderItems(childCategories, catId)}</>;
}

export default ChildCategory;
