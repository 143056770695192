import {
  SET_IS_FETCHING_BANKS,
  SET_IS_FETCHING_BANK_ACCOUNTS,
  SET_IS_FETCHING_BANK_ACCOUNT_PAYMENT,
  FETCH_BANKS,
  FETCH_BANK_ACCOUNTS,
  FETCH_BANK_ACCOUNT_PAYMENT,
} from '../actions/bank';

const initialState = {
  is_fetching_banks: false,
  is_fetching_bank_accounts: false,
  is_fetching_bank_account_payment: false,
  banks: [],
  bank_accounts: [],
  bank_account_payment: [],
};

export const bank = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_BANKS:
      return {
        ...state,
        is_fetching_banks: action.payload,
      };
    case SET_IS_FETCHING_BANK_ACCOUNTS:
      return {
        ...state,
        is_fetching_bank_accounts: action.payload,
      };

    case SET_IS_FETCHING_BANK_ACCOUNT_PAYMENT:
      return {
        ...state,
        is_fetching_bank_account_payment: action.payload,
      };
    case FETCH_BANKS:
      console.log('we are fetching banks', action.payload);
      return {
        ...state,
        banks: action.payload,
      };

    case FETCH_BANK_ACCOUNTS:
      return {
        ...state,
        bank_accounts: action.payload,
      };

    case FETCH_BANK_ACCOUNT_PAYMENT:
      return {
        ...state,
        bank_account_payment: action.payload,
      };

    default:
      return state;
  }
};
