import React, { useEffect } from 'react'
import Modal from '../../components/modal/Modal'
import Table from '../../components/tableComponent/Table'
import {IoMdArrowDropdown, IoMdArrowDropup} from "react-icons/io"

const AudienceModal = ({open, setOpen, audience}) => {
  const [show, setShow] = React.useState(true);
  const [id,setId] = React.useState(0)
  // console.log(audienceMembers,'....c...')
  // console.log(audience)
  // console.log(audience[0]?.members,'cxcv')



  const columns = [
    {
      Header: "Names",
      accessor: "names",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Telephone",
      accessor: "phone",
    },
    {
      Header: "Location",
      accessor: "location",
    },
    
  ]

  console.log(audience.length, 'xmcx............')

  return (
    <div>
    <Modal
        isOpen={open}
        onClose={() => setOpen(!open)}
        Style={'w-3/4 mx-auto h-max m-4 flex z-[10]'}
      >
        <Modal.Body>

          {audience?.length!==0 ? (
            <div className='flex flex-row justify-between'>
            <div className='flex flex-col bg-secondary rounded-lg shadow-xl w-1/4'>
            <div className="text-primary w-full flex rounded-sm px-8 py-4 flex-col gap-2 cursor-pointer justify-start items-start mt-2">
                  <button onClick={()=> setShow(!show)} className="relative flex flex-col w-full justify-around text-2xl">
                    Audience list
                    {show ? <IoMdArrowDropup className="absolute top-0 right-0" size={20} color="black" /> : <IoMdArrowDropdown className="absolute top-0 right-0" size={20} color="black" />}
                  </button>
                  
                    <div className="text-primary justify-start items-start pl-2 flex w-full  flex-col space-y-4 mt-2">
                      {show && audience.map((element,i) => {
                        return (
                          <div className={`flex flex-col w-full ${i === id ? 'bg-primary text-white p-2':''}`} >
                           <h1 onClick={() => setId(i)}>{element.name}</h1>
                        </div>
                        )  
                      })}
                    </div>
                  
                </div>
            </div>
            <div className='w-3/4'>
            <Table data={audience[id]?.members} columns={columns}/>
            </div>              
          </div>
          ):(
            <div>
              <p>💁 The selected user has no audience!</p>
            </div>
          )}
          
        </Modal.Body>
        <Modal.Footer>
            <button onClick={() => setOpen(!open)}>Close</button>
        </Modal.Footer>
      </Modal>
     
   </div>
  )
}

export default AudienceModal