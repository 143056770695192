import React, { Fragment, useState, useEffect } from 'react';
import Input from '../../components/Input';
import Select from '../../components/Select';
import Button from '../../components/Button';
import Axios from '../../components/Axios';
import { useForm } from 'react-hook-form';
import Table from '../../components/tableComponent/Table';
import { Popover, Transition } from '@headlessui/react';
import { FiMoreVertical } from 'react-icons/fi';
import { ToastComponent, ToasterComponent } from '../../components/Toast';
import Modal from '../../components/modal/Modal';
import RejectSenderName from '../modals/RejectSenderName';
import SenderNameDetails from '../modals/SenderNameDetails';
import AddSenderName from '../modals/AddSenderName';
import { useSelector } from 'react-redux';
import { APP } from '../../utils/constant';

const SenderName = ({ SkeletonTable, skeletonData, skeletonColumns }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  // function to sort data to get newly created data
  const getLatest = (a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA; // Sort in descending order of creation date
  };
  const { token } = useSelector((state) => state.user);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [senderNames, setSenderNames] = useState([]);
  const [senderNameId, setSenderNameId] = useState(6);
  const [show, setShow] = useState(false);
  const [senderNamesFetching, setSenderNamesFetching] = useState(false);
  const [toggleNames, setToggleNames] = useState(false);

  const [openSenderNameDetails, setOpenSenderNameDetails] = useState(false);
  const [addSenderName, setAddSenderName] = useState(false);

  const handleTriggerFetchSenderNames = () => {
    setToggleNames(!toggleNames);
  };

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const getSenderNames = () => {
    setSenderNamesFetching(true);
    try {
      Axios.get('https://api.account.konvey.rw/api/admin/sender-names', config)
        .then((res) => {
          setSenderNames(res.data.data.sort(getLatest));
          setSenderNamesFetching(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const confirmSenderName = () => {
    try {
      Axios.put(
        `${APP.API_MAIN_URL}/admin/sender-name-requests/${senderNameId}/approve`,
        {},
        config
      )
        .then((res) => {
          notify('success', 'status updated successfully');
          setShow(!show);
          handleTriggerFetchSenderNames();
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSenderNames();
  }, [toggleNames]);

  const columns = [
    {
      Header: 'First Name',
      Cell: ({ row }) => {
        return <h1>{row?.original?.profile?.first_name}</h1>;
      },
    },
    {
      Header: 'Last Name',
      Cell: ({ row }) => {
        return <h1>{row?.original?.profile?.last_name}</h1>;
      },
    },
    {
      Header: 'Email',
      Cell: ({ row }) => {
        return <h1>{row?.original?.profile?.email}</h1>;
      },
    },
    {
      Header: 'Name',
      Cell: ({ row }) => {
        return <h1>{row?.original?.name}</h1>;
      },
    },
    {
      Header: 'Type',
      Cell: ({ row }) => {
        return <h1>{row?.original?.type}</h1>;
      },
    },
    {
      Header: 'Source',
      Cell: ({ row }) => {
        return <h1>{row?.original?.source}</h1>;
      },
    },
    {
      Header: 'Status',
      accessor: '',
      Cell: ({ row }) => {
        if (row?.original?.status === 'Approved') {
          return (
            <div className="bg-success bg-opacity-50 px-4 py-2 rounded-sm text-sm w-[110px] flex items-center justify-center">
              {row?.original?.status}
            </div>
          );
        } else if (row?.original?.status === 'inactive') {
          return (
            <div className=" bg-error bg-opacity-50 px-4 py-2 rounded-sm text-sm w-[110px] flex items-center justify-center">
              {row?.original?.status}
            </div>
          );
        } else {
          return (
            <div className="bg-[#8ecae6] bg-opacity-90 px-2 py-2 rounded-sm text-sm w-[110px] flex items-center justify-center">
              {row?.original?.status}
            </div>
          );
        }
      },
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row  font-bold">
            <Popover className="relative">
              <Popover.Button>
                <FiMoreVertical size={30} className="cursor-pointer" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10">
                  <div
                    className="flex flex-col bg-white shadow-md border border-black/10  rounded-sm text-xs "
                    onClick={() => {}}
                  >
                    <h1
                      className="px-4 py-2  font-bold hover:bg-[#D9D9D9] text-center"
                      onClick={() => {
                        setSenderNameId(row.original.id);
                        setOpenSenderNameDetails(!openSenderNameDetails);
                      }}
                    >
                      Details
                    </h1>

                    <h1
                      className="px-4 py-2 font-bold hover:bg-[#D9D9D9] text-center"
                      onClick={() => {
                        setSenderNameId(row.original.id);
                        setShow(!show);
                      }}
                    >
                      Confirm
                    </h1>
                    <h1
                      className="px-4 py-2 text-error font-bold hover:bg-[#D9D9D9] text-center"
                      onClick={() => {
                        setSenderNameId(row.original.id);
                        setConfirmDelete(!confirmDelete);
                      }}
                    >
                      Reject
                    </h1>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <RejectSenderName
        show={confirmDelete}
        setShow={setConfirmDelete}
        senderNameId={senderNameId}
      />
      <SenderNameDetails
        senderNameId={senderNameId}
        show={openSenderNameDetails}
        setShow={setOpenSenderNameDetails}
      />
      <AddSenderName
        show={addSenderName}
        setShow={() => setAddSenderName(!addSenderName)}
        config={config}
        handleTriggerFetchSenderNames={handleTriggerFetchSenderNames}
      />
      <Modal
        isOpen={show}
        onClose={() => setShow(!show)}
        Style={'w-max m-auto h-max  p-8 flex z-[10]'}
      >
        <Modal.Body>
          <div className="flex flex-col mt-8">
            <div className="text-primary font-bold">
              Confirm the sender name ?
            </div>

            <div className="flex justify-end items-end space-x-4 mt-100">
              <Button
                type="submit"
                content="Confirm"
                loading={false}
                btnColor="primary"
                Style={`text-white w-fit h-[40px] mt-8 ml-0 border border-primary`}
                onClick={() => confirmSenderName()}
              />
              <Button
                type="button"
                content="Close"
                loading={false}
                btnColor="white"
                Style={`text-primary w-fit h-[40px] mt-8 ml-0 border border-primary`}
                onClick={() => setShow(!show)}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToasterComponent />
      <div className="flex flex-col-reverse lg:flex-row justify-between">
        <div className="w-full overflow-y-auto">
          {senderNamesFetching ? (
            <SkeletonTable columns={skeletonColumns} data={skeletonData} />
          ) : senderNames.length > 0 ? (
            <div className="flex flex-col mb-4">
              <Table columns={columns} data={senderNames} />
              <div className="flex flex-row self-end mr-4">
                <Button
                  type="submit"
                  content="Add Sendername"
                  loading={false}
                  btnColor="primary"
                  Style={`text-white w-fit h-[40px] mt-8 ml-0 border border-primary`}
                  onClick={() => setAddSenderName(!addSenderName)}
                />
              </div>
            </div>
          ) : (
            <h1 className="font-bold">You currently have no sender names </h1>
          )}
        </div>
      </div>
    </>
  );
};

export default SenderName;
