import React from "react";

function ConfirmationPop({ onClose, onConfirm }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded shadow-md">
        <p className="text-lg font-semibold mb-4">
          Are you sure you want to delete this Content?
        </p>
        <p className="text-gray-600 mb-8">
          By clicking on confirm your selected Content will be deleted
        </p>
        <div className="flex justify-end">
          <button
            className="px-4 py-2 mr-2 text-gray-600 hover:text-gray  text-[18px]"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-primary text-[18px] text-white rounded hover:opacity-80"
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationPop;
