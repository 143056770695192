import React, { useState, useEffect } from "react";
import Modal from "../../components/modal/Modal";
import Loader from "../../components/Loader";
import SmsDetails from "../../modals/campainModals/modals/SmsDetails";
import EmailDetails from "../../modals/campainModals/modals/EmailDetails";

import Axios from "../../components/Axios";
import Button from "../../components/Button";
import TextArea from "../../components/TextArea";
import { useForm } from "react-hook-form";
import { APP, notify } from "../../utils/constant";
import { useSelector } from "react-redux";
import BlastSmsDetails from "./BlastSmsDetails";
import axios from "axios";
import Modal2 from "../../components/modal/Modal2";

// const fileDisplay = (files) => {
//   if (files.length > 0) {
//     return files.map((file, index) => {
//       if (file.includes("application/pdf")) {
//         return (
//           <iframe src={`${file}`} frameborder="0" width="70%" height="500" />
//         );
//       } else if (file.includes("video")) {
//         return (
//           <video width="300" height="500" src={file} controls type="video/mp4">
//             video file not supported
//           </video>
//         );
//       } else {
//         return (
//           <div className="flex flex-row">
//             <img src={file} height={250} width={250} alt="blast file" />
//           </div>
//         );
//       }
//     });
//   }
// };

const BlastDetails = ({
  show,
  setShow,
  data,
  blastId,
  profile,
  role,
  config,
  getAllBlasts,
}) => {
  const { token } = useSelector((state) => state.user);
  const [blastObject, setBlastObject] = useState("");
  const [fetchLoading, setFetchLoading] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [open, setOpen] = useState(false);
  const [openApproval, setOpenApproval] = useState(false);
  const [sendRequest, setSendRequest] = React.useState(false);
  const [reject, setReject] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [isBlastRequestClicked, setIsBlastRequestClicked] = useState(false);
  const [blastRequestData, setBlastRequestData] = useState("");
  const [blastRequests, setBlastRequests] = useState([]);
  const [clickedIndex, setClickedIndex] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  let permission = "APPROVER";
  console.log(blastObject, "blastObject");
  const sms = blastObject?.sms?.length > 0 ? true : false;
  const email = blastObject?.email?.length > 0 ? true : false;
  const qrCode =
    blastObject?.blast_custom_qr_codes?.length > 0 ||
    blastObject?.blast_standard_qr_code?.length > 0
      ? true
      : false;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const formattedDate = dateObj.toLocaleDateString("en", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
    return formattedDate;
  };

  const formatTime = (timeString) => {
    const timeObj = new Date(timeString);
    const formattedTime = timeObj.toLocaleTimeString("en", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  };

  // delete blast
  const approveBlast = () => {
    setApproveLoading(true);
    Axios.put(
      APP.API_MAIN_URL +
        `/admin/blasts/${blastId}/requests/${requestId}/approve`,
      {},

      config
    )
      .then((res) => {
        console.log(res);
        notify("success", "Blast approved successfully");
        setApproveLoading(false);
        setOpenApproval(!openApproval);
        setRequestId("");
        getAllBlasts();
      })
      .catch((err) => {
        console.log(err);
        setApproveLoading(false);
        notify("error", "Error approving blast");
      });
  };

  const handleReason = (e) => {
    const { value } = e.target;
    setRejectReason(value);
  };

  const rejectBlast = () => {
    Axios.put(
      APP.API_MAIN_URL +
        `/admin/blasts/${blastObject.id}/requests/${requestId}/approve`,
      {},
      config
    )
      .then((res) => {
        console.log(res);
        notify("success", "Blast approved successfully");

        setOpenApproval(!openApproval);
      })
      .catch((err) => {
        console.log(err);
        setRejectLoading(false);
        notify("error", "Error approving blast");
      });
  };

  const onSubmit = () => {
    setRejectLoading(true);
    Axios.put(
      APP.API_MAIN_URL +
        `/admin/blasts/${blastObject.id}/requests/${requestId}/reject`,
      {
        reason: rejectReason,
      },
      config
    )
      .then((res) => {
        setRejectLoading(false);
        setRejectReason("");
        getAllBlasts();
        console.log(res);
        notify("success", "Blast Rejected!");
        setOpen(!open);
      })
      .catch((err) => {
        setRejectLoading(false);
        console.log(err);
        notify("error", err.response.data.message);
      });
  };

  useEffect(() => {
    setFetchLoading(true);
    Axios.get(APP.API_MAIN_URL + `/admin/blasts/${blastId}`, config)
      .then((res) => {
        setFetchLoading(false);
        setBlastObject(res.data.data);
      })
      .catch((err) => {
        setFetchLoading(false);
        console.log(err);
      });
    axios
      .get(APP.API_MAIN_URL + `/admin/blasts/${blastId}/requests`, config)
      .then((res) => {
        setBlastRequests(res.data.data);

        console.log("This is my blasts requests", res);
      })
      .catch((error) => console.log("Error in fetching requests", Error));
  }, [blastId]);

  return (
    <div className="font-nunito">
      {/* approve blast modal */}

      <Modal2
        isOpen={show}
        onClose={() => setShow()}
        Style={
          "h-[95%] lg:h-max w-[90%] lg:w-1/2 w-full mt-8  mx-auto pb-0 font-nunito text-sm"
        }
      >
        <Modal2.Header>
          <h1 className="font-bold">Blast Details</h1>
        </Modal2.Header>
        {/* cancel blast modal */}

        <Modal2
          isOpen={open}
          onClose={() => setOpen(!open)}
          Style={"w-1/2 m-4 flex m-auto"}
        >
          <Modal2.Header>
            <h1 className="font-bold">Blast Rejection</h1>
          </Modal2.Header>
          <Modal2.Body>
            <div className="flex flex-col ">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="text-primary">
                  <TextArea
                    labelText={"Reason for rejection"}
                    labelFor={`Rejection reason`}
                    name={`Rejection reason`}
                    placeholder={"Reason........"}
                    customClass="w-full mb-2"
                    inputStyle="h-[90px]"
                    isRequired={true}
                    value={rejectReason}
                    onChange={(e) => {
                      handleReason(e);
                    }}
                    register={register}
                    errors={errors}
                  />{" "}
                </div>
                <div className="flex justify-end">
                  <Button
                    type="submit"
                    content="Confirm"
                    loading={rejectLoading}
                    btnColor="black"
                    Style={"text-white h-[40px] mt-2 ml-0 border rounded-lg"}
                  />
                </div>
              </form>
            </div>
          </Modal2.Body>
        </Modal2>

        {/*end of modal*/}

        <Modal2.Body>
          <div className="p-4 flex flex-row w-full">
            {fetchLoading ? (
              <div className="h-[150px] w-full flex ">
                <Loader loader={true} />
              </div>
            ) : (
              <div className="flex flex-row w-full">
                <div className="w-4/5">
                  <div
                    className={` ${
                      isBlastRequestClicked ? "hidden" : "visible"
                    } flex flex-col `}
                  >
                    <div className="flex flex-col space-y-2 mt-2  w-full lg:w-3/4">
                      <div className="flex flex-col md:grid md:grid-cols-2 ">
                        <h1 className="text-light w-fit text-xl ">
                          Blast title:
                        </h1>
                        <h2 className="text-black text-lg">
                          {blastObject?.title}
                        </h2>
                      </div>
                      <div className="flex flex-col md:grid md:grid-cols-2">
                        <h1 className="text-light w-fit text-xl ">
                          Blast Description:
                        </h1>
                        <h2 className="text-black text-lg">
                          {blastObject?.description}
                        </h2>
                      </div>
                      <div className="flex flex-col md:grid md:grid-cols-2">
                        <h1 className="text-light w-fit text-xl ">
                          Blast type:
                        </h1>
                        <h2 className="text-black text-lg uppercase">
                          {blastObject?.blast_type}
                        </h2>
                      </div>
                      <div className="flex flex-col md:grid md:grid-cols-2">
                        <h1 className="text-light w-fit text-xl"> Status:</h1>
                        <h2 className="text-black text-lg capitalize">
                          {blastObject?.status}
                        </h2>
                      </div>
                      <div className="flex lg:flex-row  lg:gap-1 lg:items-center mt-1 flex-col">
                        <div>
                          <h2 className="text-black text-sm">
                            {sms
                              ? "SMS"
                              : email
                              ? "Email"
                              : qrCode
                              ? "QR Code"
                              : sms && email
                              ? "SMS, Email."
                              : null}
                          </h2>
                        </div>
                      </div>
                      {sms ? <BlastSmsDetails data={blastObject.sms} /> : null}

                      {email ? <EmailDetails data={blastObject.email} /> : null}

                      {/* {data[index]?.status === 'PENDING' } */}
                    </div>
                  </div>
                  <div
                    className={
                      isBlastRequestClicked
                        ? "visible w-full self-center m-auto"
                        : "hidden"
                    }
                  >
                    <div className="flex flex-row items-center gap-2">
                      <h1 className="text-lg text-light font-bold">Status :</h1>
                      <h1 className="text-lg">{blastRequestData?.status}</h1>
                    </div>
                    <div
                      className={
                        blastRequestData?.status === "rejected"
                          ? "visible"
                          : "hidden"
                      }
                    >
                      <div className="flex flex-row items-center gap-2">
                        <h1 className="text-lg text-light font-bold">
                          Reason :
                        </h1>
                        <h1 className="text-lg">{blastRequestData?.reason}</h1>
                      </div>
                    </div>
                    <div
                      className={`${
                        blastRequestData?.status !== "pending"
                          ? "visible"
                          : "visible"
                      } flex flex-col border border-error w-full p-4 rounded-lg `}
                    >
                      <h1 className="text-light  font-bold w-fit">
                        Attention : ⚠️
                      </h1>
                      <p>This action can not be reversed, be aware !</p>
                      <div className="flex flex-row space-x-4 w-full">
                        <button
                          className={`rounded-lg text-white bg-error p-2 font-bold w-fit mt-4 ${
                            permission === "APPROVER" ? "hidden" : "flex"
                          }`}
                          onClick={() => {
                            setShow(!show);
                            setOpen(!open);
                          }}
                        >
                          Cancel
                        </button>

                        <button
                          className={`rounded-lg text-white ${
                            sendRequest
                              ? "bg-success "
                              : "bg-success bg-opacity-60"
                          } ${
                            permission === "APPROVER" ? "hidden" : "flex"
                          } p-2 font-bold w-fit mt-4`}
                          onClick={() => {
                            setSendRequest(!sendRequest);
                          }}
                        >
                          {sendRequest
                            ? "🗣️ Request Approval "
                            : "ℹ️ Request has been sent"}
                        </button>

                        <Button
                          type="submit"
                          content={reject ? "🗣️ Rejected " : "Reject Approval"}
                          loading={false}
                          btnColor="error"
                          onClick={() => {
                            setReject(!reject);
                            setOpen(!open);
                          }}
                          Style={`text-white h-[40px] mt-2 rounded-lg text-white ${
                            reject ? "bg-error " : "bg-error"
                          } ${
                            permission === "APPROVER" ? "flex" : "hidden"
                          } p-2 font-bold w-fit mt-4 `}
                        />

                        {/* <button
                          className={`rounded-lg text-white ${
                            reject ? 'bg-error ' : 'bg-error bg-opacity-60'
                          } ${
                            permission === 'APPROVER' ? 'flex' : 'hidden'
                          } p-2 font-bold w-fit mt-4`}
                          onClick={() => {
                            setReject(!reject);
                          }}
                        >
                          {reject ? '🗣️ Rejected ' : 'Reject Approval'}
                        </button> */}

                        <Button
                          type="submit"
                          content={"☑️ Approve"}
                          loading={approveLoading}
                          btnColor="success/60"
                          onClick={() => {
                            approveBlast();
                          }}
                          Style={`text-white h-[40px] mt-2 rounded-lg text-white  ${
                            blastRequestData?.status !== "approved" && role == 3
                              ? "flex"
                              : "hidden"
                          } p-2 font-bold w-fit mt-4 `}
                        />

                        <button
                          className={`rounded-lg text-white bg-success bg-opacity-60 p-2 font-bold w-fit mt-4  ${
                            permission === "APPROVER" ? "flex" : "hidden"
                          } ${reject ? "flex" : "hidden"}`}
                        >
                          undo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col border-l-2 border-black/20 ml-4 pl-2 w-1/5 ">
                  {blastRequests?.map((request, index) => {
                    console.log("Bra bra bra", request);
                    return (
                      <div
                        className={`border border-black/20 p-2 flex flex-col rounded-lg cursor-pointer ${
                          clickedIndex === index && isBlastRequestClicked
                            ? "bg-black/30"
                            : "bg-white"
                        }`}
                        onClick={() => {
                          setIsBlastRequestClicked(!isBlastRequestClicked);
                          setBlastRequestData(request);
                          setClickedIndex(index);
                          setRequestId(request.id);
                        }}
                      >
                        <div className="">
                          {" "}
                          <h1 className="text-xl">Blast request {index + 1}</h1>
                          <div className="flex flex-row items-center gap-2">
                            <h1 className="">On</h1>
                            <p className="flex items-center py-2 text-sm font-bold text-light">
                              {formatDate(request.created_at)}
                              <span className="h-[5px] w-[5px] bg-light rounded-full mx-2"></span>
                              {formatTime(request.created_at)}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </Modal2.Body>
        <Modal2.Footer>
          <Modal2.DismissButton>
            <p className="text-xl">Close</p>
          </Modal2.DismissButton>
        </Modal2.Footer>
      </Modal2>
    </div>
  );
};

export default BlastDetails;
