import React from "react";
import Input from "../../../../../components/Input";
import TextArea from "../../../../../components/TextArea";
import Select from "../../../../../components/Select";

function AddEnglishCategory({
  clearErrors,
  register,
  errors,
  name,
  setNames,
  translatedEn,
  lastMod,
  setLastMod,
  typeOptions,
  control,
}) {
  const handleAddName = (e) => {
    const newName = e.target.value;
    setNames({ ...name, name: newName });
    setLastMod((prev) => ({
      ...prev,
      name: { ...prev.name, en: newName },
    }));
  };

  const handleAddDesc = (e) => {
    const newDesc = e.target.value;
    setNames({ ...name, description: newDesc });

    setLastMod((prev) => ({
      ...prev,
      description: { ...prev.description, en: newDesc },
    }));
  };
  console.log("last mode eng", lastMod);
  return (
    <div>
      <Input
        labelText={"Name"}
        labelFor={"name"}
        name={`name`}
        type={"text"}
        placeholder={"Enter name of category"}
        isRequired={true}
        customClass="w-full mb-2 w-[75%] ml-1"
        onChange={(e) => {
          clearErrors("name");
          handleAddName(e);
        }}
        value={lastMod?.name["en"]}
        register={register}
        errors={errors}
      />
      <TextArea
        labelFor={"description"}
        labelText="Description"
        name={`description`}
        errors={errors}
        placeholder={`Category description`}
        customClass={"w-full mb-2 text-[18px]"}
        isRequired={true}
        register={register}
        value={lastMod?.description["en"]}
        onChange={(e) => {
          clearErrors("description");
          handleAddDesc(e);
        }}
      />
      <Select
        label="Content type"
        customClass="w-full mb-2 text-[18px]"
        isRequired={false}
        placeholder={"Content type"}
        options={typeOptions}
        register={
          // {
          // control,
          // name: `contentType`,
          // rules: { required: "contentType is required" },
          // }
          register
        }
        defaultValue={lastMod?.content_type}
        handleChange={(e) => {
          setLastMod((prev) => ({
            ...prev,
            content_type: e,
          }));
        }}
        error={errors[`contentType`]?.message}
      />
    </div>
  );
}

export default AddEnglishCategory;
