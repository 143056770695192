import React, { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import { RxCross2 } from 'react-icons/rx';
import CampaignData from '../modals/campainModals/CampaignData';
import { Link } from 'react-router-dom';
import Approvals from './Notification/Approvals';
import Payment from './Notification/Payment';
import Support from './Notification/Support';
import Info from './Notification/Info';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Notifications = ({
  notificationLoading,
  approval,
  payment,
  support,
  info,
}) => {
  console.log(approval, 'approval in notification component');
  const [openCampaignDetails, setOpenCampaignDetails] = useState(false);
  const [campaignId, setCampaignId] = useState('');

  const handleCampaignDetailsModal = () => {
    setOpenCampaignDetails(!openCampaignDetails);
  };

  const currentDate = new Date();
  const yesterdayDate = new Date(currentDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  const categories = [
    { name: 'Approvals' },
    { name: 'Payment' },
    { name: 'Help' },
    { name: 'Info' },
  ];

  const notifications = [
    {
      id: 1,
      type: 'approval',
      sender: 'MTN Rwanda',
      image:
        'https://upload.wikimedia.org/wikipedia/commons/9/93/New-mtn-logo.jpg',
      message: 'has requested approval of campaign',
      subject: 'Download the Ayoba app ',
      subjectId: '2',
      created_at: new Date(),
    },
    {
      id: 2,
      type: 'reject',
      sender: 'Konvey Direct',
      image:
        'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/4d4786119276191.609a5318efc25.jpg',
      message: 'has declined your campaign offer',
      subject: 'Lorem Ipsum sit amet',
      subjectId: '2',
      created_at: yesterdayDate,
      //       const yesterday = new Date(currentDate);
      // yesterday.setDate(yesterday.getDate() - 1);
    },
    {
      id: 3,
      type: 'approval',
      sender: 'Konvey Direct',
      image:
        'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/4d4786119276191.609a5318efc25.jpg',
      message: 'has approved your request for sender name',
      subject: 'MTN-Izihirwe',
      subjectId: '2',
      created_at: new Date('2023-05-24T10:00:00Z'),
    },
    {
      id: 5,
      sender: '',
      image:
        'https://cdn.shopify.com/app-store/listing_images/d401b285ff06fcd1904d93a1e3263407/icon/CIu4lt70lu8CEAE=.png',
      type: 'upload',
      message: 'Your file has been uploaded successfully',
      subject: '',
      subjectId: '2',
      created_at: new Date('2023-01-23T18:20:00Z'),
    },
    {
      id: 6,
      type: 'approval',
      image:
        'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/4d4786119276191.609a5318efc25.jpg',
      sender: 'Konvey Direct',
      message: 'has denied your requester for sender name',
      subject: 'Airtel-Izihirwe',
      subjectId: '2',
      created_at: new Date('2023-05-24T10:00:00Z'),
    },
  ];
  // Sort notifications array in descending order based on creation date
  notifications.sort((a, b) => b.created_at - a.created_at);

  // Group notifications by day
  const groupedNotifications = {};

  notifications.forEach((notification) => {
    const created_at = notification.created_at;
    const dateKey = new Date(created_at).toDateString(); // Use the date as the key for grouping

    if (!groupedNotifications[dateKey]) {
      groupedNotifications[dateKey] = [];
    }

    groupedNotifications[dateKey].push(notification);
  });

  const today = new Date().toDateString();
  const yesterday = new Date(
    new Date().getTime() - 24 * 60 * 60 * 1000
  ).toDateString();

  const previousDaysNotifications = Object.keys(groupedNotifications).reduce(
    (accumulator, dateKey) => {
      if (dateKey !== today && dateKey !== yesterday) {
        accumulator.push(...groupedNotifications[dateKey]);
      }
      return accumulator;
    },
    []
  );

  const handleNotificationType = (notificationType) => {
    if (notificationType === 'approval') {
      handleCampaignDetailsModal();
    }
  };

  return (
    <>
      <CampaignData
        show={openCampaignDetails}
        setShow={handleCampaignDetailsModal}
        campaignId={campaignId}
      />
      <div className="flex flex-col content py-4 overflow-y-auto">
        <div className="flex  border-light/30 w-full pb-4 pl-4">
          <h1 className="font-bold text-xl">Notifications</h1>
        </div>
        <div className="overflow-y-hidden">
          <Tab.Group manual>
            <Tab.List className="flex flex-row pb-4 lg:gap-4 w-full items-start overflow-x-auto pl-4 ">
              {categories.map((category) => {
                return (
                  <Tab
                    className={({ selected }) =>
                      (classNames = `flex flex-row justify-between items-start font-bold whitespace-nowrap ${
                        selected
                          ? 'bg-black text-white shadow px-4 rounded-sm'
                          : 'text-black bg-white px-4'
                      }`)
                    }
                  >
                    {category.name}
                  </Tab>
                );
              })}
            </Tab.List>
            <Tab.Panels>
              {categories.map((category) => {
                if (category.name === 'Approvals') {
                  return (
                    <Tab.Panel>
                      <Approvals
                        approvalNotifications={approval}
                        notificationLoading={notificationLoading}
                      />
                    </Tab.Panel>
                  );
                } else if (category.name === 'Payment') {
                  return (
                    <Tab.Panel>
                      <Payment
                        paymentNotifications={payment}
                        notificationLoading={notificationLoading}
                      />
                    </Tab.Panel>
                  );
                } else if (category.name === 'Info') {
                  return (
                    <Tab.Panel>
                      <Info
                        infoNotifications={info}
                        notificationLoading={notificationLoading}
                      />
                    </Tab.Panel>
                  );
                } else if (category.name === 'Help') {
                  return (
                    <Tab.Panel>
                      <Support
                        supportNotifications={support}
                        notificationLoading={notificationLoading}
                      />
                    </Tab.Panel>
                  );
                }
                return null;
              })}
            </Tab.Panels>
          </Tab.Group>
        </div>

        {/* <div className="flex flex-col">
          {today in groupedNotifications && (
            <React.Fragment key={today}>
              <h2 className="font-bold p-4 border border-light/30 text-light">
                Today
              </h2>
              {groupedNotifications[today].map((notification) => {
                const dateObj = new Date(notification.created_at);
                const formattedDate = dateObj.toLocaleDateString('en', {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                });

                const formattedTime = dateObj.toLocaleTimeString('en', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                });
                return (
                  <div
                    className="border border-light/30 rounded p-4 cursor-pointer"
                    onClick={() => {
                      handleNotificationType(notification.type);
                      setCampaignId(notification.subjectId);
                    }}
                    key={notification.id}
                  >
                    <div className="flex flex-row items-start gap-2 w-full">
                      <img
                        src={notification.image}
                        alt=""
                        className="w-[100px] h-[50px] object-contain"
                      />
                      <div className="flex justify-between items-center w-full">
                        <div className="flex flex-col">
                          <p className="text-sm">
                            <b className="px-1">{notification.sender}</b>
                            {notification.message}
                            <b className="pl-1">{notification.subject}</b>
                          </p>
                          <p className="flex items-center py-2 text-sm font-bold text-light">
                            {formattedTime}
                            <span className="h-[5px] w-[5px] bg-light rounded-full mx-2"></span>
                            {formattedDate}
                          </p>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-1">
                          <div className="h-[7px] w-[7px] rounded-full bg-gray"></div>
                          <RxCross2 className="cursor-pointer" />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </React.Fragment>
          )}

          {yesterday in groupedNotifications && (
            <React.Fragment key={yesterday}>
              <h2 className="font-semibold  p-4 border border-light/30 text-light">
                Yesterday
              </h2>
              {groupedNotifications[yesterday].map((notification) => {
                const dateObj = new Date(notification.created_at);
                const formattedDate = dateObj.toLocaleDateString('en', {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                });

                const formattedTime = dateObj.toLocaleTimeString('en', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                });
                return (
                  <div
                    className="border-b border-light/30 rounded p-4 w-full cursor-pointer"
                    onClick={() => {
                      handleNotificationType(
                        notification.type,
                        notification.subjectId
                      );
                    }}
                    key={notification.id}
                  >
                    <div className="flex flex-row items-start gap-2 w-full">
                      <img
                        src={notification.image}
                        alt=""
                        className="w-[100px] h-[50px] object-contain"
                      />
                      <div className="flex items-center justify-between w-full">
                        <div className="flex flex-col">
                          <p className="text-sm">
                            <b className="px-1">{notification.sender}</b>
                            {notification.message}
                            <b className="pl-1">{notification.subject}</b>
                          </p>
                          <p className="flex items-center py-2 text-sm font-bold text-light">
                            {formattedTime}
                            <span className="h-[5px] w-[5px] bg-light rounded-full mx-2"></span>
                            {formattedDate}
                          </p>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-1">
                          <div className="h-[7px] w-[7px] rounded-full bg-gray"></div>
                          <RxCross2 className="cursor-pointer" />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </React.Fragment>
          )}

          {previousDaysNotifications.length > 0 && (
            <React.Fragment key="previousDays">
              <h2 className="font-semibold p-4 border border-light/30 text-light">
                Previous Days
              </h2>
              {previousDaysNotifications.map((notification) => {
                const dateObj = new Date(notification.created_at);
                const formattedDate = dateObj.toLocaleDateString('en', {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                });

                const formattedTime = dateObj.toLocaleTimeString('en', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                });

                return (
                  <div
                    className="border-b border-light/30 rounded p-4 cursor-pointer"
                    key={notification.id}
                    onClick={() => {
                      handleNotificationType(
                        notification.type,
                        notification.subjectId
                      );
                    }}
                  >
                    <div className="flex flex-row items-start gap-2">
                      <img
                        src={notification.image}
                        alt=""
                        className="w-[100px] h-[50px] object-contain"
                      />
                      <div className="flex items-center justify-between w-full">
                        <div className="flex flex-col">
                          <p className="text-sm">
                            <b className="px-1">{notification.sender}</b>
                            {notification.message}
                            <b className="pl-1">{notification.subject}</b>
                          </p>

                          <p className="flex items-center py-2 text-sm font-bold text-light">
                            {formattedTime}
                            <span className="h-[5px] w-[5px] bg-light rounded-full mx-2"></span>
                            {formattedDate}
                          </p>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-1">
                          <div className="h-[7px] w-[7px] rounded-full bg-gray"></div>
                          <RxCross2 className="cursor-pointer" />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </React.Fragment>
          )}
        </div> */}
      </div>
    </>
  );
};

export default Notifications;
