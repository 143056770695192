import React from "react";
import { HiMinus, HiPlus } from "react-icons/hi";
import { Tooltip } from "react-tooltip";
import TextArea from "../../../../../components/TextArea";
import Input from "../../../../../components/Input";
import Select from "../../../../../components/Select";
import CircularLoader from "../../../../../components/CircularLoader";
import { RiErrorWarningLine } from "react-icons/ri";
import FileUpload from "../../../../../components/MultipleFileUpload";

function AddNewArticleRw({
  clearErrors,
  register,
  errors,
  planData,
  setPlanData,
  translationLoading,
  isTranslated,
  files64,
  setFiles64,
}) {
  const handleDescription = (e) => {
    const { value } = e.target;
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      body: { ...prevPlanData.body, rw: value },
    }));
  };

  const handleTitle = (e) => {
    const { value } = e.target;

    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      title: { ...prevPlanData.title, rw: value },
    }));
  };
  return (
    <div>
      {translationLoading ? (
        <div className="flex items-center w-full mt-4">
          <div className="flex gap-2 items-center m-auto">
            <CircularLoader />
            <p>Translating...</p>
          </div>
        </div>
      ) : null}
      {isTranslated ? (
        <div className="flex gap-2 mt-6 mb-2">
          <RiErrorWarningLine size={30} color="blue" />
          <h1>Suzuma neza ibyahinduwe</h1>
        </div>
      ) : (
        ""
      )}
      <Input
        labelText={"Umutwe"}
        labelFor={"title"}
        name={`title `}
        type={"text"}
        isRequired={true}
        placeholder={"Enter title"}
        onChange={(e) => {
          handleTitle(e);
        }}
        value={planData.title["rw"]}
        customClass="w-full mb-2 mt-1"
        register={register}
        errors={errors}
      />
      <TextArea
        labelText={"Andika ubutumwa "}
        labelFor={"content"}
        name={`content`}
        type={"text"}
        isRequired={true}
        placeholder={"Andika ubutumwa"}
        value={planData.body["rw"]}
        onChange={(e) => {
          handleDescription(e);
        }}
        customClass="w-full mb-4"
        register={register}
        errors={errors}
      />

      <div className="flex flex-col mt-4">
        <h2 className="text-bold text-[18px]">Upload file</h2>
        <FileUpload files64={files64} setFiles64={setFiles64} />
      </div>
    </div>
  );
}

export default AddNewArticleRw;
