import React, { useState } from 'react';
import axios from 'axios';
import Modal from '../../../../components/modal/Modal';
import { useForm } from 'react-hook-form';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import { ToastComponent } from '../../../../components/Toast';
import { APP } from '../../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBankWithdraws } from '../../../../redux/actions/wallet';
import Drag from '../../../../components/Drag';

const PayBankWithdraw = ({
  openModal,
  handleOpenModal,
  walletId,
  withdrawId,
}) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [paymentProof, setPaymentProof] = useState([]);
  const [payDetails, setPayDetails] = useState({
    amount: '',
    description: '',
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm();

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const handleAmount = (e) => {
    const { value } = e.target;
    setPayDetails((prevData) => {
      return {
        ...prevData,
        amount: value,
      };
    });
  };

  const handleDescription = (e) => {
    const { value } = e.target;
    setPayDetails((prevData) => {
      return {
        ...prevData,
        description: value,
      };
    });
  };

  const onSubmit = (data) => {
    setLoading(true);

    //api.account.konvey.rw/api/admin/wallets/payout/11/withdraws/bank-withdraws/33/payments

    https: axios
      .post(
        APP.API_MAIN_URL +
          `/admin/wallets/payout/${walletId}/withdraws/bank-withdraws/${withdrawId}/payments`,
        {
          amount: payDetails.amount,
          document_file: paymentProof[0],
          description: payDetails.description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setPayDetails({
          amount: '',
          description: '',
        });
        setPaymentProof('');
        dispatch(fetchBankWithdraws(walletId));

        reset();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        notify('error', error.response.data.message);
      });
  };

  return (
    <Modal
      isOpen={openModal}
      onClose={() => {
        handleOpenModal();
      }}
      Style={'w-[90%] lg:w-3/5 flex m-auto py-4'}
    >
      <Modal.Header>
        <h1 className="font-bold">Payment Proof</h1>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col mb-4 px-4">
            <Input
              labelText={'Amount'}
              labelFor={'amount'}
              name={'amount'}
              type={'number'}
              isRequired={true}
              placeholder={'Enter Amount'}
              onChange={(e) => {
                handleAmount(e);
              }}
              customClass="w-full mb-2"
              inputStyle={'no-spinner'}
              value={payDetails.amount}
              register={register}
              errors={errors}
            />{' '}
            <Input
              labelText={'Description'}
              labelFor={'description'}
              name={'description'}
              type={'text'}
              isRequired={true}
              placeholder={'Enter Description'}
              onChange={(e) => {
                handleDescription(e);
              }}
              customClass="w-full mb-2"
              value={payDetails.description}
              register={register}
              errors={errors}
            />{' '}
            <Drag
              label="Upload proof of payment"
              style="h-max"
              flexStyle="flex flex-col space-y-6 w-full my-2"
              uploadLimit={1}
              files={paymentProof}
              setFiles={setPaymentProof}
            />
            <Button
              type="submit"
              content="Confirm Payment"
              loading={loading}
              btnColor="primary"
              Style={`text-white w-fit h-[40px] mt-8 ml-0 font-bold self-end`}
            />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default PayBankWithdraw;
