import React, { useEffect, useState } from "react";
import Modal from "../../components/modal/Modal";
import { useForm } from "react-hook-form";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Select from "../../components/Select";
import PhoneInput from "react-phone-input-2";
import { ToasterComponent } from "../../components/Toast";
import Axios from "../../components/Axios";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { APP } from "../../utils/constant";

const NewCreateUser = ({ open, setOpen }) => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    phone_code: "",
    role_id: "",
    status: "active",
  });

  const myOptions = [
    { value: "1", label: "Approver" },
    { value: "2", label: "initiator" },
  ];

  const formSchema = Yup.object().shape({
    names: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Email is invalid")
      .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email address")
      .required("email is required"),
    role: Yup.object().required("role is required").label("Set role"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };

  const onSubmit = () => {
    setLoader(true);
    Axios.post(APP.API_MAIN_URL + "/admin/users/system", data)
      .then((res) => {
        console.log(res.data);
        setLoader(false);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm(formOptions);

  return (
    <div>
      <Modal
        isOpen={open}
        onClose={() => setOpen(!open)}
        Style={"lg:w-1/4 w-3/4 m-auto h-max flex z-[10]"}
      >
        <Modal.Header>Add system user</Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col space-y-4"
          >
            <Input
              key="01"
              labelText={"First name"}
              labelFor={"firt_name"}
              name={"first_name"}
              type={"text"}
              isRequired={true}
              placeholder={"enter the first name"}
              customClass="w-full"
              register={register}
              errors={errors}
              onChange={(e) => {
                setData({ ...data, first_name: e.target.value });
              }}
            />{" "}
            <Input
              labelText={"Last name"}
              labelFor={"last_name"}
              name={"last_name"}
              type={"text"}
              isRequired={true}
              placeholder={"enter the first name"}
              customClass="w-full"
              register={register}
              errors={errors}
              onChange={(e) => {
                setData({ ...data, last_name: e.target.value });
              }}
            />{" "}
            <Input
              labelText={"Email"}
              labelFor={"email"}
              name={"email"}
              type={"text"}
              isRequired={true}
              placeholder={"enter email address"}
              customClass="w-full"
              register={register}
              errors={errors}
              onChange={(e) => {
                setData({ ...data, email: e.target.value });
              }}
            />{" "}
            <div className="w-full mb-2">
              <PhoneInput
                country={"rw"}
                onlyCountries={["rw"]}
                countryCodeEditable={false}
                value={data.phone.number}
                disableCountryCode={false}
                onChange={(value, data, e, formattedValue) => {
                  setData((prevData) => {
                    console.log(value.slice(data.dialCode.length));
                    return {
                      ...prevData,
                      phone_code: data.dialCode,
                      phone: value.slice(data.dialCode.length),
                    };
                  });
                }}
                inputClass="rounded-md appearance-none text-sm relative block w-full px-3 py-2 border border-primary/[0.40] md:border-primary/[0.40]  placeholder-gray-500 focus:appearance-none focus:outline-none focus:ring-1 focus:ring-primary/[0.25] focus:border-primary focus:z-10 sm:text-sm  focus:shadow-sm"
              />
            </div>
            <Select
              className="w-full mt-0"
              placeholder={"Select role"}
              options={myOptions}
              label="Set role"
              handleChange={(e) => {
                setData({ ...data, role: e.value });
              }}
              register={{
                control,
                name: "role",
                rules: { required: "permission is required" },
              }}
              error={errors["role"]?.message}
            />
            <Button
              type="submit"
              content="Submit"
              btnColor="primary"
              Style={"text-white w-max h-[40px] mt-2 ml-0"}
              loading={loader}
            />
            <ToasterComponent />
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewCreateUser;
