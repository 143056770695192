import React, { useEffect, useRef } from "react";
import { useState } from "react";

import Modal2 from "../../../components/modal/Modal2";
import Button from "../../../components/Button";
import { MdDoNotDisturb, MdOutlinePhoneAndroid } from "react-icons/md";
import { BsInfoCircle, BsQrCode, BsPlus } from "react-icons/bs";
import { ColorPicker, useColor } from "react-color-palette";
import { useReactToPrint } from "react-to-print";
import RichTextEditor from "../../../components/RichTextEditor";
import Select from "../../../components/Select";

const ModifyDownLoad = ({ open, handleOpen, data }) => {
  const [frame, setFrame] = useState(1);
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [colorBackground, setColorBackground] = useColor("rgb(255 255 255)");
  const [colorModal, setColorModal] = useState(false);
  const [text, setText] = useState("scan me");
  const [custom, setCustom] = useState(false);
  const [customText, setCustomText] = useState("scan me");
  const [right, setRight] = useState("");
  const [left, setLeft] = useState("");
  const [bottom, setBottom] = useState("scan me");
  const [up, setUp] = useState("");
  const [side, setSide] = useState({ value: "bottom", label: "Bottom" });

  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
  };

  const handleDownload = async () => {
    handlePrint();
  };
  const backgroundColor = `rgba(${colorBackground.rgb.r}, ${colorBackground.rgb.g}, ${colorBackground.rgb.b}, ${colorBackground.rgb.a})`;
  const handleOpenColorPicker = () => {
    setColorModal(!colorModal);
  };
  const colorPickerRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target)
      ) {
        // Click is outside the color picker, close it
        setColorModal(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  console.log("backgroundColor", backgroundColor);

  const textRef = useRef();
  const [contrastColor, setContrastColor] = useState("#000000");

  useEffect(() => {
    const getContrastColor = (hexColor) => {
      // Convert hex to RGB
      const r = parseInt(hexColor.slice(1, 3), 16);
      const g = parseInt(hexColor.slice(3, 5), 16);
      const b = parseInt(hexColor.slice(5, 7), 16);

      // Calculate luminance
      const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

      // Choose contrasting text color
      return luminance > 0.5 ? "#000000" : "#ffffff";
    };

    const adjustColor = () => {
      const contrastColor = getContrastColor(backgroundColor);
      setContrastColor(contrastColor);
    };

    adjustColor();
  }, [backgroundColor]);

  useEffect(() => {
    if (textRef.current) {
      textRef.current.style.color = contrastColor;
      textRef.current.style.backgroundColor = backgroundColor;
    }
  }, [contrastColor, backgroundColor]);

  // export to pdf

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Qr_code",
    // style for making page content in the center of page

    pageStyle: `
    @page {
      size: A4;
      margin-left:20;
      background-color:blue 
    }
  
    @media all {
      .pagebreak {
        display: flex;
        justify-content:center;
        
      }
    }
  
    @media print {
      .pagebreak {
        page-break-before: always;
      }
    }
  `,
  });
  const textDirection = [
    { value: "left", label: "Left" },
    { value: "bottom", label: "Bottom" },
    { value: "right", label: "Right" },
    { value: "top", label: "Top" },
  ];

  return (
    <Modal2
      isOpen={open}
      onClose={() => handleOpen(!open)}
      Style={"w-fit m-4 flex m-auto lg:w-full h-full "}
    >
      <Modal2.Header className={" sticky top-0 bg-[#f0fafc] z-10"}>
        <div className="font-bold ">Modify before download</div>
      </Modal2.Header>
      <Modal2.Body className={"bg-[#f0fafc] h-[90%] mb-0 pb-0"}>
        {!custom ? (
          <div className="w-full flex h-full  ">
            <div className="flex flex-col items-center w-[30%] h-full border-r border-[#ddd] mr-5">
              {frame === 1 && (
                <div
                  className="bg-cover bg-center h-[200px] w-[200px] text-center "
                  style={{
                    backgroundImage: `url(${data?.file})`,
                  }}
                  id="qrCode"
                  ref={componentRef}
                >
                  <img src={data?.file} alt="" srcset="" />
                </div>
              )}
              {frame === 2 && (
                <div
                  className="bg-cover bg-center h-[200px] w-[200px] text-center "
                  style={{
                    backgroundImage: `url(${data?.file})`,
                  }}
                  id="qrCode"
                  ref={componentRef}
                >
                  <div className="mb-2 ">
                    <img src={data?.file} alt="" srcset="" />
                  </div>
                  <div>
                    <span
                      className="self-center text-4xl font-bold uppercase font-nunito "
                      style={{
                        color: backgroundColor,
                        fontSize: `${Math.max(8, 36 - text.length)}px`,
                      }}
                    >
                      {text}
                    </span>
                  </div>
                </div>
                // <QRCodeWithText
                //   imageUrl={data?.file}
                //   text={text}
                // />
              )}
              {frame === 3 && (
                <div
                  id="qrCode"
                  className="w-max mb-3 flex flex-col items-center"
                  ref={componentRef}
                >
                  <div
                    className="bg-cover bg-center h-[160px] w-[160px] text-center "
                    // style={{
                    //   backgroundImage: `url(${data?.file})`,
                    // }}
                  >
                    <div className="p-2 mb-2 " style={{ backgroundColor }}>
                      <img src={data?.file} alt="" srcset="" />
                    </div>
                  </div>
                  <div className=" mt-2" ref={textRef}>
                    <span className="self-center text-4xl font-bold uppercase font-nunito px-4 py-2">
                      {text}
                    </span>
                  </div>
                </div>
              )}
              {frame === 4 && (
                <div id="qrCode" className="w-max" ref={componentRef}>
                  <div
                    className="bg-cover bg-center h-[200px] w-[200px] text-center "
                    style={{
                      backgroundImage: `url(${data?.file})`,
                    }}
                  >
                    <div className=" p-3 mb-2" style={{ backgroundColor }}>
                      <img src={data?.file} alt="" srcset="" />
                    </div>
                  </div>
                  <div className="" ref={textRef}>
                    <span className="self-center text-4xl font-bold uppercase font-nunito px-4">
                      {text}
                    </span>
                  </div>
                </div>
              )}
              {frame === 5 && (
                <div id="qrCode" className="w-max" ref={componentRef}>
                  <div
                    className="bg-cover bg-center h-[200px] w-[200px] text-center "
                    style={{
                      backgroundImage: `url(${data?.file})`,
                    }}
                  >
                    <div className=" p-3 mb-2" style={{ backgroundColor }}>
                      <img src={data?.file} alt="" srcset="" />
                    </div>
                  </div>
                  <div className="pb-2 flex items-center" ref={textRef}>
                    <MdOutlinePhoneAndroid size={30} />
                    <span className="self-center text-4xl font-bold uppercase font-nunito">
                      {text}
                    </span>
                  </div>
                </div>
              )}
              {frame === 6 && (
                <div
                  className="bg-cover bg-center h-[200px] w-[200px] text-center "
                  style={{
                    backgroundImage: `url(${data?.file})`,
                  }}
                  id="qrCode"
                  ref={componentRef}
                >
                  <div className=" p-3 mb-2" style={{ backgroundColor }}>
                    <img src={data?.file} alt="" srcset="" />
                  </div>
                  <span
                    className="self-center text-4xl font-bold uppercase font-nunito"
                    style={{ color: backgroundColor }}
                  >
                    Scan me
                  </span>
                </div>
              )}

              <Button
                type="button"
                content={
                  <div className="flex items-center justify-center ">
                    <span className="ml-3">Print pdf</span>
                  </div>
                }
                Style={
                  " bg-primary text-white border border-[#7F8386] hover:bg-white mt-14 hover:text-primary h-[40px] w-full lg:w-[200px] rounded-lg mt-2 ml-0 outline-none focus:ring-inset transform active:scale-x-95 transition-transform rounded-[2px]"
                }
                onClick={() => handleDownload()}
              />
            </div>
            <div className="w-[60%] ml-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <h3 className="uppercase font-bold text-[#343333] mr-2">
                    Frame
                  </h3>
                  <BsInfoCircle
                    size={12}
                    className="cursor-pointer hover:bg-blue-300 rounded-md"
                    title="Frame make your Qr code stand out from the crowd and make it more attractive."
                  />
                </div>
                <div>{/* <span>All Frame</span> */}</div>
              </div>
              <div className="flex justify-between ">
                <div
                  onClick={() => setFrame(1)}
                  className={`w-[100px]  h-[100px] cursor-pointer bg-[#E6EDF0] text-[#807f7f] flex justify-center items-center rounded-sm  border-blue-400 ${
                    frame === 1 ? "border" : ""
                  }`}
                >
                  <MdDoNotDisturb size={60} />
                </div>
                <div
                  onClick={() => setFrame(2)}
                  className={`w-[100px] h-[100px] cursor-pointer bg-[#E6EDF0] rounded-sm  text-[#807f7f] flex flex-col justify-between items-center pt-2 border-blue-400 ml-2 ${
                    frame === 2 ? "border" : ""
                  }`}
                >
                  <BsQrCode size={60} />
                  <span className="uppercase">Scan me</span>
                </div>
                <div
                  onClick={() => setFrame(3)}
                  className={`w-[100px] h-[100px] cursor-pointer bg-[#E6EDF0] rounded-sm  text-[#807f7f] flex flex-col justify-between items-center pt-2 border-blue-400 ml-2 ${
                    frame === 3 ? "border" : ""
                  }`}
                >
                  <div className=" border-2 p-2 border-primary">
                    <BsQrCode size={50} />
                  </div>
                  <div className="uppercase bg-primary text-white w-[90%] text-center text-[12px] font-bold">
                    Scan me
                  </div>
                </div>
                <div
                  onClick={() => setFrame(4)}
                  className={`w-[100px] h-[100px] cursor-pointer bg-[#E6EDF0] rounded-sm  text-[#807f7f] flex flex-col justify-between items-center pt-2 border-blue-400 ml-2 ${
                    frame === 4 ? "border" : ""
                  }`}
                >
                  <div className="w-[70%]">
                    <div className=" border-2 p-2 border-primary">
                      <BsQrCode size={50} />
                    </div>
                    <div className="uppercase bg-primary text-white text-center text-[12px] font-bold">
                      Scan me
                    </div>
                  </div>
                </div>
                <div
                  onClick={console.log("Frame 5")}
                  className={`w-[100px] h-[100px] cursor-pointer bg-[#E6EDF0] rounded-sm  text-[#807f7f] flex flex-col justify-between items-center pt-2 border-blue-400 ml-2 ${
                    frame === 5 ? "border" : ""
                  }`}
                >
                  <div
                    className="w-[70%] flex flex-col items-center"
                    onClick={() => setCustom(!custom)}
                  >
                    <div className=" border-2 p-2 border-primary rounded-full">
                      <BsPlus size={50} />
                    </div>
                    <div className="uppercase  text-primary text-center text-[12px] font-bold flex items-center ">
                      <span className="">Customize</span>
                    </div>
                  </div>
                </div>
                {/* <div
                onClick={() => setFrame(5)}
                className={`w-[100px] h-[100px] cursor-pointer bg-[#E6EDF0] rounded-sm  text-[#807f7f] flex flex-col justify-between items-center pt-2 border-blue-400 ml-2 ${
                  frame === 5 ? "border" : ""
                }`}
              >
                <div className="w-[70%]">
                  <div className=" border-2 p-2 border-primary">
                    <BsQrCode size={50} />
                  </div>
                  <div className="uppercase bg-primary text-white text-center text-[12px] font-bold flex items-center ">
                    <MdOutlinePhoneAndroid /> <span>Scan me</span>
                  </div>
                </div>
              </div> */}
                {/* <div
                onClick={() => setFrame(6)}
                className={`w-[100px] h-[100px] cursor-pointer bg-[#E6EDF0] rounded-sm  text-[#807f7f] flex flex-col justify-between items-center pt-2 border-blue-400 ${
                  frame === 6 ? "border" : ""
                }`}
              >
                <div className=" border-2 p-2 border-primary">
                  <BsQrCode size={50} />
                </div>
                <div className="uppercase w-[90%] text-center text-[13px] font-bold">
                  Scan me
                </div>
              </div> */}

                {/* <div className={`w-[100px] h-[100px] bg-[#E6EDF0] text-[#807f7f] flex justify-center items-center rounded-sm border border-blue-400"`}
                <BsQrCode size={60} />
              </div> */}
              </div>
              {frame > 1 && (
                <div>
                  <div class=" grid grid-cols-3 mt-10  ">
                    <div className="relative border rounded-md  ">
                      <span className="absolute left-5 -top-3 px-1 bg-[#f0fafc]">
                        Frame color
                      </span>
                      <input
                        type="text"
                        className="bg-[#f0fafc] border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-[#f0fafc] focus:border-[#f0fafc] block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none dark:focus:ring-[#f0fafc] dark:focus:border-[#f0fafc]"
                        placeholder={colorBackground?.hex}
                        onChange={(e) => setColorBackground(e.target.value)}
                        value={colorBackground?.hex}
                      />
                      <button
                        type="button"
                        class="absolute inset-y-0 end-0 flex items-center pe-3 right-1"
                      >
                        <div
                          className=" h-6 w-6 border border-[#e3e3e3] rounded-sm "
                          style={{ backgroundColor }}
                          onClick={handleOpenColorPicker}
                        ></div>
                      </button>
                      {colorModal && (
                        <div
                          className="absolute z-10 w-[300px] right-0 mt-2 "
                          ref={colorPickerRef}
                        >
                          <ColorPicker
                            color={colorBackground}
                            onChange={setColorBackground}
                            hideInput={["hsv", "rgb"]}
                            hideAlpha={true}
                            height={100}
                          />
                        </div>
                      )}
                    </div>

                    <div className="relative ml-5 border rounded-md ">
                      <span className="absolute left-5 -top-3 bg-[#f0fafc] px-1">
                        Frame text
                      </span>
                      <input
                        type="text"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        className="bg-[#f0fafc] border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-[#f0fafc] focus:border-[#f0fafc] block w-full  p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none dark:focus:ring-[#f0fafc] dark:focus:border-[#f0fafc]"
                        placeholder="Ex: SCAN ME"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="">
            <div className="w-full relative">
              <div className="flex flex-col  items-center h-full mr-5 ">
                {custom && (
                  <div
                    className="bg-cover bg-center h-[200px] w-[200px] text-center flex justify-center items-center "
                    // style={{
                    //   backgroundImage: `url(${data?.file})`,
                    // }}
                    id="qrCode"
                    ref={componentRef}
                  >
                    <img src={data?.file} alt="" srcset="" />
                  </div>
                )}
                {/* Bottom */}
                <div className="w-[100%] overflow-auto text-center">
                  <div dangerouslySetInnerHTML={{ __html: bottom }}></div>
                </div>
              </div>
              {/* left */}
              <div className="absolute left-0 top-0 w-[37%] flex justify-between">
                <h1></h1>
                <div dangerouslySetInnerHTML={{ __html: left }}></div>
              </div>
              {/* right */}
              <div
                className=" absolute right-0 top-0 w-[39%] "
                dangerouslySetInnerHTML={{ __html: right }}
              ></div>
              <div className=" w-full mt-10 ">
                <Select
                  options={textDirection}
                  // label="Choose side"
                  customClass=""
                  placeholder={"Choose text side"}
                  onChange={(e) => setSide(e)}
                  value={side}
                />
                {side.value === "right" && (
                  <RichTextEditor
                    placeholder="Enter text"
                    onChange={(e) => setRight(e)}
                    value={right}
                  />
                )}
                {side.value === "bottom" && (
                  <RichTextEditor
                    placeholder="Enter text"
                    onChange={(e) => setBottom(e)}
                    value={bottom}
                  />
                )}
                {side.value === "top" && (
                  <RichTextEditor
                    placeholder="Enter text"
                    onChange={(e) => setUp(e)}
                    value={up}
                  />
                )}
                {side.value === "left" && (
                  <RichTextEditor
                    placeholder="Enter text"
                    onChange={(e) => {
                      setLeft(e);
                    }}
                    value={left}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </Modal2.Body>
      {custom && (
        <Modal2.Footer className={"h-10 sticky bottom-2 bg-[#f0fafc] pb-5"}>
          <Button
            type="button"
            content={
              <div className="flex items-center justify-center ">
                <span className="ml-3">Cancel</span>
              </div>
            }
            Style={
              " bg-primary text-white border border-[#7F8386] hover:bg-white mt-14 hover:text-primary h-[40px] w-full lg:w-[200px] rounded-lg mt-2 ml-0 outline-none focus:ring-inset transform active:scale-x-95 transition-transform rounded-[2px]"
            }
            onClick={() => setCustom(false)}
          />
          <Button
            type="button"
            content={
              <div className="flex items-center justify-center ">
                <span className="ml-3">Print pdf</span>
              </div>
            }
            Style={
              " bg-primary text-white border border-[#7F8386] hover:bg-white mt-14 hover:text-primary h-[40px] w-full lg:w-[200px] rounded-lg mt-2 ml-0 outline-none focus:ring-inset transform active:scale-x-95 transition-transform rounded-[2px] ml-5"
            }
            onClick={() => handleDownload()}
          />
        </Modal2.Footer>
      )}
    </Modal2>
  );
};

export default ModifyDownLoad;
