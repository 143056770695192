import React, { useState } from "react";
import Axios from "../../../components/Axios";
import { useForm } from "react-hook-form";
import Modal from "../../../components/modal/Modal";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import TextArea from "../../../components/TextArea";
import { BsArrowLeft } from "react-icons/bs";
import { APP } from "../../../utils/constant";
import { useSelector } from "react-redux";

const SenderName = ({ senderNameDetails, openModal, handleOpenModal }) => {
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [reason, setReason] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
  } = useForm();

  const { token } = useSelector((state) => state.user);

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const approveSenderName = () => {
    setApproveLoading(true);
    Axios.put(
      APP.API_MAIN_URL +
        `/admin/sender-name-requests/${senderNameDetails.id}/approve`,
      config
    )
      .then((res) => {
        setApproveLoading(false);
        console.log(res);
        handleOpenModal();
      })
      .catch((err) => {
        setApproveLoading(false);
        console.log(err);
      });
  };

  const rejectSenderName = () => {
    setRejectLoading(true);
    Axios.put(
      APP.API_MAIN_URL +
        `/admin/sender-name-requests/${senderNameDetails.id}/reject`,
      {
        reason: rejectReason,
      },
      config
    )
      .then((res) => {
        setRejectLoading(false);
        console.log(res);
        handleOpenModal();
      })
      .catch((err) => {
        setRejectLoading(false);
        console.log(err);
      });
  };

  const onSubmit = () => {
    rejectSenderName();
  };

  return (
    <Modal
      isOpen={openModal}
      onClose={handleOpenModal}
      Style={"w-1/2 lg:w-1/3  m-auto flex mx-auto items-center justify-center"}
    >
      <Modal.Header>
        <div className="w-full flex items-center justify-center">
          <h1 className="font-bold text-xl">Sender Name</h1>
        </div>
      </Modal.Header>

      <Modal.Body>
        <div className="flex flex-col ">
          <div className="flex flex-col">
            <div className="flex flex-row gap-2 items-center">
              <h1 className="font-bold">Requested Sender Name :</h1>
              <h1>{senderNameDetails.name}</h1>
            </div>
            {reason ? (
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={reason ? "flex flex-col" : "hidden"}
              >
                <TextArea
                  labelText={"Reason"}
                  labelFor={"Reason"}
                  name={"Reason"}
                  type={"text"}
                  isRequired={true}
                  placeholder={"Reply"}
                  customClass="w-full mb-2"
                  inputStyle={"h-[80px]"}
                  value={rejectReason}
                  onChange={(e) => {
                    setRejectReason(e.target.value);
                  }}
                  register={register}
                  errors={errors}
                />

                <div className="flex flex-row justify-between">
                  <Button
                    type="button"
                    content={
                      <div className="flex items-center justify-center px-2">
                        <BsArrowLeft size={20} />
                      </div>
                    }
                    loading={false}
                    btnColor="primary"
                    onClick={() => {
                      setReason(false);
                    }}
                    Style={
                      "text-black w-max h-[40px] mt-2 ml-0 bg-white border rounded-sm cursor-pointer py-0"
                    }
                  />

                  <Button
                    type="submit"
                    content={
                      <div className="flex items-center justify-center">
                        <h1 className="text-white font-nunito text-base">
                          Reject
                        </h1>
                      </div>
                    }
                    loading={rejectLoading}
                    btnColor="error"
                    Style={"text-white w-[100px] h-[40px] mt-2 ml-0 bg-error"}
                  />
                </div>
              </form>
            ) : null}
          </div>
          <div className={`${reason ? "hidden" : "flex"} justify-between mt-8`}>
            <div className={`flex w-fit h-fit`}>
              <Button
                type="button"
                content="Close"
                loading={false}
                btnColor="primary"
                onClick={() => {
                  handleOpenModal();
                }}
                Style={
                  "text-black w-max h-[40px] mt-2 ml-0 bg-white border rounded-sm cursor-pointer"
                }
              />
            </div>

            <div className={"flex flex-row gap-2"}>
              <Button
                type="button"
                content={
                  <div className="flex items-center justify-center">
                    <h1 className="text-white font-nunito text-base">Reject</h1>
                  </div>
                }
                loading={false}
                onClick={() => {
                  setReason(true);
                }}
                btnColor="error"
                Style={"text-white w-[100px] h-[40px] mt-2 ml-0 bg-error"}
              />
              <Button
                type="button"
                content={
                  <div className="flex items-center justify-center">
                    <h1 className="text-white font-nunito text-base">
                      Approve
                    </h1>
                  </div>
                }
                loading={approveLoading}
                onClick={approveSenderName}
                btnColor="primary"
                Style={"text-white w-[100px] h-[40px] mt-2 ml-0"}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SenderName;
