import React from 'react'

const Qr_code_details = ({data, channelValue}) => {
    var  Qr_code_contents = data[0]?.details
  return (
    <div className='flex flex-col border border-gray rounded-lg p-2 mb-4 space-y-4 text-xl'>
        <div className='font-bold text-xl'> QR Code  Summary</div>
        <div className='flex flex-col space-y-2'>
        <div className='flex space-x-2'><h1 className='text-light font-bold text-xl w-fit'>QR code type:</h1> <p>{Qr_code_contents.type}</p></div>
           {Qr_code_contents.is_standard ? (
            <div className='flex flex-col space-y-2'>
            {/* <div className='flex space-x-2'><h1 className='text-light font-bold text-xl w-fit'>Location:</h1> <p>{Qr_code_contents.standard.location}</p></div> */}
            </div>
           ):(<div className='flex flex-col'>
             <div className='flex space-x-2'><h1 className='text-light font-bold text-xl w-fit'>Custom type:</h1> <p>{ Qr_code_contents.custom.type}</p></div>
             <div className='flex flex-col'>{ Qr_code_contents.custom.is_exist ? (
                <div>
                <div className='flex space-x-2'><h1 className='text-light font-bold text-xl w-fit'>Previous QR code:</h1> <p>{Qr_code_contents.custom.existing.prev_QrCode}</p></div>
                </div>
             ):(<div>
               <div className='flex space-x-2'><h1 className='text-light font-bold text-xl w-fit'>New QR code name:</h1> <p>{Qr_code_contents.custom.new.qr_code_name}</p></div>
               {Qr_code_contents.custom.new.location !== undefined &&
              Qr_code_contents.custom.new.location.map((location, i) => {
                return (
                  <div className="flex flex-col w-full space-x-8 bg-primary text-primary p-4 rounded-sm bg-opacity-10">
                    <div className="font-bold text-[18px]">
                      Location {i + 1}:
                    </div>
                    <div className="flex flex-row  space-x-2">
                      <div>
                        <strong>Province:&nbsp;</strong> {location?.province},
                      </div>
                      <div>
                        <strong>District:&nbsp;</strong> {location?.district},
                      </div>
                      <div>
                        <strong>Sector:&nbsp;</strong> {location?.sector},
                      </div>
                      <div>
                        <strong>Cell:&nbsp;</strong>
                        {location?.cell},
                      </div>
                      <div>
                        <strong>Village:&nbsp;</strong> {location?.village}
                      </div>
                    </div>
                    <br />
                    <div className="text-[18px]"><strong>Environments:&nbsp;</strong></div>
                    
                    {location?.environment?.map((env, index) => (
                      <div key={index} className="my-2">
                        <h1 className="font-bold text-[16px]">
                          Environment {index + 1}
                        </h1>
                        <div className="text-[15px]"><strong>Name:&nbsp;</strong> {env?.name} <br /></div>                      
                        <div className="text-[15px]"><strong>Placements:&nbsp;</strong> {env?.placement?.join(",")} <br /></div>                     
                      </div>
                    ))}
                  </div>
                );
              })}
             </div>
             )}</div>
           </div>)}
           <div>
            <div className='flex space-x-2'><h1 className='text-light font-bold text-xl w-fit'>Content type:</h1> <p>{Qr_code_contents.content_type.type}</p> </div>
            <div>
                {Qr_code_contents.content_type.is_text ? (
                    <div className='flex flex-col space-y-2'>
                        <div className='flex space-x-2'><h1 className='text-light font-bold text-xl w-fit'>Text content:</h1> <p>{Qr_code_contents.content_type.text.message}</p></div>
                        <div className='flex space-x-2'><h1 className='text-light font-bold text-xl w-fit'>Sender name:</h1> <p>{Qr_code_contents.content_type.text.sender_name}</p></div>
                        <div className='flex space-x-2'><h1 className='text-light font-bold text-xl w-fit'>Telephone:</h1> <p>{Qr_code_contents.content_type.text.telephone}</p></div>
                        <div className='flex space-x-2'><h1 className='text-light font-bold text-xl w-fit'>Email:</h1> <p>{Qr_code_contents.content_type.text.email}</p> </div>
                        
                    </div>
                ):(
                    Qr_code_contents.content_type.is_url ? (
                        <div className='flex flex-col space-y-2'>
                            <div  className='flex space-x-2'><h1 className='text-light font-bold text-xl w-fit'>Link:</h1> <p>{Qr_code_contents.content_type.url.link}</p></div>
                            <div className='flex space-x-2'><h1 className='text-light font-bold text-xl w-fit'>Content:</h1> <p>{Qr_code_contents.content_type.url.message}</p></div>
                        </div>
                    ):(
                        <div></div>
                    )
                )}
            </div>
           </div>
           <div className='flex flex-col space-y-4'>
           <div className="flex flex-row  gap-1 items-center">
        <h1 className="text-light  font-bold w-fit my-2">
          Target audience:
        </h1>
        {Qr_code_contents.target_audience.map((audience, i) => {
          return (
            <h2 className="flex text-black m-0 p-0 gap-1">
              {audience.label}
              <span className="text-light ">
                ({audience.maximum_audience})
                {Qr_code_contents.target_audience.length - 1 ===
                i
                  ? '.'
                  : ','}
              </span>
            </h2>
          );
        })}
      </div>
            <div>
                <div className='flex space-x-2' ><h1 className='text-light font-bold text-xl w-fit'>Scheduled</h1></div>
                <div className='pl-8'>
                <div>From: &nbsp;{Qr_code_contents.from}</div>
                <div>To: &nbsp;{Qr_code_contents.to}</div>
                </div>
            </div>
            <div className='flex space-x-2'><h1 className='text-light font-bold text-xl w-fit'>Price:</h1> &nbsp;{Qr_code_contents.price}  RWF</div>
            <div className='flex space-x-2'><h1 className='text-light font-bold text-xl w-fit'>Amount paid:</h1> &nbsp; 1000000 RWF </div>
            <div className='flex space-x-2'><h1 className='text-light font-bold text-xl w-fit'>Payment method:</h1> &nbsp; Konvey wallet </div>
           </div>
        </div>
    </div>
  )
}

export default Qr_code_details