import { useSelector } from "react-redux";

const ProtectedRoutes = ({ children, redirectTo }) => {
  const { token } = useSelector((state) => state.user);
  if (token) {
    return children;
  } else {
    return window.location.replace(redirectTo);
  }
};

export default ProtectedRoutes;
