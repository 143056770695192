import axios from "axios";
import { APP, config } from "../../utils/constant";

export const SET_CATEGORY = "SET_CATEGORY";
export const SET_PLAN = "SET_PLAN";
export const SET_BENEFIT = "SET_BENEFIT";
export const SET_SELECTED_LANGUAGE = "SET_SELECTED_LANGUAGE";
export const SET_SELECTED_CATEGORY_LANGUAGE = "SET_SELECTED_CATEGORY_LANGUAGE";

export const SET_IS_LOADING_CATEGORY = "SET_IS_LOADING_CATEGORY";
export const SET_IS_LOADING_PLAN = "SET_IS_LOADING_PLAN";
export const SET_IS_LOADING_BENEFIT = "SET_IS_LOADING_BENEFIT";

export const RESET_SUBSCRIPTIONS = "RESET_SUBSCRIPTIONS";

export const setCategory = (category) => (dispatch) => {
  dispatch({
    type: SET_CATEGORY,
    payload: category,
  });
};
export const setPlan = (plan) => (dispatch) => {
  dispatch({
    type: SET_PLAN,
    payload: plan,
  });
};

export const setBenefit = (benefit) => (dispatch) => {
  dispatch({
    type: SET_BENEFIT,
    payload: benefit,
  });
};
export const setSelecteLanguage = (language) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_LANGUAGE,
    payload: language,
  });
};
export const setSelectedCategoryLanguage = (catLan) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_CATEGORY_LANGUAGE,
    payload: catLan,
  });
};

export const setIsLoadingCategory = (trueOrFalse) => (dispatch) => {
  dispatch({
    type: SET_IS_LOADING_CATEGORY,
    payload: trueOrFalse,
  });
};
export const setIsLoadingPlan = (trueOrFalse) => (dispatch) => {
  dispatch({
    type: SET_IS_LOADING_PLAN,
    payload: trueOrFalse,
  });
};
export const setIsLoadingBenefit = (trueOrFalse) => (dispatch) => {
  dispatch({
    type: SET_IS_LOADING_BENEFIT,
    payload: trueOrFalse,
  });
};

export const resetSubscription = () => ({ type: RESET_SUBSCRIPTIONS });

export const getCategory = () => (dispatch, getState) => {
  const { token } = getState().user;

  dispatch(setIsLoadingCategory(true));
  axios
    .get(
      APP.API_MAIN_URL + `/admin/vas/subscriptions/categories`,
      config(token)
    )
    .then((res) => {
      dispatch(setCategory(res.data.data));
      dispatch(setIsLoadingCategory(false));
    })
    .catch((error) => {
      console.log("Error in retrieving categories", error);
      dispatch(setIsLoadingCategory(false));
      // error.response.status === 403 &&
      //   (window.location.href = 'https://account.konvey.rw/');
    });
};

export const getPlans = (categoryId) => (dispatch, getState) => {
  const { token } = getState().user;

  dispatch(setIsLoadingPlan(true));
  axios
    .get(
      APP.API_MAIN_URL +
        `/admin/vas/subscriptions/categories/${categoryId}/plans`,
      config(token)
    )
    .then((res) => {
      dispatch(setPlan(res.data.data));
      dispatch(setIsLoadingPlan(false));
    })
    .catch((error) => {
      console.log(
        `Error in retrieving plans for category id of ${categoryId}`,
        error
      );
      dispatch(setIsLoadingPlan(false));
      // error.response.status === 403 &&
      //   (window.location.href = 'https://account.konvey.rw/');
    });
};

// get benefits

export const getBenefits = (categoryId, planId) => (dispatch, getState) => {
  const { token } = getState().user;

  dispatch(setIsLoadingBenefit(true));
  axios
    .get(
      APP.API_ADVERTISER_URL +
        `/admin/vas/subscriptions/categories/${categoryId}/plans/${planId}/benefits`,
      config(token)
    )
    .then((res) => {
      dispatch(setBenefit(res.data.data));
      dispatch(setIsLoadingBenefit(false));
    })
    .catch((error) => {
      console.log(error);
      dispatch(setIsLoadingBenefit(false));
      // error.response.status === 403 &&
      //   (window.location.href = 'https://account.konvey.rw/');
    });
};
