import React from 'react';

const SmsDetails = ({ data, highlightedDays, fileDisplay }) => {
  console.log(data, 'data to display');
  return (
    <div className="flex flex-col border border-gray rounded-lg p-2 mb-4 text-xl">
      <div className="flex lg:flex-row flex-col  mt-1 gap-1 lg:items-center">
        <h1 className="text-light  font-bold  w-fit m-0 p-0 text-sm">
          sender name
        </h1>
        <h2 className="text-black  m-0 p-0 text-lg">
          {data[0]?.sender_name?.name}
        </h2>
      </div>

      {/* <div
        className={`${
          item?.is_app_installation ? 'flex' : 'hidden'
        } flex-col  gap-1  mt-2`}
      >
        {item?.app_installation?.app_details?.map((app, i) => {
          return (
            <div className="flex flex-row gap-2">
              <div className="flex flex-row ">
                <h1 className="text-light  font-bold text-xl w-fit ">
                  App name:
                </h1>
                <h2 className="text-black text-xl ">{app?.name} ,</h2>
              </div>
              <div className="flex flex-row ">
                <h1 className="text-light  font-bold text-xl w-fit ">
                  App link:
                </h1>
                <h2 className="text-black text-xl">{app?.link}.</h2>
              </div>
            </div>
          );
        })}
      </div> */}

      <div className={'flex flex-col lg:flex-row gap-1 mt-2'}>
        <h1 className="text-light  font-bold text-sm w-fit">Sms Text:</h1>
        <h2 className="text-black text-lg">{data[0]?.sms_text}</h2>
      </div>
      <div className="flex lg:flex-row  lg:gap-1 lg:items-center flex-col">
        <h1 className="text-light  font-bold text-sm w-fit my-2">
          Target audience:
        </h1>
        {data[0]?.audiences?.map((audience, i) => {
          return (
            <h2 className="flex text-black text-lg m-0 p-0 gap-1">
              {audience.audience?.name}
              <span className="text-light ">
                {data[0]?.audiences?.length - 1 === i ? '.' : ','}
              </span>
            </h2>
          );
        })}
      </div>

      {/* <div
        className={` ${
          item?.is_scheduled ? 'hidden' : 'flex'
        } lg:flex-row flex-col lg:gap-2`}
      >
        <div className="flex lg:flex-row  gap-1 items-center">
          <h1 className="text-light  font-bold text-sm w-fit my-2">From:</h1>
          <h2 className="text-black text-sm"> {item?.from}</h2>
        </div>
        <div className="flex flex-row  gap-1 items-center">
          <h1 className="text-light  font-bold text-sm w-fit my-2">To:</h1>
          <h2 className="text-black text-sm"> {item?.to}</h2>
        </div>
      </div> */}

      <div className={`flex flex-col`}>
        <div className="flex lg:flex-row  gap-1 lg:items-center flex-col">
          <h1 className="text-light  font-bold text-sm w-fit my-2">
            Schedule type:
          </h1>

          <h2 className="text-black text-lg "> {data[0].schedule_type}</h2>
        </div>

        <div className="flex lg:flex-row flex-col lg:gap-8">
          <div className="flex flex-row  gap-1 items-center">
            <h1 className="text-light  font-bold text-sm w-fit my-2">From:</h1>
            <h2 className="text-black  text-sm"> {data[0].start_date}</h2>
          </div>
          <div className="flex flex-row  gap-1 items-center">
            <h1 className="text-light  font-bold text-sm w-fit my-2">To:</h1>
            <h2 className="text-black text-sm"> {data[0].end_date}</h2>
          </div>
        </div>

        <div className={`flex flex-col text-sm`}>
          <h1 className="text-light  font-bold text-sm w-fit my-2">
            Dates and time
          </h1>
          <div className="flex flex-row flex-wrap">
            {data[0]?.schedules?.map((day, i) => {
              return (
                <h2 className="flex text-black text-sm m-0 p-0 gap-2">
                  {day?.date}
                  <span className="text-light mr-2">
                    ({day?.time})
                    {data[0]?.schedules.length - 1 === i ? '.' : ','}
                  </span>
                </h2>
              );
            })}
          </div>
        </div>

        {/* <div
          className={`${
            item?.schedule?.schedule_type === 'custom' ? 'flex' : 'hidden'
          } flex-col`}
        >
          <h1 className="text-light  font-bold text-xl w-fit my-2">
            Dates and time
          </h1>
          <div className="flex flex-row flex-wrap">
            {highlightedDays?.days?.map((day, i) => {
              return (
                <h2 className="flex text-black text-xl m-0 p-0 gap-1">
                  {day?.date}
                  <span className="text-light ">
                    ({day?.time})
                    {highlightedDays?.days?.length - 1 === i ? '.' : ','}
                  </span>
                </h2>
              );
            })}
          </div>
        </div> */}
      </div>
      <div className="flex flex-col space-y-2 mt-2">
        <div className="flex flex-col lg:flex-row space-x-2 lg:items-center">
          <h1 className="text-light font-bold text-sm w-fit">Price:</h1>{' '}
          <p className="text-sm">
            {JSON.parse(data[0].pricing_details).total_price} RWF
          </p>
        </div>
        <div className="flex flex-col lg:flex-row space-x-2 lg:items-center">
          <h1 className="text-light font-bold text-sm w-fit">Amount paid:</h1>{' '}
          <p className="text-sm"></p>
        </div>
        <div className="flex flex-col lg:flex-row space-x-2 lg:items-center">
          <h1 className="text-light font-bold text-sm w-fit">
            Payment method:
          </h1>{' '}
          <p className="text-sm font-bold">Bank</p>{' '}
        </div>
        <div className="flex flex-col">
          <p className="text-light font-bold text-sm">Campaign files:</p>
          <div className="flex flex-row space-x-2">
            {/* {fileDisplay(item?.files)} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmsDetails;
