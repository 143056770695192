import React from 'react';
import Modal from '../../components/modal/Modal';
const FeedbackModal = ({ open, setOpen, data }) => {
  return (
    <Modal
      isOpen={open}
      onClose={() => setOpen(!open)}
      Style={'w-3/4 lg:w-1/2 m-auto h-fit m-4 flex z-[10]'}
    >
      <Modal.Body>
        <div className="flex flex-col">
          <p>{data}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={() => setOpen(!open)}>Close</button>
      </Modal.Footer>
    </Modal>
  );
};

export default FeedbackModal;
