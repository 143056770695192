import axios from 'axios';
import { APP } from '../../utils/constant';

export const SET_IS_FETCHING_BANKS = 'SET_IS_FETCHING_BANKS';
export const FETCH_BANKS = 'FETCH_BANKS';
export const SET_IS_FETCHING_BANK_ACCOUNTS = 'SET_IS_FETCHING_BANK_ACCOUNTS';
export const FETCH_BANK_ACCOUNTS = 'FETCH_BANK_ACCOUNTS';

export const SET_IS_FETCHING_BANK_ACCOUNT_PAYMENT =
  'SET_IS_FETCHING_BANK_ACCOUNT_PAYMENT';
export const FETCH_BANK_ACCOUNT_PAYMENT = 'FETCH_BANK_ACCOUNT_PAYMENT';

export const setIsFetchingBanks = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_BANKS,
    payload,
  });
};

export const setFetchedBanks = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_BANKS,
    payload,
  });
};

export const setIsFetchingBankAccounts = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_BANK_ACCOUNTS,
    payload,
  });
};

export const setFetchedBankAccounts = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_BANK_ACCOUNTS,
    payload,
  });
};

export const fetchBankAccounts = (bankId) => (dispatch, getState) => {
  const { token } = getState().user;
  dispatch(setIsFetchingBankAccounts(true));
  axios
    .get(APP.API_MAIN_URL + `/admin/banks/${bankId}/accounts`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      dispatch(setIsFetchingBankAccounts(false));

      dispatch(setFetchedBankAccounts(res.data.data));
    })
    .catch((error) => {
      dispatch(setIsFetchingBankAccounts(false));
      console.log(error);
    });
};

export const fetchBanks = () => (dispatch, getState) => {
  const { token } = getState().user;
  dispatch(setIsFetchingBanks(true));

  axios
    .get(APP.API_MAIN_URL + `/admin/banks`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      dispatch(setIsFetchingBanks(false));
      if (res.data.data.length > 0) {
        dispatch(setFetchedBanks(res.data.data));
        dispatch(fetchBankAccounts(res.data.data[0].id));
      } else {
        dispatch(setFetchedBanks(res.data.data));
      }
    })
    .catch((error) => {
      dispatch(setIsFetchingBanks(false));
      console.log(error);
    });
};

export const setIsFetchingBankAccountPayment = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_BANK_ACCOUNT_PAYMENT,
    payload,
  });
};

export const setFetchedBankAccountPayment = (payload) => (dispatch) => {
  dispatch({
    type: FETCH_BANK_ACCOUNT_PAYMENT,
    payload,
  });
};

export const fetchBankAccountPayment =
  (bankId, bankAccountId) => (dispatch, getState) => {
    const { token } = getState().user;
    dispatch(setIsFetchingBankAccountPayment(true));
    axios
      .get(
        APP.API_MAIN_URL +
          `/admin/banks/${bankId}/accounts/${bankAccountId}/payments`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        dispatch(setIsFetchingBankAccountPayment(false));

        dispatch(setFetchedBankAccountPayment(res.data.data));
      })
      .catch((error) => {
        dispatch(setIsFetchingBankAccountPayment(false));
        console.log(error);
      });
  };
