import React, { useState } from "react";
import axios from "axios";
import Modal from "../../../../components/modal/Modal";
import Button from "../../../../components/Button";

import { RiErrorWarningFill } from "react-icons/ri";
import { APP, config } from "../../../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import {
  getBenefits,
  getCategory,
  getPlans,
} from "../../../../redux/actions/subscription";
import { ToastComponent } from "../../../../components/Toast";

const DeleteBenefit = ({
  openModal,
  handleModal,
  categoryId,
  planId,
  benId,
}) => {
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const handleDeleteBenefit = () => {
    setLoading(true);
    axios
      .delete(
        APP.API_MAIN_URL +
          `/admin/vas/subscriptions/categories/${categoryId}/plans/${planId}/benefits/${benId}`,
        config(token)
      )
      .then((res) => {
        setLoading(false);

        notify("success", "Benefit deleted");
        dispatch(getCategory());
        dispatch(getBenefits(categoryId, planId));
        handleModal();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        notify("error", error.response.data.message);
      });
  };

  return (
    <Modal
      isOpen={openModal}
      onClose={handleModal}
      Style={"w-max h-max m-auto flex mx-auto items-center justify-center"}
    >
      <Modal.Body>
        <div className="flex flex-col space-y-4 font-bold">
          <div className="w-full flex items-center justify-center">
            <RiErrorWarningFill size={40} color="#FF3333" />
          </div>
          <div>
            <h2>Confirm if you want to delete this Benefit</h2>
          </div>
          <div className="flex flex-row w-full justify-between">
            <Button
              type="button"
              content="Cancel"
              loading={false}
              btnColor="primary"
              Style={
                "text-black w-max h-[40px] mt-2 ml-0 bg-white border rounded-sm cursor-pointer"
              }
              onClick={() => {
                handleModal();
              }}
            />
            <Button
              type="button"
              content="Delete"
              loading={loading}
              btnColor="primary"
              onClick={() => {
                handleDeleteBenefit();
              }}
              Style={"text-white w-max h-[40px] mt-2 ml-0 cursor-pointer"}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteBenefit;
