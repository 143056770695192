import React, { useState, useRef, useEffect } from 'react';
import Axios from '../../components/Axios';
import { useForm } from 'react-hook-form';
import Button from '../../components/Button';
import Modal from '../../components/modal/Modal';
import Input from '../../components/Input';
import { ToastComponent, ToasterComponent } from '../../components/Toast';
import Drag from '../../components/Drag';
import Select from '../../components/Select';
import { APP } from '../../utils/constant';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBankAccounts } from '../../redux/actions/bank';

// import { token } from '../../Routes/AdvertiserRoutes';

const EditBankAccount = ({
  bankId,
  bankData,
  bankAccountId,
  openModal,
  handleEditBankAccountModal,
  isError,
  config,
  currency,
  getAccounts,
  editBankAccountData,
  setEditBankAccountData,
}) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [dataToEdit, setDataToEdit] = useState([]);

  const { token } = useSelector((state) => state.user);

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };
  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'inactive' },
  ];

  const getBankAccountToEdit = (id) => {
    Axios.get(APP.API_MAIN_URL + `/admin/banks/${id}`, config)
      .then((res) => {
        console.log('single bank Account', res.data.data);
        setDataToEdit(res.data.data);
      })
      .catch((error) => {
        console.log('Error in fetching single bank Account', error);
      });
  };

  const handleName = (e) => {
    const { value } = e.target;
    setEditBankAccountData((prevData) => {
      return {
        ...prevData,
        account_name: value,
      };
    });
  };

  const handleAccountNumber = (e) => {
    const { value } = e.target;
    setEditBankAccountData((prevData) => {
      return {
        ...prevData,
        account_number: value,
      };
    });
  };

  const handlestatus = (e) => {
    setEditBankAccountData((prevData) => {
      return {
        ...prevData,
        status: e.value,
      };
    });
  };

  const onSubmit = () => {
    setLoading(true);
    Axios.put(
      APP.API_MAIN_URL +
        `/admin/banks/${editBankAccountData.bank_id}/accounts/${bankAccountId}`,
      editBankAccountData,
      config
    )
      .then((res) => {
        setLoading(false);
        dispatch(fetchBankAccounts(editBankAccountData.bank_id));
        reset();
        handleEditBankAccountModal();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        notify('error', error.response.data.message);
      });
  };

  return (
    <>
      <ToasterComponent />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal
          isOpen={openModal}
          onClose={() => {
            reset();
            handleEditBankAccountModal();
          }}
          Style={'w-[90%] lg:w-1/3  flex m-auto py-4'}
        >
          <Modal.Header>{'Edit Account'}</Modal.Header>
          <Modal.Body>
            <div className="flex flex-col space-y-4 my-auto">
              <Input
                labelText={'Account name'}
                labelFor={'account_name'}
                name={`account_name`}
                type={'text'}
                customClass="w-full my-1"
                onChange={(e) => {
                  handleName(e);
                }}
                register={register}
                errors={errors}
                value={editBankAccountData?.account_name}
              />
              <Input
                labelText={'Account Number'}
                labelFor={'account_number'}
                name={`account_number`}
                type={'text'}
                isRequired={false}
                customClass="w-full my-2"
                onChange={(e) => {
                  handleAccountNumber(e);
                }}
                register={register}
                errors={errors}
                value={editBankAccountData?.account_number}
              />
              <Select
                label={'Status'}
                labelFor={'status'}
                className="w-full"
                name="status"
                isLoading={false}
                placeholder={'Select status'}
                value={statusOptions.find(
                  (option) => option.value === editBankAccountData.status
                )}
                onChange={(e) => {
                  handlestatus(e);
                }}
                options={statusOptions}
              />

              <Button
                type="submit"
                content={'Update'}
                loading={loading}
                btnColor="primary"
                Style={'right-[0px] bottom-[0px] text-white w-full p-8'}
              />
            </div>
          </Modal.Body>
        </Modal>
      </form>
    </>
  );
};

export default EditBankAccount;
